import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../../theme/colors';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  header: {
    backgroundColor: '#F6F6F6',
    paddingTop: 70,
    paddingBottom: 18,
    alignItems: 'center',
  },
  textBoldGray: {
    color: '#6B6B6B',
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
  },
  textBoldSmallGray: {
    color: '#6B6B6B',
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 14,
  },
  textBold: {
    color: '#000000',
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
  },
  optionContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 33,
    paddingLeft: 23,
    paddingRight: 27,
    borderBottomColor: '#00000014',
    borderBottomWidth: 1,
  },
  policyContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 17,
    paddingLeft: 28,
    borderBottomColor: '#00000014',
    borderBottomWidth: 1,
  },
  logoutContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'absolute',
    bottom: 80,
    paddingLeft: 25,
  },
});
