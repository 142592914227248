import { styles } from './style';
import { Text, View } from 'react-native';
import Status from './status';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';

const HistoryHeader = ({ ...props }) => {
  return (
    <View style={styles.accordionHeader}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Text style={styles.accordionHeaderTitle}>{props.title}</Text>
        <Text style={styles.accordionHeaderScript}>{props.time}</Text>
      </View>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Status status={props.status} />
        {props.active ? (
          <FiChevronUp
            name="up"
            style={{
              fontSize: 14,
              paddingLeft: 6,
            }}
          />
        ) : (
          <FiChevronDown
            name="down"
            style={{
              fontSize: 14,
              paddingLeft: 6,
            }}
          />
        )}
      </View>
    </View>
  );
};

export default HistoryHeader;
