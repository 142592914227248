import React from 'react';
import { GoogleMap, Marker, withScriptjs, withGoogleMap } from 'react-google-maps';
const EnvConstants = require('../../../EnvConstants');

const Map = ({ latitude, longitude, markers }) => (
  console.log('markers: ', markers),
  (
    <GoogleMap
      defaultZoom={15} // Adjust the initial zoom level
      defaultCenter={{ lat: latitude, lng: longitude }} // Set the initial map center using props
    >
      {markers == null ? (
        <Marker
          position={{ lat: latitude, lng: longitude }} // Set the marker position using props
          title="Site Location" // Add a title to the marker (optional)
        />
      ) : (
        markers.map((project, index) => (
          <Marker
            key={index}
            position={{
              lat: project.address.lat,
              lng: project.address.lng,
            }}
            onPress={() => setSelectedProject(project._id)}
          />
        ))
      )}
    </GoogleMap>
  )
);

// Wrap the Map component with the required HOCs
const WrappedMap = withScriptjs(withGoogleMap(Map));

const GoogleMapWithMarker = ({ latitude, longitude, markers = null }) => (
  <div style={{ width: '100%', height: '600px' }}>
    <WrappedMap
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${EnvConstants.config.GMAPS_API}&v=3.exp&libraries=geometry,drawing,places`} // Replace with your Google Maps API key
      loadingElement={<div style={{ height: '100%' }} />}
      containerElement={<div style={{ height: '100%' }} />}
      mapElement={<div style={{ height: '100%' }} />}
      latitude={latitude} // Pass the latitude as a prop
      longitude={longitude} // Pass the longitude as a prop
      markers={markers}
    />
  </div>
);

export default GoogleMapWithMarker;
