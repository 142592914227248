import {Dimensions, StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
    assetHistoryRow:{
      flexDirection:"row",
      justifyContent:"space-between",
      alignItems:"center",
      marginBottom:12
    },
    plainStatus: {
      fontSize:12,
      color:"#6B6B6B",
      fontFamily:"NunitoSans_300Light",
      marginRight:8
    },
    failText:{
      fontSize:12,
      color:"#FF4D4D",
      fontFamily:"NunitoSans_300Light",
      marginRight:8
    },
    passTest: {
      fontSize:12,
      color:"#046836",
      fontFamily:"NunitoSans_300Light",
      marginRight:8
    },
  });