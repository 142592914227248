import { Dimensions, StyleSheet, Platform } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
  },
  qr: {
    height: '100%',
    position: 'absolute',
    width: '100%',
    overflow: 'hidden',
  },
  code: {
    height: '100%',
    width: '100%',
    position: 'relative',
    // backgroundColor: 'rgba(0,0,0,0.3)',
  },
  qrFade: {
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0,0.3)',
  },
  qrBackground: {
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
  qrBorderImage: {
    width: 280,
    height: 280,
    tintColor: 'white',
    marginLeft: 'auto',
    marginRight: 'auto',
    tintColor: 'white',
  },
  content: {
    left: 0,
    right: 0,
    top: 160,
    bottom: 0,
    alignItems: 'center',
    zIndex: 9999,
  },
  qrText: {
    color: 'white',
    fontSize: 18,
    left: 0,
    right: 0,
    top: 250,
    bottom: 0,
    textAlign: 'center',
  },
  windowScanner: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 320,
    left: 0,
    right: 0,
  },
  enable: {
    flexDirection: 'row',
    backgroundColor: '#ffffff',
    width: 100,
    height: 40,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textEnable: {
    color: '#132FF5',
    textAlign: 'center',
    fontWeight: '600',
    fontSize: 18,
  },
  disable: {
    flexDirection: 'row',
    backgroundColor: 'transparent',
    width: 100,
    height: 40,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textDisable: {
    color: 'white',
    fontWeight: '600',
    fontSize: 18,
    textAlign: 'center',
  },
  inputTextCode: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  input: {
    borderColor: 'gray',
    width: 250,
    borderWidth: 1,
    borderRadius: 10,
    padding: 10,
    top: 270,
    textAlign: 'center',
  },
  button: {
    left: 0,
    right: 0,
    top: 30,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  codeSubmit: {
    flexDirection: 'row',
    backgroundColor: '#ffffff',
    width: 100,
    height: 40,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textSubmit: {
    color: '#132FF5',
    textAlign: 'center',
    fontWeight: '600',
    fontSize: 18,
  },
  switch: {
    flexDirection: 'row',
    backgroundColor: '#6c6b6b',
    width: 200,
    height: 40,
    borderRadius: 20,
  },
  ModalContainer: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  popupWindow: {
    backgroundColor: 'white',
    paddingHorizontal: 22,
    paddingBottom: 20,
    borderRadius: 12,
    marginHorizontal: 26,
  },
  popupClose: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
  popupCloseIcon: {
    width: 25,
    height: 25,
  },
  popupHeader: {
    alignItems: 'center',
  },
  confirmationTileIcon: {
    width: 70,
    height: 70,
    borderRadius: 16,
    marginTop: 8,
  },
  blueButton: {
    borderRadius: 8,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
  },
  blueButtonText: {
    color: 'white',
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    textAlign: 'center',
  },
  blueBorderButton: {
    borderRadius: 8,
    borderColor: '#132FF5',
    borderWidth: 1,
    paddingVertical: 10,
    marginTop: 8,
  },
  blueBorderButtonText: {
    color: '#132FF5',
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    textAlign: 'center',
  },
  blankButton: {
    paddingVertical: 15,
  },
  blankButtonText: {
    color: '#6B6B6B',
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 18,
    textAlign: 'center',
  },
  historyDetailInfo: {
    backgroundColor: 'rgba(0,0,0,0.1)',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 12,
    borderRadius: 16,
  },
  historyDetailPosition: {
    backgroundColor: '#FFFFFF',
    borderRadius: 16,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    paddingHorizontal: 10,
  },
  historyDetailLocation: {
    backgroundColor: 'rgba(0,0,0,0.1)',
    paddingVertical: 12,
    paddingHorizontal: 21,
    borderRadius: 16,
  },
  img: {
    width: 35,
    height: 35,
  },
  bottomNav: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 32,
    paddingVertical: 14,
  },
  topNavigate: {
    top: 80,
    alignItems: 'center',
    zIndex: 9999,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 23,
  },
  appbarTrailingIcon: {
    alignSelf: 'flex-end',
    width: 24,
    height: 24,
    marginRight: 16,
  },
  notiBlock: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '143%',
  },
  notiContent: {
    backgroundColor: 'rgba(255,255,255,0.38)',
    borderRadius: 8,
    paddingHorizontal: 46,
    paddingVertical: 4,
    flexDirection: 'row',
    alignItems: 'center',
  },
  textBoldLarge: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
  },
  textBold: {
    fontFamily: 'NunitoSans_700Bold',
  },
  textRegular: {
    fontFamily: 'NunitoSans_400Regular',
  },
  textRegularSmall: {
    fontFamily: 'NunitoSans_400Regular',
    fontSize: 12,
  },
  script: {
    marginTop: 8,
    marginBottom: 9,
    fontFamily: 'NunitoSans_400Regular',
    textAlign: 'center',
  },
});
