import { styles } from "./style";
import { Button, Text, View } from "react-native";

const InfoRow = ({ ...props }) => {
    return (
        <View style={styles.assetInforRow}>
            <Text style={styles.assetInforTitle}>{props.title}</Text>
            {props.content}
        </View>
    );
};

export default InfoRow;