import { styles } from "./style";
import { Button, Text, View } from "react-native";

const Status = ({ ...props }) => {
  let status = props.status;
  return (
    <View style={status == "Pass" ? styles.status : styles.statusRowFail}>
      <Text
        style={[
          status == "Pass" ? styles.statusText : styles.statusTextFail,
          {
            fontSize: 16,
          },
        ]}
      >
        •
      </Text>
      <Text
        style={[
          status == "Pass" ? styles.statusText : styles.statusTextFail,
          {
            fontSize: 12,
          },
        ]}
      >
        {props.status}
      </Text>
    </View>
  );
};

export default Status;
