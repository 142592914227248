import React, { useState, useEffect } from 'react';
import { Text, View, SafeAreaView, Image, ScrollView, TouchableOpacity } from 'react-native';
import { styles } from './style';
import NavigationHeader from '../../../../components/SignUp/SingUpNavigation';
import Button from '../../../../components/Button';
import AsyncStorage from '@react-native-async-storage/async-storage';

export default function SubscriptionCheckout({ ...props }) {
  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.loading}>
        <View style={styles.loadPercent}></View>
      </View>
      <NavigationHeader
        onPress={async () => {
          await AsyncStorage.multiRemove(['user', 'token', 'refreshToken', 'expiresIn']);
          props.navigation.navigate('GuestNavigation', { screen: 'QRCodeScan' });
        }}
        header={'Sign Up'}
        navigate={'Cancel'}
        menuStyles={styles.menu}
      />
      <View
        style={{
          marginHorizontal: 62,
          alignItems: 'center',
        }}
      >
        <Text style={styles.title}>Checkout</Text>
        <Text style={styles.script}>Please add a payment method to move forward.</Text>
      </View>
      <TouchableOpacity
        onPress={() => {
          props.navigation.navigate('JoinProject', {
            userImage: props.route.params.userImage,
            userType: props.route.params.userType,
            userLicence: props.route.params.userLicence,
            appUse: props.route.params.appUse,
            subscription: props.route.params.subscription,
            registerForPushNotificationsAsync: props.route.params.registerForPushNotificationsAsync,
          });
        }}
      >
        <Text
          style={{
            marginTop: 40,
            textAlign: 'center',
          }}
        >
          Next Step
        </Text>
      </TouchableOpacity>
      <View style={styles.assignButton}>
        <Button
          title={'Previous'}
          buttonStyle={[styles.blankButton, { marginBottom: 20 }]}
          textStyle={styles.blankButtonText}
          onPress={() => props.navigation.goBack()}
        />
      </View>
    </SafeAreaView>
  );
}
