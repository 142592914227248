import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../../theme/colors';

export const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
  },
  qr: {
    height: '100%',
    position: 'absolute',
    width: '100%',
    overflow: 'hidden',
  },
  code: {
    height: '100%',
    width: '100%',
    position: 'relative',
    // backgroundColor: 'rgba(0,0,0,0.3)',
  },
  qrFade: {
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0,0.3)',
  },
  qrBackground: {
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
  qrBorderImage: {
    width: 280,
    height: 280,
    tintColor: 'white',
    marginLeft: 'auto',
    marginRight: 'auto',
    tintColor: 'white',
  },
  content: {
    left: 0,
    right: 0,
    top: 160,
    bottom: 0,
    alignItems: 'center',
    zIndex: 9999,
  },
  qrText: {
    color: 'white',
    fontSize: 18,
    left: 0,
    right: 0,
    top: 250,
    bottom: 0,
    textAlign: 'center',
  },
  windowScanner: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 320,
    left: 0,
    right: 0,
  },
  enable: {
    flexDirection: 'row',
    backgroundColor: '#ffffff',
    width: 100,
    height: 40,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textEnable: {
    color: '#132FF5',
    textAlign: 'center',
    fontWeight: '600',
    fontSize: 18,
  },
  disable: {
    flexDirection: 'row',
    backgroundColor: 'transparent',
    width: 100,
    height: 40,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textDisable: {
    color: 'white',
    fontWeight: '600',
    fontSize: 18,
    textAlign: 'center',
  },
  inputTextCode: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  input: {
    borderColor: 'gray',
    width: 250,
    borderWidth: 1,
    borderRadius: 10,
    padding: 10,
    top: 270,
    textAlign: 'center',
  },
  button: {
    left: 0,
    right: 0,
    top: 30,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  codeSubmit: {
    flexDirection: 'row',
    backgroundColor: '#ffffff',
    width: 100,
    height: 40,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textSubmit: {
    color: '#132FF5',
    textAlign: 'center',
    fontWeight: '600',
    fontSize: 18,
  },
  switch: {
    flexDirection: 'row',
    backgroundColor: '#6c6b6b',
    width: 200,
    height: 40,
    borderRadius: 20,
  },
  buttonContainer: {
    position: 'absolute',
    bottom: 50,
    width: '100%',
  },
  blueButtonActive: {
    borderRadius: 8,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
    marginHorizontal: 20,
  },
  blueButtonText: {
    color: colors.white,
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 18,
    textAlign: 'center',
  },
});
