import React, { useState, useEffect } from 'react';
import { SafeAreaView, View, ScrollView, Text } from 'react-native';
import { styles } from './style';
import Button from '../../../../../components/Button';
import CommonHeader from '../commonHeader';
import { StatusBar } from 'expo-status-bar';
import Loading from '../../../../../components/Loading';
import { useIsFocused } from '@react-navigation/native';

export default function TestAssetFlowStep2({ ...props }) {
  const params = props.route?.params;
  const asset = params.asset;
  const isFocused = useIsFocused();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isFocused) {
      if (params.selectedValues) {
        let passed = true;
        for (let sv = 0; sv < Object.values(params.selectedValues).length; sv++) {
          const element = Object.values(params.selectedValues)[sv];
          if (element.status == 'Fail') {
            passed = false;
            setLoading(false);
          }
        }

        if (passed) {
          props.navigation.navigate('TestAssetFlowStep3', {
            status: 'Pass',
            ...params,
          });
        }
      }
    }
  }, [isFocused]);

  return (
    <SafeAreaView style={styles.container}>
      <StatusBar style="dark" />
      <View style={styles.loading}>
        <View style={styles.loadPercent}></View>
      </View>
      <CommonHeader
        onPress={() =>
          props.navigation.navigate('QrCodeNav', {
            screen: 'AssetInfo',
            isAdmin: params.isAdmin,
          })
        }
        asset={asset}
      />
      {loading ? (
        <Loading opacity={0} />
      ) : (
        <View style={styles.body}>
          <View style={styles.boxContent}>
            <Text style={styles.title}>Over all Condition</Text>
            <View style={styles.assetStatus}>
              <Text
                style={[
                  styles.assetStatusText,
                  {
                    fontSize: 16,
                    marginRight: 4,
                  },
                ]}
              >
                •
              </Text>
              <Text
                style={[
                  styles.assetStatusText,
                  {
                    fontSize: 12,
                  },
                ]}
              >
                Fail
              </Text>
            </View>
            <Button
              title={'Book for Repair'}
              buttonStyle={[styles.blueButton, { marginBottom: 10 }]}
              textStyle={styles.blueButtonText}
              onPress={() => {
                props.navigation.navigate('TestAssetFlowStep3', {
                  status: 'Needs Repair',
                  ...params,
                });
              }}
            />
            <Button
              title={'Mark as disposed'}
              buttonStyle={[styles.blueBorderButton, { marginBottom: 10 }]}
              textStyle={styles.blueBorderButtonText}
              onPress={() =>
                props.navigation.navigate('TestAssetFlowStep3', {
                  status: 'Disposed',
                  ...params,
                })
              }
            />
          </View>
          <Button
            title={'Back'}
            buttonStyle={[styles.blankButton, { marginBottom: 10 }]}
            textStyle={styles.blankButtonText}
            onPress={() => props.navigation.goBack()}
          />
        </View>
      )}
    </SafeAreaView>
  );
}
