import React, { useState, useEffect } from 'react';
import { Text, TouchableOpacity, View, Pressable } from 'react-native';
import Autocomplete from '../../../../components/AutoComplete/autoComplete';
import { styles } from './style';
import Tags from './tags';
import Subscription from './subscription';
import Billing from './billing';
import { api } from '../../../../utils/api';
import { useIsFocused } from '@react-navigation/core';

function SubscriptionBilling({ ...props }) {
  const { navigation } = props;
  const { initialViewOption, fromNotifications, company } = props.route.params;
  const [companyInput, setCompanyInput] = useState(company ? { ...company, id: company._id } : '');
  const [companyList, setCompanyList] = useState([]);
  const [isSelect, setIsSelect] = useState(false);
  const [viewOptions, setViewOptions] = useState(initialViewOption ? initialViewOption : 'Subcription');

  const handleBackPress = () => {
    if (fromNotifications) {
      navigation.getParent().setParams({
        screen: 'AccountSettings',
        isAdmin: true,
      });
      navigation.navigate('Notifications', {
        screen: 'NotificationsPage',
        params: { isAdmin: props.route.params.isAdmin },
      });
    } else {
      navigation.navigate('SettingNavigate', {
        screen: 'AccountSettings',
        isAdmin: true,
      });
    }
  };

  const getCompanies = async () => {
    const response = await api('v1/user/companies', 'GET', null);
    if (response.status == 200 && response.data.success) {
      const companies = response.data.companies;
      const tempCompanyList = [];
      for (const company of companies) {
        tempCompanyList.push({ ...company, title: company.name, id: company._id });
      }
      setCompanyList(tempCompanyList);
      if (companyInput === '' && tempCompanyList.length > 0) {
        setCompanyInput(tempCompanyList[0]);
      }
    }
  };

  const updateCompanyAndList = (updatedCompany) => {
    setCompanyInput(updatedCompany);

    const newCompanyList = companyList.map((company) => {
      if (company.id === updatedCompany.id) {
        return updatedCompany;
      } else {
        return company;
      }
    });
    setCompanyList(newCompanyList);
  };

  const isFocused = useIsFocused();
  useEffect(() => {
    getCompanies();
  }, [isFocused]);

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <View style={styles.headerTitle}>
          <TouchableOpacity onPress={handleBackPress}>
            <Text style={styles.textBold}>Back</Text>
          </TouchableOpacity>

          <Text style={styles.textBoldGray}>Subscription & Billing</Text>
        </View>
        <Autocomplete
          value={companyInput}
          setValue={setCompanyInput}
          data={companyList}
          onChange={() => setIsSelect(!isSelect)}
          placeholder="Enter company name"
          style={{ flex: 1 }}
          containerStyle={styles.inputSearchDrop}
          disableAdd={true}
        />
        {companyInput && companyInput.id && (
          <View style={styles.viewOptionsContainer}>
            <Pressable onPress={() => setViewOptions('Subcription')}>
              <View>
                <Text style={viewOptions == 'Subcription' ? styles.blueBoldText : styles.grayBoldTextView}>
                  Subcription plan
                </Text>
                {viewOptions == 'Subcription' && <View style={[styles.activeBorder, { width: '90%' }]}></View>}
              </View>
            </Pressable>
            <Pressable onPress={() => setViewOptions('Tag')}>
              <View>
                <Text style={viewOptions == 'Tag' ? styles.blueBoldText : styles.grayBoldTextView}>Tags</Text>
                {viewOptions == 'Tag' && <View style={[styles.activeBorder, { width: '90%' }]}></View>}
              </View>
            </Pressable>
            <Pressable onPress={() => setViewOptions('Billing')}>
              <View>
                <Text style={viewOptions == 'Billing' ? styles.blueBoldText : styles.grayBoldTextView}>
                  Billing details
                </Text>
                {viewOptions == 'Billing' && <View style={[styles.activeBorder, { width: '90%' }]}></View>}
              </View>
            </Pressable>
          </View>
        )}
      </View>

      {viewOptions == 'Subcription' && companyInput && companyInput.id && (
        <Subscription company={companyInput} updateCompanyAndList={updateCompanyAndList} {...props} />
      )}
      {viewOptions == 'Tag' && companyInput && companyInput.id && (
        <Tags company={companyInput} updateCompanyAndList={updateCompanyAndList} {...props} />
      )}
      {viewOptions == 'Billing' && companyInput && companyInput.id && (
        <Billing company={companyInput} updateCompanyAndList={updateCompanyAndList} {...props} />
      )}
    </View>
  );
}

export default SubscriptionBilling;
