import React, { useState } from "react";
import Accordion from "react-native-collapsible/Accordion";
import HistoryContent from "./historyContent";
import HistoryHeader from "./historyHeader";
import { styles } from "./style";
export default function AccordionContent({ ...props }) {
  const { id, title, section, selectedValues, user, asset, status } = props;
  const [activeSections, setActiveSections] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const renderHeader = (section) => {
    return <HistoryHeader {...section} active={isActive} status={status} />;
  };

  const renderContent = (section) => {
    return <HistoryContent 
      results={selectedValues}
      user={user}
      asset={asset}
    />;
  };

  const updateSections = (activeSections) => {
    setActiveSections(activeSections);
    setIsActive(!isActive);
  };
  return (
    <Accordion
      sections={section}
      sectionContainerStyle={styles.sectionContainerStyle}
      activeSections={activeSections}
      renderHeader={renderHeader}
      renderContent={renderContent}
      onChange={updateSections}
      underlayColor="transparent"
    />
  );
}
