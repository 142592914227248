import React, { useState, useRef } from 'react';
import { Pressable, Text, View, Image } from 'react-native';
import { styles } from './style';
import { IoIosAddCircle } from 'react-icons/io';
import ModalSelect from '../../ModalSelect';

function SignUpHeader({ ...props }) {
  const [displayModal, setDisplayModal] = useState(false);
  const cameraSelectRef = useRef(null);
  const gallerySelectRef = useRef(null);

  const openOption = async (value) => {
    if (value.toLowerCase() == 'camera') {
      cameraSelectRef.current.click();
    } else if (value.toLowerCase() == 'gallery') {
      cameraSelectRef.current.click();
    }
  };

  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      // Handle the selected file here
      console.log('Selected File:', selectedFile);
      props.setUserImage(selectedFile);
    }
  };

  var localUri =
    props.userImage !== null ? (props.userImage.uri ? props.userImage.uri : props.userImage?.assets[0].uri) : '';

  return (
    <View style={styles.image}>
      <Pressable style={styles.imageDrop} onPress={() => setDisplayModal(true)} disabled={props.disablePress}>
        {props.userImage == null ? (
          <IoIosAddCircle name="add" style={styles.addIconStyle} />
        ) : (
          <Image source={{ uri: localUri }} style={styles.selectedImageStyle} />
        )}
      </Pressable>
      <Text style={[styles.textBold, props.titleStyle]}>{props.title}</Text>
      <Text style={[styles.scriptStyle, props.style]}>{props.descript}</Text>
      <ModalSelect
        modalVisible={displayModal}
        setVisible={setDisplayModal}
        setValue={openOption}
        heading="Select Camera or Gallery"
        data={['Camera', 'Gallery']}
      />
      <input ref={cameraSelectRef} type="file" style={{ display: 'none' }} onChange={handleImageChange} />
      <input ref={gallerySelectRef} type="file" style={{ display: 'none' }} onChange={handleImageChange} />
    </View>
  );
}
export default SignUpHeader;
