import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Image } from 'react-native';
import { Modal } from 'react-native';
import { Pressable } from 'react-native';
import { TextInput } from 'react-native';
import { View } from 'react-native';
import { Text } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import Button from '../../../../../components/Button';
import DropInputField from '../../../../../components/DropInputField';
import SearchBar from '../../../../../components/SearchBar';
import images from '../../../../../theme/images';
import { styles } from './style';
import { useIsFocused } from '@react-navigation/native';
import { api } from '../../../../../utils/api';
import Loading from '../../../../../components/Loading';
import { showErrorToast } from '../../../../../utils/toastUtils';
import ConfirmModal from '../../subscription/confirmModal';

function ProjectTeam({ ...props }) {
  const params = props.route.params;
  const company = params.owner;

  const [text, setText] = useState('');
  const [data, setTeamData] = useState([]);
  const [filteredData, setFilteredData] = useState();
  const [displayModal, setDisplayModal] = useState(false);
  const [sentModal, setSentModal] = useState(false);
  const [teamLoading, setTeamLoading] = useState(true);
  const [inviteEmail, setInviteEmail] = useState('');
  const [selectedDropdownItem, setSelectedDropdownItem] = useState('Select type');
  const [confirmUpgradeModalVisible, setConfirmUpgradeModalVisible] = useState(false);
  const [companyUsers, setCompanyUsers] = useState([]);

  const positionType = [
    {
      title: 'Technician',
      Tech: 'Technician',
      type: 'Tech',
    },
    {
      title: 'Admin',
      PM: 'Admin',
      type: 'PM',
    },
    {
      title: 'View Only',
      OHS: 'View Only',
      type: 'OHS',
    },
  ];

  const handleSendInvitePress = () => {
    if (validInput()) {
      const emailFound = companyUsers.find((user) => user.email === inviteEmail);
      if (!emailFound && companyUsers.length + 1 > company.subscriptionType.maxLicences) {
        setDisplayModal(false);
        setConfirmUpgradeModalVisible(true);
      } else {
        sendInvite();
      }
    }
  };

  const getTeamMembers = async () => {
    var teamMembers = [];
    const team = await api(`v1/project/${params.id}/team`, 'GET', null);
    if (team.status == 200) {
      if (team.data.success) {
        let data = team.data;
        console.log('tram data: ', data);
        for (let i = 0; i < data.team.length; i++) {
          const element = data.team[i];
          var role = '';
          for (let rt = 0; rt < positionType.length; rt++) {
            const el = positionType[rt];
            if (el[element.role]) {
              role = el[element.role];
            }
          }
          const obj = {
            name: `${element.firstName} ${element.lastName}`,
            image: element.profilePicture,
            position: role,
            id: element._id,
          };
          teamMembers.push(obj);
        }
      }
    }

    setTeamData(teamMembers);
    setFilteredData(teamMembers);
    setTeamLoading(false);
  };

  const getCompanyUsers = async () => {
    const response = await api(`v1/company/${company._id}/users`, 'GET', null);

    if (response.status === 200 && response.data.success) {
      setCompanyUsers(response.data.users);
    }
  };

  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      getTeamMembers();
      getCompanyUsers();
    }
  }, [isFocused]);

  const validInput = () => {
    return inviteEmail.length > 5 && selectedDropdownItem !== 'Select type';
  };

  const sendInvite = async () => {
    const response = await api(`v1/project/${params.id}/invite`, 'POST', {
      newUsers: 1,
      email: inviteEmail,
      type: selectedDropdownItem.type,
      projectId: params.id,
    });

    if (response.status === 200 && response.data.success) {
      setDisplayModal(true);
      setSentModal(true);
    } else {
      showErrorToast('Invite failed', 'Please try again.');
    }

    setSentModal(true);
  };

  const handleSearch = (text) => {
    setText(text);
    if (text && text.length > 0) {
      setFilteredData(data.filter((item) => item.name.toLowerCase().includes(text.toLowerCase())));
    } else if (text.length == 0) {
      setFilteredData(data);
    }
  };
  const popUpModal = (
    <Modal
      animationType="fade"
      transparent={true}
      visible={displayModal}
      onRequestClose={() => {
        setDisplayModal(false);
      }}
    >
      <View style={styles.popupModal}>
        {sentModal ? (
          <View style={styles.popupWindow}>
            <Image style={styles.popupIcon} source={images.emailSentIcon} />
            <Text style={styles.popupTitle}>Sent!</Text>
            <Text style={styles.popupScript}>Your invitation has been sent.</Text>
            <Button
              title={'Ok'}
              buttonStyle={[
                styles.modalButton,
                {
                  opacity: 1,
                  marginTop: 40,
                },
              ]}
              textStyle={styles.blueButtonText}
              onPress={() => {
                setDisplayModal(false);
                setSentModal(false);
                setInviteEmail('');
                setSelectedDropdownItem('Select type');
              }}
            />
          </View>
        ) : (
          <View style={styles.popupWindow}>
            <View style={styles.modalTitle}>
              <Text style={styles.textGrayBold}>Edit member</Text>
              <Pressable
                onPress={() => {
                  setDisplayModal(false);
                  setInviteEmail('');
                  setSelectedDropdownItem('Select type');
                }}
              >
                <Image source={images.lightGreyX} style={{ width: 14, height: 14 }} />
              </Pressable>
            </View>
            <View style={styles.modalContent}>
              <Text style={styles.popupTitle}>Email address</Text>
              <TextInput
                style={styles.input}
                placeholder="Example@gmail.com"
                placeholderTextColor="#C7C7C7"
                value={inviteEmail}
                onChangeText={(text) => setInviteEmail(text)}
              />
              <Text style={[styles.popupTitle, { marginTop: 20 }]}>User role</Text>
              <Text style={styles.textRegular}>Which role title best describes their role in this project?</Text>
              <DropInputField
                data={positionType}
                selectedDropdownItem={selectedDropdownItem}
                setSelectedDropdownItem={setSelectedDropdownItem}
                havePosition={false}
              />
            </View>
            <Button
              title={'Send Invite'}
              buttonStyle={[
                styles.modalButton,
                {
                  opacity: 1,
                  marginTop: 40,
                },
              ]}
              textStyle={styles.blueButtonText}
              onPress={handleSendInvitePress}
            />
          </View>
        )}
      </View>
    </Modal>
  );
  return (
    <View style={{ marginTop: 15, flex: 1 }}>
      <SearchBar onChangeText={(text) => handleSearch(text)} text={text} placeholder="Start typing name..." />
      <ScrollView style={styles.sectionContainer}>
        {teamLoading && (
          <View style={{ marginTop: 30 }}>
            <Loading opacity={1} />
          </View>
        )}
        {filteredData?.map((item, index) => {
          return (
            <View style={styles.teamItem} key={index}>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Image source={{ uri: item.image }} style={[styles.img, { marginRight: 15 }]} />
                <Text style={styles.textBoldMedium}>{item.name}</Text>
              </View>
              <View style={styles.positionContainer}>
                <Text style={styles.textBlueSemiBold}>{item.position}</Text>
              </View>
            </View>
          );
        })}
      </ScrollView>
      {params.hasWriteAccess && (
        <Button
          title={'Add new member'}
          buttonStyle={styles.blankButton}
          textStyle={styles.blankButtonText}
          onPress={() => {
            setDisplayModal(true);
          }}
        />
      )}
      {popUpModal}
      <ConfirmModal
        visible={confirmUpgradeModalVisible}
        setVisible={setConfirmUpgradeModalVisible}
        title={'Subscription will be upgraded'}
        details={`Sending invite will upgrade subscription for ${company.name} because the ${company.subscriptionType.value} plan only allows ${company.subscriptionType.maxLicences} licences.`}
        submitButtonText={'Continue'}
        handleSubmit={sendInvite}
      />
    </View>
  );
}

export default ProjectTeam;
