import React, { useState, useEffect } from "react";
import { Text, View } from "react-native";
import { styles } from "./style";
import Autocomplete from "./autocomplete";
export default function InputField({ ...props }) {
  const {
    data,
    onPress,
    onChangeText,
    text,
    title,
    required,
    createOption,
    createOptionPress,
    selectCompany = false,
    selectProject = false
  } = props;
  const [isSelect, setIsSelect] = useState(false);
  return (
    <View style={styles.textField}>
      {isSelect ? null : (
        <Text
          style={{
            fontFamily: "NunitoSans_700Bold",
            fontSize: 16,
          }}
        >
          {title ? title : "Project Title"}
          {required && <Text style={styles.required}> *</Text>}
        </Text>
      )}
      <Autocomplete
        value={text}
        data={data}
        onChange={title ? () => null : () => setIsSelect(!isSelect)}
        onPress={onPress}
        onChangeText={onChangeText}
        createOption={createOption ? createOption : false}
        createOptionPress={createOptionPress ? createOptionPress : null}
        selectCompany={selectCompany}
        selectProject={selectProject}
      />
    </View>
  );
}
