import { Dimensions, StyleSheet, Platform } from 'react-native';
import colors from '../../../../../theme/colors';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  loading: {
    height: 8,
    backgroundColor: 'white',
    marginTop: 24,
  },
  loadPercent: {
    height: 8,
    width: '70%',
    backgroundColor: '#132FF5',
  },
  menu: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 30,
    paddingBottom: 30,
    paddingHorizontal: 24,
    alignItems: 'center',
    backgroundColor: '#EDEDED',
  },
  body: {
    paddingHorizontal: 25,
    paddingVertical: 14,
  },
  title: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
  },
  information: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 16,
  },
  inforTitle: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 16,
  },
  input: {
    // marginTop:16,
    borderColor: '#C7C7C7',
    width: '60%',
    borderWidth: 1,
    borderRadius: 10,
    paddingVertical: 10,
    paddingLeft: 13,
  },
  textField: {
    borderWidth: 1,
    borderRadius: 8,
    borderColor: '#C7C7C7',
    paddingHorizontal: 12,
    marginTop: 16,
    paddingVertical: 13,
  },
  resultBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  resultContent: {
    // backgroundColor: "#eaedfe",
    borderWidth: 1,
    paddingVertical: 8,
    paddingHorizontal: 8,
    width: '100%',
    borderRadius: 4,
    marginTop: 16,
    borderColor: '#D0D0D0',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  resultTitle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleIcon: {
    width: 27,
    height: 27,
    tintColor: 'black',
    marginRight: 6,
  },
  titleText: {
    fontFamily: 'NunitoSans_700Bold',
    paddingLeft: 4,
  },
  resultScript: {
    fontSize: 15,
    fontFamily: 'NunitoSans_400Regular',
    paddingTop: 4,
  },
  recommendBox: {
    backgroundColor: 'white',
    flexDirection: 'column',
  },
  recommendContent: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 11,
    borderTopWidth: 1,
    // marginTop: 10,
    borderColor: '#C7C7C7',
    paddingHorizontal: 8,
    justifyContent: 'space-between',
  },
  recommendIcon: {
    width: 27,
    height: 27,
    tintColor: 'black',
  },
  searchField: {
    marginTop: 16,
    borderColor: '#C7C7C7',
    width: '100%',
    borderWidth: 1,
    borderRadius: 10,
    // paddingLeft:13,
  },
  searchIcon: {
    fontSize: 15,
    paddingRight: 12,
    opacity: 0.15,
    borderRightWidth: 1,
    height: '100%',
    marginRight: 12,
    paddingTop: Platform.OS == 'ios' ? 0 : 16,
  },
  imgPicker: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#C7C7C7',
    paddingHorizontal: 7,
    paddingVertical: 8,
    marginTop: 10,
  },
  dasherBorder: {
    borderStyle: 'dotted',
    borderRadius: 8,
    borderWidth: 2,
    borderColor: '#C7C7C7',
    paddingVertical: 20,
    alignItems: 'center',
  },
  imgIcon: {
    fontSize: 34,
    paddingLeft: 6,
    color: '#C7C7C7',
  },
  pickImage: {
    marginTop: 16,
  },
  imgPicked: {
    paddingVertical: 8,
    paddingLeft: 7,
    backgroundColor: '#8997fa1a',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#132ff5',
    borderRadius: 8,
    flex: 12,
  },
  imgPickedContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  closeIcon: {
    flex: 1,
    fontSize: 20,
    alignSelf: 'center',
    paddingLeft: 13,
    color: '#C7C7C7',
  },
  noteField: {
    marginTop: 16,
    height: 218,
    width: '100%',
    borderColor: '#C7C7C7',
    borderWidth: 1,
    borderRadius: 8,
    paddingVertical: 10,
    paddingLeft: 13,
    justifyContent: 'space-between',
  },
  textCount: {
    fontFamily: 'NunitoSans_400Regular',
    color: '#C7C7C7',
  },
  assignButton: {
    // position: "absolute",
    backgroundColor: 'white',
    width: '100%',
    marginTop: 32,
  },
  blueButtonActive: {
    borderRadius: 8,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
    marginBottom: 10,
  },
  blueButtonText: {
    color: colors.white,
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 18,
    textAlign: 'center',
  },
  blankButton: {
    paddingVertical: 10,
  },
  blankButtonText: {
    color: '#000000',
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    textAlign: 'center',
    opacity: 0.5,
  },
  blueBorderButton: {
    borderRadius: 8,
    borderColor: '#132FF5',
    borderWidth: 1,
    paddingVertical: 10,
    marginBottom: 16,
  },
  blueBorderButtonText: {
    color: colors.mainBlue,
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    textAlign: 'center',
  },
  documentPicked: {
    paddingVertical: 8,
    paddingLeft: 7,
    backgroundColor: '#8997fa1a',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#132ff5',
    borderRadius: 8,
    flex: 12,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  docIcon: {
    fontSize: 18,
    paddingRight: 6,
    color: '#132FF5',
  },
  docTitle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  content: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  position: {
    backgroundColor: 'rgba(137,151,250,0.09)',
    borderRadius: 16,
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingVertical: 3,
  },
  textSemiBoldSmall: {
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 12,
  },
});
