import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../../../theme/colors';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  header: {
    backgroundColor: '#F6F6F6',
    paddingTop: 70,
    paddingBottom: 18,
  },
  body: {
    backgroundColor: 'white',
    marginBottom: 100,

    paddingTop: 10,
  },
  screenNameContainer: {
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingLeft: 23,
    paddingRight: 27,
    flexDirection: 'row',
  },
  screenTitle: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    color: '#6B6B6B',
  },
  iconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  screenTitleIcon: {
    fontSize: 22,
  },
  imgIcon: {
    width: 22,
    height: 22,
    marginHorizontal: 13,
  },
  taskBox: {
    marginTop: 16,
    paddingVertical: 12,
    borderRadius: 8,
    backgroundColor: 'white',
    flexDirection: 'row',
    alignItems: 'center',
  },
  boxHeader: {
    flexDirection: 'row',
    paddingLeft: 14,
    justifyContent: 'space-between',
    paddingRight: 21,
    alignItems: 'center',
    flex: 1,
  },
  showMoreIcon: {
    fontSize: 14,
    paddingLeft: 6,
  },
  boxContent: {
    marginLeft: 6,
    marginRight: 12,
    paddingHorizontal: 8,
    paddingVertical: 5,
    borderRadius: 16,
    backgroundColor: '#F6F6F6',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 3,
  },
  bottomSheet: {
    paddingHorizontal: 25,
    paddingTop: 30,
    minHeight: 300,
    backgroundColor: '#F6F6F6',
  },
  textBold: {
    fontFamily: 'NunitoSans_700Bold',
  },
  flatSheetList: {
    backgroundColor: '#FFFFFF',
    minHeight: 300,
  },
});
