import React, { useState, useCallback, useEffect } from 'react';
import { Platform, SafeAreaView, ScrollView, Text, TextInput, View, Image } from 'react-native';
import Button from '../../../../components/Button';
import NavigationHeader from '../../../../components/SignUp/SingUpNavigation';
import { styles } from './style';
import Autocomplete from '../../../../components/AutoComplete/autoComplete';
import { debounce } from 'lodash';
import { api } from '../../../../utils/api';
import { showSuccessToast, showErrorToast } from '../../../../utils/toastUtils';
import images from '../../../../theme/images';

const AssignAsset = ({ ...props }) => {
  const [selectedAsset, setSelectedAsset] = useState('');
  const [filteredAssetOptions, setFilteredAssetOptions] = useState([]);

  const debouncedGetFilteredAssets = useCallback(
    debounce(async (searchString) => {
      if (typeof searchString === 'string') {
        if (searchString.length < 4) {
          setFilteredAssetOptions([]);
        } else {
          const response = await api(`v1/asset/filtered?search=${searchString}`, 'GET', null);

          if (response.status === 200 && response.data.success) {
            const tempAssets = [];
            for (const asset of response.data.assets) {
              tempAssets.push({ ...asset, id: asset._id, title: asset.name });
            }
            setFilteredAssetOptions(tempAssets);
          }
        }
      }
    }, 300),
    []
  );

  const handleAssignPress = async () => {
    if (validAssetSelected()) {
      const response = await api(`v1/asset/${selectedAsset.id}/assigntag`, 'PATCH', {
        qrCode: props.route?.params.qrCode,
      });

      if (response.status === 200 && response.data.success) {
        const newAsset = response.data.asset;
        props.navigation.navigate('AssetInfo', {
          asset: newAsset,
        });
        showSuccessToast('Updated asset successfully', `Tag has been assigned to ${newAsset.name}.`);
      } else {
        showErrorToast('Update failed', 'Please try again.');
      }
    }
  };

  const validAssetSelected = () => typeof selectedAsset === 'object' && selectedAsset.id;

  return (
    <View style={styles.container}>
      <SafeAreaView
        style={{
          flex: 1,
        }}
      >
        <ScrollView keyboardShouldPersistTaps="always">
          <NavigationHeader
            onPress={() => props.navigation.goBack()}
            header={'Assign Asset'}
            navigate={'Cancel'}
            menuStyles={styles.menu}
          />
          <View style={{ marginHorizontal: 26, marginTop: 30 }}>
            <Image style={{ width: 100, height: 100, marginBottom: 10 }} source={images.confirmationTileIcon} />
            <Text style={styles.textBoldLarge}>This tag hasn't been scanned before.</Text>
            <Text style={styles.script}>Choose an asset to assign the tag.</Text>
          </View>
          <View style={styles.form}>
            <Autocomplete
              value={selectedAsset}
              setValue={setSelectedAsset}
              data={filteredAssetOptions}
              onChange={debouncedGetFilteredAssets}
              placeholder="Enter serial # / qr code"
              style={{ flex: 1 }}
              containerStyle={styles.inputSearchDrop}
              disableAdd={true}
              showAll={true}
            />
          </View>
        </ScrollView>
        <View style={styles.assignButton}>
          <Button
            title={'Assign asset'}
            buttonStyle={validAssetSelected() ? styles.blueButtonActive : styles.blueButton}
            textStyle={styles.blueButtonText}
            onPress={handleAssignPress}
            disabled={!validAssetSelected()}
          />
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginVertical: 15,
            }}
          >
            <View style={styles.line}></View>
            <Text style={[styles.textSemiBoldSmall, { paddingHorizontal: 7 }]}>OR</Text>
            <View style={styles.line}></View>
          </View>
          <Button
            title={'Add a new asset'}
            buttonStyle={styles.blueBorderButton}
            textStyle={styles.blueBorderButtonText}
            onPress={() =>
              props.navigation.navigate('TabNavigator', {
                screen: 'Assets',
                params: {
                  screen: 'AddAssets',
                  fromQRCodeNav: true,
                  qrCode: props.route?.params.qrCode,
                },
                isAdmin: props.route.params.isAdmin,
              })
            }
          />
        </View>
      </SafeAreaView>
    </View>
  );
};

export default AssignAsset;
