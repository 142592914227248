import React, { useState, useEffect } from 'react';
import {
  Text,
  View,
  SafeAreaView,
  ScrollView,
  TextInput,
  Pressable,
  TouchableOpacity,
  Modal,
  Image,
} from 'react-native';
import { styles } from './style';
import NavigationHeader from '../../../../components/SignUp/SingUpNavigation';
import SignUpHeader from '../../../../components/SignUp/SignUpHeader';
import Button from '../../../../components/Button';
import Autocomplete from './autocomplete';
import images from '../../../../theme/images';
import InputField from './inputField';
import { useIsFocused } from '@react-navigation/native';
import { api } from '../../../../utils/api';
import AsyncStorage from '@react-native-async-storage/async-storage';

function JoinProject({ ...props }) {
  const [text, onChangeText] = useState('');
  const [listInput, setListInput] = useState([{}]);
  const [isSelect, setIsSelect] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [listResult, setProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);

  const getProjects = async () => {
    const projects = await api('v1/project/all', 'GET', null);
    if (projects.status == 200) {
      if (projects.data.success) {
        let data = projects.data;
        var arrayProjects = [];
        for (let i = 0; i < data.projects.length; i++) {
          const element = data.projects[i];
          const obj = {
            title: element.name,
            id: element._id,
          };
          arrayProjects.push(obj);
        }
        setProjects(arrayProjects);
      }
    }
  };

  const sendRequests = async (projects) => {
    console.log(projects);
    let user = await AsyncStorage.getItem('user');
    user = JSON.parse(user);
    const joinedProjects = await api('v1/project/join', 'POST', {
      projects: projects,
      email: user.email,
      auth0Id: user.auth0Id,
    });

    if (joinedProjects.status == 200) {
      if (joinedProjects.data.success) {
        console.log(joinedProjects.data);
        setSelectedProjects(joinedProjects.data.projects);
        setModalVisible(true);
      }
    } else {
      if (joinedProjects.status == 404) {
        alert(joinedProjects.data.message);
      }
    }
  };

  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      getProjects();
    }
  }, [isFocused]);

  const addFields = () => {
    let newfield = {
      value: text,
    };
    setListInput([...listInput, newfield]);
  };
  const fields = listInput.map((item, index) => {
    return (
      <InputField
        data={listResult}
        key={index}
        title={'Project Title'}
        onChangeText={() => setIsSelect(!isSelect)}
        onPress={() => {
          const newList = [...listInput];
          console.log('new list: ', newList);
          newList.splice(index + 1, 1);
          setListInput(newList);
        }}
        selectProject={(data) => {
          console.log(data);
          let selectedProjectArray = listInput;
          listInput[index] = data;
          setListInput(selectedProjectArray);
        }}
      />
    );
  });
  const popUpModal = (
    <Modal
      animationType="fade"
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => {
        setModalVisible(false);
      }}
    >
      <View style={styles.popupModal}>
        <View style={styles.popupWindow}>
          <Image style={styles.popupIcon} source={images.emailSentIcon} />
          <Text style={styles.popupTitle}>Sent!</Text>
          <Text style={styles.popupScript}>
            Your request have been sent to the project admin(s). You can explore ClkTag while you wait to be added.
          </Text>
          <Button
            title={'Ok'}
            buttonStyle={styles.blueButton}
            textStyle={styles.blueButtonText}
            onPress={() => {
              setModalVisible(false);
              props.navigation.navigate('WelcomeScreen', {
                userImage: props.route.params.userImage,
                userType: props.route.params.userType,
                userLicence: props.route.params.userLicence,
                appUse: props.route.params.appUse,
                subscription: props.route.params.subscription,
                projects: selectedProjects,
                registerForPushNotificationsAsync: null,
              });
            }}
          />
        </View>
      </View>
    </Modal>
  );

  return (
    <View style={styles.container}>
      <SafeAreaView
        style={{
          flex: 1,
        }}
      >
        <ScrollView
          contentContainerStyle={
            listInput.length < 2 && {
              paddingBottom: 170,
            }
          }
        >
          <View style={styles.loading}>
            <View style={styles.loadPercent}></View>
          </View>
          <NavigationHeader
            onPress={async () => {
              await AsyncStorage.multiRemove(['user', 'token', 'refreshToken', 'expiresIn']);
              props.navigation.navigate('GuestNavigation', { screen: 'QRCodeScan' });
            }}
            header={'Sign Up'}
            navigate={'Cancel'}
            menuStyles={styles.menu}
          />
          <View style={styles.body}>
            <SignUpHeader
              title={'Join a project'}
              descript={'Select a project via title or location to request access.'}
              userImage={props.route.params.userImage}
              disablePress={true}
            />
            {popUpModal}
            <View style={styles.content}>
              {fields}
              <Pressable onPress={() => addFields()}>
                <Text style={styles.addReq}>+ Add another request</Text>
              </Pressable>
            </View>
          </View>
          {listInput.length > 1 && (
            <View style={styles.assignButtonScroll}>
              <Button
                title={'Send Request(s)'}
                buttonStyle={[styles.blueButton, { marginBottom: 10 }]}
                textStyle={styles.blueButtonText}
                onPress={() => {
                  sendRequests(listInput);
                }}
              />
              <Button
                title={'Skip'}
                buttonStyle={[styles.blueBorderButton, { marginBottom: 10 }]}
                textStyle={styles.blueBorderButtonText}
                onPress={() =>
                  props.navigation.navigate('WelcomeScreen', {
                    userImage: props.route.params.userImage,
                    userType: props.route.params.userType,
                    userLicence: props.route.params.userLicence,
                    appUse: props.route.params.appUse,
                    subscription: props.route.params.subscription,
                    registerForPushNotificationsAsync: null,
                  })
                }
              />
              <Button
                title={'Previous'}
                buttonStyle={[styles.blankButton, { marginBottom: 20 }]}
                textStyle={styles.blankButtonText}
                onPress={() => props.navigation.goBack()}
              />
            </View>
          )}
        </ScrollView>
        {listInput.length < 2 && (
          <View style={styles.assignButton}>
            <Button
              title={'Send Request(s)'}
              buttonStyle={[styles.blueButton, { marginBottom: 10 }]}
              textStyle={styles.blueButtonText}
              onPress={() => {
                sendRequests(listInput);
              }}
            />
            <Button
              title={'Skip'}
              buttonStyle={[styles.blueBorderButton, { marginBottom: 10 }]}
              textStyle={styles.blueBorderButtonText}
              onPress={() =>
                props.navigation.navigate('WelcomeScreen', {
                  userImage: props.route.params.userImage,
                  userType: props.route.params.userType,
                  userLicence: props.route.params.userLicence,
                  appUse: props.route.params.appUse,
                  subscription: props.route.params.subscription,
                  registerForPushNotificationsAsync: null,
                })
              }
            />
            <Button
              title={'Previous'}
              buttonStyle={[styles.blankButton, { marginBottom: 20 }]}
              textStyle={styles.blankButtonText}
              onPress={() => props.navigation.goBack()}
            />
          </View>
        )}
      </SafeAreaView>
    </View>
  );
}
export default JoinProject;
