import { View, Image, Text, TextInput, Pressable, TouchableOpacity } from 'react-native';
import { Menu } from 'react-native-paper';
import React, { useState } from 'react';
import images from '../../../../theme/images';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { styles } from './style';

const Autocomplete = ({
  value: origValue,
  label,
  data,
  containerStyle,
  onChange: origOnChange,
  icon = 'bike',
  style = {},
  menuStyle = {},
  onPress = {},
  createOption = false,
  createOptionPress = null,
  onChangeText,
  selectCompany,
  selectProject,
}) => {
  const [value, setValue] = useState(origValue);
  const [menuVisible, setMenuVisible] = useState(false);
  const [resultField, setResultField] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const filterData = (text) => {
    return data.filter((val) => val?.title.toLowerCase()?.indexOf(text?.toLowerCase()) > -1);
  };
  return (
    <View>
      <View style={resultField ? {} : styles.input}>
        {resultField ? (
          <View style={styles.resultBox}>
            <Pressable
              onPress={() => {
                setMenuVisible(true);
                setResultField(false);
                setValue(value.title);
              }}
            >
              <View style={styles.resultContent}>
                <View style={styles.resultTitle}>
                  {/* <Image
                    style={styles.titleIcon}
                    source={images.blueDrillIcon}
                  /> */}
                  <Text style={styles.titleText}>{value.title}</Text>
                </View>
                {/* <Text style={styles.resultScript}>{value.detail}</Text> */}
              </View>
            </Pressable>
            <TouchableOpacity
              onPress={() => {
                onPress;
                setResultField(false);
                onChangeText;
              }}
            >
              <AiOutlineCloseCircle
                name="close"
                style={{
                  fontSize: 20,
                  color: '#979797',
                }}
              />
            </TouchableOpacity>
          </View>
        ) : (
          <TextInput
            onFocus={() => {
              if (value?.length === 0) {
                setMenuVisible(true);
              }
            }}
            placeholder="Start typing title or address…"
            // onBlur={() => setMenuVisible(false)}
            style={style}
            onChangeText={(text) => {
              origOnChange(text);
              if (text && text.length > 0) {
                setFilteredData(filterData(text));
                setMenuVisible(true);
              } else if (text && text.length === 0) {
                setFilteredData(data);
                setMenuVisible(false);
              } else {
                onChangeText('');
                setFilteredData(data);
                setMenuVisible(false);
              }
              setValue(text);
            }}
            value={value}
          />
        )}
        {menuVisible && filteredData && (
          <View style={styles.recommendBox}>
            {filteredData.map((datum, i) => (
              <Pressable
                key={i}
                onPress={() => {
                  setValue(datum);
                  if (selectCompany) {
                    selectCompany(datum);
                  }
                  if (selectProject) {
                    selectProject(datum);
                  }
                  setMenuVisible(false);
                  setResultField(true);
                }}
              >
                <View style={styles.recommendContent}>
                  {/* <Image
                    style={styles.recommendIcon}
                    source={images.blueDrillIcon}
                  /> */}
                  <View
                    style={{
                      paddingLeft: 4,
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: 'NunitoSans_700Bold',
                      }}
                    >
                      {datum.title}
                    </Text>
                    {/* <Text
                      style={{
                        fontSize: 10,
                        fontFamily: "NunitoSans_400Regular",
                      }}
                    >
                      {datum.detail}
                    </Text> */}
                  </View>
                </View>
              </Pressable>
            ))}
            {createOption && (
              <View style={styles.createOption}>
                <Pressable onPress={() => createOptionPress()}>
                  <Text style={styles.addReq}>+ {createOption}</Text>
                </Pressable>
              </View>
            )}
          </View>
        )}
      </View>
    </View>
  );
};

export default Autocomplete;
