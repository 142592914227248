import React, { useState } from 'react';
import Accordion from 'react-native-collapsible/Accordion';
import AccordionContent from './accordionContent';
import AccordionHeader from './accordionHeader';
import { styles } from './style';

export default function AccordionBlock({ ...props }) {
  const { id, projectId, title, section, type, navigation, hasWriteAccess, updateAccordionData } = props;
  const [activeSections, setActiveSections] = useState([]);
  const [isActive, setIsActive] = useState(false);
  // TODO: Load screen title from QR code

  const renderHeader = (section) => {
    return <AccordionHeader {...section} active={isActive} type={type} />;
  };

  const renderContent = (section) => {
    return (
      <AccordionContent
        projectId={projectId}
        title={title}
        {...section}
        type={type}
        navigation={navigation}
        hasWriteAccess={hasWriteAccess}
        updateAccordionData={updateAccordionData}
      />
    );
  };

  const updateSections = (activeSections) => {
    setActiveSections(activeSections);
    setIsActive(!isActive);
  };
  return (
    <Accordion
      sections={section}
      sectionContainerStyle={styles.sectionContainerStyle}
      activeSections={activeSections}
      renderHeader={renderHeader}
      renderContent={renderContent}
      onChange={updateSections}
      underlayColor="transparent"
    />
  );
}
