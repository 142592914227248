import { View, ActivityIndicator } from 'react-native';

const Loading = ({ opacity = 0, size = 'auto' }) => {
  return (
    <View
      style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: `rgba(24,23,21, ${opacity})`,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 111111111111111111111111,
        width: size,
      }}
    >
      <ActivityIndicator size="large" color="#132FF5" />
    </View>
  );
};

export default Loading;
