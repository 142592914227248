import Toast from 'react-native-toast-message';

export const showSuccessToast = (title, message, position = 'top') => {
  Toast.show({
    type: 'success',
    text1: title,
    text2: message,
    position: position,
    bottomOffset: position == 'bottom' ? 100 : 0,
  });
};

export const showErrorToast = (title, message, position = 'top') => {
  Toast.show({
    type: 'error',
    text1: title,
    text2: message,
    position: position,
    bottomOffset: position == 'bottom' ? 100 : 0,
  });
};

export const showInfoToast = (title, message, autoHide = true) => {
  Toast.show({
    type: 'info',
    text1: title,
    text2: message,
    autoHide: autoHide,
  });
};
