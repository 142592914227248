import { Dimensions, StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  pickImage: {
    marginTop: 16,
  },
  imgPicker: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#C7C7C7',
    paddingHorizontal: 7,
    paddingVertical: 8,
    marginTop: 10,
  },
  dasherBorder: {
    borderStyle: 'dotted',
    borderRadius: 8,
    borderWidth: 2,
    borderColor: '#C7C7C7',
    paddingVertical: 20,
    alignItems: 'center',
  },
  imgIconPicked: {
    fontSize: 34,
    paddingLeft: 6,
    color: '#C7C7C7',
  },
  uploadText: {
    fontFamily: 'NunitoSans_600SemiBold',
    color: '#000000',
    opacity: 0.5,
    fontSize: 14,
  },
  imgPickedContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  documentPicked: {
    paddingVertical: 8,
    paddingLeft: 7,
    backgroundColor: '#8997fa1a',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#132ff5',
    borderRadius: 8,
    flex: 12,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  docTitle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  docIcon: {
    fontSize: 18,
    paddingRight: 6,
    color: '#132FF5',
  },
  titleTextPicked: {
    fontFamily: 'NunitoSans_700Bold',
    paddingLeft: 4,
  },
  closeIcon: {
    flex: 1,
    fontSize: 29,
    alignSelf: 'center',
    paddingLeft: 17,
    color: '#C7C7C7',
  },
});
