import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../../../../theme/colors';

const windowHeight = Dimensions.get('window').height;

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  loading: {
    height: 8,
    backgroundColor: 'white',
  },
  loadPercent: {
    height: 8,
    width: '100%',
    backgroundColor: '#132FF5',
  },
  menu: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 24,
    paddingHorizontal: 23,
    alignItems: 'center',
  },
  assetStatus: {
    backgroundColor: '#ffcdcd',
    borderRadius: 13,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    flexDirection: 'row',
    paddingHorizontal: 8,
    paddingVertical: 3,
    marginRight: 4,
    width: '50%',
    marginTop: 13,
  },
  assetStatusText: {
    color: '#FF4D4D',
    fontWeight: 'bold',
    marginRight: 4,
  },
  blueButton: {
    width: '100%',
    borderRadius: 8,
    backgroundColor: colors.mainBlue,
    paddingVertical: 10,
    marginTop: 32,
  },
  blueButtonText: {
    color: colors.white,
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    textAlign: 'center',
  },
  blueBorderButton: {
    width: '100%',
    borderRadius: 8,
    borderColor: colors.mainBlue,
    borderWidth: 1,
    paddingVertical: 10,
  },
  blueBorderButtonText: {
    color: colors.mainBlue,
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    textAlign: 'center',
  },
  blankButton: {
    width: '100%',
    paddingVertical: 10,
  },
  blankButtonText: {
    color: colors.mainBlue,
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    textAlign: 'center',
  },
  body: {
    backgroundColor: 'white',
    paddingHorizontal: 30,
    height: Dimensions.get('screen').height,
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    paddingBottom: 10,
    paddingTop: 50,
  },
  boxContent: {
    borderWidth: 1,
    borderRadius: 8,
    borderColor: '#C7C7C7',
    paddingHorizontal: 15,
    paddingVertical: 22,
    alignItems: 'center',
  },
  textBoldMedium: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 16,
  },
  script: {
    paddingTop: 3,
    fontFamily: 'NunitoSans_400Regular',
    textAlign: 'center',
    width: '70%',
  },
});
