import React from 'react';
import { Text, View, TextInput } from 'react-native';
import { BsSearch } from 'react-icons/bs';
import { styles } from './style';

export default function SearchBar({ ...props }) {
  return (
    <View style={styles.searchSection}>
      <View>
        <BsSearch name="search1" style={styles.searchIcon} />
      </View>
      <TextInput
        placeholderTextColor="#D0D0D0"
        placeholder="Start typing name…"
        style={styles.inputIcon}
        onChangeText={props.onChangeText}
        value={props.text}
      />
    </View>
  );
}
