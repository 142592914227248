import React, { useState } from 'react';
import { SafeAreaView, ScrollView, Text, TextInput, View } from 'react-native';
import Button from '../../../../../components/Button';
import NavigationHeader from '../../../../../components/SignUp/SingUpNavigation';
import { api } from '../../../../../utils/api';
import Autocomplete from './autocomplete';
import { styles } from './style';
import TextField from './textField';
import UploadField from './uploadField';
import { Loading } from '../../../../../constants';
import { showSuccessToast } from '../../../../../utils/toastUtils';

export default function ExtraInformation({ ...props }) {
  const params = props.route?.params;
  console.log('Extra info parmas:', params);
  const [loading, setLoading] = useState(false);
  const [isSelect, setIsSelect] = useState(false);
  const [text, onChangeText] = useState('');
  const [extraInfo, updateExtraInfo] = useState({
    costOfAsset: '',
    brand: '',
    model: '',
    assetOwner: '',
    warranty: null,
    receipt: null,
    additionalNotes: '',
  });

  const inforFields = [
    {
      title: 'Cost of Asset',
      placeholder: '$',
      key: 'costOfAsset',
    },
    {
      title: 'Brand',
      placeholder: 'E.g. Bosch',
      key: 'brand',
    },
    {
      title: 'Model #',
      placeholder: 'E.g. 2GHM7',
      key: 'model',
    },
  ];
  const listResult = [];
  const inforField = inforFields.map((item, index) => {
    return (
      <TextField
        title={item.title}
        placeholder={item.placeholder}
        key={index}
        setValue={(value) => {
          let obj = extraInfo;
          obj[item.key] = value;
          updateExtraInfo(obj);
        }}
      />
    );
  });

  const addAssets = async () => {
    setLoading(true);
    var data = new FormData();

    for (let i = 0; i < Object.keys(params).length; i++) {
      const element = Object.keys(params)[i];
      const keyValue = Object.values(params)[i];
      if (element == 'historyImages') {
        console.log('hsitoryImage: ', keyValue);
        for (let hi = 0; hi < keyValue.length; hi++) {
          const el = keyValue[hi];
          data.append('historyImages', {
            type: el.type,
            uri: el.uri,
            name: el.name,
          });
        }
      } else if (element == 'historyDocument') {
        if (keyValue !== null) {
          data.append('historyDocument', {
            type: keyValue.type,
            uri: keyValue.uri,
            name: keyValue.name,
          });
        }
      } else {
        var value = '';
        if (typeof keyValue == 'string') {
          value = keyValue;
        } else {
          value = JSON.stringify(keyValue);
        }
        data.append(element, value);
      }
    }

    for (let i = 0; i < Object.keys(extraInfo).length; i++) {
      const eiEl = Object.keys(extraInfo)[i];
      console.log('eiEl: ', eiEl);
      const keyValue = Object.values(extraInfo)[i];
      if (eiEl == 'warranty') {
        // console.log('warranty: ', keyValue);
        if (keyValue !== null) {
          data.append('warranty', keyValue);
        }
      } else if (eiEl == 'receipt') {
        if (keyValue !== null) {
          data.append('receipt', keyValue);
        }
      } else {
        if (typeof keyValue == 'string') {
          value = keyValue;
        } else {
          value = JSON.stringify(keyValue);
        }
        data.append(eiEl, value);
      }
    }

    console.log('data to send to create asset: ', data);

    const createAsset = await api('v1/asset', 'POST', data, true);
    console.log('createAsset status: ', createAsset.status);
    console.log('createAsset data: ', createAsset.data);

    if (createAsset.status == 200) {
      if (createAsset.data.success) {
        setLoading(false);
        props.navigation.navigate('TabNavigator', {
          isAdmin: true,
          screen: 'Assets',
          params: {
            screen: 'AdminAssetRegistry',
          },
        });
        showSuccessToast(
          'Successfully Created Asset',
          `New asset has been created for ${params.assetInfo.assetProject.name}`
        );
      } else {
        setLoading(false);
      }
    } else {
      alert(createAsset.data.message);
      setLoading(false);
    }
  };

  const assetOwnerSelect = (owner) => {
    updateExtraInfo({
      ...extraInfo,
      assetOwner: owner.title,
    });
  };

  const updateWarranty = (doc) => {
    updateExtraInfo({
      ...extraInfo,
      warranty: doc,
    });
  };

  const updateReceipt = (doc) => {
    console.log('receipt doc: ', doc);
    updateExtraInfo({
      ...extraInfo,
      receipt: doc,
    });
  };

  if (loading) {
    return (
      <View style={{ flex: 1 }}>
        <Loading color={'#132FF5'} />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <SafeAreaView
        style={{
          flex: 1,
        }}
      >
        <ScrollView>
          <View style={styles.loading}>
            <View style={styles.loadPercent}></View>
          </View>
          <NavigationHeader
            onPress={() => props.navigation.goBack()}
            header={'Add Asset'}
            navigate={'Cancel'}
            menuStyles={styles.menu}
          />
          <View style={styles.body}>
            <Text style={styles.title}>Testing history </Text>
            {inforField}
            <Text style={[styles.inforTitle, { marginTop: 16 }]}>Asset Owner</Text>
            <Autocomplete
              value={text}
              data={listResult}
              onChange={() => setIsSelect(!isSelect)}
              onChangeText={onChangeText}
              onSelect={assetOwnerSelect}
            />
            <Text style={[styles.inforTitle, { marginTop: 16 }]}>Upload Warranty</Text>
            <UploadField selectedFile={extraInfo.warranty} updateWarranty={updateWarranty} />
            <Text style={[styles.inforTitle, { marginTop: 16 }]}>Upload Receipt</Text>
            <UploadField selectedFile={extraInfo.reciept} updateReceipt={updateReceipt} />
            <Text style={[styles.inforTitle, { marginTop: 16 }]}>Additional Notes</Text>
            <View style={styles.noteField}>
              <TextInput
                placeholder="Add a note here…"
                onChangeText={(text) => {
                  onChangeText(text);
                  updateExtraInfo({
                    ...extraInfo,
                    additionalNotes: text,
                  });
                }}
                value={text}
                maxLength={600}
                multiline
              />
              <Text style={styles.textCount}>{text.length}/600 characters</Text>
            </View>
            <View style={styles.assignButton}>
              <Button
                title={'Done'}
                buttonStyle={styles.blueButtonActive}
                textStyle={styles.blueButtonText}
                onPress={addAssets}
              />
              <Button
                title={'Skip'}
                buttonStyle={styles.blueBorderButton}
                textStyle={styles.blueBorderButtonText}
                onPress={addAssets}
              />
              <Button
                title={'Previous'}
                buttonStyle={styles.blankButton}
                textStyle={styles.blankButtonText}
                onPress={() => props.navigation.goBack()}
              />
            </View>
          </View>
        </ScrollView>
      </SafeAreaView>
    </View>
  );
}
