import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../../../theme/colors';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  loading: {
    height: 8,
    backgroundColor: 'white',
    marginTop: 24,
  },
  loadPercent: {
    height: 8,
    width: '60%',
    backgroundColor: '#132FF5',
  },

  imageDrop: {
    height: 100,
    width: 100,
    borderRadius: 1000,
    backgroundColor: '#ffff',
    shadowColor: '#000',
    shadowOffset: {
      width: 6,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 6,
    elevation: 5,
  },
  image: {
    alignItems: 'center',
  },
  textBoldLarge: {
    fontSize: 18,
    fontFamily: 'NunitoSans_700Bold',
  },
  blueBorderButton: {
    borderRadius: 26,
    borderColor: colors.gray,
    borderWidth: 1,
    paddingVertical: 10,
    marginBottom: 16,
  },

  blueBorderButtonText: {
    color: colors.black,
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 16,
    textAlign: 'center',
  },
  buttonJob: {
    paddingHorizontal: 40,
    width: '100%',
  },
  blueButton: {
    borderRadius: 8,
    opacity: 0.5,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
    marginBottom: 10,
  },
  blueButtonActive: {
    borderRadius: 8,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
    marginBottom: 10,
  },
  blueButtonText: {
    color: colors.white,
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 18,
    textAlign: 'center',
  },
  blankButton: {
    paddingVertical: 10,
  },
  blankButtonText: {
    color: '#132FF5',
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    textAlign: 'center',
  },
  assignButton: {
    position: 'absolute',
    bottom: 0,
    paddingHorizontal: 27,
    backgroundColor: 'white',
    width: '100%',
  },
  input: {
    marginTop: 12,
    borderColor: '#C7C7C7',
    width: '100%',
    borderWidth: 1,
    borderRadius: 8,
    paddingVertical: 15,
    paddingLeft: 13,
  },
  inputDrop: {
    marginTop: 16,
    borderColor: '#C7C7C7',
    width: '100%',
    borderWidth: 1,
    borderRadius: 8,
    paddingTop: 15,
    paddingLeft: 13,
  },
  textField: {
    paddingHorizontal: 28,
    marginBottom: 140,
  },
  notipush: {
    marginTop: 25,
    backgroundColor: 'rgba(0,0,0,0.1)',
    borderRadius: 16,
    paddingHorizontal: 31,
    paddingVertical: 21,
    alignItems: 'center',
    textAlign: 'center',
  },
  menu: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 20,
    paddingBottom: 19,
    paddingHorizontal: 26,
    alignItems: 'center',
  },
  textBold: {
    fontFamily: 'NunitoSans_700Bold',
  },
  textRegular: {
    textAlign: 'center',
    fontFamily: 'NunitoSans_400Regular',
    marginTop: 16,
  },
  inputHeader: {
    fontFamily: 'NunitoSans_700Bold',
    paddingTop: 14,
  },
  inputHeaderForm: {
    fontFamily: 'NunitoSans_700Bold',
  },
  title: {
    paddingTop: 26,
    flexDirection: 'row',
  },

  titleText: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 16,
  },
  titleTextForm: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 16,
    marginBottom: 11,
  },
  required: {
    color: '#FF4D4D',
  },
  addReq: {
    marginTop: 13,
    color: '#132FF5',
    fontSize: 16,
    fontFamily: 'NunitoSans_600SemiBold',
  },
  imgPicker: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#C7C7C7',
    paddingHorizontal: 7,
    paddingVertical: 8,
    marginTop: 10,
  },
  dasherBorder: {
    borderStyle: 'dotted',
    borderRadius: 8,
    borderWidth: 2,
    borderColor: '#C7C7C7',
    paddingVertical: 20,
    alignItems: 'center',
  },
  imgPicked: {
    paddingVertical: 8,
    paddingLeft: 7,
    backgroundColor: '#8997fa1a',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#132ff5',
    borderRadius: 8,
    flex: 12,
  },
  imgPickedContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  closeIcon: {
    flex: 1,
    fontSize: 29,
    alignSelf: 'center',
    paddingLeft: 17,
    color: '#C7C7C7',
  },
  optional: {
    fontFamily: 'NunitoSans_400Regular',
    color: '#000000',
    fontSize: 14,
    opacity: 0.5,
  },
  uploadText: {
    fontFamily: 'NunitoSans_600SemiBold',
    fontWeight: 600,
    color: '#000000',
    opacity: 0.5,
    fontSize: 14,
  },
  dropItem: {
    paddingVertical: 16,
    borderBottomColor: '#0000000d',
    borderBottomWidth: 1,
  },
  dropLastItem: {
    paddingVertical: 16,
  },
  dropList: {
    fontFamily: 'NunitoSans_700Bold',
    color: '#000000',
    fontSize: 16,
  },
  unpickedTitle: {
    fontFamily: 'NunitoSans_400Regular',
    color: '#000000',
    opacity: 0.5,
    fontSize: 16,
  },
  normalText: {
    fontFamily: 'NunitoSans_400Regular',
    color: '#000000',
    fontSize: 14,
  },
  imgIcon: {
    fontSize: 25,
    paddingLeft: 6,
    color: '#000000',
  },
  dropTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 16,
  },
  calendarTitle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  calendarIcon: {
    fontSize: 25,
    marginRight: 9,
  },
  checkbox: {
    borderColor: '#132FF5',
    backgroundColor: '#fff',
    marginRight: 6,
    fontFamily: 'NunitoSans_700Bold',
  },
  checkboxContainer: {
    marginTop: 22,
    flexDirection: 'row',
  },
  checkboxDesc: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 14,
    color: '#000000',
  },
  textFieldForm: {
    paddingHorizontal: 14,
    borderWidth: 0.5,
    marginBottom: 20,
    paddingVertical: 16,
    borderRadius: 16,
    borderColor: '#C7C7C7',
  },
  textBold: {
    fontSize: 18,
    fontFamily: 'NunitoSans_700Bold',
    paddingTop: 8,
  },
  scriptStyle: {
    paddingTop: 8,
    paddingHorizontal: 62,
    textAlign: 'center',
    fontFamily: 'NunitoSans_400Regular',
  },
});
