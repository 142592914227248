import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../../../theme/colors';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  imageDrop: {
    height: 100,
    width: 100,
    borderRadius: 1000,
    backgroundColor: '#ffff',
    shadowColor: '#000',
    shadowOffset: {
      width: 6,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 6,
    elevation: 5,
  },
  image: {
    alignItems: 'center',
    marginBottom: 140,
  },
  loading: {
    height: 8,
    backgroundColor: 'white',
    marginTop: 24,
  },
  loadPercent: {
    height: 8,
    width: '16%',
    backgroundColor: '#132FF5',
  },
  textBold: {
    fontSize: 18,
    fontFamily: 'NunitoSans_700Bold',
    paddingTop: 55,
    paddingBottom: 16,
  },
  body: {
    height: Dimensions.get('screen').height,
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingBottom: 10,
  },
  blueBorderButton: {
    borderRadius: 26,
    borderColor: colors.gray,
    borderWidth: 1,
    paddingVertical: 10,
    marginBottom: 16,
  },
  blueBorderButtonText: {
    color: colors.black,
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 16,
    textAlign: 'center',
  },
  buttonJob: {
    paddingHorizontal: 40,
    width: '100%',
  },
  blueButton: {
    borderRadius: 8,
    opacity: 0.5,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
    marginBottom: 10,
  },
  blueButtonActive: {
    borderRadius: 8,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
    marginBottom: 10,
  },
  blueButtonText: {
    color: colors.white,
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 18,
    textAlign: 'center',
  },
  blankButton: {
    paddingVertical: 10,
  },
  blankButtonText: {
    color: '#132FF5',
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    textAlign: 'center',
  },
  assignButton: {
    paddingHorizontal: 27,
    width: '100%',
    position: 'absolute',
    bottom: 0,
    backgroundColor: 'white',
  },
  menu: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 20,
    paddingBottom: 36,
    paddingHorizontal: 26,
    alignItems: 'center',
  },
});
