import { useState, useEffect, useRef } from 'react';
import 'react-native-gesture-handler';
import { StyleSheet, Text, View } from 'react-native';
import RootNavigation from './src/routes';
import {
  useFonts,
  NunitoSans_200ExtraLight,
  NunitoSans_200ExtraLight_Italic,
  NunitoSans_300Light,
  NunitoSans_300Light_Italic,
  NunitoSans_400Regular,
  NunitoSans_400Regular_Italic,
  NunitoSans_600SemiBold,
  NunitoSans_600SemiBold_Italic,
  NunitoSans_700Bold,
  NunitoSans_700Bold_Italic,
  NunitoSans_800ExtraBold,
  NunitoSans_800ExtraBold_Italic,
  NunitoSans_900Black,
  NunitoSans_900Black_Italic,
} from '@expo-google-fonts/nunito-sans';
import { Loading } from './src/constants';
import * as Location from 'expo-location';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Toast from 'react-native-toast-message';
import CustomToast from './src/components/CustomToast';

import { stopReportingRuntimeErrors } from 'react-error-overlay';

export default function App() {
  let [fontsLoaded] = useFonts({
    NunitoSans_200ExtraLight,
    NunitoSans_200ExtraLight_Italic,
    NunitoSans_300Light,
    NunitoSans_300Light_Italic,
    NunitoSans_400Regular,
    NunitoSans_400Regular_Italic,
    NunitoSans_600SemiBold,
    NunitoSans_600SemiBold_Italic,
    NunitoSans_700Bold,
    NunitoSans_700Bold_Italic,
    NunitoSans_800ExtraBold,
    NunitoSans_800ExtraBold_Italic,
    NunitoSans_900Black,
    NunitoSans_900Black_Italic,
  });

  const [currentLocation, setLocation] = useState(null);
  const [notification, setNotification] = useState(false);
  const notificationListener = useRef();
  const responseListener = useRef();

  useEffect(() => {
    if (currentLocation == null) {
      (async () => {
        let { status } = await Location.requestForegroundPermissionsAsync();
        if (status !== 'granted') {
          CustomToast({ type: 'error', text1: 'Permission to access location was denied.' });
          return;
        }

        let location = await Location.getCurrentPositionAsync({});
        setLocation(location);
        await AsyncStorage.setItem('currentLocation', JSON.stringify(location));
        console.log(location);
      })();
    }
  }, [currentLocation]);

  if (!fontsLoaded) {
    return <Loading opacity={0} />;
  } else {
    return (
      <View style={styles.container}>
        <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyAvXgmTkzN4x0NHjGYwh1_JGeWOR0_wLtE"></script>
        <RootNavigation />
        <Toast
          config={{
            info: (props) => <CustomToast {...props} />,
            success: (props) => <CustomToast {...props} />,
            error: (props) => <CustomToast {...props} />,
          }}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
});
