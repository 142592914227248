import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../../../../theme/colors';

export const styles = StyleSheet.create({
  detailContainer: {
    flex: 1,
    backgroundColor: 'white',
  },
  header: {
    backgroundColor: '#F6F6F6',
    paddingTop: 70,
    paddingLeft: 23,
    paddingRight: 27,
    paddingBottom: 23,
  },
  body: {
    backgroundColor: '#FFFFFF',

    flex: 1,
  },
  sectionContainer: {
    marginTop: 12,
    paddingLeft: 23,
    paddingRight: 31,
    flex: 1,
  },
  headerTitle: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  textBoldLarge: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    color: '#000000',
  },
  textBold: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 14,
    color: '#000000',
  },
  textBlueSemiBold: {
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 12,
    color: '#132FF5',
  },
  blueBoldText: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 14,
    color: '#132FF5',
    paddingBottom: 9,
    paddingRight: 18,
  },
  textBoldGray: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    color: '#6B6B6B',
  },
  textBoldMedium: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 16,
    color: '#000000',
  },

  img: {
    width: 23,
    height: 23,
    borderRadius: 100,
  },

  viewOptionsContainer: {
    marginTop: 22,
    flexDirection: 'row',
    alignItems: 'center',
  },
  grayBoldTextView: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 14,
    color: '#6B6B6B',
    paddingBottom: 9,
    paddingRight: 18,
  },
  textGrayBold: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 14,
    color: '#6B6B6B',
  },
  activeBorder: {
    height: 1,
    position: 'absolute',
    backgroundColor: '#132FF5',
    bottom: 0,
  },
  projectDetailTitle: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    paddingVertical: 5,
    paddingHorizontal: 20,
    borderRadius: 16,
  },
  propertyContainer: {
    paddingVertical: 14,
    paddingLeft: 11,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: '#C7C7C7',
    marginTop: 14,
    marginBottom: 19,
  },
  ownerDetail: {
    flexDirection: 'row',
  },
  blueButtonActive: {
    borderRadius: 8,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
    marginBottom: 10,
  },
  blueButtonText: {
    color: colors.white,
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 18,
    textAlign: 'center',
  },
  teamItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 13,
    borderBottomWidth: 1,
    borderBottomColor: '#0000000d',
  },
  positionContainer: {
    backgroundColor: '#132ff51a',
    paddingVertical: 3,
    borderRadius: 11,
    minWidth: 87,
    alignItems: 'center',
  },
  blankButton: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#132FF5',
    paddingVertical: 13,
    marginHorizontal: 27,
    alignItems: 'center',
    marginBottom: 30,
  },
  blankButtonText: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    color: '#132FF5',
  },
  popupWindow: {
    backgroundColor: 'white',
    paddingHorizontal: 27,
    paddingVertical: 20,
    borderRadius: 12,
    alignItems: 'center',
    marginHorizontal: 26,
  },
  popupModal: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  popupIcon: {
    width: 70,
    height: 70,
    borderRadius: 16,
    marginTop: 8,
  },
  popupTitle: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    marginTop: 25,
  },
  popupScript: {
    fontFamily: 'NunitoSans_400Regular',
    marginTop: 8,
    textAlign: 'center',
  },
  modalButton: {
    borderRadius: 8,
    opacity: 0.5,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
    marginBottom: 10,
    width: '100%',
  },
  modalTitle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  modalContent: {
    alignSelf: 'start',
    paddingHorizontal: 12,
    width: '100%',
  },
  input: {
    paddingVertical: 14,
    paddingLeft: 11,
    borderWidth: 1,
    borderColor: '#C7C7C7',
    width: '100%',
    borderRadius: 8,
    marginTop: 12,
  },
  textRegular: {
    fontFamily: 'NunitoSans_400Regular',
    color: '#000000',
    fontSize: 14,
  },
  descriptionContainer: {
    marginTop: 10,
    maxWidth: 267,
  },
  popUpPickDate: {
    marginTop: 16,
    width: '100%',
  },
  inputDrop: {
    borderColor: '#C7C7C7',
    // width: "100%",
    borderWidth: 1,
    borderRadius: 8,
    paddingVertical: 13,
    paddingHorizontal: 8,
    // flex:1
  },
  dropTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    // paddingRight: 16,
  },
  flexRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  calendarIcon: {
    fontSize: 25,
    marginRight: 6,
  },
  unpickedTitle: {
    fontFamily: 'NunitoSans_400Regular',
    color: '#000000',
    opacity: 0.5,
    fontSize: 16,
  },
  dropList: {
    fontFamily: 'NunitoSans_700Bold',
    color: '#000000',
    fontSize: 16,
  },
  imgIcon: {
    fontSize: 18,
    color: '#000000',
  },
});
