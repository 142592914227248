import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../../../theme/colors';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  header: {
    backgroundColor: '#F6F6F6',
    paddingTop: 45,
    height: 100,
    paddingHorizontal: 23,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    // paddingBottom:18,
    // alignItems:"center"
  },
  textBold: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
  },
  backText: {
    fontFamily: 'NunitoSans_700Bold',
    textAlign: 'left',
    fontSize: 14,
  },
  title: {
    textAlign: 'center',
    marginTop: 52,
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
  },
  body: {
    paddingHorizontal: 32,
    height: Dimensions.get('screen').height,
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    marginTop: 46,
  },
  input: {
    marginTop: 9,
    borderColor: '#C7C7C7',
    width: '100%',
    borderWidth: 1,
    borderRadius: 10,
    paddingVertical: 10,
    paddingLeft: 13,
  },
  selectedContent: {
    alignItems: 'center',
    paddingTop: 16,
    paddingHorizontal: 16,
  },
  selectedText: {
    fontSize: 18,
    fontFamily: 'NunitoSans_700Bold',
  },
  textDetail: {
    paddingTop: 16,
    textAlign: 'center',
    fontFamily: 'NunitoSans_400Regular',
  },
  popupWindow: {
    backgroundColor: 'white',
    paddingHorizontal: 27,
    paddingVertical: 16,
    borderRadius: 12,
    alignItems: 'center',
    marginHorizontal: 26,
  },
  label: {
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 12,
    paddingLeft: 8,
    width: '92%',
  },
  blueBorderButton: {
    width: '100%',
    borderRadius: 8,
    borderColor: colors.mainBlue,
    borderWidth: 1,
    paddingVertical: 10,
  },
  blueBorderButtonText: {
    color: colors.mainBlue,
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    textAlign: 'center',
  },
  blankButton: {
    width: '100%',
    paddingVertical: 10,
  },
  blankButtonText: {
    opacity: 0.5,
    color: colors.black,
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    textAlign: 'center',
  },
  popUpTitle: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    marginTop: 18,
  },
  popUpScript: {
    fontFamily: 'NunitoSans_400Regular',
    marginTop: 4,
    textAlign: 'center',
    width: '80%',
  },
  confirm: {
    flexDirection: 'row',
    marginTop: 42,
    width: '100%',
  },
  popupAva: {
    width: 70,
    height: 70,
    borderRadius: 16,
  },
  selectedTag: {
    borderWidth: 1,
    borderRadius: 16,
    borderColor: '#C7C7C7',
    paddingHorizontal: 11,
    paddingBottom: 16,
    marginTop: 19,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  selectDot: {
    position: 'absolute',
    right: 4,
    top: 8,
    borderColor: '#979797',
    borderRadius: 32,
    borderWidth: 1,
    paddingTop: 4,
    width: 28,
    height: 28,
    alignItems: 'center',
  },
  selected: {
    borderRadius: 32,
    width: 18,
    height: 18,
    backgroundColor: '#132FF5',
    alignItems: 'center',
  },
});
