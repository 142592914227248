exports.config = {
  STAGINGDOMAIN: 'http://192.168.0.8:8092/',
  DOMAIN: 'https://api.staging.clktag.com.au/',
  AUTHDOMAIN: 'https://clktag.au.auth0.com',
  CLIENTID: 'bRqHKWkFk7Eh0gQPAAlb4klkn6yqbunI',
  AUDIENCE: 'https://clktag.au.auth0.com/api/v2/',
  SECRET: 'e74oXStuAs9Elvp6SQXFXtwhBs6Oicde7k8NCfhUGq3sdeyX_Jpy31clXzml-u3z',
  GMAPS_API: 'AIzaSyAvXgmTkzN4x0NHjGYwh1_JGeWOR0_wLtE',
  NODE_ENV: 'development',
  // redirectUrl: 'http://localhost:19006',
  redirectUrl: 'https://portal.clktag.com.au',
};
