import React, { useEffect, useState } from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import Button from '../../../components/Button';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { styles } from './style';
import { api } from '../../../utils/api';
import { showErrorToast, showSuccessToast } from '../../../utils/toastUtils';
import { getUserRoleString } from '../../../utils/userUtils';
import ConfirmModal from '../../admin/accountSettings/subscription/confirmModal';

export default function JoinRequestNotification({ ...props }) {
  const { notification, handleClosePress, getNotifications } = props;

  const [confirmUpgradeModalVisible, setConfirmUpgradeModalVisible] = useState(false);
  const [company, setCompany] = useState(null);
  const [companyUsers, setCompanyUsers] = useState([]);

  const handleAcceptPress = async () => {
    const emailFound = companyUsers.find((user) => user.email === notification.sender.email);
    if (!emailFound && companyUsers.length + 1 > company.subscriptionType.maxLicences) {
      setConfirmUpgradeModalVisible(true);
    } else {
      acceptRequest();
    }
  };

  const acceptRequest = async () => {
    const response = await api(`v1/project/${notification.project._id}/accept/${notification.sender._id}`, 'PATCH', {
      newUsers: 1,
    });

    if (response.status === 200 && response.data.success) {
      showSuccessToast(
        'Successfully accepted request',
        `${notification.sender.firstName} ${notification.sender.lastName} added to ${notification.project.name}`
      );
      getNotifications();
    } else {
      showErrorToast('Error accepting request', 'Request may have already been accepted/declined by another user');
    }
  };

  const handleDeclinePress = async () => {
    const response = await api(
      `v1/project/${notification.project._id}/reject/${notification.sender._id}`,
      'PATCH',
      null
    );

    if (response.status === 200 && response.data.success) {
      showSuccessToast(
        'Successfully declined request',
        `Request from ${notification.sender.firstName} ${notification.sender.lastName} to join ${notification.project.name} declined`
      );
      getNotifications();
    } else {
      showErrorToast('Error declining request', 'Request may have already been accepted/declined by another user');
    }
  };

  const getCompany = async () => {
    const response = await api(`v1/company/${notification.project.company}`, 'GET', null);

    if (response.status === 200 && response.data.success) {
      setCompany(response.data.company);
    }
  };

  const getCompanyUsers = async () => {
    const response = await api(`v1/company/${notification.project.company}/users`, 'GET', null);

    if (response.status === 200 && response.data.success) {
      setCompanyUsers(response.data.users);
    }
  };

  useEffect(() => {
    getCompany();
    getCompanyUsers();
  }, []);

  return (
    <View style={styles.notiBlock}>
      <View style={styles.notiHeader}>
        <View style={styles.notiTitle}>
          <Text style={styles.textBold}>Pending Request</Text>
        </View>
        <TouchableOpacity onPress={() => handleClosePress(notification._id)}>
          <AiOutlineCloseCircle name="close" style={styles.closeIcon} />
        </TouchableOpacity>
      </View>
      <View style={styles.sender}>
        <View style={styles.senderInfor}>
          <Image style={styles.notiContentAvatar} source={{ uri: notification.sender?.profilePicture }} />
          <Text
            style={[
              styles.textBold,
              {
                paddingLeft: 5,
              },
            ]}
          >
            {`${notification.sender?.firstName} ${notification.sender?.lastName}`}
          </Text>
        </View>
        <View style={styles.position}>
          <Text
            style={[
              styles.textSemiBoldSmall,
              {
                color: '#132FF5',
              },
            ]}
          >
            {getUserRoleString(notification.sender?.role)}
          </Text>
        </View>
      </View>
      <Text
        style={[
          styles.textRegular,
          {
            marginTop: 8,
            textAlign: 'center',
          },
        ]}
      >
        Requested to join:
      </Text>
      <View style={styles.inviteContent}>
        <Image style={styles.notiContentAvatar} source={{ uri: notification.project.logo }} />
        <Text
          style={[
            {
              paddingLeft: 5,
            },
          ]}
        >
          {notification.project.name}
        </Text>
      </View>
      <View style={styles.buttonsContainer}>
        <Button
          title={'Accept'}
          buttonStyle={styles.blueButton}
          textStyle={styles.blueButtonText}
          onPress={handleAcceptPress}
        />

        <Button
          title={'Decline'}
          buttonStyle={styles.blueBorderButton}
          textStyle={styles.blueBorderButtonText}
          onPress={handleDeclinePress}
        />
      </View>
      <ConfirmModal
        visible={confirmUpgradeModalVisible}
        setVisible={setConfirmUpgradeModalVisible}
        title={'Subscription will be upgraded'}
        details={`Accepting request will upgrade subscription for ${company?.name} because the ${company?.subscriptionType.value} plan only allows ${company?.subscriptionType.maxLicences} licences.`}
        submitButtonText={'Continue'}
        handleSubmit={acceptRequest}
      />
    </View>
  );
}
