import React from 'react';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import { styles } from './style';
import images from '../../../../theme/images';
import { FaChevronRight } from 'react-icons/fa';
import Status from '../../../assetInfo/status';

export default function CompleteTask({ ...props }) {
  const { task, navigation } = props;
  const handlePress = () =>
    navigation.navigate('TabNavigator', {
      screen: 'Assets',
      params: {
        screen: 'AssetInfo',
        asset: task.asset,
      },
    });

  return (
    <View style={styles.taskContainer}>
      <TouchableOpacity onPress={handlePress}>
        <View style={{ ...styles.contentBlock, paddingVertical: 6 }}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Image style={styles.completeTickIcon} source={images.completeTickIcon} />
            <View
              style={{
                paddingLeft: 8,
              }}
            >
              <Image style={styles.taskIcon} source={{ uri: task.asset.assetType.icon }} />
            </View>
            <View
              style={{
                paddingLeft: 8,
                flex: 1,
              }}
            >
              <View style={styles.taskTitle}>
                <Text style={styles.textBold}>{task.asset.name}</Text>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Status status={task.asset.currentStatus} />
                  <FaChevronRight name="right" style={styles.leadIcon} />
                </View>
              </View>
              <View style={styles.taskDetail}>
                <Text style={styles.textMicroBold}>QR Code</Text>
                <Text
                  style={[
                    styles.textLightSmall,
                    {
                      paddingLeft: 3,
                    },
                  ]}
                >
                  {task.asset.tag.code}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
}
