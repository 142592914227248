import React from 'react';
import { Text, View, TextInput } from 'react-native';
import { BsSearch } from 'react-icons/bs';
import { styles } from './style';

export default function SearchBar({ ...props }) {
  return (
    <View
      style={[
        styles.searchSection,
        {
          marginLeft: 23,
          marginRight: 27,
        },
      ]}
    >
      <View style={styles.searchIcon}>
        <BsSearch
          name="search1"
          style={{
            fontSize: 20,
            color: 'rgba(0,0,0,0.5)',
          }}
        />
      </View>
      <TextInput
        placeholder={props.placeholder}
        style={styles.inputIcon}
        onChangeText={props.onChangeText}
        value={props.text}
      />
    </View>
  );
}
