import React, { useState, useEffect } from 'react';
import { Modal, StyleSheet, Text, View, Pressable, Image, Platform } from 'react-native';
import colors from '../../../../theme/colors';
import images from '../../../../theme/images';
import Button from '../../../../components/Button';
import Autocomplete from '../../../../components/AutoComplete/autoComplete';
import { api } from '../../../../utils/api';

const SitePickerModal = ({ ...props }) => {
  const { modalVisible, setModalVisible, asset, setAsset } = props;
  const [siteList, setSitesList] = useState([]);
  const [siteTypes, setSiteTypes] = useState([]);
  const [selectedSite, setSelectedSite] = useState('');

  const getSiteTypes = async () => {
    const response = await api('v1/site-type', 'GET', null);
    if (response.status == 200 && response.data.success) {
      var arraySiteTypes = [];
      for (const siteType of response.data.siteTypes) {
        arraySiteTypes.push({
          title: siteType.name,
          id: siteType._id,
          defaultCadence: siteType.defaultTestCadence,
        });
      }
      setSiteTypes(arraySiteTypes);
    }
  };

  const getSites = async () => {
    const response = await api(`v1/site/project/${asset.project._id}`, 'GET', null);
    if (response.status == 200 && response.data.success) {
      var arraySites = [];
      for (const site of response.data.sites) {
        if (site._id !== asset.site._id) {
          arraySites.push({
            title: site.name,
            name: site.name,
            detail: `${site.address.street}, ${site.address.city}, ${site.address.state}, ${site.address.country}`,
            id: site._id,
            location: site.address,
            testCadence: site.testCadence,
          });
        }
      }
      setSitesList(arraySites);
    }
  };

  useEffect(() => {
    getSiteTypes();
    getSites();
  }, []);

  return (
    <Modal animationType="fade" transparent={true} visible={modalVisible} onRequestClose={() => setModalVisible(false)}>
      <View style={styles.popupModal}>
        <View style={styles.popupWindow}>
          <View style={styles.addModalTitleContainer}>
            <Text style={styles.addModalTitle}>Select a new site</Text>
            <Pressable onPress={() => setModalVisible(false)}>
              <Image style={styles.popupIconModal} source={images.lightGreyX} />
            </Pressable>
          </View>
          <View style={styles.textFieldModal}>
            <Text style={styles.inputHeaderModal}>Site</Text>
            <Autocomplete
              value={selectedSite}
              setValue={(data) => setSelectedSite(data)}
              data={siteList}
              siteTypes={siteTypes}
              modalType="Site"
              placeholder="Start typing site..."
              onChange={() => {}}
              containerStyle={styles.inputSearchDrop}
              newType="site"
              projectId={asset.project._id}
              disableAdd={true}
            />
          </View>
          <View>
            <Button
              title={'Update Site'}
              buttonStyle={selectedSite && selectedSite.id ? styles.blueButtonActive : styles.blueButton}
              textStyle={styles.blueButtonText}
              onPress={async () => {
                if (selectedSite && selectedSite.id) {
                  const response = await api(`v1/asset/${asset._id}/site`, 'PATCH', { siteId: selectedSite.id });
                  if (response.status === 200 && response.data.success) {
                    setAsset(response.data.asset);
                  }
                  setSelectedSite('');
                  setModalVisible(false);
                }
              }}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  popupModal: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  scrollContainer: {
    marginTop: Platform.OS == 'ios' ? 50 : 10,
    maxHeight: '100%',
  },
  popupWindow: {
    backgroundColor: 'white',
    paddingHorizontal: 27,
    paddingVertical: 20,
    borderRadius: 12,
    alignItems: 'center',
    marginHorizontal: 26,
  },
  popupIcon: {
    width: 70,
    height: 70,
    borderRadius: 16,
    marginTop: 8,
  },
  popupIconModal: {
    width: 13,
    height: 13,
    borderRadius: 16,
  },
  popupTitle: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    marginTop: 25,
  },
  popupScript: {
    fontFamily: 'NunitoSans_400Regular',
    marginTop: 8,
    textAlign: 'center',
  },
  addModalTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  addModalTitle: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    color: '#000000',
  },
  image: {
    alignItems: 'center',
  },
  textBoldLarge: {
    fontSize: 18,
    fontFamily: 'NunitoSans_700Bold',
  },
  blueBorderButton: {
    borderRadius: 8,
    borderColor: '#132FF5',
    borderWidth: 1,
    paddingVertical: 10,
    marginBottom: 16,
  },

  blueBorderButtonText: {
    color: '#132FF5',
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    textAlign: 'center',
  },
  buttonJob: {
    paddingHorizontal: 40,
    width: '100%',
  },
  blueButton: {
    borderRadius: 8,
    opacity: 0.5,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
    marginBottom: 10,
    paddingHorizontal: 30,
  },
  blueButtonActive: {
    borderRadius: 8,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
    marginBottom: 10,
    paddingHorizontal: 30,
  },
  blueButtonText: {
    color: colors.white,
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 18,
    textAlign: 'center',
  },
  inputSearchDrop: {
    marginTop: 16,
    borderColor: '#C7C7C7',
    width: '100%',
    borderWidth: 1,
    borderRadius: 8,
    paddingVertical: 15,
    paddingHorizontal: 13,
  },
  inputTitle: {
    marginTop: 16,
    borderColor: '#C7C7C7',
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 16,
    width: '100%',
    borderWidth: 1,
    borderRadius: 8,
    paddingVertical: 15,
    paddingLeft: 13,
  },
  textField: {
    paddingHorizontal: 14,
    marginBottom: 20,
    paddingVertical: 16,
  },
  textFieldModal: {
    marginBottom: 14,
    paddingTop: 23,
    width: '100%',
  },
  textBold: {
    fontFamily: 'NunitoSans_700Bold',
  },
  inputHeader: {
    fontFamily: 'NunitoSans_700Bold',
  },
  inputHeaderModal: {
    fontFamily: 'NunitoSans_700Bold',
    marginTop: 14,
  },
});

export default SitePickerModal;
