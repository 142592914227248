import React, { useState } from 'react';
import { View, TextInput, Text, ScrollView } from 'react-native';
import Button from '../../../../components/Button';
import { api } from '../../../../utils/api';
import AddressInput from '../../../../components/AddressInput';
import { showSuccessToast, showErrorToast } from '../../../../utils/toastUtils';
import { styles } from './style';
import ConfirmModal from './confirmModal';

function Billing({ ...props }) {
  const { company, updateCompanyAndList } = props;
  const billingDetails = company.account.billingDetails;
  const [address, setAddress] = useState(billingDetails.address);
  const [phone, setPhone] = useState(billingDetails.phone);
  const [email, setEmail] = useState(billingDetails.email);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  const handleSubmitPress = async () => {
    const body = { billingDetails: { address: address, phone: phone, email: email } };
    const response = await api(`v1/company/${company._id}/billing`, 'PATCH', body);

    if (response.status === 200 && response.data.success) {
      showSuccessToast('Details updated successfully', 'New billing details are now active.');
      const updatedCompany = response.data.company;
      updateCompanyAndList({ ...updatedCompany, id: updatedCompany._id, title: updatedCompany.name });
    } else {
      showErrorToast('Update failed', 'Please try again');
    }
  };

  return (
    <>
      {/* <ScrollView> AddressInputs within scrollviews causing issues */}
      <View style={styles.billingDetailsContent}>
        <Text style={styles.textBoldMedium}>Address</Text>
        <AddressInput
          selectedAddress={address}
          selectAddress={(locationData) => {
            setAddress(locationData);
          }}
          inputStyle={{ fontWeight: '700' }}
        />
        <Text style={[styles.textBoldMedium, { marginTop: 15 }]}>Phone</Text>
        <View style={styles.propertyContainer}>
          <TextInput style={styles.textBoldMedium} defaultValue={phone} onChangeText={(text) => setPhone(text)} />
        </View>
        <Text style={[styles.textBoldMedium, { marginTop: 15 }]}>Email</Text>
        <View style={styles.propertyContainer}>
          <TextInput style={styles.textBoldMedium} defaultValue={email} onChangeText={(text) => setEmail(text)} />
        </View>
      </View>
      {/* </ScrollView> */}
      <View style={{ marginHorizontal: 25, marginBottom: 8 }}>
        <Button
          title={'Update'}
          buttonStyle={styles.blueButtonActive}
          textStyle={styles.blueButtonText}
          onPress={() => setConfirmModalVisible(true)}
        />
      </View>
      <ConfirmModal
        visible={confirmModalVisible}
        setVisible={setConfirmModalVisible}
        title={'Are you sure?'}
        details={`Billing details will be updated.`}
        submitButtonText={'Yes, update'}
        handleSubmit={handleSubmitPress}
      />
    </>
  );
}

export default Billing;
