import React, { useState } from 'react';
import { Image, Pressable, ScrollView, Text, View } from 'react-native';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';

import { styles } from './style';
function DropInputField({ ...props }) {
  const { data, selectedDropdownItem, setSelectedDropdownItem, havePosition, placeholder } = props;
  const handleDropdownPicker = (item) => {
    setSelectedDropdownItem(item);
    setOpen(false);
  };
  const [open, setOpen] = useState(false);
  return (
    <>
      <Pressable onPress={() => setOpen(!open)}>
        <View style={open ? styles.inputDrop : [styles.inputDrop, { paddingBottom: 13 }]}>
          <View style={styles.dropTitleContainer}>
            {selectedDropdownItem == 'Select type' ? (
              <Text style={styles.unpickedTitle}>{selectedDropdownItem}</Text>
            ) : selectedDropdownItem == '' ? (
              <Text style={styles.unpickedTitle}>{placeholder}</Text>
            ) : (
              <View
                style={{
                  flexDirection: 'row',
                  paddingVertical: 5,
                  borderRadius: 15,
                }}
              >
                <View style={[styles.dropImageContainer, { width: '100%' }]}>
                  {selectedDropdownItem?.image && (
                    <Image
                      style={{
                        width: 20,
                        height: 20,
                        marginRight: 8,
                      }}
                      source={selectedDropdownItem.image}
                    ></Image>
                  )}

                  <Text style={styles.dropList}>{selectedDropdownItem.title}</Text>
                </View>
              </View>
            )}

            {open ? (
              <FiChevronUp name="angle-up" style={styles.imgIcon} />
            ) : (
              <FiChevronDown name="angle-down" style={styles.imgIcon} />
            )}
          </View>
          <ScrollView style={open ? { marginTop: 10 } : { marginTop: 0 }} nestedScrollEnabled={true}>
            {open &&
              data.map((item, index) => {
                return (
                  // <TouchableWithoutFeedback key={index}>
                  <Pressable onPress={() => handleDropdownPicker(item)} key={index}>
                    <View style={index !== data.length ? styles.dropItem : styles.dropLastItem}>
                      <View style={styles.dropItemContainer}>
                        <View style={styles.dropImageContainer}>
                          {item.image && (
                            <Image
                              style={{
                                width: 20,
                                height: 20,
                                marginRight: 8,
                              }}
                              source={item.image}
                            ></Image>
                          )}

                          <View>
                            <Text style={styles.dropList}>{item.title}</Text>
                          </View>
                        </View>

                        {havePosition && (
                          <View style={styles.positionContainer}>
                            <Text style={[styles.textSmallSemibold, { color: '#132FF5' }]}>{item.position}</Text>
                          </View>
                        )}
                      </View>
                    </View>
                  </Pressable>
                  // </TouchableWithoutFeedback>
                );
              })}
          </ScrollView>
        </View>
      </Pressable>
    </>
  );
}

export default DropInputField;
