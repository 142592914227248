import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../../theme/colors';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  title: {
    color: '#6B6B6B',
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
    marginBottom: 10,
  },
  blueButtonLarge: {
    alignItems: 'center',
    width: '100%',
    borderRadius: 8,
    backgroundColor: '#132FF5',
    paddingVertical: 11,
  },
  header: {
    backgroundColor: '#F6F6F6',
    paddingTop: 70,
    paddingBottom: 18,
  },
  body: {
    marginTop: 16,
    backgroundColor: 'white',
    paddingVertical: 16,
    paddingHorizontal: 23,
  },
  textBold: {
    fontFamily: 'NunitoSans_700Bold',
  },
  notiBlock: {
    marginTop: 16,
    paddingHorizontal: 15,
    borderRadius: 8,
    backgroundColor: '#F9F9F9',
    paddingHorizontal: 4,
    paddingVertical: 8,
  },
  notiHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 11,
  },
  notiTitle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  ownerInfo: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  textSemiBoldSmall: {
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 12,
  },
  textRegular: {
    fontFamily: 'NunitoSans_400Regular',
  },
  textRegularSmall: {
    fontFamily: 'NunitoSans_400Regular',
    fontSize: 12,
  },
  notiDescr: {
    paddingHorizontal: 11,
    paddingVertical: 12,
    borderRadius: 8,
    flexDirection: 'col',
    justifyContent: 'space-between',
  },
  closeIcon: {
    fontSize: 16,
    paddingLeft: 6,
    color: '#979797',
  },
  notiContent: {
    marginTop: 16,
    paddingHorizontal: 11,
    paddingVertical: 12,
    borderRadius: 8,
    backgroundColor: 'white',
    flexDirection: 'col',
    justifyContent: 'space-between',
  },
  contentHeader: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  contentDetail: {
    paddingHorizontal: 10,
    paddingVertical: 3,
    borderRadius: 16,
    backgroundColor: '#F6F6F6',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 8,
  },
  notiContentAvatar: {
    width: 24,
    height: 24,
    borderRadius: 12,
  },
  sender: {
    paddingHorizontal: 10,
    paddingVertical: 3,
    borderRadius: 16,
    backgroundColor: '#ffffff',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 18,
  },
  senderInfor: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  assetInfo: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  position: {
    backgroundColor: 'rgba(137,151,250,0.09)',
    borderRadius: 16,
    alignItems: 'center',
    paddingHorizontal: 23,
    paddingVertical: 3,
  },
  assetType: {
    backgroundColor: 'rgba(0,0,0,0.1)',
    borderRadius: 16,
    alignItems: 'center',
    paddingHorizontal: 23,
    paddingVertical: 3,
    flexDirection: 'row',
  },
  inviteContent: {
    paddingHorizontal: 10,
    paddingVertical: 3,
    borderRadius: 16,
    backgroundColor: '#ffffff',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 8,
  },
  inviteButton: {
    flexDirection: 'row',
    marginTop: 13,
    marginBottom: 10,
  },
  line: {
    borderBottomWidth: 1,
    borderBottomColor: '#D0D0D0',
    flex: 1,
  },
  blueButton: {
    width: '48%',
    borderRadius: 8,
    backgroundColor: colors.mainBlue,
    paddingVertical: 10,
  },
  blueButtonText: {
    color: colors.white,
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 16,
    textAlign: 'center',
  },
  blueBorderButton: {
    width: '48%',
    borderRadius: 8,
    borderColor: colors.mainBlue,
    borderWidth: 1,
    paddingVertical: 10,
    marginLeft: 8,
  },
  blueBorderButtonText: {
    color: colors.mainBlue,
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 16,
    textAlign: 'center',
  },
  whiteButton: {
    paddingVertical: 11,
    borderWidth: 1,
    alignItems: 'center',
    borderColor: '#132FF5',
    borderRadius: 8,
    flex: 1,
  },
});
