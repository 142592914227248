import { styles } from './style';
import { Text, View, Image } from 'react-native';
import images from '../../../../../theme/images';
import AccordionExtraContent from './accordionExtraContent';
import { getUserRoleString } from '../../../../../utils/userUtils';

const HistoryContent = ({ ...props }) => {
  var sections = [];

  if (props.results) {
    for (let sa = 0; sa < Object.keys(props.results).length; sa++) {
      const key = Object.keys(props.results)[sa];
      const values = Object.values(props.results)[sa];
      sections.push({
        section: [
          {
            title: key,
          },
        ],
        title: key.replace('_', ' ').replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase()),
        details: values.details,
        status: values.status,
      });
    }
  }

  return (
    <View style={styles.collapseContent}>
      {sections.map((item, index) => (
        <AccordionExtraContent
          key={index}
          section={item.section}
          title={item.title}
          details={item.details}
          status={item.status}
        />
      ))}
      <View style={styles.collapseDetail}>
        <Text style={styles.textBold}>Detail</Text>
        <Text
          style={[
            styles.textRegularSmall,
            {
              marginTop: 8,
              marginBottom: 5,
            },
          ]}
        >
          Conducted by
        </Text>
        <View style={styles.collapseDetailInfo}>
          <Image style={styles.avatar} source={{ uri: props.user.profilePicture }} />
          <View
            style={{
              paddingLeft: 4,
            }}
          >
            <View style={styles.employee}>
              <Text style={styles.textBold}>{`${props.user.firstName} ${props.user.lastName}`}</Text>
              <View style={styles.collapseDetailPosition}>
                <Text
                  style={[
                    styles.textBoldSmall,
                    {
                      color: '#132FF5',
                    },
                  ]}
                >
                  {getUserRoleString(props.user.role)}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <Text
          style={[
            styles.textRegularSmall,
            {
              paddingTop: 8,
              marginBottom: 8,
            },
          ]}
        >
          Location
        </Text>
        <View style={styles.collapseDetailLocation}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Text style={styles.textBold}>{props.asset.site.name}</Text>
          </View>
          <Text
            style={[
              styles.textRegularSmall,
              {
                paddingTop: 4,
              },
            ]}
          >{`${props.asset.site.address.street}, ${props.asset.site.address.city}, ${props.asset.site.address.state}, ${props.asset.site.address.country}`}</Text>
        </View>
      </View>
    </View>
  );
};

export default HistoryContent;
