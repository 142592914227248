import React from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import { styles } from './style';

export default function Tag({ ...props }) {
  const {
    title,
    price,
    options,
    recommend,
    detail,
    onPress,
    selectedSub,
    currentSubscription,
    value,
    freeTime,
    monthsFree,
    tier,
    freeTags,
  } = props;
  const selectable = !currentSubscription || tier > currentSubscription?.tier;

  return (
    <TouchableOpacity
      onPress={() => {
        if (selectable) {
          onPress();
        }
      }}
    >
      <View style={[styles.selectedTag, !selectable && { opacity: 0.5 }]}>
        <View style={[styles.selectedContent, freeTime && { marginBottom: 18 }]}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Text style={styles.selectedText}>{title}</Text>
            <View
              style={{
                flexDirection: 'row',
              }}
            >
              <Text
                style={{
                  fontSize: 18,
                  paddingTop: 8,
                  fontFamily: 'NunitoSans_700Bold',
                  color: '#6B6B6B',
                }}
              >
                {price}
              </Text>
              <View style={styles.selectedDot}>{selectedSub == value && <View style={styles.selected}></View>}</View>
            </View>
          </View>
          <View style={styles.tagInfor}>
            {options &&
              options.map((item, id) => {
                return (
                  <View style={[styles.tagScript, { marginRight: 10 }]} key={id}>
                    <Text style={styles.textScript}>{item}</Text>
                  </View>
                );
              })}
            {typeof freeTags === 'number' && freeTags > 0 && (
              <View style={[styles.tagScript, { marginRight: 10 }]}>
                <Text style={styles.textScript}>{`${freeTags}x Free Tags`}</Text>
              </View>
            )}
          </View>
          <Text style={styles.textDetail}>{detail}</Text>
        </View>
        {recommend && (
          <View style={styles.tagRecommend}>
            <Text style={styles.textRecommend}>Recommended</Text>
          </View>
        )}
        {monthsFree && (
          <View style={styles.tagWithFree}>
            <Text style={styles.textWithFree}>3 month free</Text>
          </View>
        )}
      </View>
    </TouchableOpacity>
  );
}
