import React, { useState, useRef } from 'react';
import { Image, Modal, Pressable, ScrollView, Text, TextInput, View, Alert } from 'react-native';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { AiOutlineCloseCircle, AiOutlineUpload } from 'react-icons/ai';

import { BsCalendarMonthFill } from 'react-icons/bs';
import Button from '../Button';
import images from '../../theme/images';
import { styles } from './style';
import { api } from '../../utils/api';
import AddressInput from '../AddressInput';
import InputField from '../../screens/onboarding/authen/joinProject/inputField';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import CreateModal from '../createModal';
import DropInputField from '../DropInputField';
import { Calendar } from 'react-native-calendars';
import moment from 'moment-timezone';
import * as Localization from 'expo-localization';
import CustomArrow from '../CustomArrow';
import { globalStyle } from '../../theme/globalStyle';

const Autocomplete = ({
  value,
  setValue,
  label,
  data,
  siteTypes = [],
  containerStyle,
  onChange: origOnChange,
  icon = 'bike',
  style = {},
  menuStyle = {},
  onPress = {},
  onFocus = false,
  placeholder,
  modalType,
  result = {},
  textSmall = false,
  newType = '',
  projectId = false,
  companyInput = false,
  siteInput = {},
  levelInput = {},
  disableAdd = false,
  hideInput = false,
  showAll = false,
}) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [resultField, setResultField] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [pickedDate, setPickedDate] = useState('');

  const [siteAddress, setSiteAddress] = useState(false);
  const [siteType, selectSiteType] = useState(false);
  const [siteCadence, selectSiteCadence] = useState(false);

  const [filteredData, setFilteredData] = useState(data);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalfilled, setModalFilled] = useState(false);
  const [companyModalVisible, setCompanyModalVisible] = useState(false);
  const [projectInput, setProjectInput] = useState({ title: '', address: '' });
  const [image, setImage] = useState(null);
  const [projectOwner, setProjectOwner] = useState('');
  const [company, setCompany] = useState(companyInput?.id);
  const [createValues, setCreateValues] = useState({
    name: '',
    invoiceEmail: '',
    billingAddress: '',
    billingPhone: '',
    billingEmail: '',
  });
  const periodTesting = [{ title: 'Monthly' }, { title: 'Quarterly' }, { title: 'Biannually' }, { title: 'Annually' }];
  const [createSuccess, setCreateSuccess] = useState(false);
  const createPress = async () => {
    const data = {
      companyName: createValues.name,
      invoiceEmail: createValues.invoiceEmail,
      billingDetails: {
        address: createValues.billingAddress,
        phone: createValues.billingPhone,
        email: createValues.billingEmail,
      },
    };
    const createCompany = await api('v1/company', 'POST', data);
    if (createCompany.status == 200) {
      const result = createCompany.data;
      // console.log("create company result: ", result);
      if (result.success) {
        if (result.companyExists) {
          Alert.alert('Comapny Already Exists', 'Continue with found company?', [
            {
              text: 'Cancel',
              style: 'destructive',
            },
            {
              text: 'Continue',
              onPress: () => {
                setCompanyModalVisible(false);
              },
              style: 'default',
            },
          ]);
          setCompany(result.company._id);
        } else {
          setCreateSuccess(true);
          setCompany(result.company._id);
          return;
        }
      } else {
        console.log('Handle Fail Create');
        alert('Failed to create company, please check your inputed values and try again.');
      }
      setCreateSuccess(false);
    }
  };

  const handleCompanySelect = (item) => {
    setCompany(item._id);
    setCompanyModalVisible(false);
    setModalVisible(true);
  };

  const createCompanyModal = () => {
    setCompanyModalVisible(true);
    setModalVisible(false);
  };

  const filterData = (text) => {
    if (showAll) {
      return data;
    } else {
      return data.filter((val) => val?.title?.toLowerCase()?.indexOf(text?.toLowerCase()) > -1);
    }
  };

  const updateSiteType = (data) => {
    selectSiteType(data);
  };

  const createProject = async () => {
    const details = new FormData();
    details.append(
      'projectDetails',
      JSON.stringify({
        title: projectInput.title,
        address: projectInput.address,
      })
    );
    console.log(companyInput?.id);
    if (companyInput?.id !== undefined) {
      details.append('sites', null);
      details.append('company', companyInput?.id);
      details.append('projectOwner', projectOwner);
      details.append('projectLogo', image);

      if (projectInput.title == '' || projectInput.address == '') {
        return alert('Please enter all required fields');
      } else {
        const project = await api('v1/project', 'POST', details, true);
        if (project.status == 200) {
          console.log(project.data);
          if (project.data.success) {
            setModalFilled(true);
            var projectInfo = project.data.project.project;
            projectInfo.title = project.data.project.project.name;
            projectInfo.id = project.data.project.project._id;
            setValue(projectInfo);
            setMenuVisible(!menuVisible);
          }
        }
      }
    } else {
      Alert.alert('Could not create project, please try again');
    }
  };

  const setAddressValue = (address) => {
    setProjectInput({ ...projectInput, address: address });
  };

  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    if (!result.cancelled) {
      console.log(result);
      let imgObj = {
        type: result.type,
        uri: result.uri,
        name: result.fileName,
      };
      setImage(imgObj);
    }
  };

  const fileImageRef = useRef(null);

  const handleImageSelect = () => {
    fileImageRef.current.click();
  };

  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      // Handle the selected file here
      console.log('Selected File:', selectedFile);
      setImage(selectedFile);
    }
  };

  const createLevel = async () => {
    console.log(siteInput);
    console.log(projectId);
    const level = await api('v1/level', 'POST', {
      levelName: value,
      site: siteInput.id,
      project: projectId,
    });
    if (level.status == 200) {
      let levelData = level.data.level;
      levelData.title = levelData.name;
      levelData.id = levelData._id;
      setValue(levelData);
      setModalFilled(false);
      setModalVisible(false);
      setMenuVisible(false);
    }
  };

  const createArea = async () => {
    const area = await api('v1/area', 'POST', {
      areaName: value,
      project: projectId,
      site: siteInput.id,
      level: levelInput.id,
    });
    if (area.status == 200) {
      let areaData = area.data.area;
      areaData.title = areaData.name;
      areaData.id = areaData._id;
      setValue(areaData);
      setModalFilled(false);
      setModalVisible(false);
      setMenuVisible(false);
    }
  };

  const createSite = async () => {
    const site = await api('v1/site', 'POST', {
      project: projectId,
      sites: [value],
      addManual: true,
      address: siteAddress,
      testCadence: {
        type: siteCadence,
        startDate: pickedDate,
        startDateUTC: moment(pickedDate, 'YYYY-MM-DD').utc().format('YYYY-MM-DD'),
      },
      siteType: siteType,
    });
    if (site.status == 200) {
      var data = site.data.createSite;
      data.title = site.data.createSite.name;
      data.name = site.data.createSite.name;
      data.id = site.data.createSite._id;
      console.log('success data for site: ', data);
      setValue(data);
      setModalFilled(false);
      setModalVisible(false);
      setMenuVisible(false);
    }
  };

  const popUpModal =
    modalType == 'Project' ? (
      <Modal
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(false);
        }}
      >
        <View style={styles.popupModal}>
          <KeyboardAwareScrollView
            contentContainerStyle={modalfilled ? styles.popupWindowSuccess : styles.popupWindow}
            bounces={false}
          >
            {modalfilled ? (
              <>
                <Image style={styles.popupIcon} source={images.emailSentIcon} />
                <Text style={styles.popupTitle}>Added!</Text>
                <Text style={styles.popupScript}>You can continue to add assets to this project.</Text>
                <Button
                  title={'Ok'}
                  buttonStyle={[
                    styles.blueButton,
                    {
                      opacity: 1,
                      marginTop: 40,
                    },
                  ]}
                  textStyle={styles.blueButtonText}
                  onPress={() => {
                    setModalVisible(false);
                  }}
                />
              </>
            ) : (
              <View style={{ width: '100%' }}>
                <View style={styles.addModalTitleContainer}>
                  <Text style={styles.addModalTitle}>Add a new project</Text>
                  <Pressable onPress={() => setModalVisible(false)}>
                    <Image style={styles.popupIconModal} source={images.lightGreyX} />
                  </Pressable>
                </View>
                {company == '' ? (
                  <View style={styles.textFieldModal}>
                    <View style={styles.firstTitle}>
                      <Text style={styles.titleText}>Select Company</Text>
                    </View>
                    <InputField
                      title={'Company Name'}
                      required={true}
                      data={[]}
                      onPress={() => {
                        setCompany('');
                      }}
                      onChangeText={setCompany}
                      text={company}
                      createOption="Create Comapny"
                      createOptionPress={() => createCompanyModal()}
                      selectCompany={handleCompanySelect}
                    />
                  </View>
                ) : (
                  <View style={styles.textFieldModal}>
                    <View style={styles.firstTitle}>
                      <Text style={styles.titleText}>Project</Text>
                    </View>

                    <Text style={styles.inputHeaderModal}>
                      Title <Text style={styles.required}> *</Text>
                    </Text>
                    <TextInput
                      placeholder={placeholder}
                      style={styles.input}
                      placeholderTextColor="#00000080"
                      onChangeText={(text) => {
                        setProjectInput({ ...projectInput, title: text });
                      }}
                      value={projectInput.title}
                    />
                    <Text style={styles.inputHeaderModal}>
                      Address <Text style={styles.required}> *</Text>
                    </Text>
                    <AddressInput selectAddress={setAddressValue} />
                    <View style={[styles.title, { marginTop: 30 }]}>
                      <Text style={styles.titleText}>Project Owner</Text>
                      {/* <Text style={styles.optional}> - optional</Text> */}
                    </View>

                    <Text style={styles.inputHeaderModal}>
                      Title <Text style={styles.required}>*</Text>
                    </Text>

                    <TextInput
                      placeholder="Enter name"
                      style={styles.input}
                      placeholderTextColor="#00000080"
                      onChangeText={(text) => {
                        setProjectOwner(text);
                      }}
                      value={projectOwner}
                    />
                    <Text style={styles.inputHeaderModal}>
                      Logo <Text style={styles.optional}> - optional</Text>
                    </Text>
                    <button style={globalStyle.uploadButton} onClick={handleImageSelect}>
                      Select Image
                    </button>
                    <input ref={fileImageRef} type="file" style={{ display: 'none' }} onChange={handleImageChange} />
                    {/* <Pressable onPress={pickImage}>
                      {image == null && (
                        <View style={styles.imgPicker}>
                          <View style={styles.dasherBorder}>
                            <AiOutlineUpload name="upload" style={styles.imgIcon} />
                            <Text style={styles.uploadText}>Accept .jpeg or png files</Text>
                          </View>
                        </View>
                      )}
                    </Pressable> */}

                    {image && (
                      <View style={styles.imgPickedContainer}>
                        <View style={styles.imgPicked}>
                          <Image source={{ uri: image.uri }} style={{ width: 29, height: 29, borderRadius: 99 }} />
                        </View>
                        <AiOutlineCloseCircle name="close" style={styles.closeIcon} onPress={() => setImage(null)} />
                      </View>
                    )}
                    <Button
                      title={'Next'}
                      buttonStyle={[
                        // fieldsValidated
                        //   ?
                        styles.blueButtonActive,
                        // : styles.blueButton
                        {
                          marginTop: 30,
                        },
                      ]}
                      textStyle={styles.blueButtonText}
                      onPress={() => {
                        createProject();
                      }}
                    />
                  </View>
                )}
              </View>
            )}
          </KeyboardAwareScrollView>
        </View>
      </Modal>
    ) : (
      <Modal
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(false);
        }}
      >
        <View style={styles.popupModal}>
          <View style={modalfilled ? styles.popupWindowSuccess : styles.popupWindow}>
            {modalfilled ? (
              <>
                <Image style={styles.popupIcon} source={images.emailSentIcon} />
                <Text style={styles.popupTitle}>Added!</Text>
                <Text style={styles.popupScript}>You can continue to add assets to this project.</Text>
                <Button
                  title={'Ok'}
                  buttonStyle={[
                    styles.blueButton,
                    {
                      opacity: 1,
                      marginTop: 40,
                    },
                  ]}
                  textStyle={styles.blueButtonText}
                  onPress={() => {
                    setModalVisible(false);
                    setMenuVisible(false);
                  }}
                />
              </>
            ) : (
              <>
                <View style={styles.addModalTitleContainer}>
                  <Text style={styles.addModalTitle}>Add a new {modalType}</Text>
                  <Pressable onPress={() => setModalVisible(false)}>
                    <Image style={styles.popupIconModal} source={images.lightGreyX} />
                  </Pressable>
                </View>
                <View style={styles.textFieldModal}>
                  <View style={styles.firstTitle}>
                    <Text style={styles.titleText}>{modalType} name</Text>
                  </View>

                  <TextInput
                    placeholder={`Enter a ${modalType}`}
                    style={styles.input}
                    placeholderTextColor="#00000080"
                    onChangeText={(text) => {
                      setValue(text);
                    }}
                    value={value?.title ? value.title : value}
                  />

                  {modalType == 'Site' && (
                    <>
                      <AddressInput selectAddress={(data) => setSiteAddress(data)} />
                      <DropInputField
                        placeholder={'Select site type'}
                        data={siteTypes}
                        selectedDropdownItem={siteType}
                        setSelectedDropdownItem={(data) => updateSiteType(data)}
                        havePosition={false}
                      />
                      {siteType && (
                        <>
                          <DropInputField
                            placeholder={'Select test cadence'}
                            data={periodTesting}
                            selectedDropdownItem={siteCadence}
                            setSelectedDropdownItem={selectSiteCadence}
                            havePosition={false}
                          />
                          <Pressable onPress={() => setOpenCalendar(!openCalendar)}>
                            <View style={openCalendar ? styles.inputDrop : [styles.inputDrop, { paddingBottom: 15 }]}>
                              <View style={styles.dropTitleContainer}>
                                <View style={styles.calendarTitle}>
                                  <BsCalendarMonthFill name="calendar-month" style={styles.calendarIcon} />
                                  <Text style={siteCadence == 'Select' ? styles.unpickedTitle : styles.dropList}>
                                    Starting Date: {pickedDate}
                                  </Text>
                                </View>

                                {openCalendar ? (
                                  <FiChevronUp name="angle-up" style={styles.imgIcon} />
                                ) : (
                                  <FiChevronDown name="angle-down" style={styles.imgIcon} />
                                )}
                              </View>
                              {openCalendar && (
                                <Calendar
                                  renderArrow={(direction, onPress) => (
                                    <CustomArrow direction={direction} onPress={onPress} />
                                  )}
                                  hideArrows={false}
                                  markingType={'multi-dot'}
                                  // markedDates={marked}
                                  enableSwipeMonths={true}
                                  theme={{
                                    arrowColor: '#0F0000',
                                  }}
                                  // customHeader={(day)=>{
                                  //   return(<Text>{selected}</Text>)
                                  // }}
                                  onDayPress={(day) => {
                                    setPickedDate(day.dateString);
                                    setOpenCalendar(!openCalendar);
                                  }}
                                />
                              )}
                            </View>
                          </Pressable>
                        </>
                      )}
                    </>
                  )}

                  <Button
                    title={'Next'}
                    buttonStyle={[
                      // fieldsValidated
                      //   ?
                      styles.blueButtonActive,
                      // : styles.blueButton
                      {
                        marginTop: 30,
                      },
                    ]}
                    textStyle={styles.blueButtonText}
                    onPress={() => {
                      if (modalType == 'Level') {
                        createLevel();
                      } else if (modalType == 'Area') {
                        createArea();
                      } else if (modalType == 'Site') {
                        createSite();
                      }
                    }}
                  />
                </View>
              </>
            )}
          </View>
        </View>
      </Modal>
    );

  const recommendDropdown = (dropdownData) => (
    <View style={styles.recommendBox}>
      <ScrollView nestedScrollEnabled={true} style={dropdownData ? { maxHeight: 100 } : ''}>
        {dropdownData.map((datum, i) => {
          var data = datum;
          data.name = datum.title;
          return (
            // <TouchableWithoutFeedback key={i} activeOpacity={1.0}>
            <View key={i}>
              <Pressable
                onPress={() => {
                  setValue(datum);
                  setMenuVisible(!menuVisible);
                  setResultField(true);
                }}
              >
                <View style={styles.recommendContent}>
                  {datum.image && <Image style={styles.recommendIcon} source={{ uri: datum.image }} />}

                  <View
                    style={{
                      paddingLeft: 4,
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: 'NunitoSans_700Bold',
                        alignSelf: 'center',
                      }}
                    >
                      {datum.title}
                    </Text>
                  </View>
                </View>
              </Pressable>
            </View>
            // </TouchableWithoutFeedback>
          );
        })}
      </ScrollView>
      {disableAdd == false && (
        <Pressable
          onPress={() => {
            setModalFilled(false);
            setModalVisible(true);
          }}
        >
          <Text style={styles.addReq}>+ Add another {newType}</Text>
        </Pressable>
      )}
    </View>
  );

  return (
    <>
      <View>
        <View style={containerStyle}>
          <Pressable
            onPress={() => {
              setMenuVisible(!menuVisible);
              setModalFilled(false);
            }}
          >
            <View style={styles.inputContainer}>
              {value?.name?.length > 0 && value?.logo && (
                <Image source={value.logo} style={{ width: 16, height: 16, marginRight: 6 }} />
              )}
              {hideInput == false && (
                <TextInput
                  onFocus={() => {
                    if (value?.length === 0) {
                      setMenuVisible(true);
                    }
                    if (onFocus) {
                      onFocus();
                    }
                  }}
                  placeholder={placeholder}
                  // onBlur={() => setMenuVisible(false)}
                  style={[styles.textInput, textSmall ? { fontSize: 12 } : { fontSize: 16 }]}
                  onChangeText={(text) => {
                    origOnChange(text);
                    if (text && text.length > 0) {
                      setFilteredData(filterData(text));
                    } else if (text && text.length === 0) {
                      setFilteredData(data);
                    }
                    setMenuVisible(true);
                    setValue(text);
                  }}
                  value={value?.name}
                ></TextInput>
              )}

              {menuVisible && <FiChevronUp name="angle-up" style={styles.imgIconDrop} />}
            </View>
          </Pressable>
          {menuVisible && (showAll ? recommendDropdown(data) : recommendDropdown(filteredData))}

          {hideInput && (
            <Pressable
              onPress={() => {
                setModalFilled(false);
                setModalVisible(true);
              }}
            >
              <Text style={styles.addReq}>+ Add {newType}</Text>
            </Pressable>
          )}

          {menuVisible == false && hideInput == false && (
            <FiChevronDown
              name="angle-down"
              style={{
                position: 'absolute',
                top: 17,
                fontSize: 17,
                right: 13,
                color: '#0F0000',
              }}
            />
          )}
        </View>
        {popUpModal}
        <CreateModal
          modalVisible={companyModalVisible}
          setModalVisible={setCompanyModalVisible}
          createValues={createValues}
          setCreateValues={setCreateValues}
          createSuccess={createSuccess}
          completeCreatePress={() => setModalVisible(true)}
          createPress={createPress}
        />
      </View>
    </>
  );
};

export default Autocomplete;
