import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';
import AccountSettings from '../../../screens/admin/accountSettings';
import MyProject from '../../../screens/admin/accountSettings/myProject';
import ProjectInfo from '../../../screens/admin/accountSettings/myProject/innerProject/projectInfo';
import SubscriptionBilling from '../../../screens/admin/accountSettings/subscription';
import Login from '../../../screens/guest/login';
import Setting from '../../../screens/tabs/setting';
import PersonalInfor from '../../../screens/tabs/setting/personalInfor';
import Subscription from '../../../screens/tabs/setting/subscription';
import TestingSchedule from '../../../screens/admin/accountSettings/myProject/innerProject/testingSchedule';

const Stack = createNativeStackNavigator();

const SettingNavigate = ({ ...props }) => (
  <Stack.Navigator>
    <Stack.Screen
      {...props}
      name={'Setting'}
      component={Setting}
      options={{
        headerShown: false,
        headerStyle: { backgroundColor: 'transparent' },
        tabBarVisible: false,
      }}
    />
    <Stack.Screen
      {...props}
      name={'PersonalInfor'}
      component={PersonalInfor}
      options={{
        headerShown: false,
        headerStyle: { backgroundColor: 'transparent' },
        tabBarVisible: false,
      }}
    />
    <Stack.Screen
      {...props}
      name={'Subscription'}
      component={Subscription}
      options={{
        headerShown: false,
        headerStyle: { backgroundColor: 'transparent' },
        tabBarVisible: false,
      }}
    />
    <Stack.Screen
      {...props}
      name={'AccountSettings'}
      component={AccountSettings}
      options={{
        headerShown: false,
        headerStyle: { backgroundColor: 'transparent' },
        tabBarVisible: false,
      }}
    />
    <Stack.Screen
      {...props}
      name={'SubscriptionBilling'}
      component={SubscriptionBilling}
      options={{
        headerShown: false,
        headerStyle: { backgroundColor: 'transparent' },
      }}
    />
    <Stack.Screen
      {...props}
      name={'MyProject'}
      component={MyProject}
      options={{
        headerShown: false,
        headerStyle: { backgroundColor: 'transparent' },
      }}
    />
    <Stack.Screen
      {...props}
      name={'ProjectInfo'}
      component={ProjectInfo}
      options={{
        headerShown: false,
        headerStyle: { backgroundColor: 'transparent' },
      }}
    />
    <Stack.Screen
      {...props}
      name={'TestingSchedule'}
      component={TestingSchedule}
      options={{
        headerShown: false,
        headerStyle: { backgroundColor: 'transparent' },
      }}
    />
  </Stack.Navigator>
);

export default SettingNavigate;
