import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../theme/colors';

export const styles = StyleSheet.create({
  bottomBarNavContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    paddingBottom: 0,
  },
  bottomBarWhiteBackground: {
    width: '100%',
    height: 92,
  },
  bottomNav: {
    position: 'absolute',
    zIndex: 9999,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: 'center',
    alignContent: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    paddingBottom: 32,
  },
  plainTabBar: {
    backgroundColor: '#FFF',
    height: 62,
    elevation: 5,
    shadowColor: '#000000',
    shadowOpacity: 0.3,
    shadowRadius: 2,
    shadowOffset: {
      height: 1,
      width: 1,
    },
  },
  tabNavIcon: {
    width: 30,
    height: 30,
    resizeMode: 'contain',
  },
  iconInactive: {
    opacity: 0.5,
  },
  cameraIcon: {
    width: 35,
    height: 35,
    marginTop: -25,
  },
});
