import React, { useEffect, useState } from 'react';
import { Text, View, Pressable, Image, TextInput, Platform, SafeAreaView, Modal } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { styles } from './style';
import images from '../../../../theme/images';
import Button from '../../../../components/Button';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { api } from '../../../../utils/api';
import { getUserRoleString } from '../../../../utils/userUtils';
import Autocomplete from '../../../../components/AutoComplete/autoComplete';
import { BsFillPencilFill } from 'react-icons/bs';
import SitePickerModal from './sitePickerModal';

function ScanPopUp({ ...props }) {
  const { asset, setAsset } = props;
  const [user, setUser] = useState(null);
  const [siteModalVisble, setSiteModalVisible] = useState(false);

  useEffect(() => {
    if (user == null) {
      getPopupDetails();
    }
  });

  const getPopupDetails = async () => {
    let user = await AsyncStorage.getItem('user');
    user = JSON.parse(user);
    setUser(user);
  };

  return (
    <View>
      <Modal animationType="fade" transparent={true} visible={props.modalVisible} onRequestClose={props.onRequestClose}>
        <View style={styles.container}>
          <View style={styles.popupWindow}>
            <View style={styles.popupHeader}>
              <Image style={styles.confirmationTileIcon} source={images.confirmationTileIcon} />
              <Text
                style={[
                  styles.textBoldLarge,
                  {
                    marginTop: 25,
                  },
                ]}
              >
                Please confirm the following:
              </Text>
            </View>
            <Text style={styles.script}>Test is being conducted by</Text>
            <View style={styles.historyDetailInfo}>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Image style={styles.popupIcon} source={{ uri: user ? user.profilePicture : '' }} />
                <Text style={[styles.textBold, { paddingLeft: 4 }]}>
                  {user ? `${user.firstName} ${user.lastName}` : ''}
                </Text>
              </View>
              <View style={styles.historyDetailPosition}>
                <Text style={styles.position}>{user ? `${getUserRoleString(user.role)}` : ''}</Text>
              </View>
            </View>
            <View style={styles.locationTitle}>
              <Text style={styles.location}>Location</Text>
              <View style={styles.penIcon}>
                <Pressable
                  onPress={() => {
                    setSiteModalVisible(true);
                  }}
                >
                  <BsFillPencilFill name="pen" style={{ fontSize: 13 }}></BsFillPencilFill>
                </Pressable>
              </View>
            </View>
            <View style={styles.historyDetailLocation}>
              <View style={styles.locationDetail}>
                <Text style={styles.textBold}>{asset.site.name}</Text>
              </View>
              <Text
                style={[
                  styles.textRegular,
                  {
                    paddingTop: 4,
                  },
                ]}
              >
                {asset
                  ? `${asset.site.address.street}, ${asset.site.address.city}, ${asset.site.address.state}, ${asset.site.address.country}`
                  : ''}
              </Text>
            </View>
            <Button
              title={'Confirm'}
              buttonStyle={[
                styles.blueButton,
                {
                  marginTop: 24,
                  marginHorizontal: 10,
                },
              ]}
              textStyle={styles.blueButtonText}
              onPress={props.onSubmit}
            />
            <Button
              title={'Cancel'}
              buttonStyle={[styles.blankButton]}
              textStyle={styles.blankButtonText}
              onPress={props.onCancel}
            />
          </View>
        </View>
        <SitePickerModal
          modalVisible={siteModalVisble}
          setModalVisible={setSiteModalVisible}
          asset={asset}
          setAsset={setAsset}
        />
      </Modal>
    </View>
  );
}
export default ScanPopUp;
