import React, { useRef } from 'react';
import { Image, Text, TouchableOpacity, View, PanResponder, Animated } from 'react-native';
import { styles } from './style';
import { MdDeleteForever, MdDragIndicator } from 'react-icons/md';
import { FaChevronRight } from 'react-icons/fa';
import Status from '../../../assetInfo/status';

export default function PendingTask({ ...props }) {
  const { task, setTaskToDelete } = props;
  const pan = useRef(new Animated.ValueXY()).current;

  const panResponder = useRef(
    PanResponder.create({
      onMoveShouldSetPanResponder: (evt, gestureState) => {
        // Only set the pan responder if the user is swiping to right
        return Math.abs(gestureState.dx) > Math.abs(gestureState.dy * 3) && gestureState.dx > 0;
      },
      onPanResponderMove: Animated.event([null, { dx: pan.x }], { useNativeDriver: false }),

      onPanResponderRelease: (evt, gestureState) => {
        if (gestureState.dx > 150) {
          setTaskToDelete(task);
        }
        Animated.spring(pan, { toValue: { x: 0, y: 0 }, useNativeDriver: false }).start();
      },
      onPanResponderTerminate: () => {
        Animated.spring(pan, { toValue: { x: 0, y: 0 }, useNativeDriver: false }).start();
      },
    })
  ).current;

  const handlePress = () => {
    props.navigation.getParent().setParams({
      screen: 'TaskListAll',
      isAdmin: true,
    });
    props.navigation.navigate('QrCodeNav', {
      screen: 'TestAssetFlowStep1',
      fromQRCodeNav: true,
      asset: task.asset,
      isAdmin: true,
    });
  };

  return (
    <View style={styles.taskContainer}>
      <View style={styles.contentBlock}>
        <View style={styles.contentDelete}>
          <MdDeleteForever
            name="delete"
            style={{
              fontSize: 30,
            }}
          />
        </View>
        <Animated.View
          style={[styles.animatedBlock, { transform: [{ translateX: pan.x }] }]}
          {...panResponder.panHandlers}
        >
          <TouchableOpacity onPress={handlePress}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <MdDragIndicator
                name="drag-indicator"
                style={{
                  fontSize: 22,
                  color: '#CFCFCF',
                }}
              />
              <View
                style={{
                  paddingLeft: 8,
                }}
              >
                <Image style={styles.taskIcon} source={{ uri: task.asset.assetType.icon }} />
              </View>
              <View
                style={{
                  paddingLeft: 8,
                  flex: 1,
                }}
              >
                <View style={styles.taskTitle}>
                  <Text style={styles.textBold}>{task.asset.name}</Text>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    {task.asset.currentStatus === 'Needs Repair' && <Status status={task.asset.currentStatus} />}
                    <FaChevronRight name="right" style={styles.leadIcon} />
                  </View>
                </View>
                <View style={styles.taskDetail}>
                  <Text style={styles.textMicroBold}>QR Code</Text>
                  <Text
                    style={[
                      styles.textLightSmall,
                      {
                        paddingLeft: 3,
                      },
                    ]}
                  >
                    {task.asset.tag.code}
                  </Text>
                </View>
              </View>
            </View>
          </TouchableOpacity>
        </Animated.View>
      </View>
    </View>
  );
}
