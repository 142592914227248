import React, { useEffect, useState } from 'react';
import { SafeAreaView, View, Text, Image } from 'react-native';
import { styles } from './style';
import images from '../../../../../theme/images';
import Button from '../../../../../components/Button';
import NavigationHeader from '../../../../../components/SignUp/SingUpNavigation';
import { useIsFocused } from '@react-navigation/native';
import Loading from '../../../../../components/Loading';
import { api } from '../../../../../utils/api';

export default function TestAssetFlowStep4({ ...props }) {
  const params = props.route?.params;
  const [asset, setAsset] = useState(params.asset);
  const isFocused = useIsFocused();
  const [loading, setLoading] = useState(true);

  const addAssetTest = async (obj) => {
    const response = await api(`v1/asset/${params.asset._id}/test`, 'POST', obj, true);

    if (response.status == 200 && response.data.success) {
      setAsset(response.data.data.asset);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isFocused) {
      if (params.status) {
        var formData = new FormData();
        formData.append('address', JSON.stringify(asset.site));
        formData.append('asset', asset._id);
        formData.append('result', params.status);
        //         formData.append("address", JSON.stringify(params.asset.site.address));
        //         formData.append("asset", params.asset._id);
        //         formData.append("result", params.status);
        for (let sv = 0; sv < Object.keys(params.selectedValues).length; sv++) {
          const element = Object.keys(params.selectedValues)[sv];
          const values = Object.values(params.selectedValues)[sv];

          var infoKey = `${element}_info`;
          var infoVal = JSON.stringify({
            status: values.status,
            info: values.details.info,
          });
          formData.append(infoKey, infoVal);

          var imgKey = `${element}_img`;
          var imgVal = values.details.img;
          if (imgVal.uri) {
            let imgObj = {
              type: imgVal.type,
              uri: imgVal.uri,
              name: imgVal.fileName,
            };
            formData.append(imgKey, imgObj);
          }
        }
        addAssetTest(formData);
      }
    }
  }, [isFocused]);

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.loading}>
        <View style={styles.loadPercent}></View>
      </View>
      <NavigationHeader onPress={() => props.navigation.goBack()} header={'Testing'} menuStyles={styles.menu} />
      {loading ? (
        <Loading opacity={0} />
      ) : (
        <View style={styles.body}>
          <View style={styles.boxContent}>
            <Image
              style={{
                width: 70,
                height: 70,
              }}
              source={images.successIcon}
            />
            <Text
              style={[
                styles.textBoldMedium,
                {
                  paddingTop: 15,
                },
              ]}
            >
              Success!
            </Text>
            <Text style={styles.script}>The test has been successfully logged!</Text>
          </View>
          <View style={styles.bottomBut}>
            <Button
              title={params.taskListDetails ? 'Next Test' : 'View Asset'}
              buttonStyle={[styles.blueButton, { marginBottom: 10 }]}
              textStyle={styles.blueButtonText}
              onPress={() => {
                if (params.taskListDetails) {
                  props.navigation.navigate('UserTasks', {
                    screen: 'TaskList',
                    params: {
                      project: props.route.params.taskListDetails.project,
                      dueDateUTC: props.route.params.taskListDetails.dueDateUTC,
                      continueTesting: true,
                    },
                  });
                } else {
                  props.navigation.navigate('TabNavigator', {
                    screen: 'QrCodeNav',
                    params: {
                      screen: 'AssetInfo',
                      asset: asset,
                      isAdmin: params.isAdmin,
                      fromQrCodeNav: true,
                    },
                    isAdmin: params.isAdmin,
                    fromQrCodeNav: true,
                    asset: asset,
                  });
                }
              }}
            />
            <Button
              title={'Home'}
              buttonStyle={[styles.blankButton, { marginBottom: 10 }]}
              textStyle={styles.blankButtonText}
              onPress={() =>
                props.navigation.navigate('TabNavigator', {
                  screen: 'QrCodeNav',
                  params: {
                    screen: 'QrScanner',
                    isAdmin: props.route.params.isAdmin,
                  },
                })
              }
            />
          </View>
        </View>
      )}
    </SafeAreaView>
  );
}
