import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../../../../theme/colors';

export const styles = StyleSheet.create({
  menu: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 24,
    alignItems: 'center',
  },
  textBoldMedium: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 16,
  },
  textBoldSmall: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 12,
  },
  textRegularSmall: {
    fontFamily: 'NunitoSans_400Regular',
    fontSize: 12,
  },
  assetImageContainer: {
    backgroundColor: colors.white,
    padding: 5,
    borderRadius: 100,
  },
  assetImage: {
    backgroundColor: colors.mainBlue,
    padding: 10,
    borderRadius: 100,
  },
});
