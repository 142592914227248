import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../../../theme/colors';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  selectedTag: {
    borderWidth: 1,
    borderRadius: 16,
    borderColor: '#C7C7C7',
    paddingHorizontal: 11,
    paddingBottom: 16,
    marginTop: 19,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  selectedContent: {
    alignItems: 'center',
    paddingTop: 16,
    paddingHorizontal: 16,
  },
  loading: {
    height: 8,
    backgroundColor: 'white',
    marginTop: 24,
  },
  loadPercent: {
    height: 8,
    width: '50 %',
    backgroundColor: '#132FF5',
  },
  selectedText: {
    fontSize: 18,
    fontFamily: 'NunitoSans_700Bold',
  },
  textDetail: {
    paddingTop: 16,
    textAlign: 'center',
    fontFamily: 'NunitoSans_400Regular',
  },
  blankButton: {
    paddingVertical: 10,
  },
  blankButtonText: {
    color: '#132FF5',
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    textAlign: 'center',
  },
  assignButton: {
    alignContent: 'flex-end',
    paddingHorizontal: 27,
    width: '100%',
    marginTop: 52,
  },
  menu: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 20,
    paddingBottom: 36,
    paddingHorizontal: 26,
    alignItems: 'center',
  },
  selectDot: {
    position: 'absolute',
    right: 4,
    top: 8,
    borderColor: '#979797',
    borderRadius: 32,
    borderWidth: 1,
    paddingTop: 4,
    width: 28,
    height: 28,
    alignItems: 'center',
  },
  selected: {
    borderRadius: 32,
    width: 18,
    height: 18,
    backgroundColor: '#132FF5',
    alignItems: 'center',
  },
});
