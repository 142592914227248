import { StyleSheet } from 'react-native';
import colors from '../../theme/colors';

export const styles = StyleSheet.create({
  assetInfor: {
    backgroundColor: 'white',
    flex: 1,
    marginHorizontal: 26,
    marginTop: 56,
    paddingHorizontal: 11,
    paddingVertical: 16,
    borderRadius: 8,
  },
  assetHistoryRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 12,
  },
  assetTypeContent: {
    backgroundColor: 'rgba(0,0,0,0.1)',
    borderRadius: 16,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    paddingHorizontal: 16,
    paddingVertical: 3,
  },
  assetStatusContent: {
    backgroundColor: 'rgba(0,0,0,0.1)',
    borderRadius: 16,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    paddingHorizontal: 8,
    paddingVertical: 3,
  },
  assetStatus: {
    borderRadius: 13,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    flexDirection: 'row',
    paddingHorizontal: 8,
    paddingVertical: 3,
    marginRight: 4,
  },
  assetStatusText: {
    color: '#046736',
    fontWeight: 'bold',
    marginRight: 4,
  },
  assetLocation: {
    backgroundColor: 'white',
    marginHorizontal: 26,
    marginVertical: 16,
    paddingHorizontal: 11,
    paddingVertical: 16,
    borderRadius: 8,
  },
  assetMap: {
    width: '100%',
    height: '100%',
    zIndex: -1,
    borderRadius: 10,
    borderWidth: 1,
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
  },
  assetHistory: {
    marginHorizontal: 26,
    marginTop: 19,
    paddingVertical: 16,
    borderRadius: 8,
    marginBottom: 100,
  },
  assetHistoryContent: {
    paddingVertical: 11,
    paddingHorizontal: 11,
  },
  assetHistoryRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
  },
  assetHistoryDetail: {
    backgroundColor: 'white',
    marginTop: 16,
    paddingHorizontal: 9,
    paddingVertical: 10,
    borderRadius: 8,
  },
  historyDetailInfo: {
    backgroundColor: 'rgba(0,0,0,0.1)',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 12,
    borderRadius: 16,
  },
  historyDetailPosition: {
    backgroundColor: '#FFFFFF',
    borderRadius: 16,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    paddingHorizontal: 10,
  },
  historyDetailLocation: {
    backgroundColor: 'rgba(0,0,0,0.1)',
    paddingVertical: 12,
    paddingHorizontal: 21,
    borderRadius: 16,
  },
  blueButtonText: {
    color: colors.white,
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 18,
    textAlign: 'center',
  },
  blueButton: {
    width: '100%',
    borderRadius: 8,
    backgroundColor: colors.mainBlue,
    paddingVertical: 13,
  },
  assetInforContent: {
    fontSize: 14,
    fontFamily: 'NunitoSans_400Regular',
  },
  mapView: {
    borderRadius: 10,
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 8,
    height: 200,
  },
  noteScript: {
    backgroundColor: '#D8D8D8',
    paddingHorizontal: 7,
    paddingVertical: 7,
    borderRadius: 8,
  },
  bottomNav: {
    height: 95,
    backgroundColor: '#FFFFFF',
    paddingHorizontal: 27,
    paddingVertical: 17,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    elevation: 10,
  },
  sectionContainerStyle: {
    borderColor: '#979797',
    borderWidth: 1,
    marginBottom: 8,
    borderRadius: 15,
  },
  contentPict: {
    width: 130,
    height: 130,
    borderRadius: 16,
    marginTop: 8,
  },
  extraContentHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  extraContentHeaderTitle: {
    color: '#6B6B6B',
    fontFamily: 'NunitoSans_700Bold',
  },
  extraContentHeaderStatus: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  statusText: {
    fontSize: 12,
    color: '#046836',
    fontFamily: 'NunitoSans_300Light',
    marginRight: 8,
  },
  detailScript: {
    marginTop: 8,
    fontSize: 12,
    marginBottom: 5,
    fontFamily: 'NunitoSans_400Regular',
  },
  detailBoxContent: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  jobTitle: {
    fontSize: 12,
    color: '#132FF5',
    fontWeight: 'bold',
    marginRight: 8,
  },
  avatar: {
    width: 27,
    height: 27,
    tintColor: 'black',
  },
  locationTitle: {
    marginTop: 8,
    fontSize: 14,
    fontFamily: 'NunitoSans_700Bold',
  },
  historyTitle: {
    fontSize: 18,
    color: '#132FF5',
    fontWeight: 'bold',
  },
  download: {
    flexDirection: 'row',
    marginHorizontal: 26,
    marginTop: 21,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  downloadText: {
    color: '#132FF5',
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
  },
  validate: {
    borderBottomWidth: 1,
    width: '100%',
    alignItems: 'center',
    borderColor: '#bdbdbd',
  },
  note: {
    marginTop: 14,
    marginBottom: 8,
    marginTop: 8,
    fontSize: 14,
    fontFamily: 'NunitoSans_700Bold',
  },
  rbText: {
    paddingVertical: 20,
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
  },
  detailTitle: {
    marginTop: 8,
    fontFamily: 'NunitoSans_700Bold',
  },
  historyLocationContent: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  historyLocationScript: {
    fontSize: 15,
    fontFamily: 'NunitoSans_400Regular',
    paddingTop: 4,
  },
  accordionHeader: {
    borderRadius: 12,
    borderColor: '#979797',
    padding: 11,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  accordionHeaderTitle: {
    paddingRight: 11,
    fontSize: 16,
    fontFamily: 'NunitoSans_700Bold',
  },
  accordionHeaderScript: {
    fontSize: 12,
    fontFamily: 'NunitoSans_400Regular_Italic',
  },
  textBold: {
    fontFamily: 'NunitoSans_700Bold',
  },
});
