import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../../../../theme/colors';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  loading: {
    height: 8,
    backgroundColor: 'white',
    marginTop: 24,
  },
  loadPercent: {
    height: 8,
    width: '70%',
    backgroundColor: '#132FF5',
  },
  menu: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 30,
    paddingBottom: 30,
    paddingHorizontal: 24,
    alignItems: 'center',
    backgroundColor: '#EDEDED',
  },
  body: {
    paddingHorizontal: 25,
    paddingVertical: 14,
    height: Dimensions.get('screen').height / 1.2,
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
  title: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
  },
  detail: {
    flexDirection: 'row',
    marginTop: 16,
  },
  textDetail: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 16,
    paddingRight: 6,
  },
  required: {
    color: '#FF4D4D',
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 16,
  },
  inputDrop: {
    borderColor: '#C7C7C7',
    // width: "100%",
    borderWidth: 1,
    borderRadius: 8,
    paddingVertical: 13,
    paddingHorizontal: 8,
    // flex:1
  },
  dropList: {
    fontFamily: 'NunitoSans_700Bold',
    color: '#000000',
    fontSize: 16,
  },
  dropTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    // paddingRight: 16,
  },
  unpickedTitle: {
    fontFamily: 'NunitoSans_400Regular',
    color: '#000000',
    opacity: 0.5,
    fontSize: 16,
  },
  imgIcon: {
    fontSize: 18,
    color: '#000000',
  },
  calendarTitle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  dateField: {
    flexDirection: 'row',
    width: '100%',
    marginTop: 16,
  },
  dropItem: {
    paddingVertical: 16,
    borderBottomColor: '#0000000d',
    borderBottomWidth: 1,
  },
  dropLastItem: {
    paddingVertical: 16,
  },
  testCandence: {
    paddingTop: 8,
    paddingBottom: 24,
    // paddingHorizontal:8,
    backgroundColor: '#E7EAFE',
    borderRadius: 16,
    marginTop: 16,
  },
  description: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 16,
  },
  candidateIcon: {
    width: 30,
    height: 30,
    borderRadius: 100,
  },
  candenceTitle: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    paddingHorizontal: 8,
    borderBottomColor: '#DADBE4',
    paddingBottom: 12,
  },
  candenceContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 12,
    marginTop: 16,
  },
  candenceScript: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 12,
  },
  input: {
    // marginTop:16,
    borderColor: '#C7C7C7',
    width: '100%',
    borderWidth: 1,
    borderRadius: 10,
    paddingVertical: 13,
    paddingLeft: 13,
  },
  addModalTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  addModalTitle: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    color: '#000000',
  },
  popupModal: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  popupWindow: {
    backgroundColor: 'white',
    paddingHorizontal: 16,
    paddingVertical: 24,
    borderRadius: 12,
    alignItems: 'center',
    marginHorizontal: 26,
  },
  popupIconModal: {
    width: 15,
    height: 15,
    paddingHorizontal: 5,
    paddingVertical: 5,
    borderRadius: 16,
  },
  blueButtonActive: {
    borderRadius: 8,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
    marginBottom: 10,
    width: '100%',
    marginTop: 24,
  },
  blueButtonText: {
    color: colors.white,
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 18,
    textAlign: 'center',
  },
  popUpNotice: {
    backgroundColor: '#ffe7e7',
    width: '100%',
    marginTop: 8,
    borderRadius: 8,
    paddingHorizontal: 6,
    paddingVertical: 4,
  },
  noticeText: {
    fontFamily: 'NunitoSans_400Regular',
    color: '#FF4D4D',
    fontSize: 12,
  },
  popUpSelected: {
    marginTop: 16,
    width: '100%',
  },
  popUpPickDate: {
    marginTop: 16,
    width: '100%',
  },
  calendarIcon: {
    fontSize: 25,
    marginRight: 6,
  },
  blueButton: {
    borderRadius: 8,
    opacity: 0.5,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
    marginBottom: 10,
  },
  blueButtonText: {
    color: colors.white,
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 18,
    textAlign: 'center',
  },
  blankButton: {
    paddingVertical: 10,
  },
  blankButtonText: {
    color: '#000000',
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    textAlign: 'center',
    opacity: 0.5,
  },
  assignButton: {
    // position: "absolute",
    backgroundColor: 'white',
    width: '100%',
  },
  dateData: {
    fontSize: 16,
    fontFamily: 'NunitoSans_600SemiBold',
  },
});
