import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  header: {
    backgroundColor: '#F6F6F6',
    paddingTop: 50,
    paddingBottom: 18,
  },
  textBold: {
    color: '#000000',
    fontSize: 14,
    fontFamily: 'NunitoSans_700Bold',
  },
  body: {
    backgroundColor: '#FFFFFF',
    paddingTop: 19,
  },
  viewOptionsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#00000014',
    paddingHorizontal: 15,
  },
  grayBoldTextView: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 14,
    color: '#6B6B6B',
    paddingBottom: 9,
    paddingRight: 18,
  },
  activeBorder: {
    height: 1,
    position: 'absolute',
    backgroundColor: '#132FF5',
    bottom: 0,
  },
  blueBoldText: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 14,
    color: '#132FF5',
    paddingBottom: 9,
    paddingRight: 18,
  },
  resultContainer: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D0D0D0',
    paddingVertical: 8,
    paddingLeft: 7,
    paddingRight: 13,
    marginHorizontal: 25,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 7,
  },
  resultInfo: {
    marginLeft: 13,
  },
  textBoldMedium: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    color: '#000000',
  },
  textBoldSmall: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 11,
    color: '#000000',
  },
  textSemiBoldSmall: {
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 11,
    color: '#000000',
  },
  textLightSmall: {
    fontFamily: 'NunitoSans_300Light',
    fontSize: 11,
    color: '#000000',
  },
  flexRow: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  resultSubInfo: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '60%',
  },
  resultLabel: {
    backgroundColor: '#11BB68',
    paddingVertical: 5,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 13,
    paddingHorizontal: 19,
    marginRight: 18,
    alignSelf: 'flex-start',
  },
  dot: {
    borderRadius: 99,
    backgroundColor: '#046736',
    width: 7,
    height: 7,
    marginRight: 5,
  },
});
