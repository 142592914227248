import React, { useState } from "react";
import { Text, TextInput, View } from "react-native";
import { styles } from "./style";

export default function TextField({ ...props }) {
  const { title, placeholder, setValue } = props;
  const [text, onChangeText] = useState("");

  return (
    <View style={styles.information}>
      <Text style={styles.inforTitle}>{title}</Text>
      <TextInput
        placeholder={placeholder}
        style={styles.input}
        onChangeText={(text) => {
          onChangeText(text);
          setValue(text);
        }}
        value={text}
      />
    </View>
  );
}
