import React, { useLayoutEffect, useState } from 'react';
import { Animated, Modal, StyleSheet, Text, TouchableOpacity, Platform, View, Dimensions } from 'react-native';
// import Modal from 'react-native-modal';
import { GestureHandlerRootView, PanGestureHandler } from 'react-native-gesture-handler';
import colors from '../../theme/colors';
export default function ModalSelect({ modalVisible, setValue, data, setVisible, heading }) {
  const [isModal, setIsModal] = useState(false);
  const [translateY, setTranslateY] = useState(0);
  const [layout, setLayout] = useState(100);

  useLayoutEffect(() => {
    setIsModal(modalVisible);
    setTranslateY(0);
  }, [modalVisible]);

  const renderOption = (item, index) => {
    if (item !== '') {
      return (
        <TouchableOpacity
          activeOpacity={1}
          key={index}
          onPress={() => {
            setVisible(false), setValue(item);
          }}
        >
          <View
            style={[
              styles.panel,
              {
                borderBottomWidth: index < data.length - 1 ? 1 : 0,
                justifyContent: 'center',
              },
            ]}
          >
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'space-between',
              }}
            >
              <Text style={[styles.text, { textAlign: 'center' }]}>{item}</Text>
            </View>
          </View>
        </TouchableOpacity>
      );
    } else {
      <></>;
    }
  };
  const handleTouch = (e) => {
    if (e >= 0) {
      setTranslateY(e);
    }
  };
  const handleStopTouch = (e) => {
    if (e <= layout / 3) {
      setTranslateY(0);
    }
    if (translateY >= layout / 3) {
      setVisible(false);
    }
  };
  return (
    <Modal
      // testID={'modal'}
      // animationType="slide"
      transparent
      visible={isModal}
    >
      <GestureHandlerRootView style={{ width: '100%', height: '100%' }}>
        <View style={styles.wrapper}>
          <TouchableOpacity
            style={{
              height: Dimensions.get('window').height - layout,
            }}
            onPress={() => setVisible(false)}
          ></TouchableOpacity>
          <Animated.View
            style={[
              styles.modal,
              {
                transform: [
                  {
                    translateY: translateY,
                  },
                ],
              },
            ]}
            onLayout={(e) => setLayout(e.nativeEvent.layout.height)}
          >
            <PanGestureHandler
              onGestureEvent={(e) => handleTouch(e.nativeEvent.translationY)}
              onEnded={(e) => handleStopTouch(e.nativeEvent.translationY)}
            >
              <View style={{ paddingTop: 19, alignItems: 'center' }}>
                <View style={styles.line}></View>
              </View>
            </PanGestureHandler>
            <View
              style={[
                styles.panel,
                {
                  borderBottomWidth: 1,
                  backgroundColor: 'white',
                  justifyContent: 'center',
                },
              ]}
            >
              <Text style={[styles.text, { textAlign: 'center', fontFamily: 'NunitoSans_700Bold' }]}>{heading}</Text>
            </View>
            {data.map((item, index) => {
              return renderOption(item, index);
            })}
          </Animated.View>
        </View>
      </GestureHandlerRootView>
    </Modal>
  );
}
const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: `rgba(0,0,0,0.3)`,
    justifyContent: 'flex-end',
    flex: 1,
  },
  modal: {
    backgroundColor: colors.c_ffffff,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    paddingBottom: Platform.OS === 'ios' ? 30 : 0,
  },
  line: {
    height: 5,
    width: 85,
    borderRadius: 8,
    backgroundColor: colors.c_dddddd,
  },
  content: {
    backgroundColor: 'white',
    // padding: 22,
    justifyContent: 'center',
    borderRadius: 4,
    borderColor: 'rgba(0, 0, 0, 0.1)',
  },
  contentTitle: {
    fontSize: 20,
    // marginBottom: 12,
  },
  panel: {
    alignItems: 'center',
    overflow: 'hidden',
    paddingHorizontal: 16,
    height: 55,
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(221, 221, 221, 0.3)',
    backgroundColor: 'white',
  },
  text: {
    fontFamily: 'NunitoSans_400Regular',
    fontWeight: '400',
    fontSize: 16,
    color: colors.black,
  },
  textChecked: {
    fontFamily: 'NunitoSans_400Regular',
    fontWeight: '400',
    fontSize: 16,
    color: colors.mainBlue,
  },
});
