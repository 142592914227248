const images = {
  // banner: require('../assets/images/banner.png'),
  clkTagLogo: require(`../assets/images/clkTagLogo.png`),
  clkTagWhiteLogo: require(`../assets/images/clkTagLogoWhite.png`),
  loginBG: require(`../assets/images/loginScreenBackground.png`),
  blueDrillIcon: require(`../assets/images/BlueDrillIcon.png`),
  logoIconOnlyWhite: require(`../assets/images/White-Logo-Icon-Only.png`),
  scanOutline: require(`../assets/images/scan_outline.png`),
  greyDrillIcon: require(`../assets/images/GreyDrillIcon.png`),
  companyTileIcon: require(`../assets/images/CompanyTileIcon.png`),
  individualTileIcon: require(`../assets/images/IndividualTileIcon.png`),
  emailSentIcon: require(`../assets/images/EmailSentIcon.png`),
  examplePict: require(`../assets/images/expict.png`),
  toDoTabIcon: require(`../assets/images/To-DoTabIcon.png`),
  toDoTabIconActive: require(`../assets/images/toDoTabIconActive.png`),
  assetsTabIcon: require(`../assets/images/AssetsTabIcon.png`),
  notificationTabIcon: require(`../assets/images/NotificationTabIcon.png`),
  cameraTabActive: require(`../assets/images/CameraTabActive.png`),
  accountTabIcon: require(`../assets/images/AccountTabIcon.png`),
  confirmationTileIcon: require(`../assets/images/ConfirmationTileIcon.png`),
  bottomNAV: require(`../assets/images/BottomNAV.png`),
  drillIcon_BlueBackground: require(`../assets/images/DrillIcon_BlueBackground.png`),
  successIcon: require(`../assets/images/SuccessIcon.png`),
  calendarViewIcon: require(`../assets/images/CalendarViewIcon.png`),
  locationSmallWhiteIcon: require(`../assets/images/LocationSmallWhiteIcon.png`),
  completeTickIcon: require(`../assets/images/CompleteTickIcon.png`),
  blueOutletIcon: require(`../assets/images/BlueOutletIcon.png`),
  downloadIcon: require(`../assets/images/DownloadIcon.png`),
  lightGreyDownload: require(`../assets/images/LightGreyDownload.png`),
  notificationTabActive: require(`../assets/images/NotificationTabActive.png`),
  accountTabIconActive: require(`../assets/images/AccountTabIconActive.png`),
  logOut: require(`../assets/images/logOut.png`),
  lightGreyX: require(`../assets/images/LightGreyX.png`),
  calendarGray: require(`../assets/images/CalendarGray.png`),
  calendarBlack: require(`../assets/images/CalendarBlack.png`),
  activeTag: require(`../assets/images/activeTag.png`),
  inactiveCamera: require(`../assets/images/inactiveCamera.png`),
  clkFavicon: require(`../assets/images/clk-favicon.png`),
  clkFaviconInnactive: require(`../assets/images/clk-favicon-innactive.png`),
};

export default images;
