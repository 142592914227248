import React from 'react';
import { Text, View, Image, TouchableOpacity } from 'react-native';
import { styles } from './style';
import images from '../../../../theme/images';
import { FaChevronRight } from 'react-icons/fa';
import Status from '../../../assetInfo/status';

export default function CompleteTask({ ...props }) {
  const { task } = props;

  const handlePress = () =>
    props.navigation.navigate('TabNavigator', {
      screen: 'Assets',
      params: {
        screen: 'AssetInfo',
        asset: task.asset,
      },
    });

  return (
    <TouchableOpacity onPress={handlePress}>
      <View style={styles.contentBlock}>
        <Image style={styles.completeTickIcon} source={images.completeTickIcon} />
        <View
          style={{
            paddingLeft: 8,
          }}
        >
          <Image style={styles.taskIcon} source={{ uri: task.asset.assetType.icon }} />
        </View>
        <View
          style={{
            paddingLeft: 8,
            width: '75%',
          }}
        >
          <View style={styles.taskTitle}>
            <Text style={styles.textBold}>{task.asset.name}</Text>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Status status={task.asset.currentStatus} />
              <FaChevronRight name="right" style={styles.leadIcon} />
            </View>
          </View>
          <View style={styles.taskDetail}>
            <Text style={styles.textBoldSmall}>QR Code</Text>
            <Text
              style={[
                styles.textLightSmall,
                {
                  paddingLeft: 3,
                },
              ]}
            >
              {task.asset.tag.code}
            </Text>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
}
