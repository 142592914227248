import React,{useState} from 'react';
import {Text, View,TextInput } from 'react-native';
import {styles} from './style';

export default function InputField({...props}) {
  return (
    <View>
      <Text style={[styles.textBold,{
        fontSize:14,
        marginTop:12
      }]}>{props.title}</Text>
      <TextInput
        placeholder={props.suggestText}
        style={styles.input}
        onChangeText={props.onChangeText}
        value={props.text}
      />
    </View>
  )
}
