import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';
import QrScanner from '../../../screens/tabs/qrScanner';
import TestAssetFlowStep1 from '../../../screens/tabs/qrScanner/testAssetFlow/step1';
import TestAssetFlowStep2 from '../../../screens/tabs/qrScanner/testAssetFlow/step2';
import TestAssetFlowStep3 from '../../../screens/tabs/qrScanner/testAssetFlow/step3';
import TestAssetFlowStep4 from '../../../screens/tabs/qrScanner/testAssetFlow/step4';
import AssetInfo from '../../../screens/assetInfo';
import AssignAsset from '../../../screens/tabs/qrScanner/assignAsset';
import AssignTag from '../../../screens/tabs/qrScanner/assignTag';

const Stack = createNativeStackNavigator();

const QrCodeNav = ({ ...props }) => (
  <Stack.Navigator initialRouteName="QrScanner">
    <Stack.Screen
      {...props}
      name={'QrScanner'}
      component={QrScanner}
      options={{
        headerShown: false,
        // gestureEnabled: true
      }}
      initialParams={{
        isAdmin: props.route.params?.isAdmin,
        taskListDetails: props.route.params?.taskListDetails,
      }}
    />
    <Stack.Screen
      name={'TestAssetFlowStep1'}
      component={TestAssetFlowStep1}
      options={{
        headerShown: false,
        headerStyle: { backgroundColor: 'transparent' },
        tabBarVisible: false,
      }}
      initialParams={{
        ...props.route.params,
        isAdmin: props.route.params?.isAdmin,
      }}
    />
    <Stack.Screen
      {...props}
      name={'TestAssetFlowStep2'}
      component={TestAssetFlowStep2}
      options={{
        headerShown: false,
        headerStyle: { backgroundColor: 'transparent' },
      }}
      initialParams={{
        isAdmin: props.route.params?.isAdmin,
      }}
    />
    <Stack.Screen
      name={'TestAssetFlowStep3'}
      component={TestAssetFlowStep3}
      options={{
        headerShown: false,
        headerStyle: { backgroundColor: 'transparent' },
      }}
      initialParams={{
        isAdmin: props.route.params?.isAdmin,
      }}
    />
    <Stack.Screen
      name={'TestAssetFlowStep4'}
      component={TestAssetFlowStep4}
      options={{
        headerShown: false,
        headerStyle: { backgroundColor: 'transparent' },
      }}
      initialParams={{
        isAdmin: props.route.params?.isAdmin,
      }}
    />
    <Stack.Screen
      name={'AssetInfo'}
      component={AssetInfo}
      initialParams={{
        ...props.route.params,
        fromQrCodeNav: true,
      }}
      options={{
        headerShown: false,
        headerStyle: { backgroundColor: 'transparent' },
        tabBarVisible: true,
      }}
    />
    <Stack.Screen
      name={'AssignAsset'}
      component={AssignAsset}
      options={{
        headerShown: false,
        gestureEnabled: true,
      }}
      initialParams={{
        ...props.route.params,
        isAdmin: props.route.params?.isAdmin,
      }}
    />
    <Stack.Screen
      name={'AssignTag'}
      component={AssignTag}
      options={{
        headerShown: false,
        gestureEnabled: true,
      }}
      initialParams={{
        ...props.route.params,
        isAdmin: props.route.params?.isAdmin,
      }}
    />
  </Stack.Navigator>
);

export default QrCodeNav;
