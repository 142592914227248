const USER_ROLE_STRINGS = {
  Tech: 'Technician',
  PM: 'Admin',
  OTA: 'Builder / Safety Officer',
};

export const getUserRoleString = (role) => {
  const roleString = USER_ROLE_STRINGS[role];
  return roleString ? roleString : '';
};
