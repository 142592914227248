import AsyncStorage from '@react-native-async-storage/async-storage';
import { api } from './api';

const getScannedBarcode = async (code) => {
  let token = await AsyncStorage.getItem('token');
  let user = await AsyncStorage.getItem('user');
  if (user !== null || user !== '') {
    user = JSON.parse(user);
  }

  console.log('user on scan barcode', user);

  if (token) {
    try {
      let tag = await api(`v1/asset/${code}?user=${user}`, 'GET', null);
      if (tag.status == 200) {
        let result = tag.data;
        console.log(tag);
        if (result.success) {
          return result.asset;
        }
      } else if (tag.status == 404) {
        return 'not assigned';
      }
    } catch (error) {
      if (error.status == 404) {
        return 'not assigned';
      }
      return false;
    }
  }
  return false;
};

export default getScannedBarcode;
