import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';
import AssetInfo from '../../../screens/assetInfo';
import Tasks from '../../../screens/tabs/tasks';
import TaskList from '../../../screens/tabs/tasks/taskList';
import TaskListDate from '../../../screens/admin/taskList/taskDate';
import TaskListAll from '../../../screens/admin/taskList/taskAll';
import TaskInner from '../../../screens/admin/taskList/taskInner';
import AddTask from '../../../screens/admin/taskList/addTask';
import TaskInfo from '../../../screens/admin/taskList/addTask/taskInfo';
import TaskOverView from '../../../screens/admin/taskList/addTask/taskOverview';

const Stack = createNativeStackNavigator();

const Task = ({ ...props }) => (
  <Stack.Navigator>
    <Stack.Screen
      {...props}
      name={'Tasks'}
      component={Tasks}
      options={{
        headerShown: false,
        headerStyle: { backgroundColor: 'transparent' },
        tabBarVisible: false,
      }}
    />
    <Stack.Screen
      {...props}
      name={'TaskList'}
      component={TaskList}
      options={{
        headerShown: false,
        headerStyle: { backgroundColor: 'transparent' },
      }}
    />
    <Stack.Screen
      {...props}
      name={'AssetInfo'}
      initialParams={{
        fromAssetDir: true,
      }}
      component={AssetInfo}
      options={{
        headerShown: false,
        headerStyle: { backgroundColor: 'transparent' },
      }}
    />
    <Stack.Screen
      {...props}
      name={'TaskListAll'}
      initialParams={{
        fromAssetDir: true,
      }}
      component={TaskListAll}
      options={{
        headerShown: false,
        headerStyle: { backgroundColor: 'transparent' },
      }}
    />
    <Stack.Screen
      name={'TaskInner'}
      component={TaskInner}
      options={{
        headerShown: false,
        headerStyle: { backgroundColor: 'transparent' },
      }}
    />
    <Stack.Screen
      {...props}
      name={'TaskListDate'}
      initialParams={{
        fromAssetDir: true,
      }}
      component={TaskListDate}
      options={{
        headerShown: false,
        headerStyle: { backgroundColor: 'transparent' },
      }}
    />
    <Stack.Screen
      {...props}
      name={'AddTask'}
      initialParams={{
        fromAssetDir: true,
      }}
      component={AddTask}
      options={{
        headerShown: false,
        headerStyle: { backgroundColor: 'transparent' },
      }}
    />
    <Stack.Screen
      {...props}
      name={'TaskInfo'}
      initialParams={{
        fromAssetDir: true,
      }}
      component={TaskInfo}
      options={{
        headerShown: false,
        headerStyle: { backgroundColor: 'transparent' },
      }}
    />
    <Stack.Screen
      {...props}
      name={'TaskOverView'}
      initialParams={{
        fromAssetDir: true,
      }}
      component={TaskOverView}
      options={{
        headerShown: false,
        headerStyle: { backgroundColor: 'transparent' },
      }}
    />
  </Stack.Navigator>
);

export default Task;
