import React, { useState, useEffect } from 'react';
import { Text, View, Pressable, Image, TextInput, Platform, SafeAreaView, Alert, Linking } from 'react-native';
import { styles } from './style';
import images from '../../../theme/images';
import { showInfoToast } from '../../../utils/toastUtils';
import getScannedBarcode from '../../../utils/scanBarcode';
import parseUrl from '../../../utils/parseUrl';
import { api } from '../../../utils/api';
import { showErrorToast } from '../../../utils/toastUtils';
import Button from '../../../components/Button';
import calculateDistance from '../../../utils/calculateDistance';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import moment from 'moment-timezone';

const EnvConstants = require('../../../../EnvConstants');
const redirectUrl = EnvConstants.config.redirectUrl;

export default function QRCodeScreen({ ...props }) {
  const [hasPermission, setHasPermission] = useState(null);
  const [scanned, setScanned] = useState(false);
  const [scannedResult, setScannedResult] = useState(null);
  const [text, onChangeText] = React.useState('');
  const [qrEnabled, setQrEnabled] = useState(false);
  const [selectedQrCode, setSelectedQrCode] = useState(null);

  const getAssetForTag = async (code, fromBarCode = true) => {
    setSelectedQrCode(code);
    const response = await api(`v1/asset/${code}`, 'GET', null);

    if (response.status === 200) {
      const result = response.data;
      setScannedResult(result);

      if (fromBarCode) {
        checkLocation(result.asset);
      }

      props.navigation.navigate('GuestAssetInfoScreen', { isFromGuest: true, asset: result.asset });
    } else {
      showErrorToast('Tag unavailable', 'Please login / contact project admin for help.');
    }
  };

  const handleBarCodeScanned = async ({ type, data }) => {
    setScanned(true);
    let params = parseUrl(data);
    getAssetForTag(params.data);
  };

  function parseURLParams(url) {
    const searchParams = new URLSearchParams(url.split('#')[1]);
    const params = {};

    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }

    return params;
  }

  const setRefreshToken = async (code, authcode) => {
    // console.log('code: ', code);
    // var options = {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify({
    //     grant_type: 'authorization_code',
    //     client_id: EnvConstants.config.CLIENTID,
    //     client_secret: EnvConstants.config.SECRET,
    //     code: code,
    //     redirect_uri: Platform.OS == 'android' ? redirectUrl : redirectUrl + '/home',
    //   }),
    // };

    // console.log('options: ', options);

    // await fetch(EnvConstants.config.AUTHDOMAIN + '/oauth/token', options)
    //   .then((res) => res.json())
    //   .then((result) => {
    //     console.log('refresh token: ', result);
    //     if (result.refresh_token) {
    //       AsyncStorage.setItem('token', result.access_token);
    //       AsyncStorage.setItem('refreshToken', result.refresh_token);
    //       let expiry = moment().add(result.expires_in, 'seconds').format('YYYY-MM-DD HH:mm:ss');
    //       AsyncStorage.setItem('expiresIn', String(expiry));
    //     }
    //   })
    //   .catch((error) => {
    //     console.log('refresh token error: ', error);
    //     console.log('Using Code to get Auth');
    //   });

    var options = {
      method: 'POST',
      url: 'https://clktag.au.auth0.com/oauth/token',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: new URLSearchParams({
        grant_type: 'authorization_code',
        client_id: EnvConstants.config.CLIENTID,
        client_secret: EnvConstants.config.SECRET,
        code: code,
        redirect_uri: redirectUrl,
      }),
    };

    axios
      .request(options)
      .then(function (response) {
        console.log(response.data);
        let result = response.data;
        AsyncStorage.setItem('token', result.access_token);
        AsyncStorage.setItem('refreshToken', result.refresh_token);
        let expiry = moment().add(result.expires_in, 'seconds').format('YYYY-MM-DD HH:mm:ss');
        AsyncStorage.setItem('expiresIn', String(expiry));
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const checkAuth = async () => {
    const initalURL = await Linking.getInitialURL();
    const params = parseURLParams(initalURL);
    if (params.access_token) {
      await AsyncStorage.setItem('token', params.access_token);
      let expiry = moment().add(params.expires_in, 'seconds').format('YYYY-MM-DD HH:mm:ss');
      await AsyncStorage.setItem('expiresIn', String(expiry));
    }

    const token = await AsyncStorage.getItem('token');
    if (token) {
      props.navigation.navigate('OnboardingNavigation', {
        screen: 'Auth0Screen',
      });
    }
  };

  const refresh = async (code) => {
    var data = {
      grant_type: 'authorization_code',
      client_id: EnvConstants.config.CLIENTID,
      client_secret: EnvConstants.config.SECRET,
      code: code,
      redirect_uri: redirectUrl,
      audience: EnvConstants.config.AUDIENCE,
    };

    console.log('refresh data: ', data);
    var options = {
      method: 'POST',
      url: 'https://clktag.au.auth0.com/oauth/token',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: new URLSearchParams({
        grant_type: 'authorization_code',
        client_id: EnvConstants.config.CLIENTID,
        client_secret: EnvConstants.config.SECRET,
        code: code,
        redirect_uri: redirectUrl,
        audience: EnvConstants.config.AUDIENCE,
      }),
    };

    axios
      .request(options)
      .then(function (response) {
        console.log(response.data);
        let result = response.data;
        AsyncStorage.setItem('token', result.access_token);
        AsyncStorage.setItem('refreshToken', result.refresh_token);
        let expiry = moment().add(result.expires_in, 'seconds').format('YYYY-MM-DD HH:mm:ss');
        AsyncStorage.setItem('expiresIn', String(expiry));
      })
      .catch(function (error) {
        console.error(error);
      });

    // props.navigation.navigate('OnboardingNavigation', {
    //   screen: 'Auth0Screen',
    //   params: params,
    // });
  };

  useEffect(() => {
    checkAuth();
  }, []);

  const checkLocation = async (asset) => {
    const assetLocation = {
      lat: asset.site.address.lat,
      lng: asset.site.address.lng,
    };
    var deviceLocation = await AsyncStorage.getItem('currentLocation');
    deviceLocation = JSON.parse(deviceLocation);
    const distance = calculateDistance(
      deviceLocation.coords.latitude,
      deviceLocation.coords.longitude,
      assetLocation.lat,
      assetLocation.lng
    );
    console.log('distance', distance);

    if (distance >= 200) {
      let geo = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${deviceLocation.coords.latitude},${deviceLocation.coords.longitude}&key=${EnvConstants.config.GMAPS_API}`
      );

      const address = geo.data.results[0]?.formatted_address ?? '';
      const location = {
        address: address,
        lat: deviceLocation.coords.latitude,
        lng: deviceLocation.coords.longitude,
      };

      api(`v1/asset/${asset._id}/outsideradius`, 'PATCH', { location: location });
    }
  };

  const scanQr = (
    <View style={styles.qr}>
      <View style={styles.qrFade}></View>
      <Text style={styles.qrText}>Scan QR Code</Text>
      <View style={styles.windowScanner}>
        {/* <ScanOutline width={100}/> */}
        <Image style={styles.qrBorderImage} source={images.scanOutline} />
      </View>
    </View>
  );
  const qrCode = (
    <View style={styles.code}>
      <View style={styles.inputTextCode}>
        <TextInput
          style={styles.input}
          onChangeText={onChangeText}
          value={text}
          placeholder={'Enter code below QR Code'}
          returnKeyType="done"
          keyboardType="number-pad"
        />
      </View>

      <View style={styles.button}>
        <Pressable
          onPress={() => {
            getAssetForTag(text, false);
          }}
        >
          <View style={styles.codeSubmit}>
            <Text style={styles.textSubmit}>Submit</Text>
          </View>
        </Pressable>
      </View>
    </View>
  );
  return (
    <View style={styles.container}>
      <View
        style={{
          top: 100,
          alignItems: 'center',
          zIndex: 9999,
        }}
      >
        <Image
          style={{
            width: 90,
            height: 36,
          }}
          source={qrEnabled ? images.clkTagWhiteLogo : images.clkTagLogo}
        />
      </View>
      <View style={styles.content}>
        {/* <View style={styles.switch}>
          <Pressable
            onPress={() => {
              setQrEnabled(true);
            }}
          >
            <View style={qrEnabled ? styles.enable : styles.disable}>
              <Text style={qrEnabled ? styles.textEnable : styles.textDisable}>QR</Text>
            </View>
          </Pressable>
          <Pressable
            onPress={() => {
              setQrEnabled(false);
            }}
          >
            <View style={qrEnabled ? styles.disable : styles.enable}>
              <Text style={qrEnabled ? styles.textDisable : styles.textEnable}>Code</Text>
            </View>
          </Pressable>
        </View> */}
        {qrEnabled ? scanQr : qrCode}
      </View>
      <View style={styles.buttonContainer}>
        <Button
          title={'Log in'}
          buttonStyle={styles.blueButtonActive}
          textStyle={styles.blueButtonText}
          onPress={() =>
            props.navigation.navigate('OnboardingNavigation', {
              screen: 'Auth0Screen',
            })
          }
        />
      </View>
    </View>
  );
}
