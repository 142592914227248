import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../../../theme/colors';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  loading: {
    height: 8,
    backgroundColor: 'white',
    marginTop: 24,
  },
  loadPercent: {
    height: 8,
    width: '70%',
    backgroundColor: '#132FF5',
  },

  imageDrop: {
    height: 100,
    width: 100,
    borderRadius: 1000,
    backgroundColor: '#ffff',
    shadowColor: '#000',
    shadowOffset: {
      width: 6,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 6,
    elevation: 5,
  },
  image: {
    alignItems: 'center',
  },
  textBoldLarge: {
    fontSize: 18,
    fontFamily: 'NunitoSans_700Bold',
  },
  blueBorderButton: {
    borderRadius: 8,
    borderColor: '#132FF5',
    borderWidth: 1,
    paddingVertical: 10,
    marginBottom: 16,
  },

  blueBorderButtonText: {
    color: '#132FF5',
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    textAlign: 'center',
  },
  buttonJob: {
    paddingHorizontal: 40,
    width: '100%',
  },
  blueButton: {
    borderRadius: 8,
    opacity: 0.5,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
    marginBottom: 10,
    width: '100%',
  },
  blueButtonActive: {
    borderRadius: 8,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
    marginBottom: 10,
  },
  blueButtonText: {
    color: colors.white,
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 18,
    textAlign: 'center',
  },
  blankButton: {
    paddingVertical: 10,
  },
  blankButtonText: {
    color: '#000000',
    fontFamily: 'NunitoSans_700Bold',
    opacity: 0.5,
    fontSize: 18,
    textAlign: 'center',
  },
  assignButton: {
    position: 'absolute',
    bottom: 10,
    paddingHorizontal: 27,
    backgroundColor: 'white',
    width: '100%',
  },
  input: {
    marginTop: 16,
    borderColor: '#C7C7C7',
    width: '100%',
    borderWidth: 1,
    borderRadius: 8,
    paddingVertical: 15,
    paddingLeft: 13,
  },

  inputSearchDrop: {
    marginTop: 16,
    borderColor: '#C7C7C7',
    width: '100%',
    borderWidth: 1,
    borderRadius: 8,
    paddingVertical: 15,
    paddingHorizontal: 13,
  },
  inputSearchArea: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
    borderColor: '#C7C7C7',
    paddingHorizontal: 11,
    paddingVertical: 14,
    borderRadius: 8,
    borderWidth: 1,
    marginTop: 15,
  },
  inputTitle: {
    marginTop: 16,
    borderColor: '#C7C7C7',
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 16,
    width: '100%',
    borderWidth: 1,
    borderRadius: 8,
    paddingVertical: 15,
    paddingLeft: 13,
  },
  textField: {
    paddingHorizontal: 14,
    marginBottom: 20,
    paddingVertical: 16,
  },
  textFieldModal: {
    marginBottom: 14,
    paddingTop: 23,
    width: '100%',
  },
  notipush: {
    marginTop: 25,
    backgroundColor: 'rgba(0,0,0,0.1)',
    borderRadius: 16,
    paddingHorizontal: 31,
    paddingVertical: 21,
    alignItems: 'center',
    textAlign: 'center',
  },
  menu: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 30,
    paddingBottom: 30,
    paddingHorizontal: 24,
    alignItems: 'center',
    backgroundColor: '#EDEDED',
  },
  form: {
    marginHorizontal: 14,
    marginBottom: 180,
  },
  textBold: {
    fontFamily: 'NunitoSans_700Bold',
  },
  textRegular: {
    textAlign: 'center',
    fontFamily: 'NunitoSans_400Regular',
    marginTop: 16,
  },
  inputHeader: {
    fontFamily: 'NunitoSans_700Bold',
  },
  inputHeaderModal: {
    fontFamily: 'NunitoSans_700Bold',
    marginTop: 14,
  },
  inputDescript: {
    fontFamily: 'NunitoSans_400Regular',
    marginTop: 8,
  },
  title: {
    paddingTop: 15,
    flexDirection: 'row',
    alignItems: 'center',
  },
  firstTitle: {
    flexDirection: 'row',
  },
  titleText: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
  },
  titleTextDrop: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 16,
    marginLeft: 4,
  },
  required: {
    color: '#FF4D4D',
  },
  addReq: {
    color: '#132FF5',
    fontSize: 16,
    fontFamily: 'NunitoSans_600SemiBold',
    paddingTop: 10,
  },
  imgPicker: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#C7C7C7',
    paddingHorizontal: 7,
    paddingVertical: 8,
    marginTop: 10,
  },
  dasherBorder: {
    borderStyle: 'dotted',
    borderRadius: 8,
    borderWidth: 2,
    borderColor: '#C7C7C7',
    paddingVertical: 20,
    alignItems: 'center',
  },
  imgIcon: {
    fontSize: 17,
    paddingLeft: 6,
  },
  imgIconDrop: {
    fontSize: 17,
    paddingLeft: 6,
    color: '#0F0000',
  },
  imgPicked: {
    paddingVertical: 8,
    paddingLeft: 7,
    backgroundColor: '#8997fa1a',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#132ff5',
    borderRadius: 8,
    flex: 12,
  },
  imgPickedContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  closeIcon: {
    flex: 1,
    fontSize: 29,
    alignSelf: 'center',
    paddingLeft: 17,
    color: '#C7C7C7',
  },
  optional: {
    fontFamily: 'NunitoSans_400Regular',
    color: '#000000',
    fontSize: 14,
    opacity: 0.5,
  },
  uploadText: {
    fontFamily: 'NunitoSans_600SemiBold',
    color: '#000000',
    opacity: 0.5,
    fontSize: 14,
  },
  popupWindow: {
    backgroundColor: 'white',
    paddingHorizontal: 18,
    paddingVertical: 19,
    borderRadius: 12,
    alignItems: 'center',
    marginHorizontal: 26,
  },
  popupWindowSuccess: {
    backgroundColor: 'white',
    paddingHorizontal: 27,
    paddingVertical: 20,
    borderRadius: 12,
    alignItems: 'center',
    marginHorizontal: 26,
  },
  popupModal: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  popupIcon: {
    width: 70,
    height: 70,
    borderRadius: 16,
    marginTop: 8,
  },
  popupIconModal: {
    width: 15,
    height: 15,
    paddingHorizontal: 5,
    paddingVertical: 5,
    borderRadius: 16,
  },
  popupTitle: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    marginTop: 25,
  },
  popupScript: {
    fontFamily: 'NunitoSans_400Regular',
    marginTop: 8,
    textAlign: 'center',
  },
  dropItemContainer: {
    flexDirection: 'row',
    width: '100%',
  },
  inputDrop: {
    marginTop: 16,
    borderColor: '#C7C7C7',
    flex: 1,
    borderWidth: 1,
    borderRadius: 8,
    paddingTop: 15,
    paddingLeft: 13,
    maxHeight: 193,
  },
  dropTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 16,
  },
  unpickedTitle: {
    fontFamily: 'NunitoSans_400Regular',
    color: '#000000',
    opacity: 0.5,
    fontSize: 16,
  },
  dropItem: {
    paddingVertical: 16,
    marginRight: 13,
    borderBottomColor: '#0000000d',
    borderBottomWidth: 1,
    justifyContent: 'center',
  },
  dropLastItem: {
    paddingVertical: 16,
  },
  dropImageContainer: {
    width: 23,
    height: 23,
    borderRadius: 99,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 6,
  },
  dropList: {
    alignSelf: 'center',
    fontFamily: 'NunitoSans_700Bold',
    color: '#000000',
    fontSize: 16,
  },
  optional: {
    fontFamily: 'NunitoSans_400Regular',
    color: '#000000',
    fontSize: 14,
    opacity: 0.5,
    alignSelf: 'flex-end',
  },
  resultBox: {
    flexDirection: 'row',
    paddingTop: 16,
    flex: 1,

    width: '100%',
    marginTop: 14,
  },
  resultContent: {
    backgroundColor: '#eaedfe',

    paddingVertical: 10,
    paddingHorizontal: 16,
    minWidth: '100%',
    borderRadius: 8,
  },
  resultContentArea: {
    backgroundColor: '#4A4A4A',
    minWidth: '25%',
    paddingVertical: 14,
    paddingHorizontal: 19,

    borderRadius: 8,
  },
  resultTitle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleIcon: {
    width: 23,
    height: 23,
    tintColor: 'black',
  },

  resultScript: {
    fontSize: 15,
    fontFamily: 'NunitoSans_600SemiBold',
  },
  recommendBox: {
    backgroundColor: 'white',
    flexDirection: 'column',
  },
  recommendContent: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 11,
    borderColor: '#C7C7C7',
    borderBottomWidth: 1,
  },
  recommendIcon: {
    width: 27,
    height: 27,
    tintColor: 'black',
  },
  addModalTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  addModalTitle: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    color: '#000000',
  },
  resultSite: {
    borderColor: '#C7C7C7',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderRadius: 8,
  },
  resultField: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  result: {
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 16,
    width: '30%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#d6d6d6',
  },
  imgCross: {
    width: 17,
    height: 17,
    position: 'absolute',
    top: 0,
    right: 0,
  },
  optionsContainer: {
    flexDirection: 'row',
    width: '90%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  positionContainer: {
    paddingVertical: 3,
    paddingHorizontal: 15,
    backgroundColor: '#132ff51a',
    borderRadius: 11,
  },
  textSmallSemibold: {
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 12,
  },
  serialInput: {
    paddingLeft: 22,
  },
  serialHash: {
    position: 'absolute',
    top: 32,
    left: 10,
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 16,
  },
});
