import React from 'react';
import { Modal, StyleSheet, Text, View, Pressable, Image, TextInput } from 'react-native';
import colors from '../../theme/colors';
import images from '../../theme/images';
import Button from '../Button';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import AddressInput from '../AddressInput';
import { Platform } from 'react-native';

export default function CreateModal({
  modalVisible,
  setModalVisible,
  createValues,
  setCreateValues,
  createSuccess,
  completeCreatePress,
  createPress,
}) {
  const setAddressValue = (address) => {
    setCreateValues({ ...createValues, billingAddress: address });
  };

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => {
        setModalVisible(false);
      }}
    >
      <View style={styles.popupModal}>
        <KeyboardAwareScrollView
          contentContainerStyle={styles.scrollContainer}
          bounces={false}
          enableOnAndroid={true}
          enableAutomaticScroll={Platform.OS === 'ios'}
          keyboardShouldPersistTaps="always"
          nestedScrollEnabled={true}
        >
          <View style={createSuccess ? styles.popupWindowSuccess : styles.popupWindow}>
            {createSuccess ? (
              <>
                <Image style={styles.popupIcon} source={images.companyTileIcon} />
                <Text style={styles.popupTitle}>Company Created!</Text>
                <Text style={styles.popupScript}>
                  Your company has now been created in our system. Press continue to setup your project.
                </Text>
                <Button
                  title={'Continue'}
                  buttonStyle={[
                    styles.blueButton,
                    {
                      opacity: 1,
                      marginTop: 40,
                    },
                  ]}
                  textStyle={styles.blueButtonText}
                  onPress={() => {
                    setModalVisible(false);
                    completeCreatePress();
                  }}
                />
              </>
            ) : (
              <>
                <View style={styles.addModalTitleContainer}>
                  <Text style={styles.addModalTitle}>Add a new company</Text>
                  <Pressable onPress={() => setModalVisible(false)}>
                    <Image style={styles.popupIconModal} source={images.lightGreyX} />
                  </Pressable>
                </View>
                <View style={styles.textFieldModal}>
                  <View style={styles.firstTitle}>
                    <Text style={styles.titleText}>Company</Text>
                    <Text style={styles.required}> *</Text>
                  </View>

                  <Text style={styles.inputHeaderModal}>Name</Text>
                  <TextInput
                    placeholder={'Enter company name'}
                    style={styles.input}
                    placeholderTextColor="#00000080"
                    onChangeText={(text) => {
                      setCreateValues({ ...createValues, name: text });
                    }}
                    value={createValues.name}
                  />
                  <Text style={styles.inputHeaderModal}>Invoice Email</Text>
                  <TextInput
                    placeholder="Enter email to receive invoices "
                    style={styles.input}
                    placeholderTextColor="#00000080"
                    onChangeText={(text) => {
                      setCreateValues({ ...createValues, invoiceEmail: text });
                    }}
                    value={createValues.invoiceEmail}
                  />

                  <View style={styles.title}>
                    <Text style={styles.titleText}>Billing Details</Text>
                    <Text style={styles.required}> *</Text>
                  </View>

                  <Text style={styles.inputHeaderModal}>Address</Text>
                  {/* <TextInput
                  placeholder="Start typing address"
                  style={styles.input}
                  placeholderTextColor="#00000080"
                  onChangeText={(text) => {
                    setCreateValues({ ...createValues, billingAddress: text });
                  }}
                  value={createValues.billingAddress}
                /> */}
                  <AddressInput selectAddress={setAddressValue} />

                  <Text style={styles.inputHeaderModal}>Phone</Text>
                  <TextInput
                    placeholder="Enter company contact phone"
                    style={styles.input}
                    placeholderTextColor="#00000080"
                    onChangeText={(text) => {
                      setCreateValues({ ...createValues, billingPhone: text });
                    }}
                    value={createValues.billingPhone}
                  />

                  <Text style={styles.inputHeaderModal}>Email</Text>
                  <TextInput
                    placeholder="Enter company contact email"
                    style={styles.input}
                    placeholderTextColor="#00000080"
                    onChangeText={(text) => {
                      setCreateValues({ ...createValues, billingEmail: text });
                    }}
                    value={createValues.billingEmail}
                  />
                </View>
                <View>
                  <Button
                    title={'Create Company'}
                    buttonStyle={[styles.blueButtonActive, styles.createModalButton]}
                    textStyle={styles.blueButtonText}
                    onPress={() => {
                      createPress();
                    }}
                  />
                </View>
              </>
            )}
          </View>
        </KeyboardAwareScrollView>
      </View>
    </Modal>
  );
}
const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: `rgba(0,0,0,0.3)`,
    justifyContent: 'flex-end',
    flex: 1,
  },
  modal: {
    backgroundColor: colors.c_ffffff,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    paddingBottom: 30,
  },
  line: {
    height: 5,
    width: 85,
    borderRadius: 8,
    backgroundColor: colors.c_dddddd,
  },
  content: {
    backgroundColor: 'white',
    // padding: 22,
    justifyContent: 'center',
    borderRadius: 4,
    borderColor: 'rgba(0, 0, 0, 0.1)',
  },
  contentTitle: {
    fontSize: 20,
    // marginBottom: 12,
  },
  panel: {
    alignItems: 'center',
    overflow: 'hidden',
    paddingHorizontal: 16,
    height: 55,
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(221, 221, 221, 0.3)',
    backgroundColor: 'white',
  },
  text: {
    fontFamily: 'NunitoSans_400Regular',
    fontWeight: '400',
    fontSize: 16,
    color: colors.black,
  },
  textChecked: {
    fontFamily: 'NunitoSans_400Regular',
    fontWeight: '400',
    fontSize: 16,
    color: colors.mainBlue,
  },
  popupWindow: {
    backgroundColor: 'white',
    paddingHorizontal: 18,
    paddingVertical: 19,
    borderRadius: 12,
    alignItems: 'center',
    marginHorizontal: 26,
  },
  popupWindowSuccess: {
    backgroundColor: 'white',
    paddingHorizontal: 27,
    paddingVertical: 20,
    borderRadius: 12,
    alignItems: 'center',
    marginHorizontal: 26,
  },
  popupModal: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  popupIcon: {
    width: 70,
    height: 70,
    borderRadius: 16,
    marginTop: 8,
  },
  popupIconModal: {
    width: 13,
    height: 13,
    borderRadius: 16,
  },
  popupTitle: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    marginTop: 25,
  },
  popupScript: {
    fontFamily: 'NunitoSans_400Regular',
    marginTop: 8,
    textAlign: 'center',
  },
  addModalTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  addModalTitle: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    color: '#000000',
  },
  image: {
    alignItems: 'center',
  },
  textBoldLarge: {
    fontSize: 18,
    fontFamily: 'NunitoSans_700Bold',
  },
  blueBorderButton: {
    borderRadius: 8,
    borderColor: '#132FF5',
    borderWidth: 1,
    paddingVertical: 10,
    marginBottom: 16,
  },

  blueBorderButtonText: {
    color: '#132FF5',
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    textAlign: 'center',
  },
  buttonJob: {
    paddingHorizontal: 40,
    width: '100%',
  },
  blueButton: {
    borderRadius: 8,
    opacity: 0.5,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
    marginBottom: 10,
    width: '100%',
  },
  blueButtonActive: {
    borderRadius: 8,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
    marginBottom: 10,
  },
  blueButtonText: {
    color: colors.white,
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 18,
    textAlign: 'center',
  },
  createModalButton: {
    marginTop: 20,
    borderWidth: 1,
    borderColor: '#132FF5',
    paddingHorizontal: 30,
  },
  blankButton: {
    paddingVertical: 10,
  },
  blankButtonText: {
    color: '#000000',
    fontFamily: 'NunitoSans_700Bold',
    opacity: 0.5,
    fontSize: 18,
    textAlign: 'center',
  },
  assignButton: {
    position: 'absolute',
    bottom: 10,
    paddingHorizontal: 27,
    backgroundColor: 'white',
    width: '100%',
  },
  input: {
    marginTop: 16,
    borderColor: '#C7C7C7',
    width: '100%',
    borderWidth: 1,
    borderRadius: 8,
    paddingVertical: 15,
    paddingLeft: 13,
  },
  inputSearchDrop: {
    marginTop: 16,
    borderColor: '#C7C7C7',
    width: '100%',
    borderWidth: 1,
    borderRadius: 8,
    paddingVertical: 15,
    paddingHorizontal: 13,
  },
  inputTitle: {
    marginTop: 16,
    borderColor: '#C7C7C7',
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 16,
    width: '100%',
    borderWidth: 1,
    borderRadius: 8,
    paddingVertical: 15,
    paddingLeft: 13,
  },
  textField: {
    paddingHorizontal: 14,
    marginBottom: 20,
    paddingVertical: 16,
  },
  textFieldModal: {
    marginBottom: 14,
    paddingTop: 23,
    width: '100%',
  },
  textBold: {
    fontFamily: 'NunitoSans_700Bold',
  },
  textRegular: {
    textAlign: 'center',
    fontFamily: 'NunitoSans_400Regular',
    marginTop: 16,
  },
  inputHeader: {
    fontFamily: 'NunitoSans_700Bold',
  },
  inputHeaderModal: {
    fontFamily: 'NunitoSans_700Bold',
    marginTop: 14,
  },
  inputDescript: {
    fontFamily: 'NunitoSans_400Regular',
    marginTop: 8,
  },
  title: {
    paddingTop: 15,
    flexDirection: 'row',
    alignItems: 'center',
  },
  firstTitle: {
    flexDirection: 'row',
  },
  titleText: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
  },
  required: {
    color: '#FF4D4D',
  },
  scrollContainer: {
    marginTop: Platform.OS == 'ios' ? 50 : 10,
    maxHeight: '100%',
  },
});
