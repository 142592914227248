import React from 'react';
import { Text, View, Image, TouchableOpacity, Modal, StyleSheet } from 'react-native';
import Accordion from 'react-native-collapsible/Accordion';
import { useEffect, useState } from 'react';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import images from '../../../../../theme/images';
import GestureRecognizer from 'react-native-swipe-gestures';
export default function AccordionExtraContent({ ...props }) {
  const [activeSections, setActiveSections] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const { id, title, section, details, status } = props;
  const [inspectImage, setInspectImage] = useState('');
  const image = [
    {
      source: images.examplePict,
      title: 'Paris',
      width: 806,
      height: 720,
    },
  ];
  const renderHeader = (section) => {
    return (
      <View style={styles.headerContainer}>
        <View style={styles.centerRow}>
          <Text style={styles.title}>{title}</Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Text style={!status ? styles.plainStatus : status !== 'Pass' ? styles.failText : styles.passTest}>
            {status ? status : 'N/A'}
          </Text>
          {isActive ? (
            <FiChevronUp name="up" style={styles.arrowIcon} />
          ) : (
            <FiChevronDown name="down" style={styles.arrowIcon} />
          )}
        </View>
      </View>
    );
  };

  const renderContent = (section) => {
    return (
      <View
        style={{
          marginTop: 8,
        }}
      >
        <Text style={{ fontFamily: 'NunitoSans_400Regular' }}>{details.info}</Text>
        {details.img.fileName && (
          <>
            <TouchableOpacity
              onPress={() => {
                setInspectImage(details.img);
                setIsVisible(true);
              }}
            >
              <Image
                style={{
                  width: 130,
                  height: 130,
                  borderRadius: 16,
                  marginTop: 8,
                }}
                source={{ uri: details.img.uri }}
              />
            </TouchableOpacity>
          </>
        )}
      </View>
    );
  };

  const updateSections = (activeSections) => {
    setActiveSections(activeSections);
    setIsActive(!isActive);
  };
  return (
    <>
      <GestureRecognizer onSwipe={() => setIsVisible(false)}>
        <Modal animationType="fade" visible={isVisible}>
          <View style={styles.imageInspectedContainer}>
            <TouchableOpacity style={styles.buttonClose} onPress={() => setIsVisible(false)}>
              <Image style={styles.popupIconModal} source={images.lightGreyX} />
            </TouchableOpacity>
            <Image source={{ uri: inspectImage }} style={styles.imageInspected} />
          </View>
        </Modal>
      </GestureRecognizer>
      <Accordion
        sections={section}
        sectionContainerStyle={{
          marginBottom: 8,
        }}
        activeSections={activeSections}
        renderHeader={renderHeader}
        renderContent={renderContent}
        onChange={updateSections}
        underlayColor="transparent"
      />
    </>
  );
}

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  centerRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    color: '#6B6B6B',
    fontFamily: 'NunitoSans_700Bold',
  },
  passTest: {
    fontSize: 12,
    color: '#046836',
    fontFamily: 'NunitoSans_300Light',
    marginRight: 8,
  },
  failText: {
    fontSize: 12,
    color: '#FF4D4D',
    fontFamily: 'NunitoSans_300Light',
    marginRight: 8,
  },
  plainStatus: {
    fontSize: 12,
    color: '#6B6B6B',
    fontFamily: 'NunitoSans_300Light',
    marginRight: 8,
  },
  arrowIcon: {
    fontSize: 14,
    paddingLeft: 6,
  },
  imageInspectedContainer: {
    backgroundColor: '#000000',
    flex: 1,
    opacity: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageInspected: {
    width: '100%',
    height: 500,
    opacity: 1,
  },
  popupIconModal: {
    width: 25,
    height: 25,

    paddingHorizontal: 5,
    paddingVertical: 5,
    borderRadius: 16,
  },
  buttonClose: {
    position: 'absolute',
    top: 40,
    right: 20,
  },
});
