import { Dimensions, StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  popupWindow: {
    backgroundColor: 'white',
    paddingHorizontal: 22,
    paddingBottom: 20,
    borderRadius: 12,
    marginHorizontal: 26,
  },
  popupHeader: {
    alignItems: 'center',
  },
  confirmationTileIcon: {
    width: 70,
    height: 70,
    borderRadius: 16,
    marginTop: 8,
  },
  blueButton: {
    borderRadius: 8,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
  },
  blueButtonText: {
    color: 'white',
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    textAlign: 'center',
  },
  blueBorderButton: {
    borderRadius: 8,
    borderColor: '#132FF5',
    borderWidth: 1,
    paddingVertical: 5,
    marginTop: 8,
  },
  blueBorderButtonText: {
    color: '#132FF5',
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 16,
    textAlign: 'center',
  },
  blankButton: {
    paddingVertical: 15,
  },
  blankButtonText: {
    color: '#6B6B6B',
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 18,
    textAlign: 'center',
  },
  historyDetailInfo: {
    backgroundColor: 'rgba(0,0,0,0.1)',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 12,
    borderRadius: 16,
  },
  historyDetailPosition: {
    backgroundColor: '#FFFFFF',
    borderRadius: 16,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    paddingHorizontal: 10,
  },
  historyDetailLocation: {
    backgroundColor: 'rgba(0,0,0,0.1)',
    paddingVertical: 12,
    paddingHorizontal: 21,
    borderRadius: 16,
  },
  textBoldLarge: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
  },
  textBold: {
    fontFamily: 'NunitoSans_700Bold',
  },
  textRegular: {
    fontFamily: 'NunitoSans_400Regular',
  },
  textRegularSmall: {
    fontFamily: 'NunitoSans_400Regular',
    fontSize: 12,
  },
  position: {
    fontSize: 12,
    color: '#132FF5',
    fontFamily: 'NunitoSans_700Bold',
  },
  script: {
    marginTop: 8,
    marginBottom: 9,
    fontFamily: 'NunitoSans_400Regular',
  },
  popupIcon: {
    width: 24,
    height: 24,
    borderRadius: 12,
  },
  locationTitle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: 15,
    marginBottom: 8,
  },
  location: {
    fontFamily: 'NunitoSans_400Regular',
  },
  locationDetail: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  penIcon: {
    borderWidth: 1,
    borderColor: '#979797',
    justifyContent: 'center',
    padding: 5,
    borderRadius: 99,
  },
  inputSearchDrop: {
    marginTop: 16,
    borderColor: '#C7C7C7',
    width: '100%',
    borderWidth: 1,
    borderRadius: 8,
    paddingVertical: 15,
    paddingHorizontal: 13,
  },
});
