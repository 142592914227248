import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../../../theme/colors';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  loading: {
    height: 8,
    backgroundColor: 'white',
    marginTop: 24,
  },
  loadPercent: {
    height: 8,
    width: '70%',
    backgroundColor: '#132FF5',
  },
  selectedTag: {
    borderWidth: 1,
    borderRadius: 16,
    borderColor: '#C7C7C7',
    paddingHorizontal: 16,
    paddingBottom: 16,
    marginTop: 19,
  },
  selectedContent: {
    paddingTop: 8,
  },
  selectedText: {
    fontSize: 18,
    paddingTop: 8,
    fontFamily: 'NunitoSans_700Bold',
  },
  textDetail: {
    paddingTop: 6,
    fontFamily: 'NunitoSans_400Regular',
  },
  blankButton: {
    paddingVertical: 10,
  },
  blankButtonText: {
    opacity: 0.5,
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    textAlign: 'center',
  },
  assignButton: {
    paddingHorizontal: 27,
    width: '100%',
    marginTop: 52,
  },
  blueButton: {
    borderRadius: 8,
    opacity: 0.5,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
    marginBottom: 10,
  },
  blueButtonActive: {
    borderRadius: 8,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
    marginBottom: 10,
  },
  blueButtonText: {
    color: colors.white,
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 18,
    textAlign: 'center',
  },
  menu: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 20,
    paddingBottom: 36,
    paddingHorizontal: 26,
    alignItems: 'center',
  },
  title: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
  },
  script: {
    paddingTop: 16,
    fontFamily: 'NunitoSans_400Regular',
  },
  tagInfor: {
    marginTop: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  tagScript: {
    backgroundColor: '#F6F6F6',
    paddingHorizontal: 4,
    paddingVertical: 3,
  },
  textScript: {
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 12,
  },
  tagRecommend: {
    backgroundColor: 'rgba(19,47,245,0.1)',
    marginLeft: 4,
    paddingHorizontal: 8,
    paddingVertical: 3,
  },
  textRecommend: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 12,
    color: '#132FF5',
  },
  selectedDot: {
    borderColor: '#979797',
    marginLeft: 16,
    paddingTop: 4,
    borderRadius: 32,
    borderWidth: 1,
    width: 28,
    height: 28,
    alignItems: 'center',
  },
  selected: {
    borderRadius: 32,
    width: 18,
    height: 18,
    backgroundColor: '#132FF5',
    alignItems: 'center',
  },
});
