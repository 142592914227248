import React, { useEffect, useState } from 'react';
import { Image, ImageBackground, Text, View, Platform } from 'react-native';
import Button from '../../../components/Button';
import images from '../../../theme/images';
import { styles } from './style';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as AuthSession from 'expo-auth-session';
import { api } from '../../../utils/api';
import { useIsFocused } from '@react-navigation/native';
import Loading from '../../../components/Loading';
import moment from 'moment';
import auth0 from 'auth0-js';
import axios from 'axios';

const EnvConstants = require('../../../../EnvConstants');
const redirectUrl = EnvConstants.config.redirectUrl;

export default function Auth0Screen({ ...props }) {
  const [isLoading, setIsLoading] = useState(true);
  // const redirectUrl = AuthSession.makeRedirectUri();

  var webAuth = new auth0.WebAuth({
    domain: EnvConstants.config.AUTHDOMAIN,
    clientID: EnvConstants.config.CLIENTID,
    audience: EnvConstants.config.AUDIENCE,
    responseType: 'token code',
    redirectUri: EnvConstants.config.redirectUrl,
    responseMode: 'fragment',
    scope:
      'openid profile email client_credentials offline_access read:current_user update:users update:current_user_identities create:current_user_metadata update:current_user_metadata delete:current_user_metadata create:current_user_device_credentials delete:current_user_device_credentials',
  });

  async function getUserInfo(token) {
    let email,
      name,
      lastName,
      mobile = '';
    let verified = false;
    const resp = await api('v1/user/getauth0info', 'GET', null);
    // console.log(resp.data);
    if (resp.status == 200) {
      var result = resp.data;
      if (result.success) {
        email = result.data.email;
        name = result.data?.given_name;
        lastName = result.data?.family_name;

        if (result.data.user_metadata) {
          if (result.data.user_metadata.firstName) {
            name = result.data.user_metadata.firstName;
          }
          if (result.data.user_metadata.lastName) {
            lastName = result.data.user_metadata.lastName;
          }
          if (result.data.user_metadata.mobile) {
            mobile = result.data.user_metadata.mobile;
          }
        }

        verified = result.data.email_verfied ? result.data.email_verfied : false;

        let formData = new FormData();

        formData.append('email', email);
        formData.append('firstName', name);
        formData.append('lastName', lastName);
        formData.append('mobile', mobile);
        formData.append('emailVerified', verified);

        registerUser(formData, result.data.picture);
      }
    }
  }

  const getUser = async (token = false) => {
    console.log('before get user: ', token);
    if (token) {
      token = token;
    } else {
      token = await AsyncStorage.getItem('token');
      console.log('get token from async', token);
    }

    await AsyncStorage.setItem('token', token);

    // console.log('route params: ', props);

    if (props.route.params) {
      let expiry = moment().add(props.route.params.expires_in, 'seconds').format('YYYY-MM-DD HH:mm:ss');
      await AsyncStorage.setItem('expiresIn', String(expiry));
      // console.log('code for refresh: ', props.route.params.code);
      // console.log('props.route.params.access_token: ', props.route.params.access_token);
      // setRefreshToken(props.route.params.code, props.route.params.access_token);
    }

    console.log('get user with token: ', token);

    if (token == null) {
      setIsLoading(false);
      return;
    }

    try {
      const resp = await api('v1/user', 'GET', null);
      console.log('result: ', resp);
      if (resp.status == 200) {
        var result = resp.data;
        console.log('result: ', result);
        if (result.success) {
          if (result.user == null) {
            getUserInfo(token);
          } else {
            // console.log('result profile:', result.user);
            if (result.user.active) {
              AsyncStorage.setItem('user', JSON.stringify(result.user));
              // console.log("user: ", result.user);
              if (result.user.role == 'PM' || result.user.role == 'OHS') {
                props.route.params?.assetInfo
                  ? props.navigation.navigate('TabNavigator', {
                      screen: 'Assets',
                      isAdmin: true,
                      isViewOnly: result.user.role == 'OHS' ? true : false,
                      fromLogin: true,
                      params: {
                        initial: false,
                        screen: 'AdminAssetRegistry',
                        fromQRCodeNav: true,
                        isAdmin: true,
                        fromLogin: true,
                        asset: props.route.params.assetInfo,
                      },
                    })
                  : props.navigation.navigate('TabNavigator', {
                      screen: 'Assets',
                      isAdmin: true,
                      params: {
                        screen: 'AdminAssetRegistry',
                        fromQRCodeNav: true,
                      },
                    });
              } else {
                props.route.params?.assetInfo
                  ? props.navigation.navigate('TabNavigator', {
                      screen: 'QrCodeNav',
                      isAdmin: false,
                      fromLogin: true,
                      params: {
                        initial: false,
                        screen: 'TestAssetFlowStep1',
                        fromQRCodeNav: true,
                        isAdmin: false,
                        fromLogin: true,
                        asset: props.route.params.assetInfo,
                      },
                    })
                  : props.navigation.navigate('TabNavigator', {
                      screen: 'Assets',
                      isAdmin: false,
                      params: {
                        screen: 'AdminAssetRegistry',
                        fromQRCodeNav: true,
                      },
                    });
              }
            } else {
              getUserInfo(token);
            }
          }
        } else {
          setIsLoading(false);
          if (result.error) {
            if (result.error == 'jwt expired') {
              let removeToken = AsyncStorage.removeItem('token');
              let removerefreshToken = AsyncStorage.removeItem('refreshToken');
              props.navigation.navigate('OnboardingNavigation');
            }
          }
        }
      } else {
        setIsLoading(false);
        if (result.error) {
          if (result.error == 'jwt expired') {
            let removeToken = AsyncStorage.removeItem('token');
            let removerefreshToken = AsyncStorage.removeItem('refreshToken');
            props.navigation.navigate('OnboardingNavigation');
          }
        }
      }
    } catch (error) {
      console.log('login error:', error);
    }
  };

  async function registerUser(data, pic) {
    // console.log("register data: ", data);
    const resp = await api('v1/user', 'POST', data, true);
    if (resp.status == 200) {
      var result = resp.data;
      if (result.success) {
        // console.log('Registered User: ', result);
        await AsyncStorage.setItem('user', JSON.stringify(result.user));
        props.navigation.navigate('UserProfile', {
          user: result.user,
          userImage: { uri: pic, type: 'string' },
        });
      }
    }
  }

  const setRefreshToken = async (code, authcode) => {
    // // console.log("code: ", code);
    // var options = {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify({
    //     grant_type: 'authorization_code',
    //     client_id: EnvConstants.config.CLIENTID,
    //     client_secret: EnvConstants.config.SECRET,
    //     code: code,
    //     redirect_uri: Platform.OS == 'android' ? redirectUrl : redirectUrl + '/home',
    //   }),
    // };

    // await fetch(EnvConstants.config.AUTHDOMAIN + '/oauth/token', options)
    //   .then((res) => res.json())
    //   .then((result) => {
    //     // console.log("refresh token: ", result)
    //     if (result.refresh_token) {
    //       AsyncStorage.setItem('token', result.access_token);
    //       AsyncStorage.setItem('refreshToken', result.refresh_token);
    //       let expiry = moment().add(result.expires_in, 'seconds').format('YYYY-MM-DD HH:mm:ss');
    //       AsyncStorage.setItem('expiresIn', String(expiry));
    //     }
    //   })
    //   .catch((error) => {
    //     console.log('refresh token error: ', error);
    //     console.log('Using Code to get Auth');
    //   });

    var options = {
      method: 'POST',
      url: 'https://clktag.au.auth0.com/oauth/token',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: new URLSearchParams({
        grant_type: 'authorization_code',
        client_id: EnvConstants.config.CLIENTID,
        client_secret: EnvConstants.config.SECRET,
        code: code,
        redirect_uri: redirectUrl,
      }),
    };

    axios
      .request(options)
      .then(function (response) {
        console.log(response.data);
        let result = response.data;
        AsyncStorage.setItem('token', result.access_token);
        AsyncStorage.setItem('refreshToken', result.refresh_token);
        // let expiry = moment().add(result.expires_in, 'seconds').format('YYYY-MM-DD HH:mm:ss');
        // AsyncStorage.setItem('expiresIn', String(expiry));
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const goToAuth0 = async (hint) => {
    // const request = new AuthSession.AuthRequest({
    //   clientId: `${EnvConstants.config.CLIENTID}`,
    //   clientSecret: `${EnvConstants.config.SECRET}`,
    //   redirectUri: redirectUrl,
    //   prompt: 'login',
    //   extraParams: {
    //     screen_hint: `${hint}`,
    //     audience: `${EnvConstants.config.AUDIENCE}`,
    //   },
    //   responseType: 'token code',
    //   scopes: [
    //     'openid',
    //     'profile',
    //     'email',
    //     'client_credentials',
    //     'offline_access',
    //     'read:current_user',
    //     'update:users',
    //     'update:current_user_identities',
    //     'create:current_user_metadata',
    //     'update:current_user_metadata',
    //     'delete:current_user_metadata',
    //     'create:current_user_device_credentials',
    //     'delete:current_user_device_credentials',
    //   ],
    //   usePKCE: false,
    // });

    // console.log(request);

    // // const result = await request.loadAsync({
    // //   authorizationEndpoint: `${EnvConstants.config.AUTHDOMAIN}/authorize`,
    // // });
    // let resp = await AuthSession.loadAsync(request, `${EnvConstants.config.AUTHDOMAIN}/authorize`);

    // console.log(resp);
    // await Linking.openURL(resp.url);

    // // if (result.params) {
    // //   const response = result.params;
    // //   if (!result.errorCode) {
    // //     await AsyncStorage.setItem('token', result.params.access_token);
    // //     // console.log("auth0 resp: ", result);
    // //     setRefreshToken(result.params.code, response.access_token);
    // //     console.log(response.access_token);
    // //     getUser(response.access_token);
    // //   }
    // // } else {
    // //   console.log('Failed Login');
    // //   console.log(result);
    // // }

    webAuth.authorize({
      prompt: 'login',
    });
  };

  const deviceUserToken = async (token) => {
    console.log('notification device token: ', token);
    if (token !== undefined) {
      let data = {
        token: token,
      };
      const resp = await api('v1/notification/token', 'POST', data);
      console.log(resp.data);
      if (resp.status == 200) {
        if (resp.data.success) {
          console.log('Token Saved to DB Successfully');
        }
      }
    }
  };

  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused) {
      getUser();
    }
  });

  return (
    <View style={styles.container}>
      <ImageBackground source={images.loginBG} style={styles.loginBG}>
        <View style={styles.loginLogoText}>
          <Image source={images.clkTagLogo} style={styles.oneTagLogo} />
          <Text style={styles.loginText}>Next-gen Testing and Tagging. Sign up to get started.</Text>
        </View>
      </ImageBackground>
      {isLoading ? (
        <View style={styles.loadingContainer}>
          <Loading />
        </View>
      ) : (
        <View style={styles.buttonColumn}>
          <Button
            title={'Sign Up'}
            buttonStyle={[styles.blueButton, { marginBottom: 10 }]}
            textStyle={styles.blueButtonText}
            onPress={() => goToAuth0('signUp')}
          />
          <Button
            title={'Sign In'}
            buttonStyle={[styles.blueBorderButton, { marginBottom: 10 }]}
            textStyle={styles.blueBorderButtonText}
            onPress={() => goToAuth0('login')}
            // onPress={() => props.navigation.navigate("OnboardingNavigation")}
          />
          <Button
            title={'Back'}
            buttonStyle={[styles.blankButton, { marginBottom: 20 }]}
            textStyle={styles.blankButtonText}
            onPress={() => props.navigation.goBack()}
          />
        </View>
      )}
    </View>
  );
}
