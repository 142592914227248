import React, {useState,useEffect} from 'react';
import {Text, View, SafeAreaView, Image, ScrollView, TouchableOpacity } from 'react-native';
import {styles} from './style';



export default function Type({...props}) {
  const { id, image,title,detail,onPress,onClick } = props

  return (
    <TouchableOpacity onPress={onPress}>
      <View
        style={styles.selectedTag}
      >
        <View style={styles.selectedContent}>
          <Image
            style={{
              width: 70,
              height: 70,
            }}
            source={image}
          />
          <Text style={styles.selectedText}>
            {title}
          </Text>
          <Text style={styles.textDetail}>
            {detail}
          </Text>
        </View>
        <View style={styles.selectDot}>
          {onClick 
            && <View style={styles.selected}></View>
          }
        </View>
      </View>
    </TouchableOpacity>
  )
}

