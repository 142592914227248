import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";
import qrCodeScan from "../../screens/guest/qrCodeScan";
import GuestAssetInfoScreen from "../../screens/assetInfo";
import Login from "../../screens/guest/login";
import SearchScreen from "../../components/Search";

const Stack = createNativeStackNavigator();

const GuestNavigation = ({ ...props }) => (
  <Stack.Navigator>
    <Stack.Screen
      name={"QRCodeScan"}
      component={qrCodeScan}
      options={{
        headerShown: false,
        headerStyle: { backgroundColor: "transparent" },
      }}
    />
    <Stack.Screen
      name={"GuestAssetInfoScreen"}
      component={GuestAssetInfoScreen}
      options={{
        headerShown: false,
        headerStyle: { backgroundColor: "transparent" },
      }}
    />
    <Stack.Screen
      {...props}
      name={"Login"}
      component={Login}
      options={{
        headerShown: false,
        headerStyle: { backgroundColor: "transparent" },
        tabBarVisible: false,
      }}
    />
  </Stack.Navigator>
);
export default GuestNavigation;
