import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../theme/colors';

export const styles = StyleSheet.create({
  inputDrop: {
    marginTop: 16,
    borderColor: '#C7C7C7',

    borderWidth: 1,
    borderRadius: 8,
    paddingTop: 15,
    paddingLeft: 13,
    maxHeight: 193,
  },
  dropTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 32,
  },
  unpickedTitle: {
    fontFamily: 'NunitoSans_400Regular',
    color: '#000000',
    opacity: 0.5,
    fontSize: 16,
  },

  dropList: {
    alignSelf: 'center',
    fontFamily: 'NunitoSans_700Bold',
    color: '#000000',
    fontSize: 16,
  },
  imgIcon: {
    fontSize: 17,
    paddingLeft: 6,
  },
  dropItem: {
    paddingVertical: 16,
    borderBottomColor: '#0000000d',
    borderBottomWidth: 1,
    justifyContent: 'center',
    marginRight: 13,
  },
  dropLastItem: {
    paddingVertical: 16,
  },
  dropItemContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginRight: 13,
  },
  dropImageContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    width: '65%',
  },

  positionContainer: {
    paddingVertical: 3,
    paddingHorizontal: 15,
    backgroundColor: '#132ff51a',
    borderRadius: 11,
  },
  textSmallSemibold: {
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 12,
  },
});
