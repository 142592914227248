import { styles } from './style';
import { Button, Text, View, Image } from 'react-native';
import Status from './status';

const HistoryHeader = ({ ...props }) => {
  var status = 'Pass';
  for (let i = 0; i < Object.keys(props.selectedValues).length; i++) {
    const element = Object.values(props.selectedValues)[i];
    if (element.status == 'Fail') {
      status = 'Fail';
    }
  }
  return (
    <View style={styles.accordionHeader}>
      <View style={styles.headerTitle}>
        <Text
          style={[
            styles.textBoldMedium,
            {
              paddingRight: 11,
            },
          ]}
        >
          {props.title}
        </Text>
        <Text style={styles.textItalicSmall}>{props.time}</Text>
      </View>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Status status={status} />
      </View>
    </View>
  );
};

export default HistoryHeader;
