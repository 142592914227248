import React, { useState, useMemo, useEffect } from 'react';
import { Image, Pressable, Text, TouchableOpacity, View, Modal } from 'react-native';
import images from '../../../../theme/images';
import { styles } from './style';
import { AiOutlineCheckSquare, AiFillSetting } from 'react-icons/ai';
import { ScrollView, TextInput } from 'react-native-gesture-handler';
import PendingTask from './pendingTask';
import CompleteTask from './completeTask';

import { useRef } from 'react';
import RBSheet from 'react-native-raw-bottom-sheet';
import SearchBar from './searchBar';
import { Calendar } from 'react-native-calendars';
import Button from '../../../../components/Button';
import { api } from '../../../../utils/api';
import { dateStringToUTC } from '../../../../utils/dateUtils';
import { useIsFocused } from '@react-navigation/native';
import moment from 'moment';
import 'moment-timezone';
import Loading from '../../../../components/Loading';
import { getUserRoleString } from '../../../../utils/userUtils';
import CustomArrow from '../../../../components/CustomArrow';

function TaskInner({ ...props }) {
  console.log('rpoute ', props.route);
  const { project, assignedUserId, dueDateUTC, period, hasWriteAccess, fromNotifications } = props.route.params;
  const dueDateTZ = moment(dueDateUTC).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('YYYY-MM-DD');

  const [isComplete, setIsComplete] = useState(false);
  const [taskComplete, setTaskComplete] = useState(false);
  const refAssignee = useRef();
  const refDelete = useRef();
  const [dueModalVisible, setDueModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [assigneeFilter, setAssigneeFilter] = useState('');
  const [rbHeight, setRbHeight] = useState(95);
  const [isConfirm, setIsConfirm] = useState(false);
  const [deleteReason, setDeleteReason] = useState();
  const [pendingTasks, setPendingTasks] = useState([]);
  const [completeTasks, setCompleteTasks] = useState([]);

  const [assigneeOptions, setAssigneeOptions] = useState(project.users);
  const [assignee, setAssignee] = useState(project.users.find((user) => user._id === assignedUserId));
  const [dueDate, setDueDate] = useState(dueDateTZ);
  const [tempDueDate, setTempDueDate] = useState(dueDateTZ);
  const [taskToDelete, setTaskToDelete] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dueDatePeriod, setDueDatePeriod] = useState(period);

  const marked = useMemo(() => ({
    [tempDueDate]: {
      selected: true,
      selectedColor: 'rgba(19,47,245,0.09)',
      selectedTextColor: '#132FF5',
    },
  }));

  const handleSearchAssignee = (text) => {
    setAssigneeFilter(text);
    if (text.length == 0) {
      setAssigneeOptions(project.users);
    } else {
      const resultSearch = project.users.filter((user) => {
        const filter = text.toLowerCase();
        const name = (user.firstName + user.lastName).toLowerCase();
        return name.includes(filter);
      });
      setAssigneeOptions(resultSearch);
    }
  };

  const getDueDateText = () => {
    if (dueDatePeriod === 'overdue') {
      return 'Overdue';
    } else if (moment().format('YYYY-MM-DD') === dueDate || dueDatePeriod === 'today') {
      return 'Today';
    } else if (dueDatePeriod === 'thisWeek') {
      return 'This Week';
    } else if (dueDatePeriod === 'thisMonth') {
      return 'This Month';
    } else {
      return moment(dueDate).format('DD/MM/YYYY');
    }
  };

  const dueDateModal = (
    <Modal
      animationType="fade"
      transparent={true}
      visible={dueModalVisible}
      onRequestClose={() => {
        setDueModalVisible(false);
      }}
    >
      <View style={styles.ModalContainer}>
        <View style={styles.popupWindow}>
          <View style={styles.dueControllerContainer}>
            <Text style={[styles.textMediumBold, { color: '#6B6B6B' }]}>Due Date</Text>
            <Pressable onPress={() => setDueModalVisible(false)}>
              <Image source={images.lightGreyX} style={{ width: 17, height: 17 }} />
            </Pressable>
          </View>
          <View>
            <Calendar
              renderArrow={(direction, onPress) => <CustomArrow direction={direction} onPress={onPress} />}
              hideArrows={false}
              markingType={'multi-dot'}
              markedDates={marked}
              enableSwipeMonths={true}
              context={{ date: '' }}
              theme={{
                arrowColor: '#0F0000',
              }}
              onDayPress={(day) => setTempDueDate(day.dateString)}
            />

            <Pressable
              onPress={() => {
                setDueDatePeriod('exact');
                setDueDate(tempDueDate);
                setDueModalVisible(false);
              }}
            >
              <View style={styles.smallSubmitButton}>
                <Text style={[styles.textBold, { color: '#FFFFFF' }]}>Set</Text>
              </View>
            </Pressable>
          </View>
        </View>
      </View>
    </Modal>
  );

  const deleteModal = (
    <Modal
      animationType="fade"
      transparent={true}
      visible={deleteModalVisible}
      // onRequestClose={() => setDeleteModalVisible(false)}
    >
      <View style={styles.ModalContainer}>
        <View style={styles.popupWindow}>
          <View style={styles.popupHeader}>
            <Image style={styles.confirmationTileIcon} source={images.confirmationTileIcon} />
            <Text
              style={[
                styles.textBoldLarge,
                {
                  marginTop: 25,
                },
              ]}
            >
              Are you sure?
            </Text>
            <View style={{ maxWidth: 238 }}>
              <Text style={styles.script}>
                Are you sure you want to permanently delete {taskToDelete ? 'this task' : 'all pending tasks'}?
              </Text>
            </View>
          </View>
          <Button
            title={'Yes, delete'}
            buttonStyle={[styles.redBorderButton, { marginHorizontal: 10 }]}
            textStyle={styles.redBorderButtonText}
            onPress={() => {
              refDelete.current.close();
              setDeleteModalVisible(false);
              if (taskToDelete) {
                deleteTask();
              } else {
                deleteAllTasks();
              }
            }}
          />
          <Button
            title={'Cancel'}
            buttonStyle={[
              styles.blueButton,
              {
                marginTop: 8,
                marginHorizontal: 10,
              },
            ]}
            textStyle={styles.blueButtonText}
            onPress={() => {
              refDelete.current.close();
              setDeleteModalVisible(false);
              setTaskToDelete(null);
            }}
          />
        </View>
      </View>
    </Modal>
  );

  const deleteAllTasks = async () => {
    const pendingTaskIds = pendingTasks.map((task) => task.id);
    const data = {
      taskIds: pendingTaskIds,
    };

    const result = await api(`v1/task/delete`, 'DELETE', data);

    if (result.status === 200) {
      getTasks();
    }
  };

  const deleteTask = async () => {
    const result = await api(`v1/task/${taskToDelete.id}/delete`, 'DELETE', null);

    if (result.status === 200) {
      getTasks();
    }

    setTaskToDelete(null);
  };

  const getTasks = async () => {
    let url = `v1/task/sorted/status?projectid=${project._id}&userid=${assignee._id}`;
    if (dueDatePeriod !== 'exact') {
      url += `&period=${dueDatePeriod}&selected_date_utc=${dateStringToUTC(moment().format('YYYY-MM-DD'))}`;
    } else {
      url += `&selected_date_utc=${dateStringToUTC(dueDate)}`;
    }

    const response = await api(url, 'GET', null);

    if (response.status === 200 && response.data.success) {
      const tasks = response.data.tasks;
      const pendingTasks = tasks.pendingTasks ? tasks.pendingTasks : [];
      const completeTasks = tasks.completeTasks ? tasks.completeTasks : [];
      console.log(tasks.completeTasks);

      setPendingTasks(pendingTasks);
      setCompleteTasks(completeTasks);
      if (pendingTasks.length === 0) {
        setIsComplete(true);
      } else {
        setIsComplete(false);
      }
    }

    setLoading(false);
  };

  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      getTasks();
    }
  }, [isFocused, assignee, dueDate]);

  useEffect(() => {
    if (taskToDelete !== null) {
      setDeleteModalVisible(true);
    }
  }, [taskToDelete]);

  return (
    <View style={styles.container}>
      <ScrollView>
        <View style={styles.header}>
          {dueDateModal}

          <View style={styles.menuContainer}>
            <TouchableOpacity
              onPress={() => {
                props.navigation.getParent().setParams({
                  screen: 'TaskListAll',
                  isAdmin: true,
                });
                if (fromNotifications) {
                  props.navigation.navigate('Notifications', {
                    screen: 'NotificationsPage',
                    params: { isAdmin: true },
                  });
                } else {
                  props.navigation.navigate('TaskListAll', {
                    isAdmin: true,
                  });
                }
              }}
            >
              <Text style={{ fontFamily: 'NunitoSans_700Bold' }}>Back</Text>
            </TouchableOpacity>
            <View style={{ alignItems: 'center' }}>
              <Text
                style={[
                  styles.textBold,
                  {
                    color: '#6B6B6B',
                  },
                ]}
              >
                Test {pendingTasks.length + completeTasks.length} Assets
              </Text>
              <Text style={[styles.textRegular]}>
                {completeTasks.length}/{pendingTasks.length + completeTasks.length} Complete
              </Text>
            </View>
            {hasWriteAccess ? (
              <Pressable
                onPress={() => {
                  setRbHeight(95);
                  console.log(refDelete.current);
                  refDelete.current.open();
                }}
              >
                <AiFillSetting name="options" style={{ fontSize: 23 }} />
              </Pressable>
            ) : (
              <View style={{ paddingRight: 30 }}></View>
            )}
          </View>
        </View>
        <View style={styles.body}>
          <View style={styles.overview}>
            <Text style={[styles.textBold, { color: '#4A4A4A' }]}>Overview</Text>
            <View style={{ marginTop: 18 }}>
              <View style={styles.overviewDetail}>
                <Text style={styles.textSmallBold}>Status</Text>
                <View style={styles.statusContainer}>
                  <View style={[styles.status, isComplete == false ? { backgroundColor: '#0045FF' } : '']}>
                    <Text style={[styles.textTinyBold, isComplete == false ? { color: '#FFFFFF' } : '']}>
                      Incomplete
                    </Text>
                  </View>
                  {/* <Pressable
                    onPress={() => {
                      setIsComplete(true);
                    }}
                    // Show are you sure popup & complete tasks => Create passing test for each task?
                  > */}
                  <View style={[styles.status, isComplete == true ? { backgroundColor: '#0045FF' } : '']}>
                    <Text style={[styles.textTinyBold, isComplete == true ? { color: '#FFFFFF' } : '']}>Complete</Text>
                  </View>
                  {/* </Pressable> */}
                </View>
              </View>
              <View style={styles.overviewDetail}>
                <Text style={styles.textSmallBold}>Project</Text>
                <View style={styles.overviewInfo}>
                  <Image
                    source={{ uri: project.logo }}
                    style={{ width: 22, height: 22, marginRight: 3, borderRadius: 11 }}
                  />
                  <Text style={styles.textSmallSemibold}>{project.name}</Text>
                </View>
              </View>
              <Pressable onPress={() => refAssignee.current.open()}>
                <View style={styles.overviewDetail}>
                  <Text style={styles.textSmallBold}>Assignee</Text>

                  <View style={styles.overviewInfo}>
                    <Image
                      source={{ uri: assignee.profilePicture }}
                      style={{ width: 22, height: 22, marginRight: 3, borderRadius: 11 }}
                    />
                    <Text style={styles.textSmallSemibold}>{`${assignee.firstName} ${assignee.lastName}`}</Text>
                  </View>
                </View>
              </Pressable>
              <View style={styles.overviewDetail}>
                <Text style={styles.textSmallBold}>Due Date</Text>
                <TouchableOpacity onPress={() => setDueModalVisible(true)}>
                  <View style={styles.dueDate}>
                    <Text style={[styles.textTinyBold, { color: '#FF984D' }]}>{getDueDateText()}</Text>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          </View>

          {!loading ? (
            <View style={styles.taskDetailContainer}>
              <View style={styles.taskDetailTitle}>
                <Text style={[styles.textBold, { color: '#4A4A4A' }]}>Details</Text>
              </View>
              <View style={styles.viewOptionsContainer}>
                <Pressable onPress={() => setTaskComplete(false)}>
                  <View>
                    <Text style={taskComplete == false ? styles.blueBoldText : styles.grayBoldText}>
                      Pending ({pendingTasks.length})
                    </Text>
                    {taskComplete == false && (
                      <View
                        style={{
                          height: 1,
                          width: '80%',
                          position: 'absolute',
                          backgroundColor: '#132FF5',
                          bottom: -1,
                        }}
                      ></View>
                    )}
                  </View>
                </Pressable>
                <Pressable onPress={() => setTaskComplete(true)}>
                  <View>
                    <Text style={taskComplete == true ? styles.blueBoldText : styles.grayBoldText}>
                      Complete ({completeTasks.length})
                    </Text>
                    {taskComplete == true && (
                      <View
                        style={{
                          height: 1,
                          width: '80%',
                          position: 'absolute',
                          backgroundColor: '#132FF5',
                          bottom: -1,
                        }}
                      ></View>
                    )}
                  </View>
                </Pressable>
              </View>

              {taskComplete == false &&
                pendingTasks.map((task, index) => {
                  return <PendingTask key={index} {...props} task={task} setTaskToDelete={setTaskToDelete} />;
                })}
              {taskComplete == true &&
                completeTasks.map((task, index) => {
                  return <CompleteTask key={index} {...props} task={task} navigation={props.navigation} />;
                })}
            </View>
          ) : (
            <View style={{ marginTop: 50 }}>
              <Loading />
            </View>
          )}
        </View>

        <RBSheet
          ref={refAssignee}
          closeOnDragDown={true}
          closeOnPressMask={true}
          height={478}
          openDuration={250}
          customStyles={{
            wrapper: {
              backgroundColor: 'rgba(0,0,0,0.3)',
            },
            container: {
              borderTopRightRadius: 8,
              borderTopLeftRadius: 8,
            },
          }}
        >
          <ScrollView style={{ paddingHorizontal: 24, marginBottom: 9 }}>
            <Text style={[styles.textMediumBold, { color: '#6B6B6B' }]}>Assignee</Text>
            <View style={{ marginTop: 12 }}>
              <SearchBar onChangeText={(text) => handleSearchAssignee(text)} text={assigneeFilter} />
            </View>

            <View style={{ marginTop: 7 }}>
              {assigneeOptions.map((user, index) => {
                return (
                  <TouchableOpacity key={index} onPress={() => setAssignee(user)}>
                    <View style={styles.assigneeContainer} key={index}>
                      <View style={styles.assigneeDetail}>
                        <Image
                          source={{ uri: user.profilePicture }}
                          style={{ width: 22, height: 22, marginRight: 3, borderRadius: 11 }}
                        />
                        <View style={{ marginLeft: 15 }}>
                          <Text
                            style={{
                              fontFamily: 'NunitoSans_700Bold',
                              fontSize: 16,
                            }}
                          >
                            {`${user.firstName} ${user.lastName}`}
                          </Text>
                          <Text style={[styles.textSmallerBold, { color: '#132FF5' }]}>
                            {getUserRoleString(user.role)}
                          </Text>
                        </View>
                      </View>

                      <View style={user._id === assignee._id ? styles.iconContainer : styles.iconContainerDisable}>
                        <AiOutlineCheckSquare
                          name="md-checkmark-sharp"
                          style={user._id === assignee._id ? [styles.textBold, { color: '#0045FF' }] : styles.textBold}
                        />
                      </View>
                    </View>
                  </TouchableOpacity>
                );
              })}
            </View>
          </ScrollView>
        </RBSheet>
        <RBSheet
          ref={refDelete}
          closeOnDragDown={true}
          closeOnPressMask={true}
          openDuration={250}
          customStyles={{
            wrapper: {
              backgroundColor: 'rgba(0,0,0,0.3)',
            },
            container: {
              borderTopRightRadius: 8,
              borderTopLeftRadius: 8,
              height: rbHeight,
            },
          }}
        >
          <TouchableOpacity
            onPress={() => {
              setDeleteModalVisible(true);
              setRbHeight(0);
            }}
          >
            <View style={styles.deleteTask}>
              <Text style={[styles.textMediumBold, { color: '#FF4D4D' }]}>Delete Task</Text>
            </View>
          </TouchableOpacity>
          {deleteModal}
        </RBSheet>
        {deleteModal}
      </ScrollView>
    </View>
  );
}

export default TaskInner;
