import React, { useEffect, useState } from 'react';
import {
  Text,
  TouchableOpacity,
  View,
  TextInput,
  SafeAreaView,
  Platform,
  ImageBackground,
  ActivityIndicator,
  Pressable,
  Alert,
} from 'react-native';
import Toast from 'react-native-toast-message';
import InputField from './inputField';
import Button from '../../../../components/Button';
import { styles } from './style';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useIsFocused } from '@react-navigation/native';
import ModalSelect from '../../../../components/ModalSelect';
import { api } from '../../../../utils/api';
import { showInfoToast, showSuccessToast } from '../../../../utils/toastUtils';
import SignUpHeader from '../../../../components/SignUp/SignUpHeader';

export default function PersonalInfor({ ...props }) {
  const [loading, setLoading] = useState(true);
  const [fields, setFields] = useState([]);
  const [userProfile, setUser] = useState(null);
  const [userImage, setUserImage] = useState(null);
  const [userFirstName, setUserFirstName] = useState(null);
  const [newImg, setNewImg] = useState(null);
  const [displayModal, setDisplayModal] = useState(false);

  const getUserDetails = async () => {
    let user = await AsyncStorage.getItem('user');
    user = JSON.parse(user);
    setUser(user);
    setUserImage(user.profilePicture);
    setUserFirstName(user.firstName);
    setFields([
      {
        key: 'fullName',
        title: 'Full Name',
        suggestText: `${user.firstName} ${user.lastName}`,
      },
      {
        key: 'phone',
        title: 'Your Mobile',
        suggestText: `${user.phone}`,
      },
      {
        key: 'email',
        title: 'Email',
        suggestText: `${user.email}`,
      },
    ]);
    setLoading(false);
  };

  const openOption = async (value) => {
    if (value.toLowerCase() == 'camera') {
      let permissions = await ImagePicker.getCameraPermissionsAsync();
      if (permissions.status == 'undetermined') {
        permissions = await ImagePicker.requestCameraPermissionsAsync();
      }

      if (permissions.granted) {
        setTimeout(async () => {
          let result = await ImagePicker.launchCameraAsync({
            mediaTypes: 'Images',
            quality: 0.7,
          });
          setNewImg(result);
        }, 500);
      }
    } else if (value.toLowerCase() == 'gallery') {
      let permissions = await ImagePicker.getMediaLibraryPermissionsAsync();
      if (permissions.status == 'undetermined') {
        permissions = await ImagePicker.requestMediaLibraryPermissionsAsync();
      }

      console.log(permissions);
      if (permissions.granted) {
        setTimeout(async () => {
          let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: 'Images',
            quality: 0.7,
          });
          console.log(result);
          setNewImg(result);
        }, 500);
      }
    }
  };

  const onChangeText = (item, key) => {
    let accFields = fields;
    accFields[key].text = item;
    setFields(accFields);
  };

  const updateUser = async () => {
    let newValueSet = false;
    let formData = new FormData();

    for (let ac = 0; ac < fields.length; ac++) {
      const element = fields[ac];
      if (element.text) {
        newValueSet = true;

        if (element.key == 'fullName') {
          let firstName = element.text.split(' ')[0];
          let lastName = element.text.split(' ')[1];
          formData.append('firstName', firstName);
          formData.append('lastName', lastName);
        } else {
          formData.append(element.key, element.text);
        }
      }
    }

    if (!newValueSet && newImg == null) {
      return Alert.alert('Please enter in new information or change you profile picture to save.');
    }

    if (newImg !== null) {
      formData.append('profilePicture', {
        type: newImg.type,
        uri: newImg.uri,
        name: newImg.fileName,
      });
    }

    let result = await api(`v1/user/${userProfile._id}`, 'PUT', formData, true);
    if (result.status == 200) {
      if (result.data.success) {
        await AsyncStorage.setItem('user', JSON.stringify(result.data.user));
        setUser(result.data.user);
        getUserDetails();
        setNewImg(null);
        showSuccessToast('Successfully Updated Profile', 'Your details have been saved.', 'bottom');
      }
    }
  };

  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      getUserDetails();
    }
  }, [isFocused]);

  const listFields = fields.map((item, index) => {
    return (
      <InputField
        key={index}
        title={item.title}
        suggestText={item.suggestText}
        onChangeText={(item) => onChangeText(item, index)}
        text={item.text}
      />
    );
  });

  if (loading) {
    return <ActivityIndicator />;
  }

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text
          style={[
            styles.textBold,
            {
              color: '#6B6B6B',
              textAlign: 'center',
            },
          ]}
        >
          Personal Information
        </Text>
      </View>
      <View
        style={
          Platform.OS === 'ios'
            ? [
                styles.imgPick,
                {
                  top: 120,
                },
              ]
            : styles.imgPick
        }
      >
        <Pressable
          onPress={() => {
            console.log('go back');
            props.navigation.goBack();
          }}
          style={styles.backButton}
        >
          <Text style={styles.backText}>Back</Text>
        </Pressable>
        <ImageBackground
          source={{ uri: newImg !== null ? newImg.uri.replace('file://', '') : userImage }}
          style={styles.imageDrop}
          imageStyle={[styles.imageDrop]}
          blurRadius={newImg !== null ? 0 : 0}
        />
      </View>
      <Text style={styles.title}>Hi, {userFirstName}</Text>
      <View style={styles.body}>
        <View>{listFields}</View>
        <Button
          title={'Save'}
          buttonStyle={styles.blueButton}
          textStyle={styles.blueButtonText}
          onPress={() => updateUser()}
        />
      </View>
      <ModalSelect
        modalVisible={displayModal}
        setVisible={setDisplayModal}
        setValue={openOption}
        heading="Select Camera or Gallery"
        data={['Camera', 'Gallery']}
      />
    </View>
  );
}
