import { StyleSheet } from 'react-native';
import colors from '../../../../theme/colors';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  loading: {
    height: 8,
    backgroundColor: 'white',
    marginTop: 24,
  },
  loadPercent: {
    height: 8,
    width: '70%',
    backgroundColor: '#132FF5',
  },
  blueButton: {
    borderRadius: 8,
    opacity: 0.5,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
    marginBottom: 20,
    width: '100%',
  },
  blueButtonActive: {
    borderRadius: 8,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
    marginBottom: 20,
  },
  blueButtonText: {
    color: colors.white,
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 18,
    textAlign: 'center',
  },
  assignButton: {
    position: 'absolute',
    bottom: 10,
    paddingHorizontal: 27,
    backgroundColor: 'white',
    width: '100%',
  },

  inputSearchDrop: {
    marginTop: 8,
    borderColor: '#C7C7C7',
    width: '100%',
    borderWidth: 1,
    borderRadius: 8,
    paddingVertical: 15,
    paddingHorizontal: 13,
  },

  textField: {
    paddingHorizontal: 14,
    marginBottom: 20,
    paddingVertical: 16,
  },
  menu: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 30,
    paddingBottom: 30,
    paddingHorizontal: 24,
    alignItems: 'center',
  },
  form: {
    marginHorizontal: 26,
    marginBottom: 180,
  },
  script: {
    marginTop: 8,
    marginBottom: 9,
    fontFamily: 'NunitoSans_400Regular',
  },
  inputHeader: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
  },
  title: {
    paddingTop: 15,
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleText: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
  },
});
