import * as DocumentPicker from 'expo-document-picker';
import React, { useState, useRef } from 'react';
import { Image, Pressable, Text, TouchableOpacity, View } from 'react-native';
import { AiOutlineFilePdf, AiOutlineCloseCircle, AiOutlineCloudUpload } from 'react-icons/ai';

import ModalSelect from '../../../../../components/ModalSelect';
import images from '../../../../../theme/images';
import { styles } from './style';

export default function UploadField({ ...props }) {
  const [image, setImage] = useState(null);
  const [document, setDocument] = useState(null);
  const [displayModal, setDisplayModal] = useState(false);

  const openOption = async (value) => {
    if (value.toLowerCase() == 'camera') {
      let permissions = await ImagePicker.getCameraPermissionsAsync();
      if (permissions.status == 'undetermined') {
        permissions = await ImagePicker.requestCameraPermissionsAsync();
      }

      if (permissions.granted) {
        setTimeout(async () => {
          let result = await ImagePicker.launchCameraAsync({
            mediaTypes: 'Images',
            quality: 0.7,
          });
          setImage(result.uri);
        }, 500);
      }
    } else if (value.toLowerCase() == 'gallery') {
      let permissions = await ImagePicker.getMediaLibraryPermissionsAsync();
      if (permissions.status == 'undetermined') {
        permissions = await ImagePicker.requestMediaLibraryPermissionsAsync();
      }

      console.log(permissions);
      if (permissions.granted) {
        setTimeout(async () => {
          let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: 'Images',
            quality: 0.7,
          });
          setImage(result.uri);
        }, 500);
      }
    }
  };

  const DocRef = useRef(null);

  const pickDocument = async () => {
    // let result = await DocumentPicker.getDocumentAsync({
    //   allowsEditing: true,
    //   quality: 1,
    // });

    // if (!result.cancelled) {
    //   setDocument(result.name);
    //   if (props.updateWarranty) {
    //     props.updateWarranty({
    //       type: result.name.split('.').pop(),
    //       uri: result.uri,
    //       name: result.name,
    //     });
    //   }
    //   if (props.updateReceipt) {
    //     props.updateReceipt({
    //       type: result.name.split('.').pop(),
    //       uri: result.uri,
    //       name: result.name,
    //     });
    //   }
    // }

    DocRef.current.click();
  };

  const handleDocChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      // Handle the selected file here
      console.log('Selected File:', selectedFile);
      setDocument(selectedFile.name);
      if (props.updateWarranty) {
        props.updateWarranty(selectedFile);
      }
      if (props.updateReceipt) {
        props.updateReceipt(selectedFile);
      }
    }
  };

  return (
    <View style={styles.pickImage}>
      <Pressable onPress={pickDocument}>
        {document == null && (
          <View style={styles.imgPicker}>
            <View style={styles.dasherBorder}>
              <AiOutlineCloudUpload name="upload" style={styles.imgIcon} />
              <Text style={styles.uploadText}>Accept .jpeg or png files</Text>
              <input ref={DocRef} type="file" style={{ display: 'none' }} onChange={handleDocChange} />
            </View>
          </View>
        )}
      </Pressable>
      {document && (
        <View style={styles.imgPickedContainer}>
          <View style={styles.documentPicked}>
            <View style={styles.docTitle}>
              <AiOutlineFilePdf name="file-pdf-o" style={styles.docIcon} />
              <Text style={styles.titleText}>{document}</Text>
            </View>
            <TouchableOpacity onPress={() => ''}>
              <Image
                style={{
                  width: 28,
                  height: 28,
                  marginRight: 8,
                  // tintColor:"white"
                }}
                source={images.lightGreyDownload}
              />
            </TouchableOpacity>
          </View>
          <AiOutlineCloseCircle
            name="close"
            style={styles.closeIcon}
            onPress={() => {
              setDocument(null);
              if (props.updateWarranty) {
                props.updateWarranty(null);
              }
              if (props.updateReceipt) {
                props.updateReceipt(null);
              }
            }}
          />
        </View>
      )}
      <ModalSelect
        modalVisible={displayModal}
        setVisible={setDisplayModal}
        setValue={openOption}
        heading="Select Camera or Gallery"
        data={['Camera', 'Gallery']}
      />
    </View>
  );
}
