import React, { useState, useEffect, useRef } from 'react';
import { TextInput, ScrollView, Text, View, Platform, Image, Pressable, SafeAreaView } from 'react-native';
import Button from '../../../../components/Button';
import SignUpHeader from '../../../../components/SignUp/SignUpHeader';
import NavigationHeader from '../../../../components/SignUp/SingUpNavigation';
import { styles } from './style';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BsCloudUpload } from 'react-icons/bs';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import AddressInput from '../../../../components/AddressInput';
import { useIsFocused } from '@react-navigation/native';
import DropInputField from '../../../../components/DropInputField';
import { api } from '../../../../utils/api';
import AsyncStorage from '@react-native-async-storage/async-storage';

export default function SettingUpProjectStep5({ ...props }) {
  const [projectInput, setProjectInput] = useState({ title: '', address: '' });
  const [buildingInput, setBuildingInput] = useState([{ title: '', address: '', siteType: '', defaultCadence: '' }]);
  const [fieldsValidated, setFieldsValidated] = useState(false);
  const [siteTypes, setSiteTypes] = useState([]);
  const [selectedSiteType, setSelectedSiteType] = useState('');

  const getSiteTypes = async () => {
    const siteTypes = await api('v1/site-type/', 'GET', null);
    if (siteTypes.status == 200) {
      if (siteTypes.data.success) {
        let data = siteTypes.data;
        var arraySiteTypes = [];
        for (let i = 0; i < data.siteTypes.length; i++) {
          const element = data.siteTypes[i];
          const obj = {
            title: element.name,
            id: element._id,
            defaultCadence: element.defaultTestCadence,
          };
          arraySiteTypes.push(obj);
        }
        setSiteTypes(arraySiteTypes);
      }
    }
  };

  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      getSiteTypes();
    }
  }, [isFocused]);

  useEffect(() => {
    var checkProjectInput = true;
    for (let cpi = 0; cpi < Object.values(projectInput).length; cpi++) {
      const element = Object.values(projectInput)[cpi];
      if (typeof element == 'string') {
        if (element.length == 0) {
          checkProjectInput = false;
        }
      } else {
        if (!element.street) {
          checkProjectInput = false;
        }
      }
    }

    var checkbuildingInput = true;
    for (let bi = 0; bi < buildingInput.length; bi++) {
      const el = buildingInput[bi];
      for (let cpi = 0; cpi < Object.values(el).length; cpi++) {
        const element = Object.values(el)[cpi];
        if (typeof element == 'string') {
          if (element.length == 0) {
            checkbuildingInput = false;
          }
        } else {
          if (element !== undefined) {
            if (!element.street) {
              if (!element.type) {
                checkbuildingInput = false;
              }
            }
          }
        }
      }
    }

    if (checkProjectInput && checkbuildingInput) {
      setFieldsValidated(true);
    }
  }, [projectInput, buildingInput]);

  const [image, setImage] = useState(null);
  const handleChangeBuilding = (text, index, key) => {
    const newInput = buildingInput.map((item, i) => {
      if (i == index) {
        if (key == 'siteType') {
          return { ...item, [key]: text.title, ['defaultCadence']: text.defaultCadence };
        } else {
          return { ...item, [key]: text };
        }
      }
      return item;
    });
    if (key == 'siteType') {
      setSelectedSiteType(text);
    }
    setBuildingInput(newInput);
  };

  const DocRef = useRef(null);
  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    // let result = await ImagePicker.launchImageLibraryAsync({
    //   mediaTypes: ImagePicker.MediaTypeOptions.All,
    //   allowsEditing: true,
    //   aspect: [4, 3],
    //   quality: 1,
    // });

    // if (!result.canceled) {
    //   setImage(result.uri);
    // }
    DocRef.current.click();
  };

  const handleDocChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      // Handle the selected file here
      console.log('Selected File:', selectedFile);
      setImage(selectedFile);
    }
  };

  const addFields = () => {
    let newfield = {
      title: '',
      address: '',
      siteType: '',
      defaultCadence: '',
    };
    setBuildingInput([...buildingInput, newfield]);
  };

  const fields = buildingInput.map((item, index) => {
    return (
      <View key={index}>
        <Text style={styles.inputHeader}>Title</Text>
        <TextInput
          placeholder="Title e.g. Northern Block"
          style={styles.input}
          onChangeText={(text) => {
            handleChangeBuilding(text, index, 'title');
          }}
          value={item.title}
        />
        <Text style={styles.inputHeader}>Address</Text>
        <AddressInput
          selectAddress={(data) => {
            handleChangeBuilding(data, index, 'address');
          }}
        />
        <Text style={styles.inputHeader}>Site Type</Text>
        <DropInputField
          placeholder={'Select site type'}
          data={siteTypes}
          selectedDropdownItem={selectedSiteType}
          setSelectedDropdownItem={(data) => handleChangeBuilding(data, index, 'siteType')}
          havePosition={false}
        />
      </View>
    );
  });

  return (
    <View style={styles.container}>
      <SafeAreaView
        style={{
          flex: 1,
        }}
      >
        <KeyboardAwareScrollView
          contentContainerStyle={styles.scrollContainer}
          bounces={false}
          enableOnAndroid={true}
          enableAutomaticScroll={Platform.OS === 'ios'}
          keyboardShouldPersistTaps="always"
          nestedScrollEnabled={true}
        >
          <View
            style={
              Platform.OS === 'ios'
                ? [
                    styles.loading,
                    {
                      marginTop: 0,
                    },
                  ]
                : styles.loading
            }
          >
            <View style={styles.loadPercent}></View>
          </View>
          <NavigationHeader
            onPress={async () => {
              if (props.route.params.addNewProject) {
                props.navigation.goBack();
              } else {
                await api(`v1/company/${props.route.params.company}`, 'DELETE', null);
                await AsyncStorage.multiRemove(['user', 'token', 'refreshToken', 'expiresIn']);
                props.navigation.navigate('GuestNavigation', { screen: 'QRCodeScan' });
              }
            }}
            header={props.route.params.addNewProject ? 'Add New Project' : 'Sign Up'}
            navigate={'Cancel'}
            menuStyles={styles.menu}
          />
          {props.route.params.addNewProject ? (
            <View>
              <Text style={[styles.textBold, { textAlign: 'center' }]}>Set up your next project</Text>
              <Text style={styles.scriptStyle}>
                Please add the project details. You can invite team mates in the next step.
              </Text>
            </View>
          ) : (
            <SignUpHeader
              title={'Set up your first project'}
              descript={'Please add the project details. You can invite team mate in the next step.'}
              userImage={props.route.params.userImage}
              disablePress={true}
            />
          )}

          <View style={styles.textField}>
            <View style={styles.firstTitle}>
              <Text style={styles.titleText}>Project</Text>
              <Text style={styles.required}> *</Text>
            </View>

            <Text style={styles.inputHeader}>Title</Text>
            <TextInput
              placeholder="Title e.g. Northern Block"
              style={styles.input}
              onChangeText={(text) => {
                setProjectInput({ ...projectInput, title: text });
              }}
              value={projectInput.title}
            />
            <Text style={styles.inputHeader}>Address</Text>
            <AddressInput
              selectAddress={(address) => {
                setProjectInput({ ...projectInput, address: address });
              }}
            />
            <View style={styles.title}>
              <Text style={styles.titleText}>Site</Text>
              <Text style={styles.required}> *</Text>
            </View>
            {fields}
            <Pressable onPress={() => addFields()}>
              <Text style={styles.addReq}>+ Add another Site</Text>
            </Pressable>
            <View style={styles.title}>
              <Text style={styles.titleText}>Project Logo</Text>
            </View>
            <Text style={styles.inputHeader}>
              Logo <Text style={styles.optional}> - optional</Text>
            </Text>
            <Pressable onPress={pickImage}>
              {image == null && (
                <View style={styles.imgPicker}>
                  <View style={styles.dasherBorder}>
                    <BsCloudUpload name="upload" style={styles.imgIcon} />
                    <Text style={styles.uploadText}>Accept .jpeg or png files</Text>
                  </View>
                </View>
              )}
            </Pressable>
            <input ref={DocRef} type="file" style={{ display: 'none' }} onChange={handleDocChange} />
            {image && (
              <View style={styles.imgPickedContainer}>
                <View style={styles.imgPicked}>
                  {/* <Image source={{ uri: image }} style={{ width: 29, height: 29, borderRadius: 99 }} /> */}
                  <img src={URL.createObjectURL(image)} style={{ width: 29, height: 29, borderRadius: 99 }} />
                </View>
                <AiOutlineCloseCircle name="close" style={styles.closeIcon} onPress={() => setImage(null)} />
              </View>
            )}
          </View>
        </KeyboardAwareScrollView>
        <View style={styles.assignButton}>
          <Button
            title={'Next'}
            buttonStyle={fieldsValidated ? styles.blueButtonActive : styles.blueButton}
            textStyle={styles.blueButtonText}
            onPress={() =>
              fieldsValidated
                ? props.navigation.navigate('SettingUpProjectStep6', {
                    userImage: props.route.params.userImage,
                    userType: props.route.params.userType,
                    userLicence: props.route.params.userLicence,
                    appUse: props.route.params.appUse,
                    subscription: props.route.params.subscription,
                    company: props.route.params.company,
                    newProject: {
                      project: projectInput,
                      sites: buildingInput,
                      logo: image,
                    },
                    addNewProject: props.route.params.addNewProject,
                    registerForPushNotificationsAsync: props.route.params.registerForPushNotificationsAsync,
                  })
                : ''
            }
          />
          <Button
            title={'Previous'}
            buttonStyle={[styles.blankButton, { marginBottom: 20 }]}
            textStyle={styles.blankButtonText}
            onPress={() => props.navigation.goBack()}
          />
        </View>
      </SafeAreaView>
    </View>
  );
}
