import React from 'react';
import { useState } from 'react';
import { ScrollView } from 'react-native';
import { Text } from 'react-native';
import { View } from 'react-native';
import Button from '../../../../components/Button';
import Tag from '../../../../components/Tag';
import { styles } from './style';
import { api } from '../../../../utils/api';
import { useIsFocused } from '@react-navigation/core';
import { useEffect } from 'react';
import Loading from '../../../../components/Loading';
import { showSuccessToast, showErrorToast } from '../../../../utils/toastUtils';
import ConfirmModal from './confirmModal';

function Subscription({ ...props }) {
  const { company, updateCompanyAndList } = props;
  const currentSubscription = company.subscriptionType;
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  const handleSubmitPress = async () => {
    const subscriptionId = subscriptions.find((sub) => sub.value === selectedSubscription)._id;
    const response = await api(`v1/company/${company._id}/subscription/${subscriptionId}`, 'PATCH', null);

    if (response.status === 200 && response.data.success) {
      showSuccessToast('Updated plan successfully', `Subscription plan updated to ${selectedSubscription}`);
      const updatedCompany = response.data.company;
      updateCompanyAndList({ ...updatedCompany, id: updatedCompany._id, title: updatedCompany.name });
    } else {
      showErrorToast('Update failed', 'Please try again');
    }
  };

  const handleTagPress = (value, list) => {
    for (const item of list) {
      if (value === item.value) {
        setSelectedSubscription(item.value);
      }
    }
  };

  const subscriptionsList = subscriptions
    ?.filter((subscription) => subscription.value !== currentSubscription.value)
    .map((item, index) => (
      <Tag
        key={index}
        title={item.title}
        detail={item.detail}
        price={item.price}
        recommend={item.recommend}
        value={item.value}
        options={item.options}
        currentSubscription={currentSubscription}
        selectedSub={selectedSubscription}
        onPress={() => handleTagPress(item.value, subscriptions)}
        freeTime={item.freeTime}
        tier={item.tier}
      />
    ));

  const getSubscriptions = async () => {
    const response = await api('v1/subscription/', 'GET', null);

    if (response.status === 200 && response.data.success) {
      setSubscriptions(response.data.subscriptions);
    }
    setLoading(false);
  };

  const isFocused = useIsFocused();
  useEffect(() => {
    getSubscriptions();
  }, [isFocused]);

  if (loading) {
    return (
      <View style={{ marginTop: 50 }}>
        <Loading />
      </View>
    );
  }

  return (
    <>
      <ScrollView>
        <View style={styles.body}>
          <View style={styles.currentTag}>
            <View style={styles.selectedContent}>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Text style={styles.selectedText}>{currentSubscription.title}</Text>
                <View
                  style={{
                    flexDirection: 'row',
                  }}
                >
                  <Text
                    style={{
                      fontSize: 18,
                      paddingTop: 8,
                      fontFamily: 'NunitoSans_700Bold',
                      color: '#6B6B6B',
                    }}
                  >
                    {currentSubscription.price}
                  </Text>
                </View>
              </View>
              <View style={styles.tagInfor}>
                {currentSubscription.options &&
                  currentSubscription.options.map((item, id) => {
                    return (
                      <View style={[styles.tagScript, { marginRight: 10 }]} key={id}>
                        <Text style={styles.textScript}>{item}</Text>
                      </View>
                    );
                  })}
              </View>
              <Text style={styles.textDetail}>{currentSubscription.detail}</Text>
            </View>
            <View style={styles.currentLabel}>
              <Text style={styles.labelText}>Current plan</Text>
            </View>
          </View>
          <View style={styles.breakLine}></View>
          <View style={{ marginHorizontal: 25 }}>{subscriptionsList}</View>
        </View>
      </ScrollView>
      <View style={{ marginHorizontal: 25, marginBottom: 8 }}>
        <Button
          title={'Update'}
          buttonStyle={selectedSubscription ? styles.blueButtonActive : styles.blueButton}
          textStyle={styles.blueButtonText}
          onPress={() => {
            if (selectedSubscription) {
              setConfirmModalVisible(true);
            }
          }}
        />
      </View>
      <ConfirmModal
        visible={confirmModalVisible}
        setVisible={setConfirmModalVisible}
        title={'Are you sure?'}
        details={`Subscription plan will be updated to ${selectedSubscription}.`}
        submitButtonText={'Yes, update'}
        handleSubmit={handleSubmitPress}
      />
    </>
  );
}

export default Subscription;
