import React from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import Button from '../../../components/Button';
import { styles } from './style';

function OutsideRadiusWarningNotification({ ...props }) {
  const { notification, navigation, hasWriteAccess, handleClosePress } = props;

  const handleViewAssetPress = () => {
    navigation.navigate('QrCodeNav', {
      screen: 'AssetInfo',
      params: {
        asset: notification.asset,
        isAdmin: hasWriteAccess,
        fromNotifications: true,
        location: notification.location,
      },
    });
  };

  return (
    <View style={styles.notiBlock}>
      <View style={styles.notiHeader}>
        <View style={styles.notiTitle}>
          <Text style={styles.textBold}>
            Outside Site Radius
            <Text
              style={[
                styles.textBoldMedium,
                {
                  color: '#FF4D4D',
                },
              ]}
            >
              {' '}
              !
            </Text>
          </Text>
        </View>
        <TouchableOpacity onPress={() => handleClosePress(notification._id)}>
          <AiOutlineCloseCircle name="close" style={styles.closeIcon} />
        </TouchableOpacity>
      </View>
      <View style={styles.sender}>
        <View style={styles.senderInfor}>
          <Image style={styles.notiContentAvatar} source={{ uri: notification.asset?.assetType?.icon }} />
          <Text
            style={[
              styles.textBold,
              {
                paddingLeft: 5,
              },
            ]}
          >
            {notification.asset?.name}
          </Text>
        </View>
        <View style={styles.senderInfor}>
          <Image style={styles.notiContentAvatar} source={{ uri: notification.project?.logo }} />
          <Text
            style={[
              {
                paddingLeft: 5,
              },
            ]}
          >
            {notification.project?.name}
          </Text>
        </View>
      </View>
      <Text
        style={[
          styles.textRegular,
          {
            marginTop: 8,
            textAlign: 'center',
          },
        ]}
      >
        Scanned at:
      </Text>
      <View style={[styles.inviteContent, { backgroundColor: '#FAD2D3' }]}>
        <Text style={{ paddingLeft: 5, color: '#FF4D4D' }} numberOfLines={1} ellipsizeMode="tail">
          {`${notification.location.address ?? ''}`}
        </Text>
      </View>
      <View style={styles.buttonsContainer}>
        <Button
          title={'View Asset'}
          buttonStyle={[styles.blueButton, { width: '100%' }]}
          textStyle={styles.blueButtonText}
          onPress={handleViewAssetPress}
        />
      </View>
    </View>
  );
}

export default OutsideRadiusWarningNotification;
