import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../../../theme/colors';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  header: {
    backgroundColor: '#F6F6F6',
    paddingTop: 70,
    paddingBottom: 18,
  },
  body: {
    marginTop: 16,
    backgroundColor: 'white',
    marginBottom: 100,
  },
  screenNameIcon: {
    fontSize: 17,
    color: '#6B6B6B',
    alignSelf: 'center',
  },
  screenNameContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 23,
    marginBottom: 10,
  },
  screenName: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    marginRight: 8,
    color: '#6B6B6B',
  },
  titleContainer: {
    paddingHorizontal: 25,
    paddingVertical: 13,
    marginTop: 6,
    borderBottomColor: '#00000014',
    borderBottomWidth: 1,
  },
  title: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 14,
    color: '#4A4A4A',
  },
  projectDetails: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 23,
    paddingVertical: 10,
  },
  accordionHeader: {
    paddingVertical: 4,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 23,
    alignItems: 'center',
    paddingVertical: 10,
    borderBottomColor: '#00000014',
    borderBottomWidth: 1,
  },
  accordionHeaderIcon: {
    width: 25,
    height: 25,
    alignSelf: 'center',
  },
  iconContainer: {
    height: 25,
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: 99,
    paddingHorizontal: 6,
    alignSelf: 'center',
    paddingLeft: 35,
  },
  accordionHeaderContent: {
    paddingLeft: 8,
    width: '80%',
    alignSelf: 'center',
  },
  textBold: {
    fontFamily: 'NunitoSans_700Bold',
  },
  textRegularSmall: {
    fontFamily: 'NunitoSans_400Regular',
    fontSize: 12,
    marginRight: 15,
  },
  export: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  accordionIcon: {
    fontSize: 14,
    paddingLeft: 8,
    paddingRight: 50,
  },
  headerContentDescript: {
    flexDirection: 'row',
  },

  textBoldLarge: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
  },
  validate: {
    borderBottomWidth: 1,
    width: '100%',
    alignItems: 'center',
    borderColor: '#bdbdbd',
  },
  assignButton: {
    position: 'absolute',
    flexDirection: 'row',
    bottom: 60,
    paddingHorizontal: 27,
    backgroundColor: 'white',
    width: '100%',
  },
  blueButtonActive: {
    borderRadius: 8,
    backgroundColor: '#132FF5',
    paddingVertical: 8,
    marginBottom: 10,
    flex: 1,
  },
  blueButtonText: {
    color: colors.white,
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    textAlign: 'center',
  },
  blankButton: {
    width: 162,
    paddingVertical: 8,
    borderWidth: 1,
    borderColor: '#132FF5',
    borderRadius: 8,
    flex: 1,
    marginLeft: 8,
  },
  blankButtonText: {
    color: '#132FF5',
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    textAlign: 'center',
  },
  subtitle: {
    paddingHorizontal: 18,
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 14,
    color: '#4A4A4A',
  },
  assetContainer: {
    flex: 1,
    backgroundColor: '#8997FA',
    marginHorizontal: 27,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'center',
    paddingVertical: 20,
    marginTop: 32,
  },
  grayRegularText: {
    fontFamily: 'NunitoSans_400Regular',
    fontSize: 12,
    color: '#000000',
    opacity: 0.5,
  },
  levelTitleSection: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: 'lightgrey',
    padding: 15,
  },
  levelTitle: {},
  areaTitleSection: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    padding: 15,
    paddingLeft: 50,
    paddingRight: 25,
  },
  areaTitle: {},
});
