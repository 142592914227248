import { Dimensions, StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  searchSection: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#D0D0D0',
    borderRadius: 4,
  },
  searchIcon: {
    paddingHorizontal: 8,
    marginVertical: 6,
    paddingVertical: 8,
    borderBottomLeftRadius: 4,
    borderRightWidth: 1,
    borderColor: '#D0D0D0',
    borderTopLeftRadius: 4,
  },
  inputIcon: {
    paddingHorizontal: 8,
    height: '100%',
    width: '90%',
  },
});
