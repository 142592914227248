import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../../../theme/colors';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  textField: {
    borderWidth: 1,
    borderRadius: 8,
    borderColor: '#C7C7C7',
    paddingHorizontal: 12,
    marginTop: 16,
    paddingVertical: 13,
  },
  input: {
    marginTop: 16,
    borderColor: '#C7C7C7',
    width: '100%',
    borderWidth: 1,
    borderRadius: 8,
    paddingVertical: 10,
    paddingLeft: 13,
  },
  content: {
    marginHorizontal: 16,
    marginTop: 31,
  },
  addReq: {
    marginTop: 13,
    color: '#132FF5',
    fontSize: 16,
    fontFamily: 'NunitoSans_600SemiBold',
  },
  blueBorderButton: {
    borderRadius: 8,
    borderColor: '#132FF5',
    borderWidth: 1,
    paddingVertical: 10,
    marginBottom: 16,
  },
  blueBorderButtonText: {
    color: '#132FF5',
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 16,
    textAlign: 'center',
  },
  loading: {
    height: 8,
    backgroundColor: 'white',
    marginTop: 24,
  },
  loadPercent: {
    height: 8,
    width: '70%',
    backgroundColor: '#132FF5',
  },
  buttonJob: {
    paddingHorizontal: 40,
    width: '100%',
  },
  blueButton: {
    borderRadius: 8,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
    marginTop: 40,
    width: '100%',
  },
  blueButtonText: {
    color: colors.white,
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    textAlign: 'center',
  },
  blankButton: {
    paddingVertical: 10,
  },
  blankButtonText: {
    color: '#6B6B6B',
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 18,
    textAlign: 'center',
  },
  assignButton: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    marginTop: 32,
    paddingHorizontal: 16,
    backgroundColor: 'white',
  },
  popupWindow: {
    backgroundColor: 'white',
    paddingHorizontal: 27,
    paddingVertical: 20,
    borderRadius: 12,
    alignItems: 'center',
    marginHorizontal: 26,
  },
  menu: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 20,
    paddingBottom: 36,
    paddingHorizontal: 26,
    alignItems: 'center',
  },
  body: {
    backgroundColor: 'white',
    // height:Dimensions.get("screen").height,
    flexDirection: 'column',
    flex: 1,
    paddingBottom: 10,
  },
  assignButtonScroll: {
    width: '100%',
    marginTop: 32,
    paddingHorizontal: 16,
  },
  popupModal: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  popupIcon: {
    width: 70,
    height: 70,
    borderRadius: 16,
    marginTop: 8,
  },
  popupTitle: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    marginTop: 25,
  },
  popupScript: {
    fontFamily: 'NunitoSans_400Regular',
    marginTop: 8,
    textAlign: 'center',
  },
  signupHeader: {
    paddingTop: 8,
    paddingHorizontal: 62,
    textAlign: 'center',
    fontFamily: 'NunitoSans_400Regular',
  },
  resultBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  resultContent: {
    backgroundColor: '#eaedfe',
    paddingTop: 5,
    paddingBottom: 10,
    paddingHorizontal: 16,
    width: '120%',
    borderRadius: 8,
    marginTop: 5,
  },
  resultTitle: {
    paddingTop: 5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleIcon: {
    width: 27,
    height: 27,
    tintColor: 'black',
  },
  titleText: {
    fontFamily: 'NunitoSans_700Bold',
    paddingLeft: 4,
  },
  resultScript: {
    fontSize: 15,
    fontFamily: 'NunitoSans_400Regular',
    paddingTop: 4,
  },
  recommendBox: {
    backgroundColor: 'white',
    flexDirection: 'column',
  },
  recommendContent: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 11,
    borderTopWidth: 1,
    marginRight: 10,
    marginTop: 10,
    borderColor: '#C7C7C7',
  },
  recommendIcon: {
    width: 27,
    height: 27,
    tintColor: 'black',
  },
  required: {
    color: '#FF4D4D',
  },
  createOption: {
    marginTop: 20,
    borderTopColor: '#C7C7C7',
    borderTopWidth: 1,
    width: '97%',
    marginBottom: 5,
  },
});
