import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../../theme/colors';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#0017FE',
  },
  loginBG: {
    width: 280,
    height: 355,
    resizeMode: 'contain',
    justifyContent: 'flex-start',
  },
  loginLogoText: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
  },
  oneTagLogo: {
    width: 145,
    height: 60,
    marginTop: 40,
    marginBottom: 70,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  loginText: {
    color: colors.black,
    fontFamily: 'NunitoSans_400Regular',
    fontSize: 22,
    fontWeight: '600',
    lineHeight: 30,
    textAlign: 'center',
  },
  buttonColumn: {
    width: '85%',
    position: 'absolute',
    bottom: 0,
    marginTop: 50,
  },
  whiteButton: {
    width: '100%',
    borderRadius: 8,
    backgroundColor: 'white',
    paddingVertical: 10,
  },
  whiteButtonText: {
    color: colors.mainBlue,
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    textAlign: 'center',
  },
  whiteBorderButton: {
    width: '100%',
    borderRadius: 8,
    borderColor: colors.white,
    borderWidth: 1,
    paddingVertical: 10,
  },
  whiteBorderButtonText: {
    color: colors.white,
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    textAlign: 'center',
  },
  background: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    height: 500,
  },
});
