import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../../theme/colors';

export const styles = StyleSheet.create({
  imageDrop: {
    height: 100,
    width: 100,
    borderRadius: 1000,
    backgroundColor: '#ffff',
    shadowColor: '#000',
    shadowOffset: {
      width: 6,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    alignItems: 'center',
    justifyContent: 'center',
    elevation: 5,
  },
  image: {
    alignItems: 'center',
  },
  textBold: {
    fontSize: 18,
    fontFamily: 'NunitoSans_700Bold',
    paddingTop: 8,
  },
  scriptStyle: {
    paddingTop: 8,
    paddingHorizontal: 62,
    textAlign: 'center',
    fontFamily: 'NunitoSans_400Regular',
  },
  addIconStyle: {
    fontSize: 80,
    color: 'rgba(0,0,0,0.3)',
    paddingLeft: 6,
  },
  selectedImageStyle: {
    width: 100,
    height: 100,
    aspectRatio: 1,
    borderRadius: 150,
  },
});
