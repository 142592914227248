import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../../theme/colors';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  header: {
    backgroundColor: '#F6F6F6',
    paddingTop: 70,
    paddingBottom: 18,
  },
  body: {
    marginTop: 16,
    backgroundColor: 'white',
    marginBottom: 100,
  },
  screenName: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    color: '#6B6B6B',
    paddingHorizontal: 25,
  },
  titleContainer: {
    paddingHorizontal: 25,
    paddingVertical: 13,
    marginTop: 6,
    borderBottomColor: '#00000014',
    borderBottomWidth: 1,
  },
  title: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 14,
    color: '#4A4A4A',
  },
  projectDetails: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 23,
    paddingVertical: 10,
  },
  accordionHeader: {
    paddingVertical: 4,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 23,
    alignItems: 'center',
    paddingVertical: 10,
    borderBottomColor: '#00000014',
    borderBottomWidth: 1,
  },
  accordionHeaderIcon: {
    width: 22,
    height: 22,
    borderRadius: 11,
  },
  iconContainer: {
    backgroundColor: '#132FF5',
    height: 23,
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: 99,
    paddingHorizontal: 6,
    alignSelf: 'center',
  },
  accordionHeaderContent: {
    paddingLeft: 8,
    width: '80%',
  },
  textBold: {
    fontFamily: 'NunitoSans_700Bold',
  },
  textRegularSmall: {
    fontFamily: 'NunitoSans_400Regular',
    fontSize: 12,
    marginRight: 15,
  },
  export: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  accordionIcon: {
    fontSize: 14,
    paddingLeft: 8,
  },
  headerContentDescript: {
    flexDirection: 'row',
  },

  textBoldLarge: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
  },
  validate: {
    borderBottomWidth: 1,
    width: '100%',
    alignItems: 'center',
    borderColor: '#bdbdbd',
  },
  assignButton: {
    position: 'absolute',
    flexDirection: 'row',
    bottom: 80,
    paddingHorizontal: 27,
    backgroundColor: 'white',
  },
  blueButtonActive: {
    borderRadius: 8,
    backgroundColor: '#132FF5',
    paddingVertical: 8,
    flex: 1,
    height: 40,
    // width: "100%",
  },
  blueButtonText: {
    color: colors.white,
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    textAlign: 'center',
  },
  blankButton: {
    // width: 162,
    paddingVertical: 8,
    borderWidth: 1,
    borderColor: '#132FF5',
    borderRadius: 8,
    // width: "100%",
    height: 40,
    flex: 1,
    marginLeft: 8,
  },
  blankButtonText: {
    color: '#132FF5',
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    textAlign: 'center',
  },
  popupModal: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  popupWindow: {
    backgroundColor: 'white',
    paddingHorizontal: 25,
    paddingVertical: 19,
    borderRadius: 12,
    alignItems: 'center',
    marginHorizontal: 26,
  },
  popupClose: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
  popupCloseIcon: {
    width: 25,
    height: 25,
  },
  popupHeading: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    textAlign: 'center',
    marginBottom: 15,
  },
  buttonSection: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  popupButton: {
    padding: 15,
    marginBottom: 8,
    borderRadius: 8,
    width: '47%',
  },
  withButton: {
    backgroundColor: '#132FF5',
    borderWidth: 1,
    borderColor: '#132FF5',
  },
  withoutButton: {
    borderColor: '#132FF5',
    borderWidth: 1,
  },
  withButtonText: {
    color: '#FFF',
    textAlign: 'center',
    fontWeight: '600',
  },
  withoutButtonText: {
    color: '#132FF5',
    textAlign: 'center',
    fontWeight: '600',
  },
});
