import { Image, Text, View } from 'react-native';
import HistoryRow from '../../components/AssetInfo/HistoryRow';
import images from '../../theme/images';
import AccordionExtraContent from './accordionExtraContent';
import { styles } from './style';
import { getUserRoleString } from '../../utils/userUtils';

const HistoryContent = ({ ...props }) => {
  var sections = [];

  if (props.results) {
    for (let sa = 0; sa < Object.keys(props.results).length; sa++) {
      const key = Object.keys(props.results)[sa];
      const values = Object.values(props.results)[sa];
      sections.push({
        section: [
          {
            title: key,
          },
        ],
        title: key.replace('_', ' ').replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase()),
        details: values.details
          ? values.details
          : {
              info: values.info,
              img: values.img,
            },
        status: values.status,
      });
    }
  }
  return (
    <View style={styles.assetHistoryContent}>
      {sections.map((item, index) =>
        item.details.info !== '' ? (
          <AccordionExtraContent
            key={index}
            section={item.section}
            title={item.title}
            details={item.details}
            status={item.status}
          />
        ) : (
          <HistoryRow key={index} title={item.title} status={item.status} />
        )
      )}
      <View style={styles.assetHistoryDetail}>
        <Text style={styles.detailTitle}>Detail</Text>
        <Text style={styles.detailScript}>Conducted by</Text>
        <View style={styles.historyDetailInfo}>
          <View style={styles.detailBoxContent}>
            <Image
              style={{
                width: 27,
                height: 27,
                borderRadius: 100,
              }}
              source={{ uri: props.user.profilePicture }}
            />
            <View
              style={{
                paddingLeft: 4,
              }}
            >
              <Text style={styles.textBold}>{`${props.user.firstName} ${props.user.lastName}`}</Text>
            </View>
          </View>
          <View style={styles.historyDetailPosition}>
            <Text style={styles.jobTitle}>{getUserRoleString(props.user.role)}</Text>
          </View>
        </View>
        <Text style={styles.detailScript}>Location</Text>
        <View style={styles.historyDetailLocation}>
          <View style={styles.historyLocationContent}>
            <Text style={styles.textBold}>{props.asset.site.name}</Text>
          </View>
          <Text style={styles.historyLocationScript}>
            {`${props.asset.site.address.street}, ${props.asset.site.address.city}, ${props.asset.site.address.state}, ${props.asset.site.address.country}`}{' '}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default HistoryContent;
