import React from 'react';
import { SafeAreaView, View, ScrollView, Text, Image } from 'react-native';
import { styles } from './style';
import Button from '../../../../../components/Button';
import NavigationHeader from '../../../../../components/SignUp/SingUpNavigation';
import images from '../../../../../theme/images';
import { StatusBar } from 'expo-status-bar';
import HistoryHeader from './header';
import HistoryContent from './content';
import moment from 'moment';

export default function TestAssetFlowStep3({ ...props }) {
  const params = props.route.params;
  const asset = params.asset;
  console.log(asset.site);

  return (
    <SafeAreaView style={styles.container}>
      <StatusBar style="dark" />
      <ScrollView>
        <View style={styles.loading}>
          <View style={styles.loadPercent}></View>
        </View>
        <NavigationHeader onPress={() => props.navigation.goBack()} header={'Testing'} menuStyles={styles.menu} />
        <View style={styles.body}>
          <View style={{ alignItems: 'center' }}>
            <Text style={styles.title}>Final Review</Text>
            <Text style={styles.script}>Please confirm all the information below is accurate before submitting</Text>
          </View>
          <View style={styles.review}>
            <View style={styles.assetImageContainer}>
              <View style={styles.assetImage}>
                <Image
                  style={{
                    width: 35,
                    height: 35,
                  }}
                  source={{ uri: asset.assetType.icon }}
                />
              </View>
            </View>

            <View style={styles.reviewTitle}>
              <View>
                <Text style={styles.textBoldMedium}>{asset.name}</Text>
                <View style={styles.reviewDetail}>
                  <Text
                    style={[
                      styles.textBoldSmall,
                      {
                        paddingRight: 4,
                      },
                    ]}
                  >
                    Serial #
                  </Text>
                  <Text style={styles.textRegularSmall}>{asset.serialNumber}</Text>
                </View>
              </View>
              <View style={{ height: '80%' }}>
                <View
                  style={[
                    styles.statusPass,
                    params.status === 'Needs Repair' || params.status === 'Disposed' ? styles.statusFail : null,
                  ]}
                >
                  <Text
                    style={[
                      styles.statusText,
                      params.status === 'Needs Repair' || params.status === 'Disposed' ? styles.statusTextFail : null,
                    ]}
                  >
                    {params.status}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View
            style={{
              marginTop: 11,
            }}
          >
            <View style={styles.accordion}>
              <HistoryHeader
                title={moment().format('DD/MM/YY')}
                time={moment().format('hh:mma')}
                selectedValues={params.selectedValues}
              />
              <HistoryContent results={params.selectedValues} user={params.user} asset={asset} />
            </View>
          </View>
        </View>
      </ScrollView>
      <View style={styles.bottomButton}>
        <Button
          title={'Confirm & Submit'}
          buttonStyle={[styles.blueButton, { marginBottom: 10 }]}
          textStyle={styles.blueButtonText}
          onPress={() => props.navigation.navigate('TestAssetFlowStep4', params)}
        />
        <Button
          title={'Back'}
          buttonStyle={styles.blankButton}
          textStyle={styles.blankButtonText}
          onPress={
            params.status == 'Pass'
              ? () => {
                  props.navigation.goBack();
                  props.navigation.goBack();
                }
              : () => props.navigation.goBack()
          }
        />
      </View>
    </SafeAreaView>
  );
}
