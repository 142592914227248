import React from 'react';
import {Share, Text, TouchableOpacity} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import { authConfig } from "@utils";

export default function Button({title, buttonStyle, textStyle, onPress, disabled = false}) {
  return (
    <TouchableOpacity style={[buttonStyle, disabled ? {opacity: 0.5} : null]} onPress={onPress} disabled={disabled}>
      <Text style={textStyle}>{title}</Text>
    </TouchableOpacity>
  );
}
