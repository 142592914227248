import React, { useState, useEffect } from 'react';
import { Text, TouchableOpacity, View, SafeAreaView, Image, Modal } from 'react-native';
import Button from '../../../../components/Button';
import { RadioButton } from 'react-native-paper';
import images from '../../../../theme/images';
import Checkbox from 'expo-checkbox';
import { styles } from './style';
import Type from './type';

export default function Subscription({ ...props }) {
  const [value, setValue] = useState('first');
  const [checked, setChecked] = useState(false);
  const [isSelected, setSelection] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);
  //TODO load API
  const fields = [
    {
      image: images.companyTileIcon,
      title: 'Part of a company / project',
      value: 'first',
      detail: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
      onClick: false,
    },
    {
      image: images.individualTileIcon,
      title: 'As an individual',
      value: 'second',
      detail: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
      onClick: false,
    },
  ];
  const [listType, setListType] = useState(fields);
  const handlePress = (value) => {
    const newState = listType.map((item, index) => {
      if (value == item.value) {
        if (item.value == 'second') {
          setModalVisible(true);
        }
        return { ...item, onClick: true };
      }
      return { ...item, onClick: false };
    });
    setListType(newState);
  };

  const taglist = listType?.map((item, index) => {
    return (
      <Type
        key={index}
        title={item.title}
        detail={item.detail}
        image={item.image}
        onClick={item.onClick}
        value={item.value}
        onPress={() => {
          handlePress(item.value);
        }}
      />
    );
  });
  const popUpModal = (
    <Modal
      animationType="fade"
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => {
        setModalVisible(false);
      }}
    >
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          backgroundColor: 'rgba(0,0,0,0.5)',
        }}
      >
        <View style={styles.popupWindow}>
          <Image style={styles.popupAva} source={images.confirmationTileIcon} />
          <Text style={styles.popUpTitle}>Are you sure?</Text>
          <Text style={styles.popUpScript}>Changing your subscription to Individual will incur costs.</Text>
          <View style={styles.confirm}>
            <Checkbox
              style={styles.checkbox}
              value={isSelected}
              onValueChange={setSelection}
              color={isSelected ? '#4630EB' : undefined}
            />
            <Text style={styles.label}>
              I understand I'll no longer have access to the the sites I am currently assigned to *
            </Text>
          </View>
          <Button
            title={'Confirm'}
            buttonStyle={[
              styles.blueBorderButton,
              {
                marginBottom: 10,
                marginTop: 24,
              },
            ]}
            textStyle={styles.blueBorderButtonText}
            onPress={() => ''}
          />
          <Button
            title={'Cancel'}
            buttonStyle={styles.blankButton}
            textStyle={styles.blankButtonText}
            onPress={() => setModalVisible(false)}
          />
        </View>
      </View>
    </Modal>
  );
  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <TouchableOpacity onPress={() => props.navigation.goBack()}>
          <Text style={styles.backText}>Back</Text>
        </TouchableOpacity>
        <Text
          style={[
            styles.textBold,
            {
              color: '#6B6B6B',
              textAlign: 'center',
            },
          ]}
        >
          Subscription & Billing
        </Text>
        <View style={{ width: 20 }}></View>
      </View>
      {popUpModal}
      <View style={styles.body}>
        <RadioButton.Group>{taglist}</RadioButton.Group>
      </View>
    </View>
  );
}
