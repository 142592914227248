import React, { useState, useRef } from 'react';
import { Image, TextInput, Modal, Pressable, TouchableOpacity } from 'react-native';
import { ScrollView } from 'react-native';
import { Text, View } from 'react-native';
import Button from '../../../../../components/Button';
import images from '../../../../../theme/images';
import { styles } from './style';
import AddressInput from '../../../../../components/AddressInput';
import { api } from '../../../../../utils/api';
import { showSuccessToast } from '../../../../../utils/toastUtils';
import RBSheet from 'react-native-raw-bottom-sheet';
import * as Linking from 'expo-linking';
import Loading from '../../../../../components/Loading';

function ProjectDetail({ ...props }) {
  const [projectTitle, setProjectTitle] = useState(props.route.params.projectTitle);
  const [projectLocation, setProjectLocation] = useState(props.route.params.location);
  const [projectOwner, setProjectOwner] = useState(props.route.params.owner.name);
  const [exportLoading, setExportLoading] = useState(false);
  const refRBSheet = useRef();
  const params = props.route.params;

  const exportFunction = async (withData = false, id, type) => {
    var fileURL = '';
    var fileName = '';

    setExportLoading(true);

    const csvData = await api(`v1/project/${id}/export?type=${type}&additionalDetails=${withData}`, 'GET', null);
    if (csvData.status == 200) {
      if (csvData.data.success) {
        fileURL = csvData.data.file;
        fileName = csvData.data.name;
      }
    }
    if (fileURL !== '') {
      Linking.openURL(fileURL);
      setExportLoading(false);
    }
  };

  const updateProjectDetails = async () => {
    const projectData = {
      projectName: projectTitle,
      projectLocation: projectLocation,
      projectCompanyName: projectOwner,
    };

    const updateDetails = await api(`v1/project/${params.id}`, 'PATCH', projectData);
    if (updateDetails.status == 200) {
      if (updateDetails.data.success) {
        const result = updateDetails.data.updatedDetails;
        showSuccessToast('Successfully Updated Project Details', 'Details entered have been saved successfully.');
      }
    }
  };

  if (exportLoading) {
    return (
      <View style={{ flex: 1, marginTop: 50 }}>
        <Loading opacity={0} />
      </View>
    );
  }

  return (
    <View style={[styles.sectionContainer, { marginTop: 26 }]}>
      {/* <ScrollView style={{ flexGrow: 1 }}> */}
      <Text style={styles.textBoldMedium}>Project Name</Text>
      <View style={styles.propertyContainer}>
        <TextInput
          style={styles.textBoldMedium}
          defaultValue={projectTitle}
          onChangeText={(text) => setProjectTitle(text)}
        />
      </View>
      <Text style={styles.textBoldMedium}>Location</Text>
      <AddressInput
        selectedAddress={props.route.params.locationString}
        selectAddress={(data) => {
          setProjectLocation(data);
        }}
        inputStyle={{ fontWeight: '700' }}
      />
      <Text style={[styles.textBoldMedium, { marginTop: 15 }]}>Project Owner</Text>
      <View style={styles.propertyContainer}>
        <TextInput
          style={styles.textBoldMedium}
          defaultValue={projectOwner}
          onChangeText={(text) => setProjectOwner(text)}
        />
      </View>
      {/* </ScrollView> */}
      {params.hasWriteAccess ? (
        <Button
          title={'Update'}
          buttonStyle={[
            styles.blueButtonActive,
            {
              width: '100%',
              marginBottom: 40,
            },
          ]}
          textStyle={styles.blueButtonText}
          onPress={() => {
            updateProjectDetails();
          }}
        />
      ) : (
        <>
          <Button
            title={'Export Assets'}
            buttonStyle={[styles.blueButtonActive, { marginBottom: 50 }]}
            textStyle={styles.blueButtonText}
            onPress={() => {
              refRBSheet.current.open();
            }}
          />
          <RBSheet
            ref={refRBSheet}
            closeOnDragDown={true}
            closeOnPressMask={true}
            height={150}
            openDuration={250}
            customStyles={{
              wrapper: {
                backgroundColor: 'rgba(0,0,0,0.3)',
              },
              container: {
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
              },
            }}
          >
            <View
              style={{
                alignItems: 'center',
              }}
            >
              <View style={styles.validate}>
                <TouchableOpacity
                  onPress={() => {
                    refRBSheet.current.close();
                    exportFunction(false, params.id, 'CSV');
                  }}
                >
                  <Text
                    style={[
                      styles.textBoldLarge,
                      {
                        paddingVertical: 20,
                      },
                    ]}
                  >
                    Export as CSV
                  </Text>
                </TouchableOpacity>
              </View>
              <TouchableOpacity
                onPress={() => {
                  refRBSheet.current.close();
                  exportFunction(false, params.id, 'PDF');
                }}
              >
                <Text
                  style={[
                    styles.textBoldLarge,
                    {
                      paddingVertical: 20,
                    },
                  ]}
                >
                  Export as PDF
                </Text>
              </TouchableOpacity>
            </View>
          </RBSheet>
        </>
      )}
    </View>
  );
}

export default ProjectDetail;
