import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../theme/colors';

export const styles = StyleSheet.create({
  outterContainer: {
    flex: 1,
    paddingHorizontal: 8,
    paddingVertical: 8,
    marginHorizontal: 24,
    top: 0,
    backgroundColor: '#FFF',
    borderRadius: 12,
    borderLeftWidth: 5,
    borderLeftColor: 'blue',
    zIndex: 9999,
    position: 'absolute',
    shadowColor: 'black',
    shadowOffset: { width: -2, height: 4 },
    shadowRadius: 3,
    elevation: 20,
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 40,
    paddingVertical: 8,
  },
  title: {
    color: colors.black,
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: 0,
    textAlign: 'center',
  },
  message: {
    marginTop: 5,
    color: colors.black,
    fontSize: 12,
    lineHeight: 18,
    fontWeight: 'bold',
    letterSpacing: 0,
    textAlign: 'center',
  },
  closeButton: {
    alignSelf: 'flex-end',
    right: 0,
    top: 0,
    position: 'absolute',
    width: 24,
    height: 24,
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeButtonContent: {
    color: colors.gray,
  },
});
