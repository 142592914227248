import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../../../theme/colors';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  header: {
    backgroundColor: '#F6F6F6',
    paddingTop: 70,
    paddingLeft: 23,
    paddingRight: 27,
  },
  headerTitle: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  textBoldLarge: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    color: '#000000',
  },
  textBoldMedium: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 16,
    color: '#000000',
  },
  textBold: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 14,
    color: '#000000',
  },
  textBoldGray: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    color: '#6B6B6B',
  },
  inputSearchDrop: {
    marginTop: 16,
    width: '100%',
    backgroundColor: '#FFFFFF',
    borderRadius: 17,
    paddingVertical: 15,
    paddingHorizontal: 13,
  },
  viewOptionsContainer: {
    marginTop: 22,
    flexDirection: 'row',
    alignItems: 'center',
  },
  grayBoldTextView: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 14,
    color: '#6B6B6B',
    paddingBottom: 9,
    paddingRight: 18,
  },
  blueBoldText: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 14,
    color: '#132FF5',
    paddingBottom: 9,
    paddingRight: 18,
  },
  activeBorder: {
    height: 1,
    position: 'absolute',
    backgroundColor: '#132FF5',
    bottom: 0,
  },
  body: {
    backgroundColor: '#FFFFFF',
    marginBottom: 30,
    flex: 1,
  },
  billingDetailsContent: {
    marginTop: 26,
    paddingLeft: 23,
    paddingRight: 23,
    flex: 1,
  },
  propertyContainer: {
    paddingVertical: 14,
    paddingLeft: 11,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: '#C7C7C7',
    marginTop: 14,
    marginBottom: 19,
  },
  selectedTag: {
    borderWidth: 1,
    borderRadius: 16,
    borderColor: '#C7C7C7',
    paddingHorizontal: 16,
    paddingBottom: 16,
    marginTop: 19,
  },
  currentTag: {
    borderWidth: 1,
    borderRadius: 16,
    borderColor: '#132FF5',
    backgroundColor: '#132ff51a',
    paddingHorizontal: 16,
    paddingBottom: 16,
    marginTop: 19,
    marginBottom: 11,
    marginHorizontal: 25,
    overflow: 'hidden',
  },
  currentLabel: {
    backgroundColor: '#132ff51a',
    position: 'absolute',
    right: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 20,
    paddingVertical: 5,
    borderRadius: 4,
  },
  selectedContent: {
    paddingTop: 8,
    marginBottom: 18,
  },
  selectedText: {
    fontSize: 18,
    paddingTop: 8,
    fontFamily: 'NunitoSans_700Bold',
  },
  textDetail: {
    paddingTop: 6,
    fontFamily: 'NunitoSans_400Regular',
  },

  tagInfor: {
    marginTop: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  tagScript: {
    backgroundColor: '#FFFFFF',
    paddingHorizontal: 4,
    paddingVertical: 3,
  },
  textScript: {
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 12,
  },
  tagRecommend: {
    backgroundColor: 'rgba(19,47,245,0.1)',
    marginLeft: 4,
    paddingHorizontal: 8,
    paddingVertical: 3,
  },
  textRecommend: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 12,
    color: '#132FF5',
  },
  selectedDot: {
    borderColor: '#979797',
    marginLeft: 16,
    paddingTop: 4,
    borderRadius: 32,
    borderWidth: 1,
    width: 28,
    height: 28,
    alignItems: 'center',
  },
  selected: {
    borderRadius: 32,
    width: 18,
    height: 18,
    backgroundColor: '#132FF5',
    alignItems: 'center',
  },
  labelText: {
    color: '#132FF5',
    fontSize: 12,
    fontFamily: 'NunitoSans_700Bold',
  },
  breakLine: {
    borderBottomWidth: 1,
    borderBottomColor: '#00000014',
    marginVertical: 15,
  },
  blueButtonActive: {
    borderRadius: 8,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
    marginBottom: 10,
  },
  blueButtonText: {
    color: colors.white,
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 18,
    textAlign: 'center',
  },

  blueButton: {
    borderRadius: 8,
    opacity: 0.5,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
    marginBottom: 10,
  },
  tagContainer: {
    marginHorizontal: 37,
  },
  currentTagsContainer: {
    borderWidth: 1,
    borderColor: '#132FF5',
    borderRadius: 8,
    paddingVertical: 8,
    paddingLeft: 15,
    paddingRight: 11,
    marginTop: 20,
  },
  currentTagsSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  addTaskContainer: {
    marginTop: 57,
  },
  whiteButton: {
    borderRadius: 8,
    backgroundColor: '#F6F6F6',
    borderWidth: 1,
    borderColor: '#E5E5E5',
    flex: 1,
    paddingVertical: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  blueButtonAddTag: {
    borderWidth: 1,
    borderColor: '#132FF5',
    borderRadius: 8,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  whiteButtonText: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 14,
    color: '#343147',
  },

  buttonContainer: {
    flexDirection: 'row',
  },
  input: {
    borderWidth: 1,
    borderRadius: 8,
    paddingVertical: 14,
    paddingLeft: 15,
    borderColor: '#E5E5E5',
    fontSize: 16,
    fontFamily: 'NunitoSans_400Regular',
    marginTop: 10,
  },
  tagDetails: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 21,
  },
  caution: {
    marginTop: 21,
    borderRadius: 8,
    backgroundColor: '#ff4d4d1a',
    paddingVertical: 8,
    paddingLeft: 9,
    justifyContent: 'center',
  },
  cautionText: {
    color: '#FF4D4D',
    fontFamily: 'NunitoSans_400Regular',
    fontSize: 12,
  },
  bottomButton: {
    marginHorizontal: 27,

    position: 'absolute',
    bottom: 0,
  },
  ModalContainer: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  popupWindow: {
    backgroundColor: 'white',
    paddingVertical: 20,
    borderRadius: 12,
    paddingHorizontal: 17,
    marginHorizontal: 13,
  },
  smallSubmitButton: {
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    backgroundColor: '#132FF5',
    paddingVertical: 13,
    borderRadius: 8,
    marginTop: 38,
    width: 286,
  },
  popupHeader: {
    alignItems: 'center',
  },
  confirmationTileIcon: {
    width: 70,
    height: 70,
    borderRadius: 16,
    marginTop: 8,
  },
  blueBorderButton: {
    borderRadius: 8,
    borderColor: '#132FF5',
    borderWidth: 1,
    paddingVertical: 10,
    marginTop: 8,
  },
  blueBorderButtonText: {
    color: '#132FF5',
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 16,
    textAlign: 'center',
  },
  script: {
    marginTop: 8,
    marginBottom: 9,
    fontFamily: 'NunitoSans_400Regular',
    textAlign: 'center',
  },
});
