import { View, Text } from 'react-native';
import { IconButton } from 'react-native-paper';
import colors from '../../theme/colors';
import { styles } from './style';
import Toast from 'react-native-toast-message';

const BORDER_COLOR_MAP = {
  info: 'blue',
  success: 'lightgreen',
  error: 'red',
};

const CustomToast = ({ ...props }) => {
  return (
    <View style={{ ...styles.outterContainer, borderLeftColor: BORDER_COLOR_MAP[props.type] }}>
      <View style={styles.container}>
        <Text style={styles.title}>{props.text1}</Text>
        <Text style={styles.message}>{props.text2}</Text>
      </View>
      <IconButton
        icon="close"
        onPress={() => {
          Toast.hide();
        }}
        iconColor={colors.gray}
        size={14}
        style={styles.closeButton}
      ></IconButton>
    </View>
  );
};

export default CustomToast;
