import React, { useState } from 'react';
import { TouchableOpacity } from 'react-native';
import { Image, Text, View, Modal } from 'react-native';
import { FaChevronRight } from 'react-icons/fa';
import { styles } from './style';
import images from '../../../../theme/images';
import Button from '../../../../components/Button';
import { api } from '../../../../utils/api';
import { showSuccessToast } from '../../../../utils/toastUtils';

const AccordionSubContent = ({ ...props }) => {
  const { navigation, project, period, item, last, type, hasWriteAccess, updateAccordionData } = props;
  const [completeModalVisible, setCompleteModalVisible] = useState(false);

  console.log('props: ', props);

  const handlePress = () =>
    navigation.navigate('TaskInner', {
      project: project,
      assignedUserId: item.user._id,
      dueDateUTC: item.dueDateUTC,
      period: period,
      hasWriteAccess: hasWriteAccess,
    });

  const handleCompletePress = async () => {
    const response = await api(`v1/task/${item.task._id}/complete`, 'PATCH', null);

    if (response.status === 200 && response.data.success) {
      showSuccessToast('Task completed', `'${item.task.taskTitle}' was set to complete`);
      updateAccordionData();
    }
  };

  const completeModal = (
    <Modal animationType="fade" transparent={true} visible={completeModalVisible}>
      <View style={styles.ModalContainer}>
        <View style={styles.popupWindow}>
          <View style={styles.popupHeader}>
            <Image style={styles.confirmationTileIcon} source={images.confirmationTileIcon} />
            <Text
              style={[
                styles.textBoldLarge,
                {
                  marginTop: 25,
                },
              ]}
            >
              Complete Task
            </Text>
            <View style={{ maxWidth: 238 }}>
              <Text style={styles.script}>Do you want to set task to complete?</Text>
            </View>
          </View>
          <Button
            title={'Complete'}
            buttonStyle={[styles.blueButtonActive, { marginHorizontal: 10 }]}
            textStyle={styles.blueButtonText}
            onPress={() => {
              setCompleteModalVisible(false);
              handleCompletePress();
            }}
          />
          <Button
            title={'Cancel'}
            buttonStyle={[
              styles.blueBorderButton,
              {
                marginTop: 8,
                marginHorizontal: 10,
              },
            ]}
            textStyle={styles.blueBorderButtonText}
            onPress={() => {
              setCompleteModalVisible(false);
            }}
          />
        </View>
      </View>
    </Modal>
  );

  if (item.type === 'manual') {
    console.log('item', item.task._id);
    return (
      <>
        <TouchableOpacity onPress={() => setCompleteModalVisible(true)}>
          <View style={last ? { marginBottom: 19 } : ''}>
            <View style={{ ...styles.taskDetailBlock, backgroundColor: type === 'calendar' ? '#FFFFFF' : '#F6F6F6' }}>
              <View style={[styles.taskDetailRow, { marginBottom: 8 }]}>
                <Text style={styles.taskTitle}>{item.task.taskTitle}</Text>
              </View>
              <Text style={styles.taskDesc}>{item.task.taskDescription}</Text>
              <View style={styles.taskDetailRow}>
                <View
                  style={{ ...styles.taskDetailAssignee, backgroundColor: type === 'calendar' ? '#F6F6F6' : '#FFFFFF' }}
                >
                  <Image source={{ uri: item.user.profilePicture }} style={styles.taskIcon} />
                  <Text style={styles.assignee}>{`${item.user.firstName} ${item.user.lastName}`}</Text>
                </View>
              </View>
            </View>
          </View>
        </TouchableOpacity>
        {completeModal}
      </>
    );
  }

  return (
    <TouchableOpacity onPress={handlePress}>
      <View style={last ? { marginBottom: 19 } : ''}>
        <View style={{ ...styles.taskDetailBlock, backgroundColor: type === 'calendar' ? '#FFFFFF' : '#F6F6F6' }}>
          <View style={[styles.taskDetailRow, { marginBottom: 8 }]}>
            <Text style={styles.taskTitle}>{item.title}</Text>
            <FaChevronRight name="keyboard-arrow-right" style={{ fontSize: 20 }} />
          </View>
          <View style={styles.taskDetailRow}>
            <View
              style={{ ...styles.taskDetailAssignee, backgroundColor: type === 'calendar' ? '#F6F6F6' : '#FFFFFF' }}
            >
              <Image source={{ uri: item.user.profilePicture }} style={styles.taskIcon} />
              <Text style={styles.assignee}>{`${item.user.firstName} ${item.user.lastName}`}</Text>
            </View>
            <Text style={styles.tasks}>{item.progress}</Text>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default AccordionSubContent;
