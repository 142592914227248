import * as DocumentPicker from 'expo-document-picker';
import React, { useState, useRef } from 'react';
import { Image, Pressable, Text, TouchableOpacity, View } from 'react-native';
import { AiOutlineCloseCircle, AiOutlineUpload, AiOutlineFilePdf } from 'react-icons/ai';
import images from '../../theme/images';
import { styles } from './style';

export default function UploadField({ ...props }) {
  const { document, setDocument } = props;
  const [displayModal, setDisplayModal] = useState(false);

  const DocRef = useRef(null);

  const pickDocument = async () => {
    DocRef.current.click();
  };

  const handleDocChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      // Handle the selected file here
      console.log('Selected File:', selectedFile);
      setDocument(selectedFile);
    }
  };
  return (
    <View style={styles.pickImage}>
      <Pressable onPress={pickDocument}>
        {document == null && (
          <View style={styles.imgPicker}>
            <View style={styles.dasherBorder}>
              <AiOutlineUpload name="upload" style={styles.imgIconPicked} />
              <Text style={styles.uploadText}>Accept .jpeg or png files</Text>
              <input ref={DocRef} type="file" style={{ display: 'none' }} onChange={handleDocChange} />
            </View>
          </View>
        )}
      </Pressable>
      {document && (
        <View style={styles.imgPickedContainer}>
          <View style={styles.documentPicked}>
            <View style={styles.docTitle}>
              <AiOutlineFilePdf name="file-pdf-o" style={styles.docIcon} />
              <Text style={styles.titleTextPicked}>{document.name}</Text>
            </View>
            <TouchableOpacity onPress={() => ''}>
              <Image
                style={{
                  width: 28,
                  height: 28,
                  marginRight: 8,
                  // tintColor:"white"
                }}
                source={images.lightGreyDownload}
              />
            </TouchableOpacity>
          </View>
          <AiOutlineCloseCircle name="close" style={styles.closeIcon} onPress={() => setDocument(null)} />
        </View>
      )}
    </View>
  );
}
