import React, { useState } from 'react';
import { Text, View, Pressable } from 'react-native';
import { styles } from '../../screens/admin/settingUpProject/step6/style';
import { Calendar } from 'react-native-calendars';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { BsCalendarMonthFill } from 'react-icons/bs';
import moment from 'moment';
import CustomArrow from '../CustomArrow';

export default function SiteComponent({ item, i, siteInputs, setSiteInputs }) {
  const [openCalendar, setOpenCalendar] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedFrequency, setSelectedDropdownItem] = useState('Select');
  const periodTesting = ['Monthly', 'Quarterly', 'Biannually', 'Annually'];

  const handleDropdownPicker = (item) => {
    setSelectedDropdownItem(item);
    setOpen(false);
  };

  return (
    <>
      <View style={styles.title}>
        <Text style={styles.titleTextForm}>Site</Text>
      </View>
      <View style={styles.textFieldForm}>
        <Text style={styles.inputHeaderForm}>{item.title}</Text>
        <Text style={styles.normalText}>
          {`${item.address.street}, ${item.address.city}, ${item.address.state}, ${item.address.country}`}{' '}
        </Text>
        <Pressable onPress={() => setOpen(!open)}>
          <View style={open ? styles.inputDrop : [styles.inputDrop, { paddingBottom: 15 }]}>
            <View style={styles.dropTitleContainer}>
              <Text style={selectedFrequency == 'Select' ? styles.unpickedTitle : styles.dropList}>
                {selectedFrequency}
              </Text>
              {open ? (
                <FiChevronUp name="angle-up" style={styles.imgIcon} />
              ) : (
                <FiChevronDown name="angle-down" style={styles.imgIcon} />
              )}
            </View>
            {open &&
              periodTesting.map((item, index) => {
                return (
                  <Pressable key={index} onPress={() => handleDropdownPicker(item)}>
                    <View style={index !== periodTesting.length ? styles.dropItem : styles.dropLastItem}>
                      <Text style={styles.dropList}>{item}</Text>
                    </View>
                  </Pressable>
                );
              })}
          </View>
        </Pressable>
        <Pressable onPress={() => setOpenCalendar(!openCalendar)}>
          <View style={openCalendar ? styles.inputDrop : [styles.inputDrop, { paddingBottom: 15 }]}>
            <View style={styles.dropTitleContainer}>
              <View style={styles.calendarTitle}>
                <BsCalendarMonthFill name="calendar-month" style={styles.calendarIcon} />
                <Text style={!siteInputs[i]?.testCadence?.startDate ? styles.unpickedTitle : styles.dropList}>
                  Starting Date: {siteInputs[i]?.testCadence?.startDate}
                </Text>
              </View>

              {openCalendar ? (
                <FiChevronUp name="angle-up" style={styles.imgIcon} />
              ) : (
                <FiChevronDown name="angle-down" style={styles.imgIcon} />
              )}
            </View>
            {openCalendar && (
              <Calendar
                renderArrow={(direction, onPress) => <CustomArrow direction={direction} onPress={onPress} />}
                hideArrows={false}
                markingType={'multi-dot'}
                enableSwipeMonths={true}
                theme={{
                  arrowColor: '#0F0000',
                }}
                onDayPress={(day) => {
                  let allSites = [...siteInputs];
                  allSites[i] = {
                    ...item,
                    testCadence: {
                      startDate: day.dateString,
                      startDateUTC: moment(day.dateString, 'YYYY-MM-DD').utc().format('YYYY-MM-DD'),
                      type: selectedFrequency,
                    },
                  };
                  setSiteInputs(allSites);
                  setOpenCalendar(!openCalendar);
                }}
                context={{ date: '' }}
              />
            )}
          </View>
        </Pressable>
      </View>
    </>
  );
}
