import React, { useState, useEffect } from 'react';
import { View, Platform, SafeAreaView, Alert, Text, Image, TextInput } from 'react-native';
import Button from '../../../../components/Button';
import SignUpHeader from '../../../../components/SignUp/SignUpHeader';
import NavigationHeader from '../../../../components/SignUp/SingUpNavigation';
import { styles } from './style';
import { api } from '../../../../utils/api';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import images from '../../../../theme/images';
import AddressInput from '../../../../components/AddressInput';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useIsFocused } from '@react-navigation/native';
import Autocomplete from '../../../../components/AutoComplete/autoComplete';

export default function SettingUpProjectStep4({ ...props }) {
  const [isSelect, setIsSelect] = useState(false);
  const [createOrSelect, updateCreatOrSelect] = useState('select');
  const [selectedComapny, setSelectedComapny] = useState(null);
  const [createValues, setCreateValues] = useState({
    name: '',
    invoiceEmail: '',
    billingAddress: '',
    billingPhone: '',
    billingEmail: '',
  });
  const [createSuccess, setCreateSuccess] = useState(false);
  const [companyList, setCompanyList] = useState([]);

  const [fieldsValidated, setFieldsValidated] = useState(false);

  const setAddressValue = (address) => {
    setCreateValues({ ...createValues, billingAddress: address });
  };

  const isFocused = useIsFocused();
  const getCompanies = async () => {
    const companies = await api('v1/user/companies', 'GET', null);
    if (companies.status == 200) {
      if (companies.data.success) {
        let data = companies.data;
        var arrayCompanies = [];
        if (data.companies.length > 0) {
          for (let i = 0; i < data.companies.length; i++) {
            const element = data.companies[i];
            const obj = {
              title: element.name,
              id: element._id,
            };
            arrayCompanies.push(obj);
          }
        }
        setCompanyList(arrayCompanies);
      }
    }
  };

  const updateSelectedCompany = (value) => {
    setSelectedComapny(value);
    setTimeout(() => {}, 500);
  };

  const createPress = async () => {
    if (createOrSelect == 'select') {
      setSelectedComapny(selectedComapny._id);
      completeCreatePress(selectedComapny._id);
    } else {
      var data = {
        companyName: createValues.name,
        invoiceEmail: createValues.invoiceEmail,
        billingDetails: {
          address: createValues.billingAddress,
          phone: createValues.billingPhone,
          email: createValues.billingEmail,
        },
      };
      if (props.route.params.subscription) {
        data.subscription = props.route.params.subscription.value;
      } else {
        alert('No subscription found, please add a subscription before continuing.');
        return;
      }
      const createCompany = await api('v1/company', 'POST', data);
      if (createCompany.status == 200) {
        const result = createCompany.data;
        if (result.success) {
          if (result.companyExists) {
            Alert.alert('Comapny Already Exists', 'Continue with found company?', [
              {
                text: 'Cancel',
                style: 'destructive',
              },
              {
                text: 'Continue',
                onPress: () => {
                  completeCreatePress();
                },
                style: 'default',
              },
            ]);
            setSelectedComapny(result.company._id);
            setTimeout(() => {
              completeCreatePress(result.company._id);
            }, 3000);
          } else {
            setCreateSuccess(true);
            setSelectedComapny(result.company._id);
            setTimeout(() => {
              completeCreatePress(result.company._id);
            }, 3000);
            return;
          }
        } else {
          console.log('Handle Fail Create');
          alert('Failed to create company, please check your inputed values and try again.');
        }
        setCreateSuccess(false);
      }
    }
  };

  const completeCreatePress = (companyId = false) => {
    props.navigation.navigate('SettingUpProjectStep5', {
      userImage: props.route.params.userImage,
      userType: props.route.params.userType,
      userLicence: props.route.params.userLicence,
      appUse: props.route.params.appUse,
      subscription: props.route.params.subscription,
      company: companyId ? companyId : selectedComapny,
      addNewProject: props.route.params.addNewProject ? true : false,
      registerForPushNotificationsAsync: props.route.params.registerForPushNotificationsAsync,
    });
    setCreateSuccess(false);
  };

  useEffect(() => {
    console.log(createValues);
    var checkComapnyInput = Object.values(createValues).every((x) =>
      typeof x == 'object' ? x.street.length > 5 : x.length > 3
    );
    if (checkComapnyInput) {
      console.log(checkComapnyInput);
      setFieldsValidated(true);
    } else {
      setFieldsValidated(false);
    }
  }, [createValues]);

  useEffect(() => {
    if (createOrSelect == 'select') {
      if (selectedComapny !== null) {
        setFieldsValidated(true);
      }
    }
  }, [selectedComapny]);

  useEffect(() => {
    if (isFocused) {
      getCompanies();
    }
  }, [isFocused]);

  return (
    <View style={styles.container}>
      <SafeAreaView
        style={{
          flex: 1,
        }}
      >
        <KeyboardAwareScrollView
          contentContainerStyle={styles.scrollContainer}
          bounces={false}
          enableOnAndroid={true}
          enableAutomaticScroll={Platform.OS === 'ios'}
          keyboardShouldPersistTaps="handled"
          nestedScrollEnabled={true}
        >
          <View
            style={
              Platform.OS === 'ios'
                ? [
                    styles.loading,
                    {
                      marginTop: 0,
                    },
                  ]
                : styles.loading
            }
          >
            <View style={styles.loadPercent}></View>
          </View>
          <NavigationHeader
            onPress={async () => {
              if (props.route.params.addNewProject) {
                props.navigation.goBack();
              } else {
                await AsyncStorage.multiRemove(['user', 'token', 'refreshToken', 'expiresIn']);
                props.navigation.navigate('GuestNavigation', { screen: 'QRCodeScan' });
              }
            }}
            header={props.route.params.addNewProject ? 'Add New Project' : 'Sign Up'}
            navigate={'Cancel'}
            menuStyles={styles.menu}
          />
          {props.route.params.addNewProject ? (
            <View>
              <Text style={[styles.textBold, { textAlign: 'center' }]}>Select or create a company</Text>
              <Text style={styles.scriptStyle}>
                Use the drop down to select the method you would like to select the company.
              </Text>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}>
                <select
                  onChange={(e) => {
                    console.log('value', e.target.value);
                    updateCreatOrSelect(e.target.value);
                  }}
                  style={{
                    width: 250,
                    textAlign: 'center',
                    padding: 10,
                    borderRadius: 6,
                    borderColor: 'lightgrey',
                  }}
                >
                  <option value={'select'}>Select a Company</option>
                  <option value={'create'}>Create a Company</option>
                </select>
              </div>
            </View>
          ) : (
            <SignUpHeader
              title={'Create a company'}
              descript={'Enter the company details below'}
              userImage={props.route.params.userImage}
              disablePress={true}
            />
          )}
          <View style={createSuccess ? styles.popupWindowSuccess : styles.popupWindow}>
            {createSuccess ? (
              <>
                <Image style={styles.popupIcon} source={images.companyTileIcon} />
                <Text style={styles.popupTitle}>Company Created!</Text>
                <Text style={styles.popupScript}>
                  Your company has now been created in our system. You will progress to the next screen shortly.
                </Text>
              </>
            ) : (
              <>
                {createOrSelect == 'select' ? (
                  <View style={styles.textFieldModal}>
                    <View style={styles.firstTitle}>
                      <Text style={styles.titleText}>Company</Text>
                      <Text style={styles.required}> *</Text>
                    </View>

                    <Text style={styles.inputHeaderModal}>Name</Text>
                    {
                      <Autocomplete
                        value={selectedComapny}
                        setValue={updateSelectedCompany}
                        data={companyList}
                        onChange={() => setIsSelect(!isSelect)}
                        placeholder="Enter company name"
                        style={{ flex: 1 }}
                        containerStyle={styles.inputSearchDrop}
                        disableAdd={true}
                      />
                    }
                  </View>
                ) : (
                  <View style={styles.textFieldModal}>
                    <View style={styles.firstTitle}>
                      <Text style={styles.titleText}>Company</Text>
                      <Text style={styles.required}> *</Text>
                    </View>

                    <Text style={styles.inputHeaderModal}>Name</Text>
                    <TextInput
                      placeholder={'Enter company name'}
                      style={styles.input}
                      placeholderTextColor="#00000080"
                      onChangeText={(text) => {
                        setCreateValues({ ...createValues, name: text });
                      }}
                      value={createValues.name}
                    />
                    <Text style={styles.inputHeaderModal}>Invoice Email</Text>
                    <TextInput
                      placeholder="Enter email to receive invoices "
                      style={styles.input}
                      placeholderTextColor="#00000080"
                      onChangeText={(text) => {
                        setCreateValues({ ...createValues, invoiceEmail: text });
                      }}
                      value={createValues.invoiceEmail}
                    />

                    <View style={styles.title}>
                      <Text style={styles.titleText}>Billing Details</Text>
                      <Text style={styles.required}> *</Text>
                    </View>

                    <Text style={styles.inputHeaderModal}>Address</Text>
                    <AddressInput selectAddress={setAddressValue} />

                    <Text style={styles.inputHeaderModal}>Phone</Text>
                    <TextInput
                      placeholder="Enter company contact phone"
                      style={styles.input}
                      placeholderTextColor="#00000080"
                      onChangeText={(text) => {
                        setCreateValues({ ...createValues, billingPhone: text });
                      }}
                      value={createValues.billingPhone}
                    />

                    <Text style={styles.inputHeaderModal}>Email</Text>
                    <TextInput
                      placeholder="Enter company contact email"
                      style={styles.input}
                      placeholderTextColor="#00000080"
                      onChangeText={(text) => {
                        setCreateValues({ ...createValues, billingEmail: text });
                      }}
                      value={createValues.billingEmail}
                    />
                  </View>
                )}
              </>
            )}
          </View>
        </KeyboardAwareScrollView>
        {createSuccess == false && (
          <View style={styles.assignButton}>
            <Button
              title={'Next'}
              buttonStyle={fieldsValidated ? styles.blueButtonActive : styles.blueButton}
              textStyle={styles.blueButtonText}
              onPress={() => (fieldsValidated ? createPress() : '')}
            />
            <Button
              title={'Previous'}
              buttonStyle={[styles.blankButton, { marginBottom: 20 }]}
              textStyle={styles.blankButtonText}
              onPress={() => props.navigation.goBack()}
            />
          </View>
        )}
      </SafeAreaView>
    </View>
  );
}
