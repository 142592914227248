import React, { useState, useCallback, useEffect } from 'react';
import { Platform, SafeAreaView, ScrollView, Text, TextInput, View } from 'react-native';
import Button from '../../../../components/Button';
import NavigationHeader from '../../../../components/SignUp/SingUpNavigation';
import { styles } from './style';
import Autocomplete from '../../../../components/AutoComplete/autoComplete';
import { debounce } from 'lodash';
import { api } from '../../../../utils/api';
import { showSuccessToast, showErrorToast } from '../../../../utils/toastUtils';
import CommonHeader from '../testAssetFlow/commonHeader';

const AssignTag = ({ ...props }) => {
  const { asset, taskListDetails } = props.route.params;
  const [selectedTag, setSelectedTag] = useState('');
  const [filteredTagOptions, setFilteredTagOptions] = useState([]);

  const debouncedGetFilteredTags = useCallback(
    debounce(async (searchString) => {
      if (typeof searchString === 'string') {
        if (searchString.length < 4) {
          setFilteredTagOptions([]);
        } else {
          const response = await api(`v1/asset/${asset._id}/tags/filtered?search=${searchString}`, 'GET', null);

          if (response.status === 200 && response.data.success) {
            const tempTags = [];
            for (const tag of response.data.tags) {
              tempTags.push({ ...tag, id: tag._id, title: tag.code });
            }
            setFilteredTagOptions(tempTags);
          }
        }
      }
    }, 300),
    []
  );

  const handleAssignPress = async () => {
    if (validTagSelected()) {
      const response = await api(`v1/asset/${asset._id}/assigntag`, 'PATCH', {
        qrCode: selectedTag.code,
      });

      if (response.status === 200 && response.data.success) {
        const newAsset = response.data.asset;
        props.navigation.navigate('AssetInfo', {
          asset: newAsset,
          taskListDetails: taskListDetails,
        });
        showSuccessToast('Updated asset successfully', 'Asset assigned to new tag.');
      } else {
        showErrorToast('Update failed', 'Please try again.');
      }
    }
  };

  const validTagSelected = () => typeof selectedTag === 'object' && selectedTag.code;

  return (
    <View style={styles.container}>
      <SafeAreaView
        style={{
          flex: 1,
        }}
      >
        <ScrollView keyboardShouldPersistTaps="always">
          <CommonHeader onPress={() => props.navigation.goBack()} header={'Assign Tag'} asset={asset} />
          <View style={styles.form}>
            <Text style={styles.script}>Choose a tag to assign to the asset.</Text>
            <Autocomplete
              value={selectedTag}
              setValue={setSelectedTag}
              data={filteredTagOptions}
              onChange={debouncedGetFilteredTags}
              placeholder="Enter qr code"
              style={{ flex: 1 }}
              containerStyle={styles.inputSearchDrop}
              disableAdd={true}
              showAll={true}
            />
          </View>
        </ScrollView>
        <View style={styles.assignButton}>
          <Button
            title={'Assign tag'}
            buttonStyle={validTagSelected() ? styles.blueButtonActive : styles.blueButton}
            textStyle={styles.blueButtonText}
            onPress={handleAssignPress}
          />
        </View>
      </SafeAreaView>
    </View>
  );
};

export default AssignTag;
