import { Dimensions, StyleSheet } from 'react-native';

export const globalStyle = StyleSheet.create({
  uploadButton: {
    borderRadius: 8,
    opacity: 0.5,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
    marginBottom: 10,
    width: '100%',
  },
});
