import { styles } from "./style";
import { Button, Text, View } from "react-native";

const HistoryRow = ({ ...props }) => {
  const { status } = props;
    return (
        <View style={styles.assetHistoryRow}>
          <Text style={{color:"#6B6B6B",fontFamily:"NunitoSans_700Bold"}}>{props.title}</Text>
          <View style={styles.assetStatus}>
            <Text style={ !status ? styles.plainStatus : status !== "Pass" ? styles.failText : styles.passTest}>{status ? status : 'N/A'}</Text>
          </View>
        </View>
    );
};

export default HistoryRow;