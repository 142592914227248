import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../../../../theme/colors';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  menu: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 24,
    paddingHorizontal: 23,
    alignItems: 'center',
  },
  loading: {
    height: 8,
    backgroundColor: 'white',
    marginTop: 24,
  },
  loadPercent: {
    height: 8,
    width: '100%',
    backgroundColor: '#132FF5',
  },
  statusFail: {
    backgroundColor: '#ffcdcd',
    borderRadius: 13,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 8,
    paddingVertical: 3,
  },
  statusPass: {
    backgroundColor: '#88ddb3',
    borderRadius: 13,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 8,
    paddingVertical: 3,
  },
  statusText: {
    color: '#046736',
    fontWeight: 'bold',
    marginRight: 4,
  },
  statusTextFail: {
    color: '#FF4D4D',
    fontWeight: 'bold',
    marginRight: 4,
  },
  status: {
    backgroundColor: '#88ddb3',
    borderRadius: 13,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    paddingHorizontal: 8,
    paddingVertical: 3,
    marginRight: 4,
  },
  statusRowFail: {
    backgroundColor: '#ffcdcd',
    borderRadius: 13,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    paddingHorizontal: 8,
    paddingVertical: 3,
    marginRight: 4,
  },
  collapseContent: {
    paddingVertical: 11,
    paddingHorizontal: 11,
  },
  collapseDetail: {
    backgroundColor: '#F4F4F4',
    marginTop: 16,
    paddingHorizontal: 9,
    paddingVertical: 10,
    borderRadius: 8,
  },
  collapseDetailInfo: {
    backgroundColor: 'rgba(0,0,0,0.1)',
    flexDirection: 'row',
    padding: 12,
    borderRadius: 16,
    alignItems: 'center',
  },
  collapseDetailPosition: {
    backgroundColor: '#FFFFFF',
    borderRadius: 16,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 6,
  },
  collapseDetailLocation: {
    backgroundColor: 'rgba(0,0,0,0.1)',
    paddingVertical: 12,
    paddingHorizontal: 21,
    borderRadius: 16,
  },
  body: {
    marginTop: 32,
    // alignItems:"center",
    marginHorizontal: 43,
  },
  blueButton: {
    width: '100%',
    borderRadius: 8,
    backgroundColor: colors.mainBlue,
    paddingVertical: 10,
  },
  blueButtonText: {
    color: colors.white,
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 18,
    textAlign: 'center',
  },
  blankButton: {
    width: '100%',
    paddingVertical: 10,
  },
  blankButtonText: {
    opacity: 0.5,
    color: colors.black,
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 18,
    textAlign: 'center',
  },
  accordion: {
    borderColor: '#C7C7C7',
    borderWidth: 1,
    marginBottom: 8,
    borderRadius: 15,
  },
  title: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
  },
  script: {
    textAlign: 'center',
    fontFamily: 'NunitoSans_400Regular',
    paddingTop: 5,
    width: '90%',
  },
  review: {
    borderWidth: 1,
    borderRadius: 8,
    width: '100%',
    marginTop: 16,
    borderColor: '#C7C7C7',
    padding: 11,
    flexDirection: 'row',
    alignItems: 'center',
  },
  reviewTitle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '78%',
    height: '100%',
  },
  textBold: {
    fontFamily: 'NunitoSans_700Bold',
  },
  textBoldMedium: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 16,
  },
  textBoldSmall: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 12,
  },
  textRegular: {
    fontFamily: 'NunitoSans_400Regular',
  },
  textRegularSmall: {
    fontFamily: 'NunitoSans_400Regular',
    fontSize: 12,
  },
  textItalicSmall: {
    fontFamily: 'NunitoSans_400Regular_Italic',
    fontSize: 12,
  },
  statusText: {
    fontSize: 12,
    color: '#046736',
    fontFamily: 'NunitoSans_700Bold',
    marginRight: 4,
  },
  reviewDetail: {
    flexDirection: 'row',
    paddingTop: 3,
  },
  bottomButton: {
    marginHorizontal: 27,
    marginTop: 30,
  },
  accordionHeader: {
    borderRadius: 8,
    borderColor: '#979797',
    padding: 11,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerTitle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  accordionIcon: {
    fontSize: 14,
    paddingLeft: 6,
  },
  avatar: {
    width: 27,
    height: 27,
    borderRadius: 100,
  },
  employee: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: 4,
    width: 180,
  },
  assetImageContainer: {
    backgroundColor: colors.white,
    padding: 5,
    borderRadius: 100,
  },
  assetImage: {
    backgroundColor: colors.mainBlue,
    padding: 10,
    borderRadius: 100,
  },
});
