import React, { useState } from 'react';
import { ScrollView, Text, View, Platform, Pressable, SafeAreaView } from 'react-native';
import Checkbox from 'expo-checkbox';
import Button from '../../../../components/Button';
import SignUpHeader from '../../../../components/SignUp/SignUpHeader';
import NavigationHeader from '../../../../components/SignUp/SingUpNavigation';
import { styles } from './style';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { BsCalendarMonthFill } from 'react-icons/bs';
import { Calendar } from 'react-native-calendars';
import SiteComponent from '../../../../components/SiteComponent';
import moment from 'moment';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { api } from '../../../../utils/api';
import CustomArrow from '../../../../components/CustomArrow';

export default function SettingUpProjectStep6({ ...props }) {
  const projectSites = props.route.params.newProject?.sites;
  const [siteInputs, setAllSites] = useState(projectSites);
  const [open, setOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [selectedFrequency, setSelectedDropdownItem] = useState('Select');
  const [pickedDate, setPickedDate] = useState('');
  const [newProject, setNewProject] = useState(props.route.params.newProject);
  const periodTesting = ['Monthly', 'Quarterly', 'Biannually', 'Annually'];

  const handleDropdownPicker = (item) => {
    setSelectedDropdownItem(item);
    setOpen(false);
  };

  const checkInputs = () => {
    if (isChecked == true) {
      return selectedFrequency !== 'Select' && pickedDate !== '';
    } else {
      for (const siteInput of siteInputs) {
        const testCadence = siteInput.testCadence;
        if (!testCadence || !testCadence.startDate || !testCadence.type || testCadence.type === 'Select') {
          return false;
        }
      }
      return true;
    }
  };

  const setSiteInputs = (data) => {
    setAllSites(data);

    const tempNewProject = JSON.parse(JSON.stringify(newProject));
    tempNewProject.sites = data;
    setNewProject(tempNewProject);
  };

  const handleCheckMark = (data) => {
    let newsites = [...projectSites];
    for (let s = 0; s < newsites.length; s++) {
      newsites[s].testCadence = {
        startDate: pickedDate,
        startDateUTC: moment(pickedDate, 'YYYY-MM-DD').utc().format('YYYY-MM-DD'),
        type: selectedFrequency,
      };
    }

    const tempNewProject = JSON.parse(JSON.stringify(newProject));
    tempNewProject.sites = newsites;
    setNewProject(tempNewProject);
    setIsChecked(data);
  };

  return (
    <View style={styles.container}>
      <SafeAreaView
        style={{
          flex: 1,
        }}
      >
        <ScrollView>
          <View
            style={
              Platform.OS === 'ios'
                ? [
                    styles.loading,
                    {
                      marginTop: 0,
                    },
                  ]
                : styles.loading
            }
          >
            <View style={styles.loadPercent}></View>
          </View>
          <NavigationHeader
            onPress={async () => {
              if (props.route.params.addNewProject) {
                props.navigation.goBack();
              } else {
                await api(`v1/company/${props.route.params.company}`, 'DELETE', null);
                await AsyncStorage.multiRemove(['user', 'token', 'refreshToken', 'expiresIn']);
                props.navigation.navigate('GuestNavigation', { screen: 'QRCodeScan' });
              }
            }}
            header={props.route.params.addNewProject ? 'Add New Project' : 'Sign Up'}
            navigate={'Cancel'}
            menuStyles={styles.menu}
          />
          {props.route.params.addNewProject ? (
            <View>
              <Text style={[styles.textBold, { textAlign: 'center' }]}>Testing schedule</Text>
              <Text style={styles.scriptStyle}>
                Each project has a set testing cadence. All assets linked to this project is set to be tested
                periodically
              </Text>
            </View>
          ) : (
            <SignUpHeader
              title={'Testing schedule'}
              descript={
                'Each project has a set testing cadence. All Assets linked to this project is set to be tested periodically.'
              }
              userImage={props.route.params.userImage}
              disablePress={true}
            />
          )}
          <View style={styles.textField}>
            <View style={styles.title}>
              <Text style={styles.titleText}>Project</Text>
            </View>

            <View style={styles.checkboxContainer}>
              <Checkbox
                style={styles.checkbox}
                value={isChecked}
                onValueChange={(data) => handleCheckMark(data)}
                color={isChecked ? '#132FF5' : undefined}
              />
              <Text style={styles.checkboxDesc}>Apply test cadence to all buildings under project</Text>
            </View>

            {isChecked && (
              <>
                <Text style={styles.inputHeader}>Test</Text>

                <Pressable onPress={() => setOpen(!open)}>
                  <View style={open ? styles.inputDrop : [styles.inputDrop, { paddingBottom: 15 }]}>
                    <View style={styles.dropTitleContainer}>
                      <Text style={selectedFrequency == 'Select' ? styles.unpickedTitle : styles.dropList}>
                        {selectedFrequency}
                      </Text>
                      {open ? (
                        <FiChevronUp name="angle-up" style={styles.imgIcon} />
                      ) : (
                        <FiChevronDown name="angle-down" style={styles.imgIcon} />
                      )}
                    </View>
                    {open &&
                      periodTesting.map((item, index) => {
                        return (
                          <Pressable key={index} onPress={() => handleDropdownPicker(item)}>
                            <View style={index !== periodTesting.length ? styles.dropItem : styles.dropLastItem}>
                              <Text style={styles.dropList}>{item}</Text>
                            </View>
                          </Pressable>
                        );
                      })}
                  </View>
                </Pressable>
                <Pressable onPress={() => setOpenCalendar(!openCalendar)}>
                  <View style={openCalendar ? styles.inputDrop : [styles.inputDrop, { paddingBottom: 15 }]}>
                    <View style={styles.dropTitleContainer}>
                      <View style={styles.calendarTitle}>
                        <BsCalendarMonthFill name="calendar-month" style={styles.calendarIcon} />
                        <Text style={!pickedDate ? styles.unpickedTitle : styles.dropList}>
                          Starting Date: {pickedDate}
                        </Text>
                      </View>

                      {openCalendar ? (
                        <FiChevronUp name="angle-up" style={styles.imgIcon} />
                      ) : (
                        <FiChevronDown name="angle-down" style={styles.imgIcon} />
                      )}
                    </View>
                    {openCalendar && (
                      <Calendar
                        renderArrow={(direction, onPress) => <CustomArrow direction={direction} onPress={onPress} />}
                        hideArrows={false}
                        markingType={'multi-dot'}
                        enableSwipeMonths={true}
                        theme={{
                          arrowColor: '#0F0000',
                        }}
                        onDayPress={(day) => {
                          setPickedDate(day.dateString);
                          setOpenCalendar(!openCalendar);
                        }}
                        context={{ date: '' }}
                      />
                    )}
                  </View>
                </Pressable>
              </>
            )}
            {isChecked == false &&
              projectSites.map(function (item, i) {
                return (
                  <SiteComponent
                    key={i}
                    item={item}
                    i={i}
                    siteInputs={siteInputs}
                    setSiteInputs={(data) => setSiteInputs(data)}
                  />
                );
              })}
          </View>
        </ScrollView>
        <View style={styles.assignButton}>
          <Button
            title={'Next'}
            buttonStyle={checkInputs() ? styles.blueButtonActive : styles.blueButton}
            textStyle={styles.blueButtonText}
            onPress={() =>
              checkInputs()
                ? newProject !== null &&
                  props.navigation.navigate('SettingUpProjectStep7', {
                    userImage: props.route.params.userImage,
                    userType: props.route.params.userType,
                    userLicence: props.route.params.userLicence,
                    appUse: props.route.params.appUse,
                    subscription: props.route.params.subscription,
                    company: props.route.params.company,
                    newProject: newProject,
                    schedule: {
                      type: selectedFrequency,
                      startDate: pickedDate,
                    },
                    scheduleApplyAll: isChecked,
                    addNewProject: props.route.params.addNewProject,
                    registerForPushNotificationsAsync: props.route.params.registerForPushNotificationsAsync,
                  })
                : ''
            }
          />
          <Button
            title={'Previous'}
            buttonStyle={[styles.blankButton, { marginBottom: 20 }]}
            textStyle={styles.blankButtonText}
            onPress={() => props.navigation.goBack()}
          />
        </View>
      </SafeAreaView>
    </View>
  );
}
