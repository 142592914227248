import { Dimensions, StyleSheet, Platform } from 'react-native';
import colors from '../../../../theme/colors';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  header: {
    backgroundColor: '#F6F6F6',
    paddingTop: 60,
    height: Platform.OS === 'ios' ? 180 : 150,
    paddingHorizontal: 23,
    // paddingBottom:18,
    // alignItems:"center"
  },
  textBold: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
  },
  backButton: {
    width: 60,
    padding: 10,
    position: 'absolute',
    left: 15,
    top: 20,
  },
  backText: {
    fontFamily: 'NunitoSans_700Bold',
    textAlign: 'left',
    fontSize: 14,
  },
  imageDrop: {
    height: 100,
    width: 100,
    borderRadius: 1000,
    shadowColor: '#000',
    // shadowRadius: 3.84,
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 6,
    // elevation: 5,
  },
  image: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 100,
    width: 100,
    borderRadius: 1000,
    shadowColor: '#000',
    shadowOpacity: 0.25,
  },

  imgPick: {
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 90,
  },
  title: {
    textAlign: 'center',
    marginTop: 52,
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
  },
  body: {
    marginHorizontal: 23,
    // height:Dimensions.get("screen").height,
    // flexDirection: 'column',
    // justifyContent: 'space-between',
    flex: 1,
  },
  input: {
    marginTop: 9,
    borderColor: '#C7C7C7',
    width: '100%',
    borderWidth: 1,
    borderRadius: 10,
    paddingVertical: 10,
    paddingLeft: 13,
  },
  blueButton: {
    width: '100%',
    position: 'absolute',
    bottom: 20,
    borderRadius: 8,
    backgroundColor: colors.mainBlue,
    paddingVertical: 10,
  },
  blueButtonText: {
    color: colors.white,
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 18,
    textAlign: 'center',
  },
  // overlay: {
  //   ...StyleSheet.absoluteFillObject,
  //   backgroundColor: 'rgba(0,0,0,0.1)',
  //   elevation: 0,
  //   shadowOffset: {
  //     width: 0,
  //     height: 0,
  //   },
  //   marginLeft: -3
  // }
});
