import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../theme/colors';

export const styles = StyleSheet.create({
  appbarContainer: {
    width: '100%',
    paddingBottom: 16,
  },
  appbarContentContainer: {
    flex: 1,
  },
  appbarInnerContentContainer: {
    flex: 1,
    paddingHorizontal: 3,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  appbarBackground: {
    backgroundColor: colors.mainBlue,
    elevation: 0,
  },
  appbarTitleContainer: {
    position: 'absolute',
    alignSelf: 'center',
    bottom: 0,
    paddingHorizontal: 48,
    height: 34,
    backgroundColor: colors.white,
    borderRadius: 17,
    shadowColor: colors.black,
    shadowOpacity: 0.05,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
  },
  appbarTitleIconContainer: {
    borderRadius: 12,
    width: 24,
    height: 24,
    justifyContent: 'center',
    alignItems: 'center',
  },
  appbarTitle: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  appbarTitleText: {
    color: colors.black,
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: 19,
    marginLeft: 6,
  },
  appbarBackButton: {
    alignSelf: 'flex-start',
  },
  appbarTrailingIcon: {
    alignSelf: 'flex-end',
    width: 24,
    height: 24,
    marginRight: 16,
    top: -3,
  },
  bodyContainer: {
    flex: 1,
    width: '100%',
  },
});
