import { Dimensions, StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  assetStatus: {
    backgroundColor: '#ffcdcd',
    borderRadius: 13,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    flexDirection: 'row',
    paddingHorizontal: 8,
    paddingVertical: 3,
    marginRight: 4,
  },
  assetStatusText: {
    fontSize: 16,
    color: '#FF4D4D',
    fontWeight: 'bold',
    marginRight: 4,
  },
});
