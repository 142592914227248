import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../../theme/colors';

export const styles = StyleSheet.create({
  menu: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 20,
    paddingBottom: 36,
    paddingHorizontal: 26,
    alignItems: 'center',
  },
  appbarTrailingIcon: {
    alignSelf: 'flex-end',
    width: 24,
    height: 24,
    tintColor: 'black',
    marginRight: 12,
  },
  textBold: {
    fontSize: 18,
    fontFamily: 'NunitoSans_700Bold',
  },
});
