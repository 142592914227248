import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Text, View, SafeAreaView, ScrollView } from 'react-native';
import { styles } from './style';
import images from '../../../../theme/images';
import Button from '../../../../components/Button';
import NavigationHeader from '../../../../components/SignUp/SingUpNavigation';
import SignUpHeader from '../../../../components/SignUp/SignUpHeader';
import { useIsFocused } from '@react-navigation/native';
import { api } from '../../../../utils/api';
import Loading from '../../../../components/Loading';
import AsyncStorage from '@react-native-async-storage/async-storage';

function UserProfile({ navigation, route }) {
  const [isSelect, setIsSelect] = useState(false);
  const [loading, setLoading] = useState(true);

  //TODO load API
  const jobsArray = [
    {
      title: 'Technician',
      value: 'Tech',
      onClick: false,
    },
    {
      title: 'Administrator',
      value: 'PM',
      onClick: false,
    },
    {
      title: 'Viewer Only',
      value: 'OHS',
      onClick: false,
    },
  ];

  const [userImage, setUserImage] = useState(
    route.params && route.params?.userImage !== '' ? route.params.userImage : null
  );
  const [listJob, setListJob] = useState(jobsArray);
  const [userType, setSelectedType] = useState('');

  const handlePress = (value) => {
    const newState = listJob.map((item, index) => {
      if (value == item.value) {
        setSelectedType(value);
        return { ...item, onClick: true };
      }
      return { ...item, onClick: false };
    });
    setListJob(newState);
    setIsSelect(true);
  };

  const jobs = listJob.map((item, index) => {
    return (
      <Button
        title={item.title}
        value={item.value}
        key={index}
        buttonStyle={[
          styles.blueBorderButton,
          item.onClick ? { backgroundColor: '#EDEDED' } : { backgroundColor: 'white' },
        ]}
        textStyle={styles.blueBorderButtonText}
        onPress={() => handlePress(item.value)}
      />
    );
  });

  const checkUserInvited = async () => {
    let user = route.params.user;
    console.log('user: ', user);
    const checkInvited = await api(`v1/user/checkinvite?email=${encodeURIComponent(user.email)}`, 'GET', null);
    console.log('checkInvited: ', checkInvited.data);
    if (checkInvited.data.invited == false) {
      console.log('not invited');
      setLoading(false);
    } else {
      setSelectedType(checkInvited.data.invited.type);
      setLoading(false);
      navigation.navigate('LicenseNumber', {
        userType: checkInvited.data.invited.type,
        userImage: userImage,
      });
    }
  };

  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      checkUserInvited();
    }
  }, [isFocused]);

  if (loading) {
    return <Loading />;
  }

  return (
    <View style={styles.container}>
      <SafeAreaView
        style={{
          flex: 1,
        }}
      >
        <ScrollView>
          <View style={styles.loading}>
            <View style={styles.loadPercent}></View>
          </View>
          <NavigationHeader
            onPress={async () => {
              await AsyncStorage.multiRemove(['user', 'token', 'refreshToken', 'expiresIn']);
              navigation.navigate('GuestNavigation', { screen: 'QRCodeScan' });
            }}
            header={'Sign Up'}
            navigate={'Cancel'}
            menuStyles={styles.menu}
          />
          <View style={styles.image}>
            <SignUpHeader
              title={`Hi, ${route.params.user.firstName}`}
              descript={
                'Welcome to ClkTag. We just need a few more details. Which role title best describes your role?'
              }
              setUserImage={setUserImage}
              userImage={userImage}
              disablePress={false}
            />
            <Text style={styles.textBold}>I'm a …</Text>
            <View style={styles.buttonJob}>{jobs}</View>
          </View>
        </ScrollView>
        <View style={styles.assignButton}>
          <Button
            title={'Next'}
            buttonStyle={isSelect ? styles.blueButtonActive : styles.blueButton}
            textStyle={styles.blueButtonText}
            onPress={() =>
              isSelect
                ? navigation.navigate(userType == 'OHS' ? 'JoinProject' : 'LicenseNumber', {
                    userType: userType,
                    userImage: userImage,
                    registerForPushNotificationsAsync: route.params.registerForPushNotificationsAsync,
                  })
                : ''
            }
            // onPress={()=>console.log(userType)}
          />
          <Button
            title={'Cancel'}
            buttonStyle={[styles.blankButton, { marginBottom: 20 }]}
            textStyle={styles.blankButtonText}
            onPress={() => navigation.goBack()}
          />
        </View>
      </SafeAreaView>
    </View>
  );
}

export default UserProfile;
