import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../theme/colors';

export const styles = StyleSheet.create({
  imgIconDrop: {
    fontSize: 17,
    paddingLeft: 6,
    color: '#0F0000',
  },
  uploadText: {
    fontFamily: 'NunitoSans_600SemiBold',
    color: '#000000',
    opacity: 0.5,
    fontSize: 14,
  },
  imgPickedContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  textInput: {
    flex: 1,
    fontFamily: 'NunitoSans_700Bold',
    color: '#000000',
  },
  imgPicked: {
    paddingVertical: 8,
    paddingLeft: 7,
    backgroundColor: '#8997fa1a',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#132ff5',
    borderRadius: 8,
    flex: 12,
  },
  recommendBox: {
    backgroundColor: 'white',
    flexDirection: 'column',
  },
  inputContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  recommendContent: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 11,
    borderColor: '#C7C7C7',
    borderBottomWidth: 1,
  },
  recommendIcon: {
    width: 20,
    height: 20,
    borderRadius: 100,
  },
  addReq: {
    color: '#132FF5',
    fontSize: 16,
    fontFamily: 'NunitoSans_600SemiBold',
    paddingTop: 10,
  },
  popupModal: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  popupWindowSuccess: {
    backgroundColor: 'white',
    paddingHorizontal: 27,
    paddingVertical: 20,
    borderRadius: 12,
    alignItems: 'center',
    marginHorizontal: 26,
    marginTop: 150,
  },
  popupWindow: {
    backgroundColor: 'white',
    paddingHorizontal: 18,
    paddingVertical: 19,
    borderRadius: 12,
    alignItems: 'center',
    marginHorizontal: 26,
    marginTop: 50,
  },
  popupIcon: {
    width: 70,
    height: 70,
    borderRadius: 16,
    marginTop: 8,
  },
  popupIconModal: {
    width: 15,
    height: 15,
    paddingHorizontal: 5,
    paddingVertical: 5,
    borderRadius: 16,
  },
  popupTitle: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    marginTop: 25,
  },
  popupScript: {
    fontFamily: 'NunitoSans_400Regular',
    marginTop: 8,
    textAlign: 'center',
  },
  addModalTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  addModalTitle: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    color: '#000000',
  },
  popupIconModal: {
    width: 15,
    height: 15,
    paddingHorizontal: 5,
    paddingVertical: 5,
    borderRadius: 16,
  },
  textFieldModal: {
    marginBottom: 14,
    paddingTop: 23,
    width: '100%',
  },
  firstTitle: {
    flexDirection: 'row',
  },
  titleText: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
  },
  inputHeaderModal: {
    fontFamily: 'NunitoSans_700Bold',
    marginTop: 14,
  },
  input: {
    marginTop: 16,
    borderColor: '#C7C7C7',
    width: '100%',
    borderWidth: 1,
    borderRadius: 8,
    paddingVertical: 15,
    paddingLeft: 13,
  },
  required: {
    color: '#FF4D4D',
  },
  optional: {
    fontFamily: 'NunitoSans_400Regular',
    color: '#000000',
    fontSize: 14,
    opacity: 0.5,
  },
  imgPicker: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#C7C7C7',
    paddingHorizontal: 7,
    paddingVertical: 8,
    marginTop: 10,
  },
  dasherBorder: {
    borderStyle: 'dotted',
    borderRadius: 8,
    borderWidth: 2,
    borderColor: '#C7C7C7',
    paddingVertical: 20,
    alignItems: 'center',
  },
  closeIcon: {
    flex: 1,
    fontSize: 29,
    alignSelf: 'center',
    paddingLeft: 17,
    color: '#C7C7C7',
  },
  blueButtonText: {
    color: colors.white,
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 18,
    textAlign: 'center',
  },
  blueButtonActive: {
    borderRadius: 8,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
    marginBottom: 10,
  },
  blueButton: {
    borderRadius: 8,
    opacity: 0.5,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
    marginBottom: 10,
    width: '100%',
  },
  inputDrop: {
    marginTop: 16,
    borderColor: '#C7C7C7',
    width: '100%',
    borderWidth: 1,
    borderRadius: 8,
    paddingTop: 15,
    paddingLeft: 13,
  },
  dropTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 16,
  },
  calendarTitle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  calendarIcon: {
    fontSize: 25,
    marginRight: 9,
  },
});
