import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../../../theme/colors';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    paddingBottom: 30,
  },
  detailContainer: {
    flex: 1,
    backgroundColor: 'white',
  },
  header: {
    backgroundColor: '#F6F6F6',
    paddingTop: 70,
    paddingLeft: 23,
    paddingRight: 27,
    paddingBottom: 23,
  },
  body: {
    paddingTop: 10,
  },
  headerTitle: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  textBoldLarge: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    color: '#000000',
  },
  textBold: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 14,
    color: '#000000',
  },
  textBoldGray: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    color: '#6B6B6B',
  },
  textBoldMedium: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 16,
    color: '#000000',
  },
  textLight: {
    fontFamily: 'NunitoSans_300Light',
    fontSize: 14,
    color: '#000000',
  },
  projectsContainer: {
    marginTop: 19,
    paddingLeft: 23,
    paddingRight: 27,
  },
  projectContainer: {
    paddingVertical: 12,
    borderBottomWidth: 1,
    borderBottomColor: '#0000000d',
    flexDirection: 'row',
  },
  img: {
    width: 23,
    height: 23,
    borderRadius: 11,
  },
  projectDetail: {
    marginLeft: 15,
  },
  blankButton: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#132FF5',
    paddingVertical: 13,
    marginHorizontal: 27,
    alignItems: 'center',
  },
  blankButtonText: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    color: '#132FF5',
  },
  viewOptionsContainer: {
    marginTop: 22,
    flexDirection: 'row',
    alignItems: 'center',
  },
  grayBoldTextView: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 14,
    color: '#6B6B6B',
    paddingBottom: 9,
    paddingRight: 18,
  },
  blueBoldText: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 14,
    color: '#132FF5',
    paddingBottom: 9,
    paddingRight: 18,
  },
  activeBorder: {
    height: 1,
    position: 'absolute',
    backgroundColor: '#132FF5',
    bottom: 0,
  },
  projectDetailTitle: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    paddingVertical: 5,
    paddingHorizontal: 20,
    borderRadius: 16,
  },
});
