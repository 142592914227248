import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, Linking } from 'react-native';
import { styles } from './style';
import { FaChevronRight } from 'react-icons/fa';
import { Image } from 'react-native';
import images from '../../../theme/images';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useIsFocused } from '@react-navigation/native';

export default function AccountSettings({ ...props }) {
  const [hasWriteAccess, setHasWriteAccess] = useState(false);
  const settingOptions = [
    'Personal Information',
    'My Projects',
    'Subscription & Billing',
    'Privacy Policy',
    'Terms and Conditions',
  ];

  const logoutUser = async () => {
    await AsyncStorage.multiRemove(['user', 'token', 'refreshToken', 'expiresIn']);
    console.log('props for logout: ', props);
    props.navigation.navigate('GuestNavigation', { screen: 'QRCodeScan' });
  };

  const navigate = (...item) => {
    if (item == 'Personal Information') {
      props.navigation.navigate('PersonalInfor');
    }
    if (item == 'My Projects') {
      props.navigation.navigate('MyProject', {
        hasWriteAccess: hasWriteAccess,
      });
    }
    if (item == 'Subscription & Billing') {
      props.navigation.navigate('SubscriptionBilling', {
        hasWriteAccess: hasWriteAccess,
      });
    }
    if (item == 'Privacy Policy') {
      Linking.openURL('https://clktag.com.au/privacy-policy/');
    }
    if (item == 'Terms and Conditions') {
      Linking.openURL('https://clktag.com.au/terms-conditions/');
    }
  };

  const getUser = async () => {
    var user = await AsyncStorage.getItem('user');
    user = JSON.parse(user);

    if (user.role !== 'OHS') {
      setHasWriteAccess(true);
    }
  };

  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      getUser();
    }
  }, [isFocused]);

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.textBoldGray}>Account Settings</Text>
      </View>
      <View style={styles.body}>
        {settingOptions.map((item, id) => {
          return item == 'Subscription & Billing' && hasWriteAccess == false ? (
            <View key={id}></View>
          ) : (
            <TouchableOpacity
              key={id}
              onPress={() => {
                navigate(item);
              }}
            >
              <View key={id} style={styles.optionContainer}>
                <Text style={styles.textBold}>{item}</Text>
                <FaChevronRight name="right" style={{ fontSize: 13 }} />
              </View>
            </TouchableOpacity>
          );
        })}
      </View>

      <View style={styles.logoutContainer}>
        <TouchableOpacity
          onPress={() => {
            logoutUser();
          }}
        >
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Image source={images.logOut} style={{ width: 30, height: 27, marginRight: 8 }} />
            <Text style={[styles.textBold, { color: '#FF4D4D' }]}>Log out</Text>
            <FaChevronRight name="right" style={{ color: '#FF4D4D', fontSize: 15 }} />
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
}
