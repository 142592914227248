import React, { useMemo, useState, useEffect, useRef } from 'react';
import { FlatList, Image, ScrollView, Text, TouchableOpacity, View } from 'react-native';
import { BiSolidPlusCircle } from 'react-icons/bi';
import { AiOutlineUnorderedList } from 'react-icons/ai';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { useIsFocused } from '@react-navigation/native';
import BottomSheet, { BottomSheetFlatList } from '@gorhom/bottom-sheet';
import moment from 'moment';
import 'moment-timezone';
import AccordionBlock from '../taskAll/accordionBlock';
import TaskCalendar from '../../../tabs/tasks/taskCalendar';
import { styles } from './style';
import images from '../../../../theme/images';
import { api } from '../../../../utils/api';
import { dateStringToUTC } from '../../../../utils/dateUtils';
import AsyncStorage from '@react-native-async-storage/async-storage';
import SearchScreen from '../../../../components/Search';
import Loading from '../../../../components/Loading';

export default function TaskListDate({ ...props }) {
  const [text, onChangeText] = useState('');
  const [taskGroups, setTaskGroups] = useState([]);
  const [taskGroupsLoading, setTaskGroupsLoading] = useState(true);
  const snapPoints = useMemo(() => ['38%', '76%'], []);
  const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));
  const [markedDates, setMarkedDates] = useState({});
  const sheetRef = useRef(null);

  const [hasWriteAccess, setHasWriteAccess] = useState(false);

  // variables
  const data = useMemo(
    () =>
      Array(1)
        .fill(0)
        .map((_, index) => `index-${index}`),
    []
  );

  const getTaskGroups = async () => {
    const response = await api(
      `v1/task/admin/group/calendar?selected_date_utc=${dateStringToUTC(selectedDate)}`,
      'GET',
      null
    );
    const sections = [];

    if (response.status === 200 && response.data.success) {
      for (const projectId in response.data.groups) {
        const projectGroups = response.data.groups[projectId];

        let projectTaskCount = 0;
        const groups = [];
        for (const groupId in projectGroups.groups) {
          const group = projectGroups.groups[groupId];

          if (group.type === 'manual') {
            if (group.task.status === 'Pending') {
              projectTaskCount += 1;
              groups.push(group);
            }
          } else {
            const groupTaskCount = group.total - group.complete;
            if (groupTaskCount > 0) {
              groups.push({
                type: 'auto',
                title: `Test ${group.total} Asset(s)`,
                progress: `${group.complete}/${group.total} Complete`,
                user: group.user,
                dueDateUTC: group.dueDateUTC,
              });
              projectTaskCount += groupTaskCount;
            }
          }
        }

        if (projectTaskCount > 0) {
          const sectionObj = {
            project: projectGroups.project,
            groups: groups,
            taskCount: projectTaskCount,
          };

          sections.push({ section: [sectionObj] });
        }
      }

      setTaskGroups(sections);
    }
    setTaskGroupsLoading(false);
  };

  const getUser = async () => {
    var user = await AsyncStorage.getItem('user');
    user = JSON.parse(user);

    if (user.role !== 'OHS') {
      setHasWriteAccess(true);
    }
  };

  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      getTaskGroups();
      getUser();
    }
  }, [isFocused, selectedDate]);

  const renderItem = () => {
    return (
      <View style={styles.bottomSheet}>
        {taskGroups.map((item, index) => {
          return (
            <View key={index}>
              <AccordionBlock {...item} type={'calendar'} {...props} />
            </View>
          );
        })}
      </View>
    );
  };

  return (
    <SearchScreen
      body={
        <ScrollView style={{ flex: 1 }}>
          <View style={styles.body}>
            <View style={styles.screenNameContainer}>
              <Text style={styles.screenTitle}>Tasks</Text>
              <View style={styles.iconContainer}>
                <TouchableOpacity onPress={() => props.navigation.navigate('TaskListAll')}>
                  <View style={[styles.screenTitleIcon, { color: '#000000' }]}>
                    <AiOutlineUnorderedList />
                  </View>
                </TouchableOpacity>
                <Image source={images.calendarBlack} style={styles.imgIcon} />
                {hasWriteAccess && (
                  <TouchableOpacity onPress={() => props.navigation.navigate('AddTask')}>
                    <BiSolidPlusCircle name="add-circle-sharp" style={{ color: '#132FF5', fontSize: 30 }} />
                  </TouchableOpacity>
                )}
              </View>
            </View>
            <TaskCalendar
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              setTaskGroupsLoading={setTaskGroupsLoading}
            />
          </View>

          {taskGroupsLoading ? (
            <View style={{ marginTop: 50 }}>
              <Loading opacity={0} />
            </View>
          ) : (
            taskGroups.length > 0 && (
              // <BottomSheet
              //   ref={sheetRef}
              //   snapPoints={snapPoints}
              //   enableHandlePanningGesture={true}
              //   enablePanDownToClose={false}
              //   handleStyle={{
              //     backgroundColor: '#F6F6F6',
              //     borderTopLeftRadius: 16,
              //     borderTopRightRadius: 16,
              //   }}
              // >
              //   <BottomSheetFlatList
              //     data={data}
              //     keyExtractor={(i) => i}
              //     renderItem={renderItem}
              //     contentContainerStyle={styles.flatSheetList}
              //   />
              // </BottomSheet>
              <FlatList
                data={data}
                keyExtractor={(i) => i}
                renderItem={renderItem}
                contentContainerStyle={styles.flatSheetList}
              />
            )
          )}
        </ScrollView>
      }
      {...props}
    />
  );
}
