import React, { useState } from 'react';
import { Pressable, Text, View, Image, Modal, ScrollView } from 'react-native';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { BsCalendarMonthFill } from 'react-icons/bs';
import { styles } from './style';
import images from '../../../../../theme/images';
import Button from '../../../../../components/Button';
import { Calendar } from 'react-native-calendars';
import CustomArrow from '../../../../../components/CustomArrow';

export default function PopUpModal({ ...props }) {
  const { selectedCandenceItem, setSelectedCandenceItem, pickedDate, setPickedDate } = props;
  const [open, setOpen] = useState(false);
  const periodTesting = ['Monthly', 'Quarterly', 'Biannually', 'Annually'];
  const [openCalendar, setOpenCalendar] = useState(false);

  const handleDropdownPicker = (item) => {
    setSelectedCandenceItem(item);
    setOpen(false);
  };
  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={props.visible}
      onRequestClose={props.onRequestClose}
      style={{ flexGrow: 1 }}
    >
      <View style={styles.popupModal}>
        <View style={styles.popupWindow}>
          <View style={styles.addModalTitleContainer}>
            <Text style={styles.addModalTitle}>Edit test cadence</Text>
            <Pressable onPress={props.onRequestClose}>
              <Image style={styles.popupIconModal} source={images.lightGreyX} />
            </Pressable>
          </View>
          <View style={styles.popUpNotice}>
            <Text style={styles.noticeText}>* This will effect the test cadence for this asset only</Text>
          </View>
          <View style={styles.popUpSelected}>
            <Pressable onPress={() => setOpen(!open)}>
              <View style={styles.inputDrop}>
                <View style={styles.dropTitleContainer}>
                  <Text style={selectedCandenceItem == 'Select' ? styles.unpickedTitle : styles.dropList}>
                    {selectedCandenceItem}
                  </Text>
                  {open ? (
                    <FiChevronUp name="angle-up" style={styles.imgIcon} />
                  ) : (
                    <FiChevronDown name="angle-down" style={styles.imgIcon} />
                  )}
                </View>
                {open &&
                  periodTesting.map((item, index) => {
                    return (
                      <Pressable key={index} onPress={() => handleDropdownPicker(item)}>
                        <View style={index !== periodTesting.length ? styles.dropItem : styles.dropLastItem}>
                          <Text style={styles.dropList}>{item}</Text>
                        </View>
                      </Pressable>
                    );
                  })}
              </View>
            </Pressable>
          </View>
          <View style={styles.popUpPickDate}>
            <Pressable onPress={() => setOpenCalendar(!openCalendar)}>
              <View style={openCalendar ? styles.inputDrop : [styles.inputDrop, { paddingBottom: 15 }]}>
                <View style={styles.dropTitleContainer}>
                  <View style={styles.calendarTitle}>
                    <BsCalendarMonthFill name="calendar-month" style={styles.calendarIcon} />
                    <Text style={selectedCandenceItem == '' ? styles.unpickedTitle : styles.dropList}>
                      Starting Date: {pickedDate}
                    </Text>
                  </View>

                  {openCalendar ? (
                    <FiChevronUp name="angle-up" style={styles.imgIcon} />
                  ) : (
                    <FiChevronDown name="angle-down" style={styles.imgIcon} />
                  )}
                </View>
                {openCalendar && (
                  <Calendar
                    renderArrow={(direction, onPress) => <CustomArrow direction={direction} onPress={onPress} />}
                    hideArrows={false}
                    markingType={'multi-dot'}
                    // markedDates={marked}
                    enableSwipeMonths={true}
                    theme={{
                      arrowColor: '#0F0000',
                    }}
                    // customHeader={(day)=>{
                    //   return(<Text>{selected}</Text>)
                    // }}
                    onDayPress={(day) => {
                      // let dateFormat1 = day.dateString.format('D-MM-YYYY');
                      // console.log(typeof new Date(day.dateString));
                      setPickedDate(day.day + '/' + day.month + '/' + day.year);
                      setOpenCalendar(!openCalendar);
                    }}
                  />
                )}
              </View>
            </Pressable>
          </View>
          <Button
            title={'Done'}
            buttonStyle={[styles.blueButtonActive]}
            textStyle={styles.blueButtonText}
            onPress={props.onRequestClose}
          />
        </View>
      </View>
    </Modal>
  );
}
