import React, { useEffect, useState } from 'react';
import { Text, View, ScrollView, Image, TouchableOpacity, Modal, TextInput } from 'react-native';
import SearchBar from '../../../../components/SearchBar';
import { styles } from './style';
import { FiChevronLeft, FiChevronRight, FiSettings, FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { BsCalendarMonthFill } from 'react-icons/bs';
import RBSheet from 'react-native-raw-bottom-sheet';
import { useRef } from 'react';
import images from '../../../../theme/images';
import { Platform } from 'expo-modules-core';
import Button from '../../../../components/Button';
import { Pressable } from 'react-native';
import SearchScreen from '../../../../components/Search';
import { useIsFocused } from '@react-navigation/native';
import { api, logout } from '../../../../utils/api';
import Loading from '../../../../components/Loading';
import * as Linking from 'expo-linking';
import Autocomplete from '../../../../components/AutoComplete/autoComplete';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import AddressInput from '../../../../components/AddressInput';
import DropInputField from '../../../../components/DropInputField';
import moment from 'moment';
import { Calendar } from 'react-native-calendars';
import CustomArrow from '../../../../components/CustomArrow';

export default function AdminSiteAssets({ ...props }) {
  const [text, onChangeText] = useState('');
  const refRBSheet = useRef();
  const [data, setSites] = useState([]);
  const [loading, setLoading] = useState(true);
  const [displayModal, setDisplayModal] = useState(false);
  const [isSelect, setIsSelect] = useState(false);
  const [siteInput, setSiteInput] = useState({
    siteName: '',
    siteAddress: '',
    siteType: '',
    siteCadence: '',
  });
  const [siteList, setSitesList] = useState([]);
  const [siteTypes, setSiteTypes] = useState([]);
  const [siteType, updateSiteType] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [pickedDate, setPickedDate] = useState('');
  const [fieldsValidated, setFieldsValidated] = useState(false);

  const periodTesting = [{ title: 'Monthly' }, { title: 'Quarterly' }, { title: 'Biannually' }, { title: 'Annually' }];

  const params = props.route.params;

  const getProjectSites = async () => {
    const sites = await api(`v1/site/project/${params.dataAsset.id}`, 'GET', null);
    // console.log("sites: ", sites.data);
    if (sites.status == 200) {
      if (sites.data.success) {
        let projectSites = [];
        let data = sites.data;

        for (let i = 0; i < data.sites.length; i++) {
          const element = data.sites[i];
          let assetCount = element.assetCount;
          const obj = {
            title: element.name,
            detail: `${element.address.street}, ${element.address.city}, ${element.address.state}, ${element.address.country}`,
            id: element._id,
            description: assetCount == 1 ? `${assetCount} Asset` : `${assetCount} Assets`,
          };
          projectSites.push(obj);
        }
        setSites(projectSites);
      }
    } else {
      if (sites.data.error && sites.data.error == 'jwt expired') {
        logout(props.navigation);
      }
    }
    setLoading(false);
  };

  const exportFunction = async (siteid, type) => {
    var projectid = params.dataAsset.id;
    var fileUrl = '';
    var fileName = '';

    const data = await api(`v1/project/${projectid}/export?type=${type}&siteid=${siteid}`, 'GET', null);
    if (data.status == 200) {
      if (data.data.success) {
        fileUrl = data.data.file;
        fileName = data.data.name;
      }
    }

    if (fileUrl !== '') {
      Linking.openURL(fileUrl);
    }
  };

  const getSiteTypes = async () => {
    const siteTypes = await api('v1/site-type/', 'GET', null);
    if (siteTypes.status == 200) {
      if (siteTypes.data.success) {
        let data = siteTypes.data;
        var arraySiteTypes = [];
        for (let i = 0; i < data.siteTypes.length; i++) {
          const element = data.siteTypes[i];
          const obj = {
            title: element.name,
            id: element._id,
            defaultCadence: element.defaultTestCadence,
          };
          arraySiteTypes.push(obj);
        }
        console.log('array site types: ', arraySiteTypes);
        setSiteTypes(arraySiteTypes);
      }
    }
  };

  const createSite = async () => {
    const site = await api('v1/site', 'POST', {
      project: params.dataAsset.id,
      sites: [siteInput.siteName],
      addManual: true,
      address: siteInput.siteAddress,
      testCadence: {
        type: siteInput.siteCadence,
        startDate: pickedDate,
        startDateUTC: moment(pickedDate, 'YYYY-MM-DD').utc().format('YYYY-MM-DD'),
      },
      siteType: siteInput.siteType,
    });
    console.log('site creation : ', site);
    if (site.status == 200) {
      setDisplayModal(false);
      getProjectSites();
    }
  };

  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      getProjectSites();
      getSiteTypes();
    }
  }, [isFocused]);

  useEffect(() => {
    if (
      siteInput.siteAddress !== '' &&
      siteInput.siteName !== '' &&
      siteInput.siteType !== '' &&
      siteInput.testCadence !== ''
    ) {
      setFieldsValidated(true);
    } else {
      setFieldsValidated(false);
    }
  }, [siteInput]);

  return (
    <SearchScreen
      body={
        <>
          <ScrollView>
            <View style={styles.body}>
              <View style={styles.screenNameContainer}>
                <Pressable onPress={() => props.navigation.goBack()}>
                  <View style={{ justifyContent: 'center', flexDirection: 'row' }}>
                    <FiChevronLeft name="chevron-back" style={styles.screenNameIcon} />
                    <Text style={styles.screenName}>{props.route.params.title}</Text>
                  </View>
                </Pressable>
                {params.admin && <FiSettings name="cog" style={styles.screenNameIcon} />}
              </View>

              <View style={styles.titleContainer}>
                <Text style={styles.title}>Sites ({data.length})</Text>
              </View>
              {loading ? (
                <View style={{ marginTop: 50 }}>
                  <Loading />
                </View>
              ) : (
                data.length == 0 && (
                  <View style={styles.assetContainer}>
                    <Text style={styles.grayRegularText}>No Sites</Text>
                  </View>
                )
              )}
              {data.map((item, index) => {
                return (
                  <TouchableOpacity
                    key={index}
                    onPress={() =>
                      props.navigation.navigate('AdminViewProject', {
                        title: props.route.params.title,
                        siteTitle: item.title,
                        siteDetail: item,
                        admin: params.admin,
                        projectid: params.dataAsset.id,
                        companyid: params.dataAsset.companyid,
                        hasWriteAccess: params.hasWriteAccess,
                      })
                    }
                  >
                    <View style={styles.accordionHeader}>
                      <View>
                        <View
                          style={{
                            flexDirection: 'row',
                          }}
                        >
                          <View style={styles.iconContainer}>
                            <Image style={styles.accordionHeaderIcon} source={images.locationSmallWhiteIcon} />
                          </View>
                          <View style={styles.accordionHeaderContent}>
                            <Text style={styles.textBold}>{item.title}</Text>
                            <View style={styles.headerContentDescript}>
                              <Text style={styles.textRegularSmall}>{item.description}</Text>
                            </View>
                          </View>
                        </View>
                      </View>
                      <View style={styles.export}>
                        {params.admin && (
                          <TouchableOpacity
                            onPress={() => {
                              refRBSheet.current.open();
                            }}
                          >
                            <Image
                              style={{
                                width: 28,
                                height: 28,
                              }}
                              source={images.lightGreyDownload}
                            />
                          </TouchableOpacity>
                        )}
                        <FiChevronRight name="right" style={styles.accordionIcon} />
                      </View>
                      <RBSheet
                        ref={refRBSheet}
                        closeOnDragDown={true}
                        closeOnPressMask={true}
                        height={150}
                        openDuration={250}
                        customStyles={{
                          wrapper: {
                            backgroundColor: 'rgba(0,0,0,0.3)',
                          },
                          container: {
                            borderTopRightRadius: 8,
                            borderTopLeftRadius: 8,
                          },
                        }}
                      >
                        <View
                          style={{
                            alignItems: 'center',
                          }}
                        >
                          <View style={styles.validate}>
                            <TouchableOpacity
                              onPress={() => {
                                exportFunction(item.id, 'CSV');
                                refRBSheet.current.close();
                              }}
                            >
                              <Text
                                style={[
                                  styles.textBoldLarge,
                                  {
                                    paddingVertical: 20,
                                  },
                                ]}
                              >
                                Export as CSV
                              </Text>
                            </TouchableOpacity>
                          </View>
                          <TouchableOpacity
                            onPress={() => {
                              exportFunction(item.id, 'PDF');
                              refRBSheet.current.close();
                            }}
                          >
                            <Text
                              style={[
                                styles.textBoldLarge,
                                {
                                  paddingVertical: 20,
                                },
                              ]}
                            >
                              Export as PDF
                            </Text>
                          </TouchableOpacity>
                        </View>
                      </RBSheet>
                    </View>
                  </TouchableOpacity>
                );
              })}
            </View>
          </ScrollView>
          {siteTypes.length > 0 && (
            <Modal visible={displayModal} onRequestClose={() => setDisplayModal(false)}>
              <View style={styles.popupModal}>
                <KeyboardAwareScrollView
                  contentContainerStyle={styles.scrollContainer}
                  bounces={false}
                  enableOnAndroid={true}
                  enableAutomaticScroll={Platform.OS === 'ios'}
                  keyboardShouldPersistTaps="always"
                  nestedScrollEnabled={true}
                >
                  <View style={styles.popupWindow}>
                    <Pressable style={styles.closeIcon} onPress={() => setDisplayModal(false)}>
                      <Image style={styles.popupIconModal} source={images.lightGreyX} />
                    </Pressable>
                    <View style={styles.textFieldModal}>
                      <View style={styles.firstTitle}>
                        <Text style={styles.titleText}>Create Site</Text>
                        <Text style={styles.required}>*</Text>
                      </View>
                      <View style={styles.textFieldModal}>
                        <View>
                          <Text style={styles.textBold}>Site name</Text>
                        </View>

                        <TextInput
                          placeholder={`Enter a site`}
                          style={styles.input}
                          placeholderTextColor="#00000080"
                          onChangeText={(text) => {
                            setSiteInput({
                              ...siteInput,
                              siteName: text,
                            });
                          }}
                          value={siteInput.siteName}
                        />

                        <View style={styles.modalInputTitle}>
                          <Text style={styles.textBold}>Site address</Text>
                        </View>
                        <AddressInput
                          selectAddress={(data) => {
                            setSiteInput({
                              ...siteInput,
                              siteAddress: data,
                            });
                          }}
                        />

                        <View style={styles.modalInputTitle}>
                          <Text style={styles.textBold}>Site Type</Text>
                        </View>
                        <DropInputField
                          placeholder={'Select site type'}
                          data={siteTypes}
                          selectedDropdownItem={siteInput.siteType}
                          setSelectedDropdownItem={(data) =>
                            setSiteInput({
                              ...siteInput,
                              siteType: data,
                            })
                          }
                          havePosition={false}
                        />

                        <View style={styles.modalInputTitle}>
                          <Text style={styles.textBold}>Site Test Cadence</Text>
                        </View>
                        <DropInputField
                          placeholder={'Select test cadence'}
                          data={periodTesting}
                          selectedDropdownItem={siteInput.siteCadence}
                          setSelectedDropdownItem={(data) =>
                            setSiteInput({
                              ...siteInput,
                              siteCadence: data,
                            })
                          }
                          havePosition={false}
                        />
                        <Pressable onPress={() => setOpenCalendar(!openCalendar)}>
                          <View style={openCalendar ? styles.inputDrop : [styles.inputDrop, { paddingBottom: 15 }]}>
                            <View style={styles.dropTitleContainer}>
                              <View style={styles.calendarTitle}>
                                <BsCalendarMonthFill name="calendar-month" style={styles.calendarIcon} />
                                <Text
                                  style={siteInput.siteCadence == 'Select' ? styles.unpickedTitle : styles.dropList}
                                >
                                  Starting Date: {pickedDate}
                                </Text>
                              </View>

                              {openCalendar ? (
                                <FiChevronUp name="angle-up" style={styles.imgIcon} />
                              ) : (
                                <FiChevronDown name="angle-down" style={styles.imgIcon} />
                              )}
                            </View>
                            {openCalendar && (
                              <Calendar
                                renderArrow={(direction, onPress) => (
                                  <CustomArrow direction={direction} onPress={onPress} />
                                )}
                                hideArrows={false}
                                markingType={'multi-dot'}
                                // markedDates={marked}
                                enableSwipeMonths={true}
                                theme={{
                                  arrowColor: '#0F0000',
                                }}
                                // customHeader={(day)=>{
                                //   return(<Text>{selected}</Text>)
                                // }}
                                onDayPress={(day) => {
                                  setPickedDate(day.dateString);
                                  setOpenCalendar(!openCalendar);
                                }}
                              />
                            )}
                          </View>
                        </Pressable>

                        <Button
                          title={'Add Site'}
                          buttonStyle={[
                            fieldsValidated ? styles.blueButtonActive : styles.blueButton,
                            {
                              marginTop: 30,
                            },
                          ]}
                          textStyle={styles.blueButtonText}
                          onPress={() => {
                            fieldsValidated && createSite();
                          }}
                        />
                      </View>
                    </View>
                  </View>
                </KeyboardAwareScrollView>
              </View>
            </Modal>
          )}
          {params.admin && params.hasWriteAccess && (
            <View style={styles.assignButton}>
              <Button
                title={'+ Add a site'}
                buttonStyle={[styles.blankButton, { marginBottom: 20 }]}
                textStyle={styles.blankButtonText}
                onPress={() => setDisplayModal(true)}
              />
            </View>
          )}
        </>
      }
      {...props}
    />
  );
}
