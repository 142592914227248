import React from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import Button from '../../../components/Button';
import { styles } from './style';
import moment from 'moment';
import 'moment-timezone';
import Status from '../../assetInfo/status';

function RepairReminderNotification({ ...props }) {
  const { notification, navigation, hasWriteAccess, handleClosePress } = props;

  const handleViewAssetPress = () => {
    navigation.navigate('QrCodeNav', {
      screen: 'AssetInfo',
      params: {
        asset: notification.asset,
        isAdmin: hasWriteAccess,
        fromNotifications: true,
      },
    });
  };

  return (
    <View style={styles.notiBlock}>
      <View style={styles.notiHeader}>
        <View style={styles.notiTitle}>
          <Text style={styles.textBold}>Reminder </Text>
          <Text style={styles.textRegular}>
            - Since {moment(notification.sendOnUTC).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).fromNow()}
          </Text>
        </View>
        <TouchableOpacity onPress={() => handleClosePress(notification._id)}>
          <AiOutlineCloseCircle name="close" style={styles.closeIcon} />
        </TouchableOpacity>
      </View>
      <View style={styles.sender}>
        <View style={styles.senderInfor}>
          <Image style={styles.notiContentAvatar} source={{ uri: notification.asset?.assetType?.icon }} />
          <Text
            style={[
              styles.textBold,
              {
                paddingLeft: 5,
              },
            ]}
          >
            {notification.asset?.name}
          </Text>
        </View>
        <Status status={notification.asset.currentStatus} />
      </View>
      <Text
        style={[
          styles.textRegular,
          {
            marginTop: 8,
            textAlign: 'center',
          },
        ]}
      >
        Needs repair & retest at:
      </Text>
      <View style={styles.inviteContent}>
        <Image style={styles.notiContentAvatar} source={{ uri: notification.project?.logo }} />
        <Text
          style={[
            {
              paddingLeft: 5,
            },
          ]}
        >
          {`${notification.asset.site.address.street}`}
        </Text>
      </View>
      <View style={styles.buttonsContainer}>
        <Button
          title={'View Asset'}
          buttonStyle={[styles.blueButton, { width: '100%' }]}
          textStyle={styles.blueButtonText}
          onPress={handleViewAssetPress}
        />
      </View>
    </View>
  );
}

export default RepairReminderNotification;
