import React, { useState } from 'react';
import { Image, Pressable, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { BsSearch } from 'react-icons/bs';
import { AiOutlineCloseCircle } from 'react-icons/ai';

import images from '../../../../../theme/images';
import { styles } from './style';

const Autocomplete = ({
  value: origValue,
  data,
  onChange: origOnChange,
  style = {},
  onPress = {},
  createOption = false,
  createOptionPress = null,
  onSelect = {},
}) => {
  const [value, setValue] = useState(origValue);
  const [menuVisible, setMenuVisible] = useState(false);
  const [resultField, setResultField] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const filterData = (text) => {
    return data.filter((val) => val?.title.toLowerCase()?.indexOf(text?.toLowerCase()) > -1);
  };
  return (
    <View>
      <View style={resultField ? {} : styles.searchField}>
        {resultField ? (
          <View style={styles.resultContent}>
            <Pressable
              onPress={() => {
                setMenuVisible(true);
                setResultField(false);
                setValue(value.title);
              }}
            >
              <View style={styles.resultTitle}>
                <Image style={styles.titleIcon} source={images.blueDrillIcon} />
                <Text style={styles.titleText}>{value.title}</Text>
              </View>
            </Pressable>
            <TouchableOpacity
              onPress={() => {
                setResultField(false);
              }}
            >
              <AiOutlineCloseCircle
                name="close"
                style={{
                  fontSize: 20,
                  color: '#979797',
                }}
              />
            </TouchableOpacity>
          </View>
        ) : (
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              paddingLeft: 11,
            }}
          >
            <BsSearch name="search1" style={styles.searchIcon} />
            <TextInput
              onFocus={() => {
                if (value.length === 0) {
                  setMenuVisible(true);
                }
              }}
              placeholder="Start typing name…"
              // onBlur={() => setMenuVisible(false)}
              onChangeText={(text) => {
                origOnChange(text);
                if (text && text.length > 0) {
                  setFilteredData(filterData(text));
                } else if (text && text.length === 0) {
                  setFilteredData(data);
                }
                setMenuVisible(true);
                setValue(text);
              }}
              style={{ paddingVertical: 10 }}
              value={value}
            />
          </View>
        )}
        {menuVisible && filteredData && (
          <View style={styles.recommendBox}>
            {filteredData.map((datum, i) => (
              <Pressable
                key={i}
                onPress={() => {
                  setValue(datum);
                  onSelect(datum);
                  setMenuVisible(false);
                  setResultField(true);
                }}
              >
                <View style={styles.recommendContent}>
                  <View style={styles.content}>
                    <Image style={styles.recommendIcon} source={images.blueDrillIcon} />
                    <Text
                      style={{
                        fontFamily: 'NunitoSans_700Bold',
                        paddingLeft: 8,
                      }}
                    >
                      {datum.title}
                    </Text>
                  </View>
                  <View style={styles.position}>
                    <Text
                      style={[
                        styles.textSemiBoldSmall,
                        {
                          fontSize: 11,
                          color: '#132FF5',
                        },
                      ]}
                    >
                      Electrician
                    </Text>
                  </View>
                </View>
              </Pressable>
            ))}
            {createOption && (
              <View style={styles.createOption}>
                <Pressable onPress={() => createOptionPress()}>
                  <Text style={styles.addReq}>+ {createOption}</Text>
                </Pressable>
              </View>
            )}
          </View>
        )}
      </View>
    </View>
  );
};

export default Autocomplete;
