import { StyleSheet } from 'react-native';
import colors from '../../theme/colors';
export const styles = StyleSheet.create({
  selectedTag: {
    borderWidth: 1,
    borderRadius: 16,
    borderColor: '#C7C7C7',
    paddingHorizontal: 16,
    paddingBottom: 16,
    marginTop: 19,
    overflow: 'hidden',
  },
  currentLabel: {
    backgroundColor: '#23b2441a',
    position: 'absolute',
    right: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 20,
    paddingVertical: 5,
    borderRadius: 4,
  },
  selectedContent: {
    paddingTop: 8,
  },
  selectedText: {
    fontSize: 18,
    paddingTop: 8,
    fontFamily: 'NunitoSans_700Bold',
  },
  textDetail: {
    paddingTop: 6,
    fontFamily: 'NunitoSans_400Regular',
  },

  labelText: {
    color: '#23B244',
    fontSize: 12,
    fontFamily: 'NunitoSans_700Bold',
  },
  tagInfor: {
    marginTop: 16,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  tagScript: {
    backgroundColor: '#F6F6F6',
    paddingHorizontal: 4,
    paddingVertical: 3,
  },
  textScript: {
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 12,
  },
  tagRecommend: {
    backgroundColor: 'rgba(19,47,245,0.1)',
    marginLeft: 4,
    paddingHorizontal: 8,
    paddingVertical: 5,
    position: 'absolute',
    bottom: 0,
    right: 0,
    borderRadius: 4,
  },
  tagWithFree: {
    backgroundColor: '#E9F7EC',
    marginLeft: 4,
    paddingHorizontal: 8,
    paddingVertical: 5,
    position: 'absolute',
    bottom: 0,
    right: 0,
    borderRadius: 4,
  },
  textRecommend: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 12,
    color: '#132FF5',
  },
  textWithFree: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 12,
    color: '#22B345',
  },
  selectedDot: {
    borderColor: '#979797',
    marginLeft: 16,
    paddingTop: 4,
    borderRadius: 32,
    borderWidth: 1,
    width: 28,
    height: 28,
    alignItems: 'center',
  },
  selected: {
    borderRadius: 32,
    width: 18,
    height: 18,
    backgroundColor: '#132FF5',
    alignItems: 'center',
  },
});
