import { Text, View } from 'react-native';
import { styles } from './style';

const Status = ({ ...props }) => {
  var color = '';
  var bgColor = '';
  switch (props.status) {
    case 'Untested':
      color = 'rgba(0,0,0,0.6)';
      bgColor = '#E9E8E8';
      break;
    case 'Pass':
      color = '#046736';
      bgColor = '#b7ead1';
      break;
    case 'Fail':
      color = '#FF4D4D';
      bgColor = '#FAD2D3';
      break;
    case 'Needs Repair':
      color = '#FF4D4D';
      bgColor = '#FAD2D3';
      break;
    case 'Disposed':
      color = '#FF4D4D';
      bgColor = '#FAD2D3';
      break;
    default:
      color = 'rgba(0,0,0,0.6)';
      bgColor = '#E9E8E8';
      break;
  }
  return (
    <View style={[styles.assetStatus, { backgroundColor: bgColor }]}>
      <Text
        style={[
          styles.assetStatusText,
          {
            fontSize: 16,
            color: color,
            fontFamily: 'NunitoSans_700Bold',
            marginRight: 4,
          },
        ]}
      >
        •
      </Text>
      <Text
        style={{
          fontSize: 12,
          color: color,
          fontFamily: 'NunitoSans_700Bold',
          marginRight: 4,
        }}
      >
        {props.status}
      </Text>
    </View>
  );
};

export default Status;
