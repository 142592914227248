import { Dimensions, StyleSheet, Platform } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F6F6F6',
  },
  appbarTrailingIcon: {
    alignSelf: 'flex-end',
    width: 24,
    height: 24,
    tintColor: 'black',
    marginRight: 12,
  },
  appbarTitleContainer: {
    position: 'absolute',
    alignSelf: 'center',
    bottom: -20,
    paddingHorizontal: 16,
    height: 34,
    backgroundColor: 'white',
    borderRadius: 17,
    shadowColor: 'black',
    shadowOpacity: 0.05,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    // zIndex:9999
  },
  appbarTitleIconContainer: {
    borderRadius: 12,
    width: 24,
    height: 24,
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentBlock: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D0D0D0',
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 6,
    paddingHorizontal: 8,
    marginTop: 10,
  },
  appbarTitle: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  appbarTitleText: {
    fontSize: 14,
    fontFamily: 'NunitoSans_600SemiBold',
    marginLeft: 6,
  },
  blueButton: {
    borderRadius: 8,
    backgroundColor: '#132FF5',
    paddingVertical: 5,
    paddingHorizontal: 32,
  },
  blueButtonText: {
    color: 'white',
    fontFamily: 'NunitoSans_700Bold',
    textAlign: 'center',
  },
  wrapper: {
    marginTop: Platform.OS === 'ios' ? 0 : 30,
    marginHorizontal: 23,
    paddingBottom: 26,
  },
  pendingList: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  menu: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  taskNumber: {
    textAlign: 'center',
    fontFamily: 'NunitoSans_400Regular',
    fontSize: 12,
    paddingTop: 3,
  },
  completed: {
    backgroundColor: 'white',
    paddingVertical: 25,
    paddingHorizontal: 23,
    zIndex: -9999,
  },
  textBold: {
    fontFamily: 'NunitoSans_700Bold',
  },
  textBoldSmall: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 12,
  },
  textLightSmall: {
    fontFamily: 'NunitoSans_300Light',
    fontSize: 12,
  },
  showMore: {
    fontFamily: 'NunitoSans_700Bold',
    marginTop: 32,
    textAlign: 'center',
  },
  completeTickIcon: {
    width: 16,
    height: 16,
    marginRight: 4,
  },
  taskIcon: {
    width: 43,
    height: 43,
  },
  leadIcon: {
    fontSize: 14,
    paddingLeft: 6,
  },
  taskTitle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  taskDetail: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});
