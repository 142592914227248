import * as DocumentPicker from 'expo-document-picker';
import React, { useEffect, useState, useRef } from 'react';
import { Image, Platform, Pressable, SafeAreaView, ScrollView, Text, TouchableOpacity, View } from 'react-native';
import { AiOutlineFilePdf, AiOutlineCloseCircle, AiOutlineCloudUpload } from 'react-icons/ai';

import Button from '../../../../../components/Button';
import NavigationHeader from '../../../../../components/SignUp/SingUpNavigation';
import images from '../../../../../theme/images';
import { styles } from './style';
import UploadField from './uploadField';
export default function TestingHistory({ ...props }) {
  const params = props.route?.params;

  console.log('test history images: ', images);

  const [document, setDocument] = useState(null);
  const [selectedImages, setImages] = useState([]);
  const [buttonDisable, updateButtonDisable] = useState(true);
  const pickDocument = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      allowsEditing: true,
      quality: 1,
    });

    if (!result.cancelled) {
      console.log('image seclect result: ', result);
      setDocument(result);
      checkValid(result.name, []);
    }
  };

  const updateImages = (images) => {
    console.log('images: ', images);
    setImages(images);
    setDocument(null);
    checkValid(null, images);
  };

  const checkValid = (document, selectedImages) => {
    if (document !== undefined && selectedImages !== undefined) {
      if (document !== null || selectedImages.length > 0) {
        console.log('one value is set');
        updateButtonDisable(false);
      } else {
        updateButtonDisable(true);
      }
    } else {
      updateButtonDisable(true);
    }
  };

  const fileImageRef = useRef(null);

  const handleImageSelect = () => {
    fileImageRef.current.click();
  };

  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      // Handle the selected file here
      console.log('Selected File:', selectedFile);
      setImages([selectedFile]);
      checkValid(null, [selectedFile.name]);
    }
  };

  const fileDocumentRef = useRef(null);

  const handleFileSelect = () => {
    fileDocumentRef.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      // Handle the selected file here
      console.log('Selected File:', selectedFile);
      setDocument(selectedFile);
      checkValid(selectedFile.name, []);
    }
  };

  return (
    <View style={styles.container}>
      <SafeAreaView
        style={{
          flex: 1,
        }}
      >
        <ScrollView>
          <View
            style={
              Platform.OS === 'ios'
                ? [
                    styles.loading,
                    {
                      marginTop: 0,
                    },
                  ]
                : styles.loading
            }
          >
            <View style={styles.loadPercent}></View>
          </View>
          <NavigationHeader
            onPress={() => props.navigation.goBack()}
            header={'Add Asset'}
            navigate={'Cancel'}
            menuStyles={styles.menu}
          />
          <View style={styles.body}>
            <View>
              <Text style={styles.title}>Testing history </Text>
              <Text style={styles.textDetail}>Please upload past testing history as a photo or PDF. </Text>
              <Text
                style={[
                  styles.title,
                  {
                    marginTop: 40,
                    fontSize: 16,
                  },
                ]}
              >
                Upload a photo
              </Text>
              {/* <UploadField selectedImages={selectedImages} setImages={(array) => updateImages(array)} /> */}
              <button onClick={handleImageSelect}>Select Image</button>
              <input ref={fileImageRef} type="file" style={{ display: 'none' }} onChange={handleImageChange} />
              <Text
                style={[
                  styles.title,
                  {
                    marginTop: 32,
                    fontSize: 16,
                  },
                ]}
              >
                Upload a photo or PDF
              </Text>
              <View style={styles.pickImage}>
                <button onClick={handleFileSelect}>Select File</button>
                <input ref={fileDocumentRef} type="file" style={{ display: 'none' }} onChange={handleFileChange} />
                {document && (
                  <View style={styles.imgPickedContainer}>
                    <View style={styles.documentPicked}>
                      <View style={styles.docTitle}>
                        <AiOutlineFilePdf name="file-pdf-o" style={styles.docIcon} />
                        <Text style={styles.titleText}>{document.name}</Text>
                      </View>
                      <TouchableOpacity onPress={() => ''}>
                        <Image
                          style={{
                            width: 28,
                            height: 28,
                            marginRight: 8,
                            // tintColor:"white"
                          }}
                          source={images.lightGreyDownload}
                        />
                      </TouchableOpacity>
                    </View>
                    <AiOutlineCloseCircle name="close" style={styles.closeIcon} onPress={() => setDocument(null)} />
                  </View>
                )}
              </View>
            </View>
            <View style={styles.assignButton}>
              <Button
                title={'Next'}
                buttonStyle={buttonDisable ? styles.blueButton : styles.blueButtonActive}
                textStyle={styles.blueButtonText}
                onPress={() =>
                  props.navigation.navigate('ExtraInformation', {
                    ...params,
                    historyDocument: document,
                    historyImages: selectedImages,
                  })
                }
                disabled={buttonDisable}
              />
              <Button
                title={'Previous'}
                buttonStyle={[styles.blankButton, { marginBottom: 20 }]}
                textStyle={styles.blankButtonText}
                onPress={() => props.navigation.goBack()}
              />
            </View>
          </View>
        </ScrollView>
      </SafeAreaView>
    </View>
  );
}
