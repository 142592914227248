import React from 'react';
import { Text, View, Modal, Image } from 'react-native';
import Button from '../../../../components/Button';
import { styles } from './style';
import images from '../../../../theme/images';

const ConfirmModal = ({ ...props }) => {
  const { visible, setVisible, title, details, submitButtonText, handleSubmit } = props;

  return (
    <Modal animationType="fade" transparent={true} visible={visible}>
      <View style={styles.ModalContainer}>
        <View style={styles.popupWindow}>
          <View style={styles.popupHeader}>
            <Image style={styles.confirmationTileIcon} source={images.confirmationTileIcon} />
            <Text
              style={[
                styles.textBoldLarge,
                {
                  marginTop: 25,
                },
              ]}
            >
              {title}
            </Text>
            <View style={{ maxWidth: 238 }}>
              <Text style={styles.script}>{details}</Text>
            </View>
          </View>
          <Button
            title={submitButtonText}
            buttonStyle={[styles.blueButtonActive, { marginHorizontal: 10 }]}
            textStyle={styles.blueButtonText}
            onPress={() => {
              setVisible(false);
              handleSubmit();
            }}
          />
          <Button
            title={'Cancel'}
            buttonStyle={[
              styles.blueBorderButton,
              {
                marginTop: 8,
                marginHorizontal: 10,
              },
            ]}
            textStyle={styles.blueBorderButtonText}
            onPress={() => {
              setVisible(false);
            }}
          />
        </View>
      </View>
    </Modal>
  );
};

export default ConfirmModal;
