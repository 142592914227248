import React, { useState, useEffect } from 'react';
import { Text, View, SafeAreaView, Image, ScrollView, TouchableOpacity } from 'react-native';
import { styles } from './style';
import NavigationHeader from '../../../../components/SignUp/SingUpNavigation';
import { RadioButton } from 'react-native-paper';
import images from '../../../../theme/images';
import Button from '../../../../components/Button';
import Tag from '../../../../components/Tag';
import { api } from '../../../../utils/api';
import { useIsFocused } from '@react-navigation/native';
import Loading from '../../../../components/Loading';
import AsyncStorage from '@react-native-async-storage/async-storage';

function SubscriptionSelection({ ...props }) {
  //TODO load API
  const [fields, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedSub, setSelectedSubscription] = useState(null);

  const [listTag, setListTag] = useState([]);
  const handlePress = (value) => {
    const newState = listTag.map((item, index) => {
      if (value == item.value) {
        setSelectedSubscription(item.value);
        //     return { ...item, onClick: true };
      }
      //   return { ...item, onClick: false };
    });
    // setListTag(newState);
  };

  const getSubscriptions = async () => {
    const subs = await api('v1/subscription', 'GET', null);
    if (subs.status == 200) {
      const result = subs.data;
      if (result.success) {
        console.log('subscriptions: ', result.subscriptions);
        setListTag(result.subscriptions);
        setLoading(false);
      }
    }
  };

  const isFocused = useIsFocused();
  useState(() => {
    if (isFocused) {
      getSubscriptions();
    }
  }, [isFocused]);

  const taglist = listTag?.map((item, index) => (
    <Tag
      key={index}
      title={item.title}
      detail={item.detail}
      price={item.price}
      recommend={item.recommend}
      monthsFree={item.monthsFree}
      onClick={item.onClick}
      value={item.value}
      options={item.options}
      selectedSub={selectedSub}
      onPress={() => handlePress(item.value)}
      freeTags={item.freeTags}
    />
  ));

  if (loading) {
    return <Loading opacity={0} />;
  }

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView>
        <View style={styles.loading}>
          <View style={styles.loadPercent}></View>
        </View>
        <NavigationHeader
          onPress={async () => {
            await AsyncStorage.multiRemove(['user', 'token', 'refreshToken', 'expiresIn']);
            props.navigation.navigate('GuestNavigation', { screen: 'QRCodeScan' });
          }}
          header={'Sign Up'}
          navigate={'Cancel'}
          menuStyles={styles.menu}
        />
        <View
          style={{
            marginHorizontal: 62,
            alignItems: 'center',
          }}
        >
          <Text style={styles.title}>Great, let's get it moving</Text>
          <Text style={styles.script}>Please select a subscription package.</Text>
        </View>
        <View
          style={{
            marginHorizontal: 37,
          }}
        >
          {taglist}
        </View>
        <View style={styles.assignButton}>
          <Button
            title={'Next'}
            buttonStyle={selectedSub ? styles.blueButtonActive : styles.blueButton}
            textStyle={styles.blueButtonText}
            onPress={() => {
              if (selectedSub) {
                const subObject = listTag.find((sub) => sub.value === selectedSub);
                if (props.route.params.userType == 'PM') {
                  props.navigation.navigate('SettingUpProjectStep4', {
                    userImage: props.route.params.userImage,
                    userType: props.route.params.userType,
                    userLicence: props.route.params.userLicence,
                    appUse: props.route.params.appUse,
                    subscription: subObject,
                    registerForPushNotificationsAsync: props.route.params.registerForPushNotificationsAsync,
                  });
                } else {
                  props.navigation.navigate('SubscriptionCheckout', {
                    userImage: props.route.params.userImage,
                    userType: props.route.params.userType,
                    userLicence: props.route.params.userLicence,
                    appUse: props.route.params.appUse,
                    subscription: subObject,
                    registerForPushNotificationsAsync: props.route.params.registerForPushNotificationsAsync,
                  });
                }
              } else {
                null;
              }
            }}
          />
          <Button
            title={'Previous'}
            buttonStyle={[styles.blankButton, { marginBottom: 20 }]}
            textStyle={styles.blankButtonText}
            onPress={() => props.navigation.goBack()}
          />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}
export default SubscriptionSelection;
