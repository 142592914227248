import React, { useState, useEffect } from 'react';
import { useIsFocused } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { api } from '../../../../utils/api';
import { styles } from './style';
import GoogleMapWithMarker from '../../../../components/GoogleMapWithMarker';
import Loading from '../../../../components/Loading';
import * as Location from 'expo-location';

export default function TaskMap({ ...props }) {
  const { setSelectedProject } = props;
  const [loading, setLoading] = useState(true);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [markedProjects, setMarkedProject] = useState([]);

  const getMarkedProjects = async () => {
    const response = await api(`v1/task/map/marked`, 'GET', null);

    if (response.status === 200 && response.data.success) {
      const newMarkedProjectsObj = response.data.projects;
      console.log(response.data);
      setMarkedProject(Object.values(newMarkedProjectsObj));
    }

    setLoading(false);
  };

  const getCurrentLocation = async () => {
    let { status } = await Location.requestForegroundPermissionsAsync();
    if (status !== 'granted') {
      setErrorMsg('Permission to access location was denied');
      return;
    }

    let location = await Location.getCurrentPositionAsync({});
    setCurrentLocation({
      latitude: location.coords.latitude,
      longitude: location.coords.longitude,
      latitudeDelta: 0.2,
      longitudeDelta: 0.2,
    });

    getMarkedProjects();
  };

  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      if (currentLocation == null) {
        getCurrentLocation();
      }
    }
  }, [isFocused]);

  if (loading) {
    return <Loading opacity={0} />;
  }

  return (
    // <MapView style={styles.assetMap} initialRegion={currentLocation}>
    //   {markedProjects.map((project, index) => (
    //     <Marker
    //       key={index}
    //       coordinate={{
    //         latitude: project.address.lat,
    //         longitude: project.address.lng,
    //       }}
    //       onPress={() => setSelectedProject(project._id)}
    //     />
    //   ))}
    // </MapView>
    <GoogleMapWithMarker
      latitude={currentLocation.latitude}
      longitude={currentLocation.longitude}
      markers={markedProjects}
    />
  );
}
