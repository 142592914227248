import React from 'react';
import {Text, View, Pressable, Image, TouchableOpacity } from 'react-native';
import {styles} from './style';
import images from '../../../theme/images';

const NavigationHeader = ({...props}) => {
  return (
    <View style={props.menuStyles}>
      <TouchableOpacity onPress={props.onPress}>
        <Text style={{fontFamily:"NunitoSans_700Bold"}}>{props.navigate}</Text>
      </TouchableOpacity>
      <Text
        style={[styles.textBold,
        {
          color:"#6B6B6B"
        }
      ]}>{props.header}</Text>
      <Image
        style={styles.appbarTrailingIcon}
        source={images.logoIconOnlyWhite}
      />
    </View>
  )
}
export default NavigationHeader

