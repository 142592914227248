import React, { useState, useRef, useEffect } from 'react';
import { Image, Pressable, Text, View, TextInput } from 'react-native';
import { styles } from './style';
import { FiChevronUp, FiChevronDown, FiCamera } from 'react-icons/fi';
import RBSheet from 'react-native-raw-bottom-sheet';
import Pass from '../statusPass';
import Fail from '../statusFail';
import Accordion from 'react-native-collapsible/Accordion';
import ModalSelect from '../../../../../components/ModalSelect';
import { useIsFocused } from '@react-navigation/native';

function Selected({ ...props }) {
  const { id, title, required } = props;
  const refRBSheet = useRef();
  const [isValidate, setIsValidate] = useState({ id: null, state: 0 });
  const [isShow, setIsShow] = useState(false);
  const [activeSections, setActiveSections] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [text, onChangeText] = useState('');
  const SECTIONS = [{}];
  const [image, setImage] = useState(null);
  const [displayModal, setDisplayModal] = useState(false);
  const setSelectedValue = props?.setSelectedValue;
  const setSelectedDetails = props?.setSelectedDetails;
  const isFocused = useIsFocused();

  const setDetailsText = (text) => {
    onChangeText(text);
    setSelectedDetails(id, 'info', text);
  };

  // const pickImage = async () => {
  //   // No permissions request is necessary for launching the image library
  //   let result = await ImagePicker.launchImageLibraryAsync({
  //     mediaTypes: ImagePicker.MediaTypeOptions.All,
  //     allowsEditing: true,
  //     aspect: [4, 3],
  //     quality: 1,
  //   });

  //   console.log("result", result);

  //   if (!result.cancelled) {
  //     setImage(result.uri);
  //     setSelectedDetails(id, "img", result);
  //   }
  // };
  const imageSelectRef = useRef(null);

  const openOption = async (value) => {
    // if (value.toLowerCase() == 'camera') {
    //   // let permissions = await ImagePicker.getCameraPermissionsAsync();
    //   // if (permissions.status == 'undetermined') {
    //   //   permissions = await ImagePicker.requestCameraPermissionsAsync();
    //   // }

    //   // if (permissions.granted) {
    //   //   setTimeout(async () => {
    //   //     let result = await ImagePicker.launchCameraAsync({
    //   //       mediaTypes: 'Images',
    //   //       quality: 0.7,
    //   //     });

    //   //     setSelectedDetails(id, 'img', result.assets[0]);
    //   //     setImage(result.assets[0]);
    //   //   }, 500);
    //   // }
    // } else if (value.toLowerCase() == 'gallery') {
    // //   let permissions = await ImagePicker.getMediaLibraryPermissionsAsync();
    // //   if (permissions.status == 'undetermined') {
    // //     permissions = await ImagePicker.requestMediaLibraryPermissionsAsync();
    // //   }

    // //   console.log(permissions);
    // //   if (permissions.granted) {
    // //     setTimeout(async () => {
    // //       let result = await ImagePicker.launchImageLibraryAsync({
    // //         mediaTypes: 'Images',
    // //         quality: 0.7,
    // //       });
    // //       console.log(result.assets[0]);
    // //       setSelectedDetails(id, 'img', result.assets[0]);
    // //       setImage(result.assets[0]);
    // //     }, 500);
    // //   }
    // }
    imageSelectRef.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      // Handle the selected file here
      console.log('Selected File:', selectedFile);
      setSelectedDetails(id, 'img', selectedFile);
      setImage(selectedFile);
    }
  };
  const selected = () => {
    switch (isValidate.state) {
      case 0:
        return (
          <Text
            style={{
              fontFamily: 'NunitoSans_400Regular',
              fontSize: 16,
              opacity: 0.5,
            }}
          >
            Make a selection
          </Text>
        );
      case 1:
        return <Pass />;
      case 2:
        return <Fail />;
      default:
        return (
          <Text
            style={{
              fontFamily: 'NunitoSans_400Regular',
              fontSize: 16,
              opacity: 0.5,
            }}
          >
            Make a selection
          </Text>
        );
    }
  };
  const renderHeader = (section) => {
    return (
      <View style={styles.accordionHeader}>
        <Text
          style={[
            styles.textSemiBold,
            {
              fontSize: 16,
            },
          ]}
        >
          Details
        </Text>
        {isActive ? (
          <FiChevronDown name="down" style={styles.accordionIcon} />
        ) : (
          <FiChevronUp name="up" style={styles.accordionIcon} />
        )}
      </View>
    );
  };

  const renderContent = (section) => {
    return (
      <View>
        <TextInput
          placeholder="E.g XXXX XXXX XX"
          style={styles.input}
          onChangeText={(text) => setDetailsText(text)}
          value={text}
          onSubmitEditing={() => ''}
        />
        <Pressable onPress={() => setDisplayModal(true)}>
          {image ? (
            (console.log(image),
            (
              <View style={styles.selecteImage}>
                {/* <Image source={{ uri: image.uri }} style={styles.image} /> */}
                <img src={URL.createObjectURL(image)} style={styles.image} />
              </View>
            ))
          ) : (
            <View style={styles.imgPicker}>
              <View style={styles.dasherBorder}>
                <FiCamera name="camera" style={styles.imgIcon} />
                <Text style={styles.imgPicker}>Add a photo</Text>
              </View>
            </View>
          )}
        </Pressable>
      </View>
    );
  };
  const updateSections = (activeSections) => {
    setActiveSections(activeSections);
    setIsActive(!isActive);
  };

  useEffect(() => {
    if (isFocused) {
      setIsValidate({ id: id, state: 0 });
      setIsShow(false);
      setSelectedValue(id, '');
    }
  }, [isFocused]);

  return (
    <View
      style={{
        paddingTop: 22,
      }}
    >
      <View
        style={
          required
            ? {
                flexDirection: 'row',
              }
            : {
                flexDirection: 'row',
                alignItems: 'center',
              }
        }
      >
        <Text style={styles.textBoldMedium}>{title}</Text>
        {required ? (
          <Text
            style={[
              styles.textBoldMedium,
              {
                color: '#FF4D4D',
              },
            ]}
          >
            *
          </Text>
        ) : (
          <Text
            style={{
              fontFamily: 'NunitoSans_400Regular',
              opacity: 0.5,
              paddingLeft: 3,
            }}
          >
            - If applicable
          </Text>
        )}
      </View>
      <Pressable
        onPress={() => {
          refRBSheet.current.open();
        }}
      >
        <View style={styles.content}>
          {selected()}
          <FiChevronDown
            name="down"
            style={{
              fontSize: 14,
              paddingLeft: 6,
            }}
          />
        </View>
      </Pressable>
      {isShow ? (
        <Accordion
          sections={SECTIONS}
          sectionContainerStyle={styles.collapse}
          activeSections={activeSections}
          renderHeader={renderHeader}
          renderContent={renderContent}
          onChange={updateSections}
          underlayColor="transparent"
        />
      ) : (
        <></>
      )}
      <RBSheet
        ref={refRBSheet}
        closeOnDragDown={true}
        closeOnPressMask={true}
        height={150}
        openDuration={250}
        customStyles={{
          wrapper: {
            backgroundColor: 'rgba(0,0,0,0.3)',
          },
          container: {
            borderTopRightRadius: 8,
            borderTopLeftRadius: 8,
          },
        }}
      >
        <View
          style={{
            alignItems: 'center',
          }}
        >
          <View style={styles.validate}>
            <Pressable
              onPress={() => {
                setIsValidate({ id: id, state: 1 });
                setIsShow(true);
                setSelectedValue(id, 'Pass');
                refRBSheet.current.close();
              }}
              style={{
                width: '100%',
                alignItems: 'center',
              }}
            >
              <Text
                style={[
                  styles.textBoldLarge,
                  {
                    paddingVertical: 10,
                  },
                ]}
              >
                Pass
              </Text>
            </Pressable>
          </View>
          <View>
            <Pressable
              onPress={() => {
                setIsValidate({ id: id, state: 2 });
                setIsShow(true);
                setSelectedValue(id, 'Fail');
                refRBSheet.current.close();
              }}
              style={styles.pressableWidth}
            >
              <Text
                style={[
                  styles.textBoldLarge,
                  {
                    paddingVertical: 20,
                  },
                ]}
              >
                Fail
              </Text>
            </Pressable>
          </View>
        </View>
      </RBSheet>
      <input ref={imageSelectRef} type="file" style={{ display: 'none' }} onChange={handleFileChange} />

      <ModalSelect
        modalVisible={displayModal}
        setVisible={setDisplayModal}
        setValue={openOption}
        heading="Select Image"
        data={['Upload and Image']}
      />
    </View>
  );
}
export default Selected;
