import React, { useEffect, useState } from 'react';
import { Image, ImageBackground, Text, View, SafeAreaView, ActivityIndicator } from 'react-native';
import Button from '../../../components/Button';
import images from '../../../theme/images';
import { styles } from './style';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { api } from '../../../utils/api';
import { useIsFocused } from '@react-navigation/native';

export default function WelcomeScreen({ ...props }) {
  const [user, setUser] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  let isFocused = useIsFocused();

  const getUser = async () => {
    let user = await AsyncStorage.getItem('user');
    user = JSON.parse(user);
    setUser(user);
    setUserDetails(props.route.params);
  };

  const completeRegister = async (details) => {
    let user = await AsyncStorage.getItem('user');
    user = JSON.parse(user);

    let formData = new FormData();
    formData.append('userType', details.appUse);
    formData.append('subscription', details.subscription);
    formData.append('licenseNumber', details.userLicence);
    formData.append('role', details.userType);
    formData.append('active', true);

    console.log('details: ', details);

    if (details.userImage.type == 'string') {
      formData.append('profilePicture', JSON.stringify(details.userImage));
    } else {
      formData.append('profilePicture', {
        type: details.userImage.type,
        uri: details.userImage.uri,
        name: details.userImage.name,
      });
    }

    let result = await api(`v1/user/${user._id}`, 'PUT', formData, true);
    if (result.status == 200) {
      if (result.data.success) {
        await AsyncStorage.setItem('user', JSON.stringify(result.data.user));
        console.log('user role: ', result.data.user.role);
        console.log('props.route : ', props.route);
        if (props.route.params.registerForPushNotificationsAsync !== null) {
          props.route.params.registerForPushNotificationsAsync();
        }
        if (result.data.user.role == 'PM' || result.data.user.role == 'OHS') {
          props.navigation.navigate('TabNavigator', {
            screen: 'Assets',
            isAdmin: true,
            params: {
              screen: 'AdminAssetRegistry',
              fromQRCodeNav: true,
            },
          });
        } else {
          props.navigation.navigate('TabNavigator', { screen: 'QrCodeNav', isAdmin: false });
        }
      }
    }
  };

  useEffect(() => {
    if (isFocused) {
      if (user == null) {
        getUser();
      }
    }
  });

  if (user == null) {
    return <ActivityIndicator />;
  }

  return (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: 'white',
      }}
    >
      <View style={styles.loading}>
        <View style={styles.loadPercent}></View>
      </View>
      <Text style={styles.signUpText}>Sign Up</Text>
      <View style={styles.container}>
        <ImageBackground source={images.loginBG} style={styles.welcomeBG}>
          <View style={styles.loginLogoText}>
            <Image source={images.clkTagLogo} style={styles.oneTagLogo} />
            <Text style={styles.welcomeText}>Welcome to ClkTag,{user.firstName}!</Text>
          </View>
        </ImageBackground>
        <View style={styles.buttonColumn}>
          <Button
            title={'Get Started!'}
            buttonStyle={[styles.blueButton, { marginBottom: 10 }]}
            textStyle={styles.blueButtonText}
            onPress={() => completeRegister(userDetails)}
          />
        </View>
      </View>
    </SafeAreaView>
  );
}
