import React, { useEffect, useState } from 'react';
import { ScrollView, SafeAreaView, Pressable, Text, View, TextInput } from 'react-native';
import NavigationHeader from '../../../../../components/SignUp/SingUpNavigation';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { BsFillPencilFill } from 'react-icons/bs';
import { styles } from './style';
import { TouchableOpacity } from 'react-native';
import { Image } from 'react-native';
import images from '../../../../../theme/images';
import PopUpModal from './popUpModal';
import { TextInputMask } from 'react-native-masked-text';
import Button from '../../../../../components/Button';
import { Calendar } from 'react-native-calendars';
import { useIsFocused } from '@react-navigation/native';
import DropInputField from '../../../../../components/DropInputField';
import moment from 'moment';
import { api } from '../../../../../utils/api';
import CustomArrow from '../../../../../components/CustomArrow';

export default function TestingSchedule({ ...props }) {
  const params = props.route?.params;
  console.log('params', params);
  const [selectedDropdownItem, setSelectedDropdownItem] = useState('Select');
  const [selectedCandenceItem, setSelectedCandenceItem] = useState('Select');
  const [pickedDate, setPickedDate] = useState('');
  const [nextTestDate, setNextTestDate] = useState('');
  const [openCalendar, setCalendarView] = useState(false);
  const periodTesting = ['Monthly', 'Quarterly', 'Biannually', 'Annually'];
  const vertify = ['Pass', 'Fail'];
  // const [text, onChangeText] = useState("");
  // const [showCalendar, setShowCalendar] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [projectUsers, setProjectUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');

  const handleDropdownPicker = (item) => {
    setSelectedDropdownItem(item);
    setOpen(false);
  };

  const getProjectUsers = async () => {
    const projectId = props.route.params.assetInfo.assetProject.id;

    const response = await api(`v1/project/${projectId}/team?includeMe=true`, 'GET', null);

    if (response.status === 200 && response.data.success) {
      const team = response.data.team;
      const tempProjectUsers = [];
      for (const user of team) {
        tempProjectUsers.push({
          title: user.firstName + ' ' + user.lastName,
          id: user._id,
          position: user.role,
        });
      }
      setProjectUsers(tempProjectUsers);
    }
  };

  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      getProjectUsers();
      console.log('test cadence params: ', params);
      setSelectedCandenceItem(params.assetInfo.assetSite.testCadence.type.title);
      switch (params.assetInfo.assetSite.testCadence.type.title) {
        case 'Monthly':
          var date = moment('01', 'DD').add(1, 'months').format('DD/MM/YYYY');
          setNextTestDate(date);
          break;
        case 'Quarterly':
          var quarter = moment().quarter() + 1;
          var date = moment('01', 'DD').quarter(quarter).format('DD/MM/YYYY');
          setNextTestDate(date);
          break;
        case 'Biannually':
          var currentDate = moment().isAfter(moment('06-01', 'MM-DD'));
          console.log(currentDate);
          var date = '';
          if (currentDate) {
            date = moment('01-01', 'MM-DD').add(1, 'years').format('DD/MM/YYYY');
          } else {
            date = moment('06-01', 'MM-DD').format('DD/MM/YYYY');
          }
          setNextTestDate(date);
          break;
        case 'Annually':
          var date = moment(params.assetInfo.assetSite.testCadence.startDate, 'YYYY-MM-DD')
            .add(1, 'years')
            .format('DD/MM/YYYY');
          setNextTestDate(date);
          break;
        default:
          break;
      }
    }
  }, [isFocused]);

  const popUpModal = (
    <PopUpModal
      visible={modalVisible}
      onRequestClose={() => setModalVisible(false)}
      selectedCandenceItem={selectedCandenceItem}
      setSelectedCandenceItem={setSelectedCandenceItem}
      pickedDate={nextTestDate}
      setPickedDate={setNextTestDate}
    />
  );
  return (
    <View style={styles.container}>
      <SafeAreaView
        style={{
          flex: 1,
        }}
      >
        {popUpModal}
        <ScrollView>
          <View style={styles.loading}>
            <View style={styles.loadPercent}></View>
          </View>
          <NavigationHeader
            onPress={() => props.navigation.goBack()}
            header={'Add Asset'}
            navigate={'Cancel'}
            menuStyles={styles.menu}
          />
          <View style={styles.body}>
            <View>
              <Text style={styles.title}>Testing schedule</Text>
              <View style={styles.detail}>
                <Text style={styles.textDetail}>Last Tested</Text>
              </View>
              <View style={styles.dateField}>
                <View style={{ width: '50%' }}>
                  <TouchableOpacity onPress={() => setCalendarView(true)}>
                    <View style={styles.input}>
                      <Text style={pickedDate ? styles.dateData : [styles.dateData, { opacity: 0.5 }]}>
                        {pickedDate ? pickedDate : 'DD/MM/YYYY'}
                      </Text>
                    </View>
                  </TouchableOpacity>
                </View>
                <View
                  style={{
                    width: '50%',
                    paddingLeft: 16,
                  }}
                >
                  <Pressable onPress={() => setOpen(!open)}>
                    <View style={styles.inputDrop}>
                      <View style={styles.dropTitleContainer}>
                        <Text style={selectedDropdownItem == 'Select' ? styles.unpickedTitle : styles.dropList}>
                          {selectedDropdownItem}
                        </Text>
                        {open ? (
                          <FiChevronUp name="angle-up" style={styles.imgIcon} />
                        ) : (
                          <FiChevronDown name="angle-down" style={styles.imgIcon} />
                        )}
                      </View>
                      {open &&
                        vertify.map((item, index) => {
                          return (
                            <Pressable key={index} onPress={() => handleDropdownPicker(item)}>
                              <View style={index !== periodTesting.length ? styles.dropItem : styles.dropLastItem}>
                                <Text style={styles.dropList}>{item}</Text>
                              </View>
                            </Pressable>
                          );
                        })}
                    </View>
                  </Pressable>
                </View>
              </View>
              <View style={{ width: '100%' }}>
                {openCalendar && (
                  <Calendar
                    renderArrow={(direction, onPress) => <CustomArrow direction={direction} onPress={onPress} />}
                    hideArrows={false}
                    markingType={'multi-dot'}
                    // markedDates={marked}
                    enableSwipeMonths={true}
                    theme={{
                      arrowColor: '#0F0000',
                    }}
                    // customHeader={(day)=>{
                    //   return(<Text>{selected}</Text>)
                    // }}
                    onDayPress={(day) => {
                      // let dateFormat1 = day.dateString.format('D-MM-YYYY');
                      // console.log(typeof new Date(day.dateString));
                      setPickedDate(day.day + '/' + day.month + '/' + day.year);
                      setCalendarView(!openCalendar);
                    }}
                  />
                )}
              </View>
              <View style={styles.description}>
                <Text style={styles.dropList}>Test Cadence </Text>
                <TouchableOpacity onPress={() => setModalVisible(true)}>
                  <BsFillPencilFill name="pen" style={{ fontSize: 13 }}></BsFillPencilFill>
                </TouchableOpacity>
              </View>
              <View style={styles.testCandence}>
                <View style={styles.candenceTitle}>
                  <Image style={styles.candidateIcon} source={{ uri: params.assetInfo.assetProject.image }} />
                  <Text style={[styles.dropList, { paddingLeft: 8 }]}>{params.assetInfo.assetProject.name}</Text>
                </View>
                <View style={styles.candenceContent}>
                  <Text style={styles.candenceScript}>Site Default:</Text>
                  <Text style={styles.candenceScript}>{selectedCandenceItem}</Text>
                </View>
                <View style={styles.candenceContent}>
                  <Text style={styles.candenceScript}>Next Test due:</Text>
                  <Text style={styles.candenceScript}>{nextTestDate}</Text>
                </View>
              </View>
              <View style={styles.detail}>
                <Text style={styles.textDetail}>Assignee</Text>
                <Text style={styles.required}> *</Text>
              </View>
              <DropInputField
                data={projectUsers}
                selectedDropdownItem={selectedUser}
                setSelectedDropdownItem={setSelectedUser}
                havePosition={true}
              />
            </View>
            <View style={styles.assignButton}>
              <Button
                title={'Next'}
                buttonStyle={nextTestDate && selectedUser ? styles.blueButtonActive : styles.blueButton}
                textStyle={styles.blueButtonText}
                onPress={() => {
                  if (nextTestDate && selectedUser) {
                    props.navigation.navigate(pickedDate !== '' ? 'TestingHistory' : 'ExtraInformation', {
                      assetInfo: params.assetInfo,
                      qrCode: params.qrCode,
                      lastTested: {
                        date: pickedDate,
                        status: selectedDropdownItem,
                      },
                      testCandence: {
                        nextTestDate: nextTestDate,
                        cadence: selectedCandenceItem,
                      },
                      assignee: selectedUser.id,
                    });
                  }
                }}
                disabled={nextTestDate ? false : true}
              />
              <Button
                title={'Previous'}
                buttonStyle={styles.blankButton}
                textStyle={styles.blankButtonText}
                onPress={() => props.navigation.goBack()}
              />
            </View>
          </View>
        </ScrollView>
      </SafeAreaView>
    </View>
  );
}
