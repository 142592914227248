import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Image, Pressable, ScrollView, Text, View, TouchableOpacity } from 'react-native';
import { BiSolidPlusCircle } from 'react-icons/bi';
import { AiOutlineUnorderedList } from 'react-icons/ai';
import SearchScreen from '../../../../components/Search';
import images from '../../../../theme/images';
import AccordionBlock from './accordionBlock';
import { styles } from './style';
import { api } from '../../../../utils/api';
import { useIsFocused } from '@react-navigation/native';
import Loading from '../../../../components/Loading';
import AsyncStorage from '@react-native-async-storage/async-storage';

export default function TaskListAll({ ...props }) {
  const [viewOptions, setViewOptions] = useState('all');
  const [allTaskGroups, setAllTaskGroups] = useState([]);
  const [allTasksCount, setAllTasksCount] = useState(0);
  const [allTasksLoading, setAllTasksLoading] = useState(true);

  const [myTaskGroups, setMyTaskGroups] = useState([]);
  const [myTasksCount, setMyTasksCount] = useState(0);
  const [myTasksLoading, setMyTasksLoading] = useState(true);

  const [hasWriteAccess, setHasWriteAccess] = useState(false);

  const updateAccordionData = () => {
    setAllTasksLoading(true);
    setMyTasksLoading(true);
    getTaskGroups();
    getTaskGroups(false);
  };

  const allTaskGroupsAccordion = allTaskGroups.map((item, index) => {
    return (
      <View key={index}>
        <AccordionBlock
          {...item}
          type={viewOptions}
          {...props}
          hasWriteAccess={hasWriteAccess}
          updateAccordionData={updateAccordionData}
        />
      </View>
    );
  });

  const myTaskGroupsAccordion = myTaskGroups.map((item, index) => {
    return (
      <View key={index}>
        <AccordionBlock
          {...item}
          type={viewOptions}
          {...props}
          hasWriteAccess={hasWriteAccess}
          updateAccordionData={updateAccordionData}
        />
      </View>
    );
  });

  const createGroupsArrayAndCountTasks = (groups) => {
    const groupsArr = [];
    let tasksCount = 0;
    for (const groupId in groups) {
      const group = groups[groupId];

      if (group.type === 'manual' && group.task.status === 'Pending') {
        tasksCount += 1;
        groupsArr.push(group);
      } else {
        if (group.complete < group.total) {
          tasksCount += group.total - group.complete;
          groupsArr.push({
            type: group.type,
            title: `Test ${group.total} Asset(s)`,
            progress: `${group.complete}/${group.total} Complete`,
            user: group.user,
            dueDateUTC: group.dueDateUTC,
          });
        }
      }
    }
    return { arr: groupsArr, count: tasksCount };
  };

  const getTaskGroups = async (all = true) => {
    let totalTaskCount = 0;
    const response = await api(
      `v1/task/admin/group${
        all
          ? `?all=true&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`
          : `?timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`
      }`,
      'GET',
      null
    );
    const sections = [];

    if (response.status === 200 && response.data.success) {
      for (const projectId in response.data.groups) {
        const projectGroups = response.data.groups[projectId];
        const overdueResult = createGroupsArrayAndCountTasks(projectGroups.overdue);
        const todayResult = createGroupsArrayAndCountTasks(projectGroups.today);
        const weekResult = createGroupsArrayAndCountTasks(projectGroups.thisWeek);
        const monthResult = createGroupsArrayAndCountTasks(projectGroups.thisMonth);

        const taskCount = overdueResult.count + todayResult.count + weekResult.count + monthResult.count;

        if (taskCount > 0) {
          const sectionObj = {
            project: projectGroups.project,
            today: todayResult.arr,
            overdue: overdueResult.arr,
            thisWeek: weekResult.arr,
            thisMonth: monthResult.arr,
            taskCount: taskCount,
          };

          sectionObj.taskCount = overdueResult.count + todayResult.count + weekResult.count + monthResult.count;
          totalTaskCount += sectionObj.taskCount;

          sections.push({ section: [sectionObj] });
        }
      }

      if (all) {
        setAllTasksCount(totalTaskCount);
        setAllTaskGroups(sections);
      } else {
        setMyTasksCount(totalTaskCount);
        setMyTaskGroups(sections);
      }
    }
    if (all) {
      setAllTasksLoading(false);
    } else {
      setMyTasksLoading(false);
    }
  };

  const getUser = async () => {
    var user = await AsyncStorage.getItem('user');
    user = JSON.parse(user);

    if (user.role !== 'OHS') {
      setHasWriteAccess(true);
    }
  };

  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      getTaskGroups();
      getTaskGroups(false);
      getUser();
    }
  }, [isFocused]);

  return (
    <SearchScreen
      body={
        <ScrollView>
          <View style={styles.body}>
            <View style={styles.screenNameContainer}>
              <Text style={styles.screenTitle}>Tasks</Text>
              <View style={styles.iconContainer}>
                <View style={[styles.screenTitleIcon, { color: '#000000' }]}>
                  <AiOutlineUnorderedList />
                </View>
                <TouchableOpacity onPress={() => props.navigation.navigate('TaskListDate')}>
                  <Image source={images.calendarGray} style={styles.imgIcon} />
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() =>
                    props.navigation.navigate('AddTask', {
                      hasWriteAccess: hasWriteAccess,
                    })
                  }
                >
                  <BiSolidPlusCircle name="add-circle-sharp" style={{ color: '#132FF5', fontSize: 30 }} />
                </TouchableOpacity>
              </View>
            </View>
            <View>
              <View style={styles.viewOptionsContainer}>
                <Pressable onPress={() => setViewOptions('all')}>
                  <View>
                    <Text style={viewOptions == 'all' ? styles.blueBoldText : styles.grayBoldText}>
                      All ({allTasksCount})
                    </Text>
                    {viewOptions == 'all' && <View style={styles.activeBorder}></View>}
                  </View>
                </Pressable>
                <Pressable onPress={() => setViewOptions('my task')}>
                  <View>
                    <Text style={viewOptions == 'my task' ? styles.blueBoldText : styles.grayBoldText}>
                      My Tasks ({myTasksCount})
                    </Text>
                    {viewOptions == 'my task' && <View style={styles.activeBorder}></View>}
                  </View>
                </Pressable>
              </View>
              {allTasksLoading || myTasksLoading ? (
                <View style={{ marginTop: 50 }}>
                  <Loading />
                </View>
              ) : (
                <View>{viewOptions == 'all' ? allTaskGroupsAccordion : myTaskGroupsAccordion}</View>
              )}
            </View>
          </View>
        </ScrollView>
      }
      {...props}
    />
  );
}
