import React, { useEffect } from 'react';
import { useState } from 'react';
import { Text, TouchableOpacity, View, Pressable, Image } from 'react-native';
import DropInputField from '../../../../../components/DropInputField';
import { styles } from './style';
import { Calendar } from 'react-native-calendars';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { BsFillCalendarWeekFill } from 'react-icons/bs';
import Button from '../../../../../components/Button';
import { useIsFocused } from '@react-navigation/native';
import Loading from '../../../../../components/Loading';
import { api } from '../../../../../utils/api';
import moment from 'moment';
import { showSuccessToast } from '../../../../../utils/toastUtils';
import CustomArrow from '../../../../../components/CustomArrow';

function TestingSchedule({ ...props }) {
  const [selectedDropdownItem, setSelectedDropdownItem] = useState('Select type');
  const [openCalendar, setOpenCalendar] = useState(false);
  const [pickedDate, setPickedDate] = useState();
  const [loading, setLoading] = useState(true);

  const params = props.route.params;

  const siteCadence = [
    {
      title: 'Monthly',
    },
    {
      title: 'Quarterly',
    },
    {
      title: 'Biannually',
    },
    {
      title: 'Annually',
    },
  ];

  const updateSiteCadence = async () => {
    const siteResult = await api(`v1/site/${params.site.id}/sitecadence`, 'PATCH', {
      startDate: moment(pickedDate, 'DD/MM/YYYY'),
      type: selectedDropdownItem.title,
    });
    console.log('updateSiteCadenceL ', siteResult);
  };

  const getSiteCadence = async () => {
    const siteCadence = await api(`v1/site/${params.site.id}`, 'GET', null);
    if (siteCadence.status == 200) {
      if (siteCadence.data.success) {
        var result = siteCadence.data;
        console.log('site cadence: ', result);
        setSelectedDropdownItem({ title: result.site.testCadence.type });
        setPickedDate(moment(result.site.testCadence.startDate).format('DD/MM/YYYY'));
        showSuccessToast(
          'Successfully updated site cadence!',
          'Site Cadence updated, also upding all assets that are apart of this site.'
        );
      }
    }
    setLoading(false);
  };

  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      getSiteCadence();
    }
  }, [isFocused]);

  return (
    <View style={styles.detailContainer}>
      <View style={[styles.header, { paddingBottom: 0 }]}>
        <View style={styles.headerTitle}>
          <TouchableOpacity onPress={() => props.navigation.goBack()}>
            <Text style={styles.textBold}>Back</Text>
          </TouchableOpacity>
          <View style={[styles.projectDetailTitle, { marginBottom: 20 }]}>
            <Image source={images.blueDrillIcon} style={[styles.img, { marginRight: 8 }]} />
            <Text style={styles.textBold}>{params.site.title}</Text>
          </View>
        </View>
      </View>
      <View style={styles.body}>
        <View style={[styles.sectionContainer, { marginTop: 32 }]}>
          <Text style={styles.textBoldLarge}>Testing Schedule</Text>
          <View style={styles.descriptionContainer}>
            <Text style={styles.textRegular}>
              Each project has a set testing cadence. All Assets linked to this project is set to be tested
              periodically.
            </Text>
          </View>
          <Text style={[styles.textBoldMedium, { marginTop: 32 }]}>Testing Schedule</Text>
          {loading ? (
            <View style={{ flex: 1 }}>
              <Loading opacity={0} />
            </View>
          ) : (
            <>
              <DropInputField
                data={siteCadence}
                selectedDropdownItem={selectedDropdownItem}
                setSelectedDropdownItem={setSelectedDropdownItem}
                havePosition={false}
              />
              <View style={styles.popUpPickDate}>
                <Pressable onPress={() => setOpenCalendar(!openCalendar)}>
                  <View style={openCalendar ? styles.inputDrop : [styles.inputDrop, { paddingBottom: 15 }]}>
                    <View style={styles.dropTitleContainer}>
                      <View style={styles.flexRow}>
                        <BsFillCalendarWeekFill name="calendar-month" style={styles.calendarIcon} />
                        <Text style={styles.dropList}>Starting Date: {pickedDate}</Text>
                      </View>

                      {openCalendar ? (
                        <FiChevronUp name="angle-up" style={styles.imgIcon} />
                      ) : (
                        <FiChevronDown name="angle-down" style={styles.imgIcon} />
                      )}
                    </View>
                    {openCalendar && (
                      <Calendar
                        renderArrow={(direction, onPress) => <CustomArrow direction={direction} onPress={onPress} />}
                        hideArrows={false}
                        markingType={'multi-dot'}
                        // markedDates={marked}
                        enableSwipeMonths={true}
                        theme={{
                          arrowColor: '#0F0000',
                        }}
                        // customHeader={(day)=>{
                        //   return(<Text>{selected}</Text>)
                        // }}
                        onDayPress={(day) => {
                          setPickedDate(day.day + '/' + day.month + '/' + day.year);
                          setOpenCalendar(!openCalendar);
                        }}
                      />
                    )}
                  </View>
                </Pressable>
              </View>
            </>
          )}

          <Button
            title={'Update'}
            buttonStyle={[
              styles.modalButton,
              {
                opacity: 1,
                marginTop: 40,
                position: 'absolute',
                bottom: 30,
                left: '8%',
              },
            ]}
            textStyle={styles.blueButtonText}
            onPress={() => {
              updateSiteCadence();
            }}
          />
        </View>
      </View>
    </View>
  );
}

export default TestingSchedule;
