import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../../theme/colors';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  header: {
    backgroundColor: '#F6F6F6',
    paddingTop: 60,
    height: 120,
    // paddingBottom:18,
    alignItems: 'center',
  },
  textBold: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
  },
  body: {
    backgroundColor: 'white',
    height: Dimensions.get('screen').height,
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    paddingBottom: 80,
  },
  section: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    paddingHorizontal: 28,
    paddingVertical: 32,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderColor: 'rgba(0,0,0,0.08)',
  },
  bottom: {
    paddingHorizontal: 28,
    paddingVertical: 32,
    flexDirection: 'row',
    alignItems: 'center',
  },
  showMoreIcon: {
    fontSize: 14,
    paddingLeft: 6,
  },
  logoutIcon: {
    width: 30,
    height: 30,
  },
});
