import { View, Text, Image, Animated, Pressable } from 'react-native';
import { Appbar, Button } from 'react-native-paper';
import colors from '../../theme/colors';
import { styles } from './style';
import images from '../../theme/images';
import PlaceholderLoading from '../PlaceholderLoading';

// Common screen scaffold
const CommonScaffold = ({ ...props }) => {
  const _goBack = () => {
    props.navigation.goBack();
  };

  return (
    <View
      style={{
        flex: 0,
        zIndex: 9999,
      }}
    >
      <View style={styles.appbarContainer}>
        <Appbar.Header style={styles.appbarBackground} elevation={0}>
          <View style={styles.appbarInnerContentContainer}>
            <Button
              onPress={props.onPress}
              textColor={colors.white}
              color={colors.white}
              uppercase={false}
              style={styles.appbarBackButton}
            >
              Back
            </Button>
            {/* <Image style={styles.appbarTrailingIcon} source={props.icon} /> */}
          </View>
        </Appbar.Header>
        <View style={styles.appbarTitleContainer}>
          <View style={styles.appbarTitle}>
            {props.assetInfoLoading.assetInfoContent ? (
              <PlaceholderLoading isFetching={props.assetInfoLoading.assetInfoLoader} />
            ) : (
              <>
                <View style={styles.appbarTitleIconContainer}>
                  <Image
                    style={{
                      width: 24,
                      height: 24,
                    }}
                    source={{ uri: props.icon }}
                  />
                </View>
                <Text style={styles.appbarTitleText}>{props.title}</Text>
              </>
            )}
          </View>
        </View>
      </View>
    </View>
  );
};

export default CommonScaffold;
