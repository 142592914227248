import { Dimensions, StyleSheet } from 'react-native';
export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  header: {
    backgroundColor: '#F6F6F6',
    paddingTop: 70,
    paddingBottom: 30,
  },
  body: {
    backgroundColor: 'white',
    marginBottom: 20,
    paddingTop: 10,
  },
  menuContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 23,
  },
  appbarTrailingIcon: {
    alignSelf: 'flex-end',
    width: 24,
    height: 24,
    tintColor: 'black',
  },
  textBold: {
    fontSize: 18,
    fontFamily: 'NunitoSans_700Bold',
  },
  textMediumBold: {
    fontSize: 14,
    fontFamily: 'NunitoSans_700Bold',
  },
  textSmallBold: {
    fontSize: 14,
    fontFamily: 'NunitoSans_700Bold',
    color: '#6B6B6B',
  },
  textSmallerBold: {
    textTransform: 'capitalize',
    fontSize: 12,
    fontFamily: 'NunitoSans_700Bold',
    color: '#6B6B6B',
  },
  textRegular: {
    fontSize: 12,
    fontFamily: 'NunitoSans_400Regular',
  },
  textTinyBold: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 10,
  },
  textSmallSemibold: {
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 12,
  },
  overview: {
    paddingLeft: 23,
    paddingRight: 27,
  },
  overviewDetail: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 16,
  },
  statusContainer: {
    flexDirection: 'row',
    padding: 3,
    backgroundColor: '#132ff51a',
    borderRadius: 13,
  },
  status: {
    paddingHorizontal: 5,
    borderRadius: 8,
    paddingVertical: 1,
  },

  overviewInfo: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  dueDate: {
    backgroundColor: '#ff984d1a',
    paddingHorizontal: 20,
    justifyContent: 'center',
    borderRadius: 13,
    paddingVertical: 2,
  },
  penIcon: {
    borderWidth: 1,
    borderColor: '#979797',
    justifyContent: 'center',
    padding: 5,
    borderRadius: 99,
  },
  taskDetailContainer: {
    borderTopColor: '#00000014',
    borderTopWidth: 1,
  },
  taskDetailTitle: {
    paddingLeft: 23,
    paddingRight: 27,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 19,
  },
  viewOptionsContainer: {
    marginTop: 22,
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 23,
    paddingRight: 27,
    borderBottomWidth: 1,
    borderBottomColor: '#00000014',
  },
  grayBoldText: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 14,
    color: '#6B6B6B',
    paddingBottom: 9,
    paddingRight: 18,
  },
  blueBoldText: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 14,
    color: '#132FF5',
    paddingBottom: 9,
    paddingRight: 18,
  },

  taskTitle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  leadIcon: {
    fontSize: 14,
    paddingRight: 6,
  },
  taskDetail: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  textLightSmall: {
    fontFamily: 'NunitoSans_300Light',
    fontSize: 12,
  },
  textMicroBold: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 11,
  },
  inputIcon: {
    paddingLeft: 8,
  },
  assigneeContainer: {
    marginTop: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomColor: '#0000000d',
    borderBottomWidth: 1,
    paddingBottom: 7,
  },
  assigneeDetail: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconContainer: {
    backgroundColor: '#132ff51a',
    padding: 5,
    borderRadius: 99,
  },
  iconContainerDisable: {
    backgroundColor: '#D0D0D0',
    opacity: 0.2,
    padding: 5,
    borderRadius: 99,
  },
  ModalContainer: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  popupWindow: {
    backgroundColor: 'white',
    paddingVertical: 20,
    borderRadius: 12,
    paddingHorizontal: 17,
    marginHorizontal: 13,
  },
  smallSubmitButton: {
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    backgroundColor: '#132FF5',
    paddingVertical: 13,
    borderRadius: 8,
    marginTop: 38,
    width: 286,
  },
  dueControllerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 17,
    marginBottom: 26,
  },
  deleteTask: {
    alignItems: 'center',
    paddingVertical: 5,
  },
  popupHeader: {
    alignItems: 'center',
  },
  confirmationTileIcon: {
    width: 70,
    height: 70,
    borderRadius: 16,
    marginTop: 8,
  },
  blueButton: {
    borderRadius: 8,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
  },
  blueButtonText: {
    color: 'white',
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    textAlign: 'center',
  },
  blueBorderButton: {
    borderRadius: 8,
    borderColor: '#132FF5',
    borderWidth: 1,
    paddingVertical: 10,
    marginTop: 8,
  },
  blueBorderButtonText: {
    color: '#132FF5',
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 16,
    textAlign: 'center',
  },

  redBorderButton: {
    borderRadius: 8,
    borderColor: '#FF4D4D',
    borderWidth: 1,
    paddingVertical: 10,
    marginTop: 8,
  },
  redBorderButtonText: {
    color: '#FF4D4D',
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 16,
    textAlign: 'center',
  },
  blankButton: {
    paddingVertical: 15,
  },
  blankButtonText: {
    color: '#6B6B6B',
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 18,
    textAlign: 'center',
  },

  textBoldLarge: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
  },

  textRegular: {
    fontFamily: 'NunitoSans_400Regular',
  },
  textRegularSmall: {
    fontFamily: 'NunitoSans_400Regular',
    fontSize: 12,
  },
  position: {
    fontSize: 12,
    color: '#132FF5',
    fontFamily: 'NunitoSans_700Bold',
    marginRight: 8,
  },
  script: {
    marginTop: 8,
    marginBottom: 9,
    fontFamily: 'NunitoSans_400Regular',
    textAlign: 'center',
  },
  popupIcon: {
    width: 27,
    height: 27,
    tintColor: 'black',
  },

  input: {
    marginTop: 16,
    borderColor: '#C7C7C7',
    borderWidth: 1,
    borderRadius: 8,
    paddingVertical: 20,
    paddingLeft: 13,
    marginHorizontal: 10,
    minHeight: 121,
    marginBottom: 7,
    fontFamily: 'NunitoSans_400Regular',
    fontSize: 14,
  },
  taskIcon: {
    width: 43,
    height: 43,
  },
  taskContainer: {
    paddingLeft: 23,
    paddingRight: 27,
  },
  contentBlock: {
    borderWidth: 1,
    borderColor: '#D0D0D0',
    borderRadius: 8,
    marginTop: 10,
    overflow: 'hidden',
  },
  contentDelete: {
    position: 'absolute',
    height: '100%',
    width: '95%',
    backgroundColor: 'red',
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: 8,
  },
  animatedBlock: {
    backgroundColor: '#FFFFFF',
    paddingVertical: 6,
  },
  taskLabel: {
    borderRadius: 8,
    paddingVertical: 2,
    paddingHorizontal: 10,
    backgroundColor: '#FFFFFF',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.3,
    shadowRadius: 2,
    elevation: 2,
    alignSelf: 'flex-start',
  },
  searchSection: {
    borderRadius: 4,
    borderColor: '#D0D0D0',
    borderWidth: 1,
    flexDirection: 'row',
    paddingVertical: 14,
    paddingLeft: 9,
  },
  searchIcon: {
    fontSize: 20,
    color: 'rgba(0,0,0,0.5)',
    opacity: 0.15,
  },
  completeTickIcon: {
    width: 16,
    height: 16,
    marginLeft: 8,
  },
});
