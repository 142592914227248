import React, { useState, useEffect } from 'react';
import { SafeAreaView, ScrollView, Text, View, Image, Pressable, TouchableOpacity } from 'react-native';
import { styles } from './style';
import Button from '../../../../components/Button';
import CompleteTask from './completeTask';
import PendingTask from './pendingTask';
import NavigationHeader from '../../../../components/SignUp/SingUpNavigation';
import { useIsFocused } from '@react-navigation/native';
import { api } from '../../../../utils/api';
import Loading from '../../../../components/Loading';

export default function TaskList({ ...props }) {
  const { project, dueDateUTC, fromNotifications, continueTesting } = props.route.params;
  const [showMore, setShowMore] = useState(false);
  const [pendingTasks, setPendingTasks] = useState([]);
  const [completeTasks, setCompleteTasks] = useState([]);
  const [loading, setLoading] = useState(true);

  const getTasks = async () => {
    const response = await api(
      `v1/task/sorted/status?projectid=${project._id}&selected_date_utc=${dueDateUTC}`,
      'GET',
      null
    );

    if (response.status === 200 && response.data.success) {
      const tasks = response.data.tasks;
      const pendingTasks = tasks.pendingTasks ? tasks.pendingTasks : [];
      const completeTasks = tasks.completeTasks ? tasks.completeTasks : [];

      setPendingTasks(pendingTasks);
      setCompleteTasks(completeTasks);
    }

    setLoading(false);
  };

  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      getTasks();
    }
  }, [isFocused]);

  const showCompleteList = completeTasks
    .filter((task, idx) => (showMore ? idx > -1 : idx < 2))
    .map((task, idx) => <CompleteTask key={idx} task={task} {...props} />);

  const showPendingList = pendingTasks.map((task, idx) => <PendingTask key={idx} task={task} {...props} />);

  const handleStartTestingPress = () => {
    props.navigation.navigate('QrCodeNav', {
      isAdmin: true,
      taskListDetails: {
        project: project,
        dueDateUTC: dueDateUTC,
      },
    });
  };

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView>
        <View style={styles.wrapper}>
          <NavigationHeader
            onPress={() => {
              if (fromNotifications) {
                props.navigation.getParent().setParams({
                  screen: 'Tasks',
                });
                props.navigation.navigate('Notifications', {
                  screen: 'NotificationsPage',
                  params: { isAdmin: props.route.params.isAdmin },
                });
              } else {
                props.navigation.navigate('Tasks');
              }
            }}
            navigate={'Back'}
            header={`Test ${pendingTasks.length + completeTasks.length} Assets`}
            menuStyles={styles.menu}
          />
          <Text style={styles.taskNumber}>{`${completeTasks.length}/${
            pendingTasks.length + completeTasks.length
          } Complete`}</Text>
          <View style={styles.appbarTitleContainer}>
            <View style={styles.appbarTitle}>
              <View style={styles.appbarTitleIconContainer}>
                <Image
                  style={{
                    width: 24,
                    height: 24,
                    borderRadius: 12,
                  }}
                  source={{ uri: project.logo }}
                />
              </View>
              <Text style={styles.appbarTitleText}>{project.address.street}</Text>
            </View>
          </View>
        </View>
        {loading ? (
          <View style={{ marginTop: 100 }}>
            <Loading />
          </View>
        ) : (
          <>
            <View style={styles.completed}>
              {completeTasks.length > 0 && (
                <View>
                  <Text style={styles.textBold}>Complete ({completeTasks.length})</Text>
                  {showCompleteList}
                  {completeTasks.length > 2 && (
                    <TouchableOpacity onPress={() => setShowMore(!showMore)}>
                      {showMore ? (
                        <Text style={styles.showMore}>View Less</Text>
                      ) : (
                        <Text style={styles.showMore}>View All</Text>
                      )}
                    </TouchableOpacity>
                  )}
                </View>
              )}
              {pendingTasks.length > 0 && (
                <View>
                  <Text
                    style={[
                      styles.textBold,
                      {
                        marginTop: 16,
                      },
                    ]}
                  >
                    Pending ({pendingTasks.length})
                  </Text>
                  <View
                    style={[
                      styles.pendingList,
                      {
                        marginTop: 12,
                      },
                    ]}
                  >
                    <>
                      <View></View>
                      <Button
                        title={continueTesting ? 'Continue Testing' : 'Start Testing'}
                        buttonStyle={styles.blueButton}
                        textStyle={styles.blueButtonText}
                        onPress={handleStartTestingPress}
                      />
                    </>
                  </View>
                  {showPendingList}
                </View>
              )}
            </View>
          </>
        )}
      </ScrollView>
    </SafeAreaView>
  );
}
