import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../../theme/colors';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  assetStatus: {
    backgroundColor: '#88ddb3',
    borderRadius: 13,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    flexDirection: 'row',
    paddingHorizontal: 8,
    paddingVertical: 3,
    marginRight: 4,
  },
  assetStatusText: {
    color: '#046736',
    fontWeight: 'bold',
    marginRight: 4,
  },
  contentBlock: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D0D0D0',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 6,
    paddingHorizontal: 7,
    marginHorizontal: 23,
    marginTop: 10,
  },
  screenName: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    color: '#6B6B6B',
    paddingHorizontal: 25,
  },
  titleContainer: {
    paddingHorizontal: 25,
    paddingVertical: 13,
    marginTop: 6,
    borderBottomColor: '#00000014',
    borderBottomWidth: 1,
  },
  title: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 14,
    color: '#4A4A4A',
  },
  accordionHeader: {
    paddingVertical: 4,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 23,
    alignItems: 'center',
    paddingVertical: 10,
    borderBottomColor: '#00000014',
    borderBottomWidth: 1,
  },
  accordionHeaderIcon: {
    width: 11,
    height: 13,
    tintColor: '#fff',
    alignSelf: 'center',
    backgroundColor: '#132FF5',
  },
  textRegularSmall: {
    fontFamily: 'NunitoSans_400Regular',
    fontSize: 12,
    marginRight: 15,
  },
  headerContentDescript: {
    flexDirection: 'row',
  },
  validate: {
    borderBottomWidth: 1,
    width: '100%',
    alignItems: 'center',
    borderColor: '#bdbdbd',
  },
  header: {
    backgroundColor: '#F6F6F6',
    paddingTop: 70,
    paddingBottom: 18,
  },
  body: {
    marginTop: 16,
    backgroundColor: 'white',
    marginBottom: 100,
  },
  iconContainer: {
    backgroundColor: '#132FF5',
    height: 23,
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: 99,
    paddingHorizontal: 6,
    alignSelf: 'center',
  },
  accordionHeaderContent: {
    paddingLeft: 8,
    width: '80%',
  },
  textBold: {
    fontFamily: 'NunitoSans_700Bold',
  },
  textBoldLarge: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
  },
  textBoldSmall: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 12,
  },
  export: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  accordionIcon: {
    fontSize: 14,
    paddingLeft: 6,
  },
  accordionContent: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: '#F6F6F6',
    paddingVertical: 7,
  },
  accordionContentTitle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});
