import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../../theme/colors';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  searchSection: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#D0D0D0',
    borderRadius: 4,
  },
  searchIcon: {
    paddingHorizontal: 8,
    marginVertical: 6,
    paddingVertical: 8,
    borderBottomLeftRadius: 4,
    borderRightWidth: 1,
    borderColor: '#D0D0D0',
    borderTopLeftRadius: 4,
  },
  inputIcon: {
    paddingLeft: 8,
  },
  img: {
    width: 35,
    height: 35,
  },
  bottomNav: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 32,
    paddingVertical: 14,
  },
  mapView: {
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 8,
    height: 300,
  },
  calendarViewIcon: {
    width: 22,
    height: 22,
    marginRight: 14,
  },
  locationSmallWhiteIcon: {
    width: 22,
    height: 24,
  },
  taskBox: {
    marginTop: 16,
    paddingVertical: 12,
    paddingHorizontal: 12,
    borderRadius: 8,
    backgroundColor: 'white',
    flexDirection: 'row',
    alignItems: 'center',
  },
  boxHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  boxContent: {
    paddingHorizontal: 8,
    paddingVertical: 5,
    borderRadius: 16,
    backgroundColor: '#F6F6F6',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 3,
  },
  header: {
    backgroundColor: '#F6F6F6',
    paddingTop: 70,
    paddingBottom: 18,
  },
  body: {
    marginTop: 16,
    backgroundColor: 'white',
  },
  title: {
    paddingVertical: 16,
    paddingHorizontal: 23,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  taskDesc: {
    paddingVertical: 6,
  },
  textBold: {
    fontFamily: 'NunitoSans_700Bold',
  },
  taskList: {
    paddingHorizontal: 25,
    paddingTop: 30,
    marginBottom: 100,
  },
  titleContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemContainer: {
    padding: 6,
    margin: 6,
    backgroundColor: '#eee',
  },
  contentContainer: {
    backgroundColor: '#F6F6F6',
    zIndex: -9999,
    // height:200
  },
  taskImg: {
    width: 22,
    height: 22,
    tintColor: 'black',
  },
  showMoreIcon: {
    fontSize: 14,
    paddingLeft: 6,
  },
  textBold: {
    fontFamily: 'NunitoSans_700Bold',
  },
  textBoldLarge: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
  },
  bottomSheet: {
    paddingHorizontal: 25,
    paddingTop: 30,
    paddingBottom: 100,
    backgroundColor: '#F6F6F6',
    minHeight: 600,
  },
  taskTitle: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 14,
    color: '#000000',
  },
});
