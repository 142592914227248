import React, { useEffect, useState } from 'react';
import { SafeAreaView, Text, Image, View, TouchableOpacity } from 'react-native';
import { FaChevronRight } from 'react-icons/fa';
import { styles } from './style';
import images from '../../../theme/images';
import { StatusBar } from 'expo-status-bar';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useIsFocused } from '@react-navigation/native';

export default function Setting({ ...props }) {
  const [hasWriteAccess, setHasWriteAccess] = useState(false);

  const logoutUser = async () => {
    await AsyncStorage.multiRemove(['user', 'token', 'refreshToken', 'expiresIn']);
    props.navigation.navigate('GuestNavigation', { screen: 'QRCodeScan' });
  };

  const getUser = async () => {
    var user = await AsyncStorage.getItem('user');
    user = JSON.parse(user);

    if (user.role !== 'OHS') {
      setHasWriteAccess(true);
    }
  };

  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      getUser();
    }
  }, [isFocused]);

  return (
    <View style={styles.container}>
      <StatusBar style="dark" />
      <View style={styles.header}>
        <Text
          style={[
            styles.textBold,
            {
              color: '#6B6B6B',
            },
          ]}
        >
          Account Settings
        </Text>
      </View>
      <View style={styles.body}>
        <View>
          <TouchableOpacity onPress={() => props.navigation.navigate('PersonalInfor')}>
            <View style={styles.section}>
              <Text style={styles.textBold}>Personal Information</Text>
              <FaChevronRight name="right" style={styles.showMoreIcon} />
            </View>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => props.navigation.navigate('Subscription')}>
            <View style={[styles.section, { borderTopWidth: 0 }]}>
              <Text style={styles.textBold}>Subscription & Billing</Text>
              <FaChevronRight name="right" style={styles.showMoreIcon} />
            </View>
          </TouchableOpacity>
        </View>
        <TouchableOpacity
          onPress={() => {
            logoutUser();
          }}
        >
          <View style={styles.bottom}>
            <Image style={styles.logoutIcon} source={images.logOut} />
            <Text
              style={[
                styles.textBold,
                {
                  color: '#FF4D4D',
                  marginLeft: 16,
                },
              ]}
            >
              Log out
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
}
