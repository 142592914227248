import React, { useState, useEffect } from 'react';
import { TextInput, ScrollView, Text, View, Platform, Image, Pressable, SafeAreaView, Modal } from 'react-native';
import Button from '../../../../components/Button';
import NavigationHeader from '../../../../components/SignUp/SingUpNavigation';
import { styles } from './style';
import SignUpHeader from '../../../../components/SignUp/SignUpHeader';
import images from '../../../../theme/images';
import InputField from './field';
import { useIsFocused } from '@react-navigation/native';
import { api } from '../../../../utils/api';
import AsyncStorage from '@react-native-async-storage/async-storage';

function SettingUpProjectStep7({ ...props }) {
  const [modalVisible, setModalVisible] = useState(false);
  const [emailInput, setEmailInput] = useState([{ email: '', type: '' }]);
  const [fieldsValidated, setFieldsValidated] = useState(false);
  const isFocused = useIsFocused();
  const [projectId, setProjectId] = useState(null);
  const maxLicenses = props.route.params.subscription.maxLicenses;

  const createProject = async (data) => {
    console.log('create project : ', data);
    const createProject = await api('v1/project', 'POST', data);
    if (createProject.status == 200) {
      const result = createProject.data;
      if (result.success) {
        console.log('created project: ', result.project);
        const createProjectSites = await api('v1/site', 'POST', {
          project: result.project.project._id,
          sites: data.sites,
        });
        setProjectId(result.project.project._id);
      } else {
        Alert.alert('Project Creation Failed', result.project, [
          {
            text: 'Cancel',
            style: 'destructive',
          },
          {
            text: 'Try Again',
            onPress: () => {
              props.navigation.goBack();
            },
            style: 'default',
          },
        ]);
      }
    }
  };

  const sendInvites = async () => {
    for (let se = 0; se < emailInput.length; se++) {
      const element = emailInput[se];
      const sendInvite = await api(`v1/project/${projectId}/invite`, 'POST', {
        email: element.email,
        type: element.type,
        projectId: projectId,
      });

      console.log('invite sent: ', sendInvite.data);
    }
    setModalVisible(true);
  };

  useEffect(() => {
    if (isFocused) {
      if (props.route.params.newProject) {
        const company = props.route.params.company;
        const newProject = props.route.params.newProject;
        const schedule = props.route.params.schedule;
        const applyAll = props.route.params.scheduleApplyAll;
        var sites = [];

        if (applyAll) {
          for (let i = 0; i < newProject.sites.length; i++) {
            var element = newProject.sites[i];
            element.testCadence = schedule;
            sites.push(element);
          }
        } else {
          sites = newProject.sites;
        }

        let data = {
          company: company,
          projectDetails: newProject.project,
          sites: sites,
        };

        createProject(data);
      }
    }
  }, [isFocused]);

  useEffect(() => {
    var checkEmailInput = emailInput.every((item) => item.email.length > 5 && item.type !== '');
    console.log(emailInput);
    setFieldsValidated(checkEmailInput);
  }, [emailInput]);

  const handleChangeEmail = (text, index) => {
    setEmailInput((prevEmailInput) => {
      const updatedEmailInput = [...prevEmailInput];
      updatedEmailInput[index].email = text;
      return updatedEmailInput;
    });
  };

  const handleChangeType = (text, index) => {
    setEmailInput((prevEmailInput) => {
      const updatedEmailInput = [...prevEmailInput];
      updatedEmailInput[index].type = text;
      return updatedEmailInput;
    });
  };

  const addFields = () => {
    if (emailInput.length < maxLicenses) {
      let newfield = {
        email: '',
        type: '',
      };
      setEmailInput([...emailInput, newfield]);
    }
  };

  const fields = emailInput.map((item, index) => {
    return (
      <View key={index} style={styles.textField}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Text style={styles.inputHeader}>Email address</Text>
          {emailInput.length > 1 && (
            <Pressable
              onPress={() => {
                const tempEmailInput = [...emailInput];
                tempEmailInput.splice(index, 1);
                setEmailInput(tempEmailInput);
              }}
            >
              <Image style={styles.xIcon} source={images.lightGreyX} />
            </Pressable>
          )}
        </View>
        <TextInput
          key={`emailInput-${index}`}
          placeholder="example@gmail.com"
          style={styles.input}
          onChangeText={(text) => {
            handleChangeEmail(text, index, 'email');
          }}
          value={item.title}
        />
        <InputField
          key={`roleInput-${index}`}
          index={index}
          onChangeText={(text) => {
            handleChangeType(text, index, 'type');
          }}
        />
      </View>
    );
  });

  const popUpModal = (
    <Modal
      animationType="fade"
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => {
        setModalVisible(false);
      }}
    >
      <View style={styles.popupModal}>
        <View style={styles.popupWindow}>
          <Image style={styles.popupIcon} source={images.emailSentIcon} />
          <Text style={styles.popupTitle}>Sent!</Text>
          <Text style={styles.popupScript}>
            Your invitations have been sent. You can add assets and explore ClkTag while you wait for your team to join
            you.
          </Text>
          <Button
            title={'Ok'}
            buttonStyle={[
              styles.blueButton,
              {
                opacity: 1,
                marginTop: 40,
              },
            ]}
            textStyle={styles.blueButtonText}
            onPress={() => {
              setModalVisible(false);
              props.navigation.navigate(props.route.params.addNewProject ? 'TabNavigator' : 'WelcomeScreen', {
                userImage: props.route.params.userImage,
                userType: props.route.params.userType,
                userLicence: props.route.params.userLicence,
                appUse: props.route.params.appUse,
                subscription: props.route.params.subscription.value,
                isAdmin: props.route.params.addNewProject,
                registerForPushNotificationsAsync: props.route.params.registerForPushNotificationsAsync,
              });
            }}
          />
        </View>
      </View>
    </Modal>
  );

  return (
    <View style={styles.container}>
      <SafeAreaView
        style={{
          flex: 1,
        }}
      >
        <ScrollView>
          <View
            style={
              Platform.OS === 'ios'
                ? [
                    styles.loading,
                    {
                      marginTop: 0,
                    },
                  ]
                : styles.loading
            }
          >
            <View style={styles.loadPercent}></View>
          </View>
          <NavigationHeader
            onPress={async () => {
              if (props.route.params.addNewProject) {
                props.navigation.goBack();
              } else {
                await api(`v1/company/${props.route.params.company}`, 'DELETE', null);
                await AsyncStorage.multiRemove(['user', 'token', 'refreshToken', 'expiresIn']);
                props.navigation.navigate('GuestNavigation', { screen: 'QRCodeScan' });
              }
            }}
            header={props.route.params.addNewProject ? 'Add New Project' : 'Sign Up'}
            navigate={'Cancel'}
            menuStyles={styles.menu}
          />
          {props.route.params.addNewProject ? (
            <View>
              <Text style={[styles.textBold, { textAlign: 'center', marginBottom: 20 }]}>
                Great, let's invite some team members
              </Text>
            </View>
          ) : (
            <SignUpHeader
              title={"Great, let's invite some team members"}
              titleStyle={{
                textAlign: 'center',
                width: '60%',
              }}
              userImage={props.route.params.userImage}
              disablePress={true}
            />
          )}
          {popUpModal}
          <View style={styles.form}>
            {fields}
            {emailInput.length < maxLicenses && (
              <Pressable onPress={() => addFields()}>
                <Text style={styles.addReq}>+ Add another invitation</Text>
              </Pressable>
            )}
          </View>
        </ScrollView>
        <View style={styles.assignButton}>
          <Button
            title={'Send Invites'}
            buttonStyle={fieldsValidated ? styles.blueButtonActive : styles.blueButton}
            textStyle={styles.blueButtonText}
            onPress={() => {
              fieldsValidated ? sendInvites() : '';
            }}
          />
          <Button
            title={'Skip'}
            buttonStyle={styles.blueBorderButton}
            textStyle={styles.blueBorderButtonText}
            onPress={() =>
              props.navigation.navigate(props.route.params.addNewProject ? 'TabNavigator' : 'WelcomeScreen', {
                userImage: props.route.params.userImage,
                userType: props.route.params.userType,
                userLicence: props.route.params.userLicence,
                appUse: props.route.params.appUse,
                subscription: props.route.params.subscription,
                isAdmin: props.route.params.addNewProject,
                registerForPushNotificationsAsync: props.route.params.registerForPushNotificationsAsync,
              })
            }
          />
          <Button
            title={'Previous'}
            buttonStyle={styles.blankButton}
            textStyle={styles.blankButtonText}
            onPress={() => props.navigation.goBack()}
          />
        </View>
      </SafeAreaView>
    </View>
  );
}
export default SettingUpProjectStep7;
