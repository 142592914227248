import React from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { FaChevronRight } from 'react-icons/fa';

import { styles } from './style';
import moment from 'moment';
import 'moment-timezone';

export default function TaskReminderNotification({ ...props }) {
  const { notification, navigation, hasWriteAccess, handleClosePress } = props;
  const dueDateUTC = moment(notification.sendOnUTC).add(1, 'week').format();

  const handlePress = () => {
    if (props.route.params.isAdmin) {
      navigation.navigate('UserTasks', {
        screen: 'TaskInner',
        params: {
          project: notification.project,
          assignedUserId: notification.user._id,
          dueDateUTC: dueDateUTC,
          period: 'exact',
          hasWriteAccess: hasWriteAccess,
          fromNotifications: true,
        },
      });
    } else {
      props.navigation.navigate('UserTasks', {
        screen: 'TaskList',
        params: {
          project: notification.project,
          dueDateUTC: dueDateUTC,
          fromNotifications: true,
        },
      });
    }
  };

  return (
    <View style={styles.notiBlock}>
      <View style={[styles.notiHeader]}>
        <View style={styles.notiTitle}>
          <Text style={styles.textBold}>Reminder </Text>
          <Text style={styles.textRegular}>
            - Due{' '}
            {moment(notification.sendOnUTC)
              .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
              .add(1, 'week')
              .fromNow()}
          </Text>
        </View>
        <TouchableOpacity onPress={() => handleClosePress(notification._id)}>
          <AiOutlineCloseCircle name="close" style={styles.closeIcon} />
        </TouchableOpacity>
      </View>
      <TouchableOpacity onPress={handlePress}>
        <View style={styles.notiContent}>
          <View style={styles.contentHeader}>
            <Text style={styles.textBold}>Test {notification.tasks.length} Assets</Text>
            <FaChevronRight
              name="right"
              style={{
                fontSize: 14,
                paddingLeft: 6,
              }}
            />
          </View>
          <View style={styles.contentDetail}>
            <Image style={styles.notiContentAvatar} source={{ uri: notification.project.logo }} />
            <Text
              style={{
                paddingLeft: 5,
              }}
            >
              {notification.project.name}
            </Text>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
}
