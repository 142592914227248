import React, { useEffect, useState } from 'react';
import { Text, View, SafeAreaView, Image, ScrollView } from 'react-native';
import { styles } from './style';
import NavigationHeader from '../../../../components/SignUp/SingUpNavigation';
import SignUpHeader from '../../../../components/SignUp/SignUpHeader';
import { RadioButton } from 'react-native-paper';
import images from '../../../../theme/images';
import Button from '../../../../components/Button';
import Type from './type';
import AsyncStorage from '@react-native-async-storage/async-storage';

function UserType({ ...props }) {
  const isAdmin = props.route.params.userType == 'PM';

  //TODO load API
  const fields = [
    {
      image: images.companyTileIcon,
      title: isAdmin ? 'Joining an existing company / project' : 'Part of a company / project',
      value: 'first',
      detail: '',
      onClick: false,
    },
    {
      image: images.individualTileIcon,
      title: isAdmin ? 'Setting up a project' : 'As an individual',
      value: 'second',
      detail: '',
      onClick: false,
    },
  ];

  const [listType, setListType] = useState(fields);
  const handlePress = (value) => {
    const newState = listType.map((item, index) => {
      if (value == item.value) {
        if (item.value == 'first') {
          props.navigation.navigate('JoinProject', {
            userImage: props.route.params.userImage,
            userType: props.route.params.userType,
            userLicence: props.route.params.userLicence,
            appUse: 'Part of a company / project',
            registerForPushNotificationsAsync: props.route.params.registerForPushNotificationsAsync,
          });
        } else if (item.value == 'second') {
          props.navigation.navigate('SubscriptionSelection', {
            userImage: props.route.params.userImage,
            userType: props.route.params.userType,
            userLicence: props.route.params.userLicence,
            appUse: isAdmin ? 'Setting up a project' : 'As an individual',
            registerForPushNotificationsAsync: props.route.params.registerForPushNotificationsAsync,
          });
        }
        return { ...item, onClick: true };
      }
      return { ...item, onClick: false };
    });
    setListType(newState);
  };

  const typelist = listType?.map((item, index) => {
    return (
      <Type
        key={index}
        title={item.title}
        detail={item.detail}
        image={item.image}
        onClick={item.onClick}
        value={item.value}
        onPress={() => handlePress(item.value)}
      />
    );
  });
  return (
    <View style={styles.container}>
      <SafeAreaView
        style={{
          flex: 1,
        }}
      >
        <ScrollView>
          <View style={styles.loading}>
            <View style={styles.loadPercent}></View>
          </View>
          <NavigationHeader
            onPress={async () => {
              await AsyncStorage.multiRemove(['user', 'token', 'refreshToken', 'expiresIn']);
              props.navigation.navigate('GuestNavigation', { screen: 'QRCodeScan' });
            }}
            header={'Sign Up'}
            navigate={'Cancel'}
            menuStyles={styles.menu}
          />
          <SignUpHeader
            title={'How will you use One Tag?'}
            descript={'Please select one of the below options'}
            userImage={props.route.params.userImage}
            disablePress={true}
            style={{
              paddingHorizontal: 42,
            }}
          />
          <View
            style={{
              marginHorizontal: 37,
            }}
          >
            {typelist}
          </View>
          <View style={styles.assignButton}>
            <Button
              title={'Previous'}
              buttonStyle={[styles.blankButton, { marginBottom: 20 }]}
              textStyle={styles.blankButtonText}
              onPress={() => props.navigation.goBack()}
            />
          </View>
        </ScrollView>
      </SafeAreaView>
    </View>
  );
}

export default UserType;
