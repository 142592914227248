import React, { useEffect, useState } from 'react';
import { Text, View, ScrollView, SectionList, Image, TouchableOpacity, Animated } from 'react-native';
import { styles } from './style';
import { FiChevronLeft, FiChevronRight, FiChevronUp, FiChevronDown } from 'react-icons/fi';
import Button from '../../../../components/Button';
import { Pressable } from 'react-native';
import SearchScreen from '../../../../components/Search';
import Loading from '../../../../components/Loading';
import { useIsFocused } from '@react-navigation/native';
import { api, logout } from '../../../../utils/api';

export default function AdminViewProject({ ...props }) {
  const params = props.route.params;
  const [innerData, setInnerData] = useState([]);
  const [siteLevelNAreas, setSiteLevelNAreas] = useState([]);
  const [allAssets, setAllAssets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [assetLoading, setAssetLoading] = useState(false);
  const [currentLevel, setCurrentLevel] = useState(0);
  const [searchLevel, setSearchLevel] = useState(null);
  const [currentArea, setCurrentArea] = useState(-1);
  const [fadeAnimation, setFadeAnimation] = useState(new Animated.Value(1));

  const getSitesLevelNAreas = async () => {
    setInnerData([]);
    const siteAssets = await api(`v1/site/${params.siteDetail.id}/siteLevelAreas`, 'GET', null);
    if (siteAssets.status == 200) {
      if (siteAssets.data.success) {
        var sectionListArray = [];
        for (let anl = 0; anl < siteAssets.data.siteLevelNAreas.length; anl++) {
          const element = siteAssets.data.siteLevelNAreas[anl];
          if (anl == 0) {
            setSearchLevel(element.level._id);
          }
          sectionListArray.push({
            levelid: element.level._id,
            title: element.level.name,
            data: element.areas,
            index: anl,
          });
        }
        setSiteLevelNAreas(sectionListArray);
      }
    } else {
      if (siteAssets.data.error && siteAssets.data.error == 'jwt expired') {
        logout(props.navigation);
      }
    }
    setLoading(false);
  };

  const getLevelAreaAssets = async (level, area, onLoad = false) => {
    setInnerData([]);
    setAssetLoading(true);
    const siteAssets = await api(
      `v1/site/${params.siteDetail.id}/getLevelAreaAssets?level=${level}&area=${area}`,
      'GET',
      null
    );
    console.log('siteAssets: ', siteAssets.data);
    if (siteAssets.status == 200) {
      if (siteAssets.data.success) {
        setInnerData(siteAssets.data.siteLevelAssets);
      }
    }
    setAssetLoading(false);
  };

  const getAllAssets = async () => {
    const assets = await api(`v1/site/${params.siteDetail.id}/assets`, 'GET', null);
    if (assets.status == 200) {
      if (assets.data.success) {
        setAllAssets(assets.data.siteAssets);
      }
    }
  };

  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      getSitesLevelNAreas();
      getAllAssets();
    }
  }, [isFocused]);

  const openSection = (index, levelid) => {
    if (index !== currentLevel) {
      setSearchLevel(levelid);
      fadeOut();
      setTimeout(() => {
        fadeIn();
        setCurrentLevel(index);
      }, 300);
    }
  };

  const openInnerSection = (index, areaid) => {
    setCurrentArea(index);
    getLevelAreaAssets(searchLevel, areaid, true);
  };

  const fadeIn = () => {
    Animated.timing(fadeAnimation, {
      toValue: 1,
      duration: 300,
      useNativeDriver: false,
    }).start();
  };

  const fadeOut = () => {
    Animated.timing(fadeAnimation, {
      toValue: 0,
      duration: 300,
      useNativeDriver: false,
    }).start();
  };

  return (
    <SearchScreen
      body={
        <>
          <ScrollView>
            <View>
              <View style={styles.body}>
                <View style={styles.screenNameContainer}>
                  <Pressable onPress={() => props.navigation.goBack()}>
                    <View style={{ justifyContent: 'flex-start', flexDirection: 'row' }}>
                      <FiChevronLeft name="chevron-back" style={styles.screenNameIcon} />
                      <Text style={styles.screenName}>
                        {props.route.params.title} / {props.route.params.siteTitle}
                      </Text>
                    </View>
                    <Text style={styles.subtitle}>{props.route.params.siteDetail.detail}</Text>
                  </Pressable>
                </View>
                {loading && (
                  <View style={{ marginTop: 50 }}>
                    <Loading />
                  </View>
                )}
                {
                  <SectionList
                    sections={siteLevelNAreas}
                    renderItem={({ item, section, index }) =>
                      section.index == currentLevel ? (
                        <Animated.View
                          style={[
                            {
                              opacity: fadeAnimation,
                            },
                          ]}
                        >
                          <Pressable
                            style={styles.areaTitleSection}
                            onPress={() => {
                              openInnerSection(index, item._id);
                            }}
                          >
                            <Text style={styles.lareaTitle}>{item.name}</Text>
                            {currentArea == index ? (
                              <FiChevronUp style={styles.screenNameIcon} />
                            ) : (
                              <FiChevronDown style={styles.screenNameIcon} />
                            )}
                          </Pressable>

                          {currentArea == index && assetLoading ? (
                            <View style={{ marginTop: 50, marginBottom: 50 }}>
                              <Loading opacity={1} />
                            </View>
                          ) : (
                            currentArea == index &&
                            innerData.map((item, index) => {
                              console.log(item);
                              return (
                                <TouchableOpacity
                                  onPress={() =>
                                    props.navigation.navigate('AssetInfo', {
                                      asset: item,
                                      fromAssetDir: true,
                                      fromQrCodeNav: true,
                                    })
                                  }
                                  key={index}
                                >
                                  <View style={styles.accordionHeader}>
                                    <View>
                                      <View
                                        style={{
                                          flexDirection: 'row',
                                          width: '85%',
                                        }}
                                      >
                                        <View style={styles.iconContainer}>
                                          <Image
                                            style={styles.accordionHeaderIcon}
                                            source={{ uri: item.assetType.icon }}
                                          />
                                        </View>
                                        <View style={styles.accordionHeaderContent}>
                                          <Text style={styles.textBold}>{item.name}</Text>
                                        </View>
                                      </View>
                                    </View>
                                    <View style={styles.export}>
                                      <FiChevronRight name="right" style={styles.accordionIcon} />
                                    </View>
                                  </View>
                                </TouchableOpacity>
                              );
                            })
                          )}
                        </Animated.View>
                      ) : (
                        <></>
                      )
                    }
                    renderSectionHeader={({ section: { title, index, levelid } }) => {
                      return (
                        <Pressable
                          style={styles.levelTitleSection}
                          onPress={() => {
                            openSection(index, levelid);
                          }}
                        >
                          <Text style={styles.levelTitle}>{title}</Text>
                          {currentLevel == index ? (
                            <FiChevronUp style={styles.screenNameIcon} />
                          ) : (
                            <FiChevronDown style={styles.screenNameIcon} />
                          )}
                        </Pressable>
                      );
                    }}
                  />
                }
                <Pressable
                  style={styles.levelTitleSection}
                  onPress={() => {
                    openSection(siteLevelNAreas.length + 1, 0);
                  }}
                >
                  <Text style={styles.levelTitle}>All Assets</Text>
                  {currentLevel == siteLevelNAreas.length + 1 ? (
                    <FiChevronUp style={styles.screenNameIcon} />
                  ) : (
                    <FiChevronDown style={styles.screenNameIcon} />
                  )}
                </Pressable>
                {allAssets &&
                  currentLevel === siteLevelNAreas.length + 1 &&
                  allAssets.map((item, index) => {
                    console.log(item);
                    return (
                      <TouchableOpacity
                        onPress={() =>
                          props.navigation.navigate('AssetInfo', {
                            asset: item,
                            fromAssetDir: true,
                            fromQrCodeNav: true,
                          })
                        }
                        key={index}
                      >
                        <View style={styles.accordionHeader}>
                          <View>
                            <View
                              style={{
                                flexDirection: 'row',
                                width: '85%',
                              }}
                            >
                              <View style={styles.iconContainer}>
                                <Image style={styles.accordionHeaderIcon} source={{ uri: item.assetType.icon }} />
                              </View>
                              <View style={styles.accordionHeaderContent}>
                                <Text style={styles.textBold}>{item.name}</Text>
                              </View>
                            </View>
                          </View>
                          <View style={styles.export}>
                            <FiChevronRight name="right" style={styles.accordionIcon} />
                          </View>
                        </View>
                      </TouchableOpacity>
                    );
                  })}
              </View>
            </View>
          </ScrollView>
          {params.admin && params.hasWriteAccess && (
            <View style={styles.assignButton}>
              <Button
                title={'+ Add Asset'}
                buttonStyle={styles.blueButtonActive}
                textStyle={styles.blueButtonText}
                onPress={() => {
                  console.log('clieck to add asset, prams: ', params);
                  props.navigation.navigate('TabNavigator', {
                    screen: 'Assets',
                    params: {
                      screen: 'AddAssets',
                      fromQRCodeNav: true,
                      fromViewProjectAssets: true,
                      companyid: params.companyid,
                      projectid: params.projectid,
                      siteid: params.siteDetail.id,
                    },
                  });
                }}
              />
            </View>
          )}
        </>
      }
      {...props}
    />
  );
}
