import React, { useEffect, useState, useCallback } from 'react';
import { Platform, SafeAreaView, ScrollView, Text, TextInput, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import Button from '../../../../components/Button';
import NavigationHeader from '../../../../components/SignUp/SingUpNavigation';
import images from '../../../../theme/images';
import Autocomplete from '../../../../components/AutoComplete/autoComplete';
import { styles } from './style';
import DropInputField from '../../../../components/DropInputField';
import { AssetTypes } from '../../../../constants/assetTypes';
import { api } from '../../../../utils/api';
import { useIsFocused } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { debounce } from 'lodash';

function AddAssets({ ...props }) {
  const params = props.route?.params;
  const [isSelect, setIsSelect] = useState(false);
  const [input, setInput] = useState('');
  const [serial, setSerial] = useState('');
  const [assetType, setSelectedDropdownItem] = useState('Select type');
  const [fieldsValidated, setFieldsValidated] = useState(false);
  const [company, setCompany] = useState(null);
  const [projectInput, setProjectInput] = useState('');
  const [siteInput, setSiteInput] = useState('');
  const [levelInput, setLevelInput] = useState('');
  const [areaInput, setAreaInput] = useState('');
  const [companyList, setCompanyList] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [siteList, setSitesList] = useState([]);
  const [levelData, setLevelData] = useState([]);
  const [areaData, setAreaData] = useState([]);
  const [siteTypes, setSiteTypes] = useState([]);
  const [tagInput, setTagInput] = useState('');
  const [filteredTagOptions, setFilteredTagOptions] = useState([]);

  const getProjects = async (company) => {
    const projects = await api(`v1/project/company/${company}`, 'GET', null);
    if (projects.status == 200) {
      if (projects.data.success) {
        let data = projects.data;
        console.log(data);
        var arrayProjects = [];
        for (let i = 0; i < data.projects.length; i++) {
          const element = data.projects[i];
          const obj = {
            title: element.name,
            detail: `${element.address.street}, ${element.address.city}, ${element.address.state}, ${element.address.country}`,
            image: element.logo,
            id: element._id,
          };
          arrayProjects.push(obj);
        }
        setProjectData(arrayProjects);
      }
    }
  };

  const debouncedGetFilteredTags = useCallback(
    debounce(async (searchString) => {
      if (typeof searchString === 'string') {
        if (searchString.length < 4) {
          setFilteredTagOptions([]);
        } else {
          const response = await api(`v1/company/${company.id}/tags/filtered?search=${searchString}`, 'GET', null);

          if (response.status === 200 && response.data.success) {
            const tempTags = [];
            for (const tag of response.data.tags) {
              tempTags.push({ ...tag, id: tag._id, title: tag.code });
            }
            setFilteredTagOptions(tempTags);
          }
        }
      }
    }, 300),
    [company]
  );

  const getSiteTypes = async () => {
    const siteTypes = await api('v1/site-type/', 'GET', null);
    if (siteTypes.status == 200) {
      if (siteTypes.data.success) {
        let data = siteTypes.data;
        var arraySiteTypes = [];
        for (let i = 0; i < data.siteTypes.length; i++) {
          const element = data.siteTypes[i];
          const obj = {
            title: element.name,
            id: element._id,
            defaultCadence: element.defaultTestCadence,
          };
          arraySiteTypes.push(obj);
        }
        setSiteTypes(arraySiteTypes);
      }
    }
  };

  const getCompany = async () => {
    const response = await api(`v1/company/qrcode/?qrcode=${params.qrCode}`, 'GET', null);
    if (response.status === 200 && response.data.success) {
      const tempCompany = response.data.company;
      setCompany({ title: tempCompany.name, id: tempCompany._id });
    }
  };

  const getCompanies = async () => {
    const response = await api('v1/user/companies', 'GET', null);
    if (response.status == 200 && response.data.success) {
      const companies = response.data.companies;
      const tempCompanyList = [];
      for (const company of companies) {
        tempCompanyList.push({ title: company.name, id: company._id });
      }
      setCompanyList(tempCompanyList);
    }
  };

  const getSites = async (project) => {
    const sites = await api(`v1/site/project/${project}`, 'GET', null);
    if (sites.status == 200) {
      if (sites.data.success) {
        let data = sites.data;
        var arraySites = [];
        for (let i = 0; i < data.sites.length; i++) {
          const element = data.sites[i];
          const obj = {
            title: element.name,
            name: element.name,
            detail: `${element.address.street}, ${element.address.city}, ${element.address.state}, ${element.address.country}`,
            id: element._id,
            location: element.address,
            testCadence: element.testCadence,
          };
          arraySites.push(obj);
        }
        setSitesList(arraySites);
      }
    }
  };

  const getLevels = async (project, site) => {
    const levels = await api(`v1/level/${project}?siteid=${site}`, 'GET', null);
    console.log('levels: ', levels.data);
    if (levels.status == 200) {
      if (levels.data.success) {
        let data = levels.data;
        var arrayLevels = [];
        for (let i = 0; i < data.levels.length; i++) {
          const element = data.levels[i];
          const obj = {
            title: element.name,
            id: element._id,
          };
          arrayLevels.push(obj);
        }
        setLevelData(arrayLevels);
      }
    }
  };

  const getAreas = async (project, site, level) => {
    const areas = await api(`v1/area/${project}?site=${site}&level=${level}`, 'GET', null);
    console.log('areas: ', areas.data);
    if (areas.status == 200) {
      if (areas.data.success) {
        let data = areas.data;
        var arrayAreas = [];
        for (let i = 0; i < data.areas.length; i++) {
          const element = data.areas[i];
          const obj = {
            title: element.name,
            id: element._id,
          };
          arrayAreas.push(obj);
        }
        setAreaData(arrayAreas);
      }
    }
  };

  const setProjectValue = (data) => {
    console.log(data);
    setProjectInput(data);
  };

  const updateSiteInput = (data) => {
    // console.log("data: ", data);
    if (Array.isArray(data)) {
      var updateData = data[0];
      updateData.title = data[0].name;
      setSiteInput(updateData);
    } else {
      setSiteInput(data);
    }
  };

  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused) {
      getSiteTypes();
      if (params.qrCode) {
        getCompany();
      } else {
        getCompanies();
      }

      if (params.fromViewProjectAssets) {
        console.log('params: ', params);
        setPrefilledValues(params.companyid, params.projectid, params.siteid);
      }
    }
  }, [isFocused]);

  useEffect(() => {
    console.log('assetType: ', assetType?.title);
    console.log('input: ', input);
    console.log('projectInput: ', projectInput?.title);
    console.log('assetType: ', assetType?.title);
    console.log('siteInput: ', siteInput?.title);
    console.log('tagInput: ', tagInput?.code);
    console.log('params.qrCode : ', params.qrCode);
    if (
      assetType?.title?.length > 2 &&
      input.length > 2 &&
      projectInput?.title?.length > 2 &&
      siteInput?.title?.length > 2
      // (params.qrCode || tagInput?.code?.length > 3)
    ) {
      setFieldsValidated(true);
    }
  }, [siteInput, projectInput, levelInput, areaInput, assetType, input, tagInput]);

  const setPrefilledValues = async (cId, pId, sId) => {
    const company = await api(`v1/company/${cId}`, 'GET', null);
    if (company.status == 200) {
      const data = company.data.company;
      let obj = {
        name: data.name,
        title: data.name,
        id: data._id,
      };
      setCompany(obj);
    }

    const project = await api(`v1/project/${pId}`, 'GET', null);
    if (project.status == 200) {
      if (project.data.success) {
        const data = project.data.project;
        let obj = {
          name: data.name,
          title: data.name,
          id: data._id,
        };
        setProjectInput(obj);
      }
    }

    const site = await api(`v1/site/${sId}`, 'GET', null);
    if (site.status == 200) {
      if (site.data.success) {
        const data = site.data.site;
        console.log(data);
        let obj = {
          name: data.name,
          title: data.name,
          id: data._id,
          testCadence: data.testCadence,
        };
        setSiteInput(obj);
      }
    }
  };

  // console.log("params: ", params);

  return (
    <View style={styles.container}>
      <SafeAreaView
        style={{
          flex: 1,
        }}
      >
        <KeyboardAwareScrollView keyboardShouldPersistTaps="always">
          <View
            style={
              Platform.OS === 'ios'
                ? [
                    styles.loading,
                    {
                      marginTop: 0,
                    },
                  ]
                : styles.loading
            }
          >
            <View style={styles.loadPercent}></View>
          </View>
          <NavigationHeader
            onPress={() => {
              props.navigation.getParent().setParams({
                screen: 'AdminAssetRegistry',
                isAdmin: params.isAdmin,
              });
              if (params.qrCode) {
                props.navigation.navigate('QrCodeNav', {
                  isAdmin: params.isAdmin,
                });
              } else {
                props.navigation.navigate('AdminAssetRegistry');
              }
            }}
            header={'Add Asset'}
            navigate={'Cancel'}
            menuStyles={styles.menu}
          />
          <View style={styles.form}>
            <View style={styles.textField}>
              <Text style={styles.titleText}>The basics</Text>
              <View style={styles.title}>
                <Text style={styles.inputHeader}>Asset Type</Text>
                <Text style={styles.required}> *</Text>
              </View>
              <DropInputField
                data={AssetTypes}
                selectedDropdownItem={assetType}
                setSelectedDropdownItem={setSelectedDropdownItem}
                havePosition={false}
              />
              <View style={styles.title}>
                <Text style={styles.inputHeader}>Title</Text>
                <Text style={styles.required}> *</Text>
              </View>

              <TextInput
                placeholder="Bosch Drill"
                style={styles.inputTitle}
                onChangeText={(text) => {
                  setInput(text);
                }}
                value={input}
              />
              <View style={styles.title}>
                <Text style={styles.inputHeader}>Serial Number</Text>
                <Text style={styles.optional}> - If applicable</Text>
              </View>

              <View style={{ position: 'relative' }}>
                <TextInput
                  style={[styles.inputTitle, styles.serialInput]}
                  onChangeText={(text) => {
                    setSerial(text);
                  }}
                  value={serial}
                />
                <Text style={styles.serialHash}>#</Text>
              </View>
              {params.fromViewProjectAssets ? (
                <></>
              ) : (
                <>
                  {!params.qrCode && (
                    <>
                      <View style={styles.title}>
                        <Text style={styles.inputHeader}>Company</Text>
                        <Text style={styles.required}> *</Text>
                      </View>
                      <Autocomplete
                        value={company}
                        setValue={setCompany}
                        data={companyList}
                        onChange={() => setIsSelect(!isSelect)}
                        placeholder="Enter company name"
                        style={{ flex: 1 }}
                        containerStyle={styles.inputSearchDrop}
                        disableAdd={true}
                      />

                      {company?.title?.length > 5 && (
                        <>
                          <View style={styles.title}>
                            <Text style={styles.inputHeader}>Tag</Text>
                            <Text style={styles.required}> *</Text>
                          </View>
                          <Autocomplete
                            value={tagInput}
                            setValue={setTagInput}
                            data={filteredTagOptions}
                            onChange={debouncedGetFilteredTags}
                            placeholder="Enter tag code"
                            style={{ flex: 1 }}
                            containerStyle={styles.inputSearchDrop}
                            disableAdd={true}
                            showAll={true}
                          />
                        </>
                      )}
                    </>
                  )}

                  {company?.title?.length > 5 && (
                    <>
                      <View style={styles.title}>
                        <Text style={styles.inputHeader}>Project</Text>
                        <Text style={styles.required}> *</Text>
                      </View>
                      <Autocomplete
                        value={projectInput}
                        setValue={(data) => setProjectValue(data)}
                        data={projectData}
                        onChange={() => setIsSelect(!isSelect)}
                        onFocus={() => getProjects(company.id)}
                        placeholder="Enter project name"
                        style={{ flex: 1 }}
                        modalType="Project"
                        containerStyle={styles.inputSearchDrop}
                        newType="project"
                        companyInput={company}
                      />
                    </>
                  )}

                  {projectInput?.title?.length > 5 && (
                    <>
                      <View style={styles.title}>
                        <Text style={styles.inputHeader}>Site</Text>
                        <Text style={styles.required}> *</Text>
                      </View>
                      <Autocomplete
                        value={siteInput}
                        setValue={(data) => updateSiteInput(data)}
                        data={siteList}
                        siteTypes={siteTypes}
                        onChange={() => setIsSelect(!isSelect)}
                        onFocus={() => getSites(projectInput.id)}
                        modalType="Site"
                        placeholder="Start typing site..."
                        containerStyle={styles.inputSearchDrop}
                        newType="site"
                        projectId={projectInput.id}
                      />
                    </>
                  )}
                </>
              )}

              {(siteInput?.title?.length > 5 || params.fromViewProjectAssets) && (
                <>
                  <View style={styles.title}>
                    <Text style={styles.inputHeader}>Level</Text>
                    <Text style={styles.optional}> - optional</Text>
                  </View>
                  <Autocomplete
                    value={levelInput}
                    setValue={setLevelInput}
                    data={levelData}
                    onChange={() => setIsSelect(!isSelect)}
                    onFocus={() => getLevels(projectInput.id, siteInput.id)}
                    modalType="Level"
                    placeholder="e.g. Level 1"
                    containerStyle={styles.inputSearchDrop}
                    newType="level"
                    projectId={projectInput.id}
                    siteInput={siteInput}
                  />
                </>
              )}
              {(levelInput?.title?.length > 3 || params.fromViewProjectAssets) && (
                <>
                  <View style={styles.title}>
                    <Text style={styles.inputHeader}>Area</Text>
                    <Text style={styles.optional}> - optional</Text>
                  </View>
                  <Autocomplete
                    value={areaInput}
                    setValue={setAreaInput}
                    data={areaData}
                    onChange={() => setIsSelect(!isSelect)}
                    onFocus={() => getAreas(projectInput.id, siteInput.id, levelInput.id)}
                    modalType="Area"
                    style={{
                      flex: 1,
                      fontFamily: 'NunitoSans_700Bold',
                      fontSize: 16,
                      color: '#000000',
                    }}
                    placeholder="Start typing area..."
                    containerStyle={styles.inputSearchDrop}
                    newType="area"
                    projectId={projectInput.id}
                    siteInput={siteInput}
                    levelInput={levelInput}
                  />
                </>
              )}
            </View>
          </View>
        </KeyboardAwareScrollView>
        <View style={styles.assignButton}>
          <Button
            title={'Next'}
            buttonStyle={fieldsValidated ? styles.blueButtonActive : styles.blueButton}
            textStyle={styles.blueButtonText}
            onPress={() => {
              fieldsValidated
                ? props.navigation.navigate('TestingSchedule', {
                    assetInfo: {
                      assetType: assetType,
                      assetName: input,
                      assetSerial: serial,
                      assetProject: projectInput,
                      assetSite: siteInput,
                      assetLevel: levelInput,
                      assetArea: areaInput,
                    },
                    qrCode: params.qrCode ? params.qrCode : tagInput.code,
                  })
                : '';
            }}
          />
        </View>
      </SafeAreaView>
    </View>
  );
}
export default AddAssets;
