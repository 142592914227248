import React, { useMemo, useState, useEffect } from 'react';
import { Calendar } from 'react-native-calendars';
import { useIsFocused } from '@react-navigation/native';
import moment from 'moment';
import { api } from '../../../../utils/api';
import { dateStringToUTC } from '../../../../utils/dateUtils';
import CustomArrow from '../../../../components/CustomArrow';

export default function TaskCalendar({ ...props }) {
  const { selectedDate, setSelectedDate, setTaskGroupsLoading } = { ...props };

  const [selectedMonth, setSelectedMonth] = useState(moment().startOf('month'));
  const [markedDates, setMarkedDates] = useState({});
  const marked = useMemo(
    () => ({
      ...markedDates,
      [selectedDate]: {
        selected: true,
        selectedColor: 'rgba(19,47,245,0.09)',
        selectedTextColor: '#132FF5',
        marked: markedDates[selectedDate] ? true : false,
      },
    }),
    [selectedDate, markedDates]
  );

  const getMarkedDates = async () => {
    const response = await api(
      `v1/task/calendar/marked?selected_month_utc=${dateStringToUTC(selectedMonth)}&timezone=${
        Intl.DateTimeFormat().resolvedOptions().timeZone
      }`,
      'GET',
      null
    );

    if (response.status === 200 && response.data.success) {
      const newMarkedDates = response.data.dates;
      setMarkedDates(newMarkedDates);
    }
  };

  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      getMarkedDates();
    }
  }, [isFocused, selectedMonth]);

  return (
    <Calendar
      renderArrow={(direction, onPress) => <CustomArrow direction={direction} onPress={onPress} />}
      hideArrows={false}
      markedDates={marked}
      enableSwipeMonths={true}
      theme={{
        arrowColor: '#0F0000',
      }}
      context={{ date: '' }}
      onDayPress={(day) => {
        setSelectedDate(day.dateString);
        setTaskGroupsLoading(true);
      }}
      onMonthChange={(day) => {
        setSelectedMonth(moment(day.dateString).startOf('month'));
      }}
    />
  );
}
