import React from 'react';
import {Text, View } from 'react-native';
import {styles} from './style';

export default function Pass({...props}) {
  return (
    <View style={styles.assetStatus}>
      <Text style={styles.assetStatusText}>•</Text>
      <Text style={[styles.assetStatusText,{
        fontSize:12,
      }]}>Pass</Text>
    </View>
  )
}

