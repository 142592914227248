import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../../../../theme/colors';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  header: {
    backgroundColor: '#F6F6F6',
    paddingTop: 40,
    paddingBottom: 30,
  },
  body: {
    backgroundColor: 'white',
    marginBottom: 20,
    paddingTop: 10,
  },
  menuContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 23,
  },
  appbarTrailingIcon: {
    alignSelf: 'flex-end',
    width: 24,
    height: 24,
    tintColor: 'black',
  },
  textBold: {
    fontSize: 18,
    fontFamily: 'NunitoSans_700Bold',
  },
  textMediumBold: {
    fontSize: 14,
    fontFamily: 'NunitoSans_700Bold',
  },
  textSmallBold: {
    fontSize: 14,
    fontFamily: 'NunitoSans_700Bold',
    color: '#6B6B6B',
  },
  inputHeader: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 16,
  },
  title: {
    paddingTop: 15,
    paddingHorizontal: 23,
    flexDirection: 'row',
    alignItems: 'center',
  },
  required: {
    color: '#FF4D4D',
  },
  textSmallerBold: {
    textTransform: 'capitalize',
    fontSize: 12,
    fontFamily: 'NunitoSans_700Bold',
    color: '#6B6B6B',
  },
  textRegular: {
    fontSize: 12,
    fontFamily: 'NunitoSans_400Regular',
  },
  textTinyBold: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 10,
  },
  textSmallSemibold: {
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 12,
  },
  overview: {
    paddingLeft: 23,
    paddingRight: 27,
  },
  overviewDetail: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 16,
  },
  statusContainer: {
    flexDirection: 'row',
    padding: 3,
    backgroundColor: '#132ff51a',
    borderRadius: 13,
  },
  status: {
    paddingHorizontal: 5,
    borderRadius: 8,
    paddingVertical: 1,
  },

  overviewInfo: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  dueDate: {
    backgroundColor: '#6b6b6b1a',
    paddingHorizontal: 20,
    justifyContent: 'center',
    borderRadius: 13,
    paddingVertical: 2,
  },
  penIcon: {
    borderWidth: 1,
    borderColor: '#979797',
    justifyContent: 'center',
    padding: 5,
    borderRadius: 99,
  },
  taskDetailContainer: {
    borderTopColor: '#00000014',
    borderTopWidth: 1,
  },
  taskDetailTitle: {
    paddingLeft: 23,
    paddingRight: 27,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 19,
  },

  grayBoldText: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 14,
    color: '#6B6B6B',
    paddingBottom: 9,
    paddingRight: 18,
  },
  blueBoldText: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 14,
    color: '#132FF5',
    paddingBottom: 9,
    paddingRight: 18,
  },
  taskLabel: {
    backgroundColor: '#FFFFFF',
    alignSelf: 'flex-start',
    paddingHorizontal: 10,
    paddingVertical: 2,
    borderRadius: 8,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.13,
    shadowRadius: 2,
    elevation: 5,
  },
  contentBlock: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D0D0D0',
    flexDirection: 'row',
    alignItems: 'center',
    // justifyContent:"space-between",
    paddingVertical: 6,
    paddingHorizontal: 8,
    marginTop: 10,
  },
  taskIcon: {
    width: 43,
    height: 43,
  },
  taskTitle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  leadIcon: {
    fontSize: 14,
    paddingLeft: 6,
  },
  taskDetail: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  textLightSmall: {
    fontFamily: 'NunitoSans_300Light',
    fontSize: 12,
  },
  textMicroBold: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 11,
  },
  inputIcon: {
    paddingLeft: 8,
  },
  assigneeContainer: {
    marginTop: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomColor: '#0000000d',
    borderBottomWidth: 1,
    paddingBottom: 7,
  },
  assigneeDetail: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  popupHeader: {
    alignItems: 'center',
  },
  textBoldLarge: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
  },
  textRegular: {
    fontFamily: 'NunitoSans_400Regular',
  },
  textRegularSmall: {
    fontFamily: 'NunitoSans_400Regular',
    fontSize: 12,
  },

  script: {
    marginTop: 8,
    marginBottom: 9,
    fontFamily: 'NunitoSans_400Regular',
    textAlign: 'center',
  },

  input: {
    marginTop: 16,
    borderColor: '#C7C7C7',
    borderWidth: 1,
    borderRadius: 8,
    paddingVertical: 20,
    paddingLeft: 13,
    marginHorizontal: 10,
    minHeight: 121,
    marginBottom: 7,
    fontFamily: 'NunitoSans_400Regular',
    fontSize: 14,
  },
  noteField: {
    marginTop: 16,
    height: 218,
    marginHorizontal: 23,
    borderColor: '#C7C7C7',
    borderWidth: 1,
    borderRadius: 8,
    paddingVertical: 10,
    paddingLeft: 13,
    justifyContent: 'space-between',
  },
  textCount: {
    fontFamily: 'NunitoSans_400Regular',
    color: '#C7C7C7',
  },
  imgPickedContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 16,
  },
  documentPicked: {
    paddingVertical: 8,
    paddingLeft: 7,
    backgroundColor: '#8997fa1a',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#132ff5',
    borderRadius: 8,
    flex: 12,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  docTitle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  docIcon: {
    fontSize: 18,
    paddingRight: 6,
    color: '#132FF5',
  },
  titleTextPicked: {
    fontFamily: 'NunitoSans_700Bold',
    paddingLeft: 4,
  },
  closeIcon: {
    flex: 1,
    fontSize: 29,
    alignSelf: 'center',
    paddingLeft: 17,
    color: '#C7C7C7',
  },
  uploadField: {
    paddingTop: 15,
    paddingHorizontal: 23,
  },
  blueButtonActive: {
    borderRadius: 8,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
    marginBottom: 10,
  },
  blueButtonText: {
    color: colors.white,
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 18,
    textAlign: 'center',
  },
});
