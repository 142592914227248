import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';
import Auth0Screen from '../../screens/onboarding/auth0Screen';
import LicenseNumber from '../../screens/onboarding/authen/licenseNumber';
import JoinProject from '../../screens/onboarding/authen/joinProject';
import UserProfile from '../../screens/onboarding/authen/userProfile';
import WelcomeScreen from '../../screens/onboarding/welcomeScreen';
import UserType from '../../screens/onboarding/authen/userType';
import SubscriptionSelection from '../../screens/onboarding/authen/subscriptionSelection';
import SubscriptionCheckout from '../../screens/onboarding/authen/subscriptionCheckout';
import SettingUpProjectStep4 from '../../screens/admin/settingUpProject/step4';
import SettingUpProjectStep5 from '../../screens/admin/settingUpProject/step5';
import SettingUpProjectStep6 from '../../screens/admin/settingUpProject/step6';
import SettingUpProjectStep7 from '../../screens/admin/settingUpProject/step7';

const Stack = createNativeStackNavigator();

const OnboardingNavigation = ({ ...props }) => (
  <Stack.Navigator>
    <Stack.Screen
      name={'Auth0Screen'}
      component={Auth0Screen}
      options={{
        headerShown: false,
        headerStyle: { backgroundColor: 'transparent' },
      }}
    />
    <Stack.Screen
      {...props}
      name={'UserProfile'}
      component={UserProfile}
      options={{
        headerShown: false,
        gestureEnabled: true,
      }}
    />
    <Stack.Screen
      {...props}
      name={'LicenseNumber'}
      component={LicenseNumber}
      options={{
        headerShown: false,
        gestureEnabled: true,
      }}
    />
    <Stack.Screen
      {...props}
      name={'UserType'}
      component={UserType}
      options={{
        headerShown: false,
        gestureEnabled: true,
      }}
    />
    <Stack.Screen
      {...props}
      name={'JoinProject'}
      component={JoinProject}
      options={{
        headerShown: false,
        gestureEnabled: true,
      }}
    />
    <Stack.Screen
      {...props}
      name={'SubscriptionSelection'}
      component={SubscriptionSelection}
      options={{
        headerShown: false,
        gestureEnabled: true,
      }}
    />
    <Stack.Screen
      {...props}
      name={'SubscriptionCheckout'}
      component={SubscriptionCheckout}
      options={{
        headerShown: false,
        gestureEnabled: true,
      }}
    />

    <Stack.Screen
      {...props}
      name={'WelcomeScreen'}
      component={WelcomeScreen}
      options={{
        headerShown: false,
        gestureEnabled: true,
      }}
    />
    <Stack.Screen
      {...props}
      name={'SettingUpProjectStep4'}
      component={SettingUpProjectStep4}
      options={{
        headerShown: false,
        gestureEnabled: true,
      }}
    />
    <Stack.Screen
      {...props}
      name={'SettingUpProjectStep5'}
      component={SettingUpProjectStep5}
      options={{
        headerShown: false,
        gestureEnabled: true,
      }}
    />
    <Stack.Screen
      {...props}
      name={'SettingUpProjectStep6'}
      component={SettingUpProjectStep6}
      options={{
        headerShown: false,
        gestureEnabled: true,
      }}
    />
    <Stack.Screen
      {...props}
      name={'SettingUpProjectStep7'}
      component={SettingUpProjectStep7}
      options={{
        headerShown: false,
        gestureEnabled: true,
      }}
    />
  </Stack.Navigator>
);
export default OnboardingNavigation;
