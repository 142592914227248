import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import moment from 'moment';

const EnvConstants = require('../../../EnvConstants');

export const api = async function (endpoint, type, data, useFormData = false) {
  // const domain = 'http://192.168.0.8:8092/';
  // const domain = EnvConstants.config.STAGINGDOMAIN;
  const domain = EnvConstants.config.DOMAIN;
  const token = await AsyncStorage.getItem('token');
  const expiresIn = await AsyncStorage.getItem('expiresIn');

  console.log('expires in: ', expiresIn);

  if (expiresIn) {
    var duration = moment.duration(moment(expiresIn, 'YYYY-MM-DD HH:mm:ss').diff(moment()));
    var difInMins = duration.asMinutes();

    if (difInMins < -1) {
      console.log('expired token');
      // await AsyncStorage.multiRemove(['user', 'token', 'refreshToken', 'expiresIn']);
      return 'expired';
    } else if (difInMins <= 4000) {
      // await refreshToken();
    }
  }

  let headers = {};

  if (useFormData) {
    headers = {
      authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    };
  } else {
    headers = {
      authorization: `Bearer ${token}`,
    };
  }

  try {
    let request = await axios({
      method: type,
      url: domain + endpoint,
      headers,
      data: data,
    });
    return request;
  } catch (error) {
    console.log('Domain: ', domain);
    console.log('Endpoint: ', endpoint);
    console.log('Type: ', endpoint);
    console.log('API Error: ', error);
    console.log('API response: ', error.response);
    // const token = await AsyncStorage.removeItem('token');
    // const expiresIn = await AsyncStorage.removeItem('expiresIn');
    return error.response;
  }
};

export const refreshToken = async function () {
  console.log('refresh token, its about to expire');
  const refreshToken = await AsyncStorage.getItem('refreshToken');

  console.log('rf token: ', refreshToken);

  if (refreshToken !== null) {
    try {
      let request = await axios({
        method: 'POST',
        url: EnvConstants.config.AUTHDOMAIN + '/oauth/token',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify({
          grant_type: 'refresh_token',
          client_id: EnvConstants.config.CLIENTID,
          client_secret: EnvConstants.config.SECRET,
          refresh_token: refreshToken,
        }),
      });
      if (request.status == 200) {
        let result = request.data;
        AsyncStorage.setItem('token', result.access_token);
        AsyncStorage.setItem('refreshToken', result.refresh_token);
        let expiry = moment().add(result.expires_in, 'seconds');
        AsyncStorage.setItem('expiresIn', String(expiry));
      }
    } catch (error) {
      console.log('API Error: ', error);
    }
  }
};
