import { useEffect, useRef } from 'react';
import { Animated } from 'react-native';

const PlaceholderLoading = ({ isFetching, width = 120, height = 20, marginBottom = 0 }) => {
  const backgroundColourIndex = useRef(new Animated.Value(0)).current;
  var color = backgroundColourIndex.interpolate({
    inputRange: [1, 2],
    outputRange: ['rgba(198, 198, 198, 1)', 'rgba(240, 240, 240, 1)'],
  });

  useEffect(() => {
    if (isFetching) {
      animateLoading(2);
    }
  }, [isFetching]);

  const animateLoading = (input) => {
    var toValue = input == 2 ? 1 : 2;

    Animated.timing(backgroundColourIndex, {
      toValue: toValue,
      duration: 1000,
      useNativeDriver: false,
    }).start(() => (isFetching ? animateLoading(toValue) : null));
  };

  return (
    <Animated.View
      style={[{ backgroundColor: color, width: width, height: height, borderRadius: 8, marginBottom: marginBottom }]}
    />
  );
};

export default PlaceholderLoading;
