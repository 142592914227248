import React from 'react';
import { View, Image, TouchableOpacity, Text } from 'react-native';
import images from '../../theme/images';
import { styles } from './style';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import QrCodeNav from './qrCodeNav';
import Task from './task';
import AssetRegistry from './assetRegistry';
import Notifications from './notifications';
import SettingNavigate from './setting';
import { getFocusedRouteNameFromRoute } from '@react-navigation/native';

function OneTagBar({ state, descriptors, navigation, isAdmin, isViewOnly }) {
  const focusedOptions = descriptors[state.routes[state.index].key].options;

  if (focusedOptions.tabBarVisible === false) {
    return null;
  }

  let qrCodeNavFocused = false;
  // if (!isAdmin) {
  //   for (let ri = 0; ri < state.routes.length; ri++) {
  //     const element = state.routes[ri];
  //     if (element.name == 'QrCodeNav') {
  //       if (state.index == ri) {
  //         qrCodeNavFocused = true;
  //       }
  //     }
  //   }
  // }

  return (
    <View style={[styles.bottomBarNavContainer, qrCodeNavFocused == false ? styles.plainTabBar : '']}>
      {/* {qrCodeNavFocused && <Image style={styles.bottomBarWhiteBackground} source={images.bottomNAV} />} */}
      <View
        style={[
          styles.bottomNav,
          !qrCodeNavFocused && { paddingBottom: 24 },
          isViewOnly && { justifyContent: 'space-around' },
        ]}
      >
        {state.routes.map((route, index) => {
          const { options } = descriptors[route.key];
          const isFocused = state.index === index;
          const onPress = () => {
            const event = navigation.emit({
              type: 'tabPress',
              target: route.key,
              canPreventDefault: true,
            });

            if (!isFocused && !event.defaultPrevented) {
              // The `merge: true` option makes sure that the params inside the tab screen are preserved
              navigation.navigate({ name: route.name, merge: true });
            }
          };

          let image = '';
          let display = true;
          switch (route.name) {
            case 'UserTasks':
              image = !isFocused ? images.toDoTabIcon : images.toDoTabIconActive;
              display = isViewOnly ? false : true;
              break;
            case 'Assets':
              image = !isFocused ? images.assetsTabIcon : images.activeTag;
              display = true;
              break;
            case 'QrCodeNav':
              image = !isFocused ? images.clkFaviconInnactive : images.clkFavicon;
              display = true;
              break;
            case 'Notifications':
              image = !isFocused ? images.notificationTabIcon : images.notificationTabActive;
              display = isViewOnly ? false : true;
              break;
            case 'SettingNavigate':
              image = !isFocused ? images.accountTabIcon : images.accountTabIconActive;
              display = true;
              break;

            default:
              break;
          }
          if (display) {
            return (
              <TouchableOpacity
                accessibilityRole="button"
                accessibilityState={isFocused ? { selected: true } : {}}
                accessibilityLabel={options.tabBarAccessibilityLabel}
                testID={options.tabBarTestID}
                onPress={onPress}
                style={{ position: 'relative' }}
                key={index}
              >
                {
                  <Image
                    style={[
                      styles.tabNavIcon,
                      !isFocused && styles.iconInactive,
                      route.name == 'QrCodeNav' && qrCodeNavFocused && styles.cameraIcon,
                    ]}
                    source={image}
                  />
                }
              </TouchableOpacity>
            );
          }
        })}
      </View>
    </View>
  );
}

const getTabBarVisibility = (route) => {
  const routeScreen = getFocusedRouteNameFromRoute(route) ?? '';
  // const routeScreen = route?.params?.screen || ''
  // const routeName = route?.name || ''
  if (routeScreen === 'TestAssetFlowStep1') {
    return false;
  }
  if (routeScreen === 'TestAssetFlowStep2') {
    return false;
  }
  if (routeScreen === 'TestAssetFlowStep3') {
    return false;
  }
  if (routeScreen === 'TestAssetFlowStep4') {
    return false;
  }
  if (routeScreen === 'TestAssetFlowStep5') {
    return false;
  }
  if (routeScreen === 'TabAssetInfoScreen') {
    return false;
  }
  if (routeScreen === 'AddAssets') {
    return false;
  }
  if (routeScreen === 'AssetInfo') {
    return false;
  }
  if (routeScreen === 'TaskInner') {
    return false;
  }
  if (routeScreen === 'AddTask') {
    return false;
  }
  if (routeScreen === 'TaskInfo') {
    return false;
  }
  if (routeScreen === 'TaskOverView') {
    return false;
  }
  if (routeScreen === 'PersonalInfor') {
    return false;
  }
  if (routeScreen === 'MyProject') {
    return false;
  }
  if (routeScreen === 'SubscriptionBilling') {
    return false;
  }
  if (routeScreen === 'ProjectInfo') {
    return false;
  }
  if (routeScreen === 'TestingSchedule') {
    return false;
  }
  if (routeScreen === 'TestingHistory') {
    return false;
  }
  if (routeScreen === 'AssignAsset') {
    return false;
  }
  if (routeScreen === 'AssignTag') {
    return false;
  }
  return true;
};

const Tab = createBottomTabNavigator();
const TabNavigator = React.memo((props) => {
  let parentProps = props;
  return (
    <Tab.Navigator
      screenOptions={({ route }) => ({
        tabBarActiveTintColor: 'blue',
        tabBarInactiveTintColor: 'grey',
        tabBarVisible: getTabBarVisibility(route),
        unmountOnBlur: true,
      })}
      tabBar={(props) => (
        <OneTagBar
          {...props}
          isAdmin={parentProps.route.params.isAdmin}
          isViewOnly={parentProps.route.params.isViewOnly}
        />
      )}
      initialRouteName={'QrCodeNav'}
    >
      {parentProps.route.params.isAdmin ? (
        <Tab.Screen
          name="QrCodeNav"
          component={QrCodeNav}
          options={({ route }) => ({
            tabBarLabel: '',
            headerShown: false,
            gestureEnabled: true,
          })}
          initialParams={{
            isAdmin: true,
          }}
        ></Tab.Screen>
      ) : (
        <Tab.Screen
          name="UserTasks"
          component={Task}
          options={({ route }) => ({
            tabBarLabel: '',
            headerShown: false,
            gestureEnabled: true,
          })}
        ></Tab.Screen>
      )}
      {parentProps.route.params.isAdmin ? (
        <Tab.Screen
          name="UserTasks"
          component={Task}
          options={({ route }) => ({
            tabBarLabel: '',
            headerShown: false,
            gestureEnabled: true,
          })}
          initialParams={{
            screen: 'TaskListAll',
            isAdmin: true,
          }}
        ></Tab.Screen>
      ) : (
        <Tab.Screen
          name="Assets"
          component={AssetRegistry}
          options={({ route }) => ({
            tabBarLabel: '',
            headerShown: false,
            gestureEnabled: true,
          })}
        ></Tab.Screen>
      )}
      {parentProps.route.params.isAdmin ? (
        <Tab.Screen
          name="Assets"
          component={AssetRegistry}
          options={({ route }) => ({
            tabBarLabel: '',
            headerShown: false,
            gestureEnabled: true,
          })}
          initialParams={{
            screen: 'AdminAssetRegistry',
            isAdmin: true,
          }}
        ></Tab.Screen>
      ) : (
        <Tab.Screen
          name="QrCodeNav"
          component={QrCodeNav}
          options={({ route }) => ({
            tabBarLabel: '',
            headerShown: false,
            gestureEnabled: true,
          })}
        ></Tab.Screen>
      )}
      <Tab.Screen
        name="Notifications"
        component={Notifications}
        options={({ route }) => ({
          tabBarLabel: '',
          headerShown: false,
          gestureEnabled: true,
        })}
        initialParams={{
          isAdmin: parentProps.route.params.isAdmin,
        }}
      ></Tab.Screen>
      {parentProps.route.params.isAdmin ? (
        <Tab.Screen
          name="SettingNavigate"
          component={SettingNavigate}
          options={({ route }) => ({
            tabBarLabel: '',
            headerShown: false,
            gestureEnabled: true,
          })}
          initialParams={{
            screen: 'AccountSettings',
            isAdmin: true,
          }}
        ></Tab.Screen>
      ) : (
        <Tab.Screen
          name="SettingNavigate"
          component={SettingNavigate}
          options={({ route }) => ({
            tabBarLabel: '',
            headerShown: false,
            gestureEnabled: true,
          })}
        ></Tab.Screen>
      )}
    </Tab.Navigator>
  );
});

export default TabNavigator;
