import React, { useEffect, useState } from 'react';
import { Image, Pressable, View } from 'react-native';
import { FaChevronRight } from 'react-icons/fa';

import { Text } from 'react-native';
import images from '../../../../../theme/images';
import { styles } from './style';
import { useIsFocused } from '@react-navigation/native';
import { api } from '../../../../../utils/api';
import Loading from '../../../../../components/Loading';

function ProjectSite({ ...props }) {
  const [data, setProjectSites] = useState([]);
  const [siteLoading, setSiteLoading] = useState(true);
  const params = props.route.params;

  const getProjectSites = async () => {
    const sites = await api(`v1/site/project/${params.id}`, 'GET', null);
    // console.log("sites: ", sites.data);
    if (sites.status == 200) {
      if (sites.data.success) {
        let projectSites = [];
        let data = sites.data;

        for (let i = 0; i < data.sites.length; i++) {
          const element = data.sites[i];
          let assetCount = element.assetCount;
          const obj = {
            title: element.name,
            detail: `${element.address.street}, ${element.address.city}, ${element.address.state}, ${element.address.country}`,
            id: element._id,
            description: assetCount == 1 ? `${assetCount} Asset` : `${assetCount} Assets`,
          };
          projectSites.push(obj);
        }
        setProjectSites(projectSites);
      }
    } else {
      if (sites.data.error && sites.data.error == 'jwt expired') {
      }
    }
    setSiteLoading(false);
  };

  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      getProjectSites();
    }
  }, [isFocused]);

  return (
    <View style={{ marginTop: 21 }}>
      {siteLoading && (
        <View style={{ marginTop: 30 }}>
          <Loading opacity={1} />
        </View>
      )}
      {data.map((item, id) => {
        return (
          <View key={id} style={[styles.teamItem, { paddingLeft: 23, paddingRight: 31 }]}>
            <Pressable
              style={[styles.flexRow, { justifyContent: 'space-between', width: '100%' }]}
              onPress={() => {
                props.navigation.navigate('TestingSchedule', {
                  site: item,
                });
              }}
            >
              <View>
                <Text style={styles.textBold}>{item.title}</Text>
                <Text>{item.description}</Text>
              </View>
              <FaChevronRight name="right" style={styles.accordionIcon} />
            </Pressable>
          </View>
        );
      })}
    </View>
  );
}

export default ProjectSite;
