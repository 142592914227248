import React, { useEffect, useState } from 'react';
import { Platform, SafeAreaView, ScrollView, Text, View } from 'react-native';
import Button from '../../../../components/Button';
import NavigationHeader from '../../../../components/SignUp/SingUpNavigation';
import images from '../../../../theme/images';
import Autocomplete from '../../../../components/AutoComplete/autoComplete';
import { styles } from './style';
import { useMemo } from 'react';
import { Calendar } from 'react-native-calendars';
import DropInputField from '../../../../components/DropInputField';
import { api } from '../../../../utils/api';
import { useIsFocused } from '@react-navigation/native';
import { AssetTypes } from '../../../../constants/assetTypes';
import AsyncStorage from '@react-native-async-storage/async-storage';
import moment from 'moment';
import CustomArrow from '../../../../components/CustomArrow';

function AddTask({ ...props }) {
  const [isSelect, setIsSelect] = useState(false);
  const [date, setDate] = useState('');
  const [selectedDropdownItem, setSelectedDropdownItem] = useState('Select type');
  const [projectData, setProjectData] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);

  const params = props.route.params;

  const positionType = [
    {
      title: 'Technician',
      Tech: 'Technician',
      type: 'Tech',
    },
    {
      title: 'Admin',
      PM: 'Admin',
      type: 'PM',
    },
    {
      title: 'View Only',
      OHS: 'View Only',
      type: 'OHS',
    },
  ];

  const getTeamMembers = async (projectid) => {
    const team = await api(`v1/project/${projectid}/team?includeMe=true`, 'GET', null);
    if (team.status == 200) {
      if (team.data.success) {
        let data = team.data;
        console.log('datas: ', data);
        let teamMembers = [];
        for (let tm = 0; tm < data.team.length; tm++) {
          const element = data.team[tm];
          var role = '';
          for (let rt = 0; rt < positionType.length; rt++) {
            const el = positionType[rt];
            if (el[element.role]) {
              role = el[element.role];
            }
          }
          const obj = {
            title: element.firstName + ' ' + element.lastName,
            id: element._id,
            position: role,
          };
          teamMembers.push(obj);
        }
        setTeamMembers(teamMembers);
      }
    }
  };

  const getProjects = async () => {
    var userProjects = [];
    const projects = await api(`v1/project/userprojects`, 'GET', null);
    if (projects.status == 200) {
      if (projects.data.success) {
        let data = projects.data;
        console.log('project data: ', data);
        for (let i = 0; i < data.projects.length; i++) {
          const element = data.projects[i];
          const obj = {
            title: element.name,
            locationString: `${element.address.street}, ${element.address.city}, ${element.address.state}, ${element.address.country}`,
            location: element.address,
            image: element.logo,
            owner: element.company,
            id: element._id,
          };
          userProjects.push(obj);
        }
      }
    }

    setProjectData(userProjects);
  };

  const getCompanies = async () => {
    const companies = await api('v1/user/companies', 'GET', null);
    if (companies.status == 200) {
      if (companies.data.success) {
        let data = companies.data;
        var arrayCompanies = [];
        if (data.companies.length > 0) {
          for (let i = 0; i < data.companies.length; i++) {
            const element = data.companies[i];
            const obj = {
              title: element.name,
              id: element._id,
            };
            arrayCompanies.push(obj);
          }
        }
        setCompanyList(arrayCompanies);
      }
    }
  };

  const [fieldsValidated, setFieldsValidated] = useState(false);
  const [companyInput, setCompanyInput] = useState('');
  const [projectInput, setProjectInput] = useState('');
  const [selected, setSelected] = useState('');

  const marked = useMemo(
    () => ({
      [selected]: {
        selected: true,
        selectedColor: 'rgba(19,47,245,0.09)',
        selectedTextColor: '#132FF5',
      },
    }),
    [selected]
  );

  const getUser = async () => {
    var user = await AsyncStorage.getItem('user');
    user = JSON.parse(user);
    // console.log("add task params: ", params);
    if (params.hasWriteAccess == false) {
      var role = '';
      for (let rt = 0; rt < positionType.length; rt++) {
        const el = positionType[rt];
        if (el[user.role]) {
          role = el[user.role];
        }
      }
      const obj = {
        title: user.firstName + ' ' + user.lastName,
        id: user._id,
        position: role,
      };

      setSelectedDropdownItem(obj);
    }
  };

  useEffect(() => {
    if (selectedDropdownItem?.title?.length > 5 && selected.length > 5) {
      setFieldsValidated(true);
    }
  }, [selectedDropdownItem, selected]);

  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      getCompanies();
      getProjects();
      getUser();
    }
  }, [isFocused]);

  return (
    <View style={styles.container}>
      <SafeAreaView
        style={{
          flex: 1,
        }}
      >
        <ScrollView keyboardShouldPersistTaps="always">
          <View
            style={
              Platform.OS === 'ios'
                ? [
                    styles.loading,
                    {
                      marginTop: 0,
                    },
                  ]
                : styles.loading
            }
          >
            <View style={styles.loadPercent}></View>
          </View>
          <NavigationHeader
            onPress={() => props.navigation.goBack()}
            header={'Add A Task'}
            navigate={'Cancel'}
            menuStyles={styles.menu}
          />
          <View style={styles.form}>
            <View style={styles.textField}>
              <View style={styles.title}>
                <Text style={styles.inputHeader}>Company</Text>
                <Text style={styles.required}> *</Text>
              </View>
              {
                <Autocomplete
                  value={companyInput}
                  setValue={setCompanyInput}
                  data={companyList}
                  onChange={() => setIsSelect(!isSelect)}
                  placeholder="Enter company name"
                  style={{ flex: 1 }}
                  containerStyle={styles.inputSearchDrop}
                  disableAdd={true}
                />
              }

              <View style={styles.title}>
                <Text style={styles.inputHeader}>Project</Text>
              </View>

              <Autocomplete
                value={projectInput}
                setValue={(data) => {
                  setProjectInput(data);
                  getTeamMembers(data.id);
                }}
                data={projectData}
                onChange={() => setIsSelect(!isSelect)}
                placeholder="Enter project name..."
                style={{ flex: 1 }}
                modalType="Project"
                containerStyle={styles.inputSearchDrop}
                newType="project"
                companyInput={companyInput}
              />

              {projectInput?.title?.length > 5 && params.hasWriteAccess && (
                <>
                  <View style={styles.title}>
                    <Text style={styles.inputHeader}>Assignee</Text>
                    <Text style={styles.required}> *</Text>
                  </View>
                  <DropInputField
                    data={teamMembers}
                    selectedDropdownItem={selectedDropdownItem}
                    setSelectedDropdownItem={setSelectedDropdownItem}
                    havePosition={true}
                  />
                </>
              )}
            </View>
            <View style={{ paddingHorizontal: 17, flexDirection: 'row' }}>
              <Text style={styles.inputHeader}>Due Date</Text>
              <Text style={styles.required}>*</Text>
            </View>
            <Calendar
              renderArrow={(direction, onPress) => <CustomArrow direction={direction} onPress={onPress} />}
              hideArrows={false}
              markingType={'multi-dot'}
              markedDates={marked}
              enableSwipeMonths={true}
              theme={{
                arrowColor: '#0F0000',
              }}
              minDate={moment().format('YYYY-MM-DD')}
              onDayPress={(day) => {
                setDate(day.day + '/' + day.month + '/' + day.year);
                setSelected(day.dateString);
              }}
              context={{ date: '' }}
            />
          </View>
          <View style={styles.assignButton}>
            <Button
              title={'Next'}
              buttonStyle={fieldsValidated ? styles.blueButtonActive : styles.blueButton}
              textStyle={styles.blueButtonText}
              onPress={() => {
                fieldsValidated
                  ? props.navigation.navigate('TaskInfo', {
                      assign: selectedDropdownItem,
                      project: projectInput,
                      date: date,
                    })
                  : '';
              }}
            />
            <Button
              title={'Previous'}
              buttonStyle={[styles.blankButton, { marginBottom: 10 }]}
              textStyle={styles.blankButtonText}
              onPress={() => {
                props.navigation.goBack();
              }}
            />
          </View>
        </ScrollView>
      </SafeAreaView>
    </View>
  );
}
export default AddTask;
