import React, { useState, useEffect } from 'react';
import { Text, View, Pressable, Image, TextInput, Modal, Alert, TouchableOpacity } from 'react-native';
import { styles } from './style';
import images from '../../../theme/images';
import { showInfoToast } from '../../../utils/toastUtils';
import getScannedBarcode from '../../../utils/scanBarcode';
import parseUrl from '../../../utils/parseUrl';
import { api } from '../../../utils/api';
import ScanPopUp from './scanPopUp';
import { showSuccessToast, showErrorToast } from '../../../utils/toastUtils';
import Button from '../../../components/Button';
import AsyncStorage from '@react-native-async-storage/async-storage';
import calculateDistance from '../../../utils/calculateDistance';
import { useIsFocused } from '@react-navigation/native';
import axios from 'axios';

const EnvConstants = require('../../../../EnvConstants');

export default function QrScanner({ ...props }) {
  const [hasPermission, setHasPermission] = useState(null);
  const [scanned, setScanned] = useState(false);
  const [text, onChangeText] = useState('');
  const [qrEnabled, setQrEnabled] = useState(true);
  const [confimModalVisible, setConfirmModalVisible] = useState(false);
  const [scannedAsset, setScannedAsset] = useState(null);
  const [selectedQrCode, setSelectedQrCode] = useState(null);
  const [displayModal, setDisplayModal] = useState(false);
  const [checkAddressDone, setCheckAddressDone] = useState(false);
  const [user, setUser] = useState(null);
  const [newTagModalVisible, setNewTagModalVisible] = useState(false);

  const getAssetForTag = async (code, fromBarCode = true) => {
    setSelectedQrCode(code);
    const response = await api(`v1/asset/${code}?userid=${user._id}`, 'GET', null);

    if (response.status === 200) {
      const result = response.data;
      setScannedAsset(result.asset);

      if (fromBarCode) {
        checkLocation(result.asset);
      }

      if (user.role === 'OHS') {
        props.navigation.navigate('AssetInfo', {
          asset: scannedAsset,
        });
      } else {
        if (fromBarCode) {
          props.navigation.navigate('AssetInfo', {
            asset: scannedAsset,
            taskListDetails: props.route?.params?.taskListDetails,
          });
        } else {
          setNewTagModalVisible(true);
        }
      }
    } else if (response.status === 401) {
      showErrorToast('Tag not found', 'Please try again.');
    } else if (response.status === 403) {
      showErrorToast('Tag unavailable', 'Tag not assigned to asset in your projects.');
    } else if (response.status === 404) {
      if (user.role === 'OHS') {
        showErrorToast('Tag not assigned', 'Please contact project admin for help.');
      } else {
        props.navigation.navigate('AssignAsset', {
          qrCode: selectedQrCode,
        });
      }
    } else if (response.status === 410) {
      showErrorToast('Tag disposed', 'This tag has been marked as disposed.');
    }
  };

  const handleBarCodeScanned = async ({ type, data }) => {
    setScanned(true);
    let params = parseUrl(data);
    getAssetForTag(params.data);
  };

  const getUser = async () => {
    let tempUser = await AsyncStorage.getItem('user');
    tempUser = JSON.parse(tempUser);
    setUser(tempUser);
  };

  useEffect(() => {
    getUser();
    return () => {
      setScanned(false);
    };
  }, []);

  const checkLocation = async (asset) => {
    const assetLocation = {
      lat: asset.site.address.lat,
      lng: asset.site.address.lng,
    };
    var deviceLocation = await AsyncStorage.getItem('currentLocation');
    deviceLocation = JSON.parse(deviceLocation);
    const distance = calculateDistance(
      deviceLocation.coords.latitude,
      deviceLocation.coords.longitude,
      assetLocation.lat,
      assetLocation.lng
    );
    console.log('distance', distance);

    if (distance >= 200) {
      let geo = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${deviceLocation.coords.latitude},${deviceLocation.coords.longitude}&key=${EnvConstants.config.GMAPS_API}`
      );
      console.log('geo', geo.data.results[0].formatted_address);
      const address = geo.data.results[0]?.formatted_address ?? '';
      const location = {
        address: address,
        lat: deviceLocation.coords.latitude,
        lng: deviceLocation.coords.longitude,
      };

      api(`v1/asset/${asset._id}/outsideradius`, 'PATCH', { location: location });
    }
  };

  // if (hasPermission === null) {
  //   return <Text>Requesting for camera permission</Text>;
  // }
  // if (hasPermission === false) {
  //   return <Text>No access to camera</Text>;
  // }

  const newTagModal = (
    <Modal
      animationType="fade"
      transparent={true}
      visible={newTagModalVisible}
      onRequestClose={() => setNewTagModalVisible(false)}
    >
      <View style={styles.ModalContainer}>
        <View style={styles.popupWindow}>
          <Pressable style={styles.popupClose} onPress={() => setNewTagModalVisible(false)}>
            <Image style={styles.popupCloseIcon} source={images.lightGreyX} />
          </Pressable>
          <View style={styles.popupHeader}>
            <Image style={styles.confirmationTileIcon} source={images.confirmationTileIcon} />
            <Text
              style={[
                styles.textBoldLarge,
                {
                  marginTop: 10,
                },
              ]}
            >
              Does this need a new tag?
            </Text>
            <View style={{ maxWidth: 238 }}>
              <Text style={styles.script}>Do you want to assign a new tag to {scannedAsset?.name}?</Text>
            </View>
          </View>
          <Button
            title={'Yes, Assign New Tag'}
            buttonStyle={[styles.blueButton, { marginHorizontal: 10 }]}
            textStyle={styles.blueButtonText}
            onPress={() => {
              setNewTagModalVisible(false);
              props.navigation.navigate('AssignTag', {
                asset: scannedAsset,
                taskListDetails: props.route?.params?.taskListDetails,
              });
            }}
          />
          <Button
            title={'No, Continue'}
            buttonStyle={[
              styles.blueBorderButton,
              {
                marginTop: 8,
                marginHorizontal: 10,
              },
            ]}
            textStyle={styles.blueBorderButtonText}
            onPress={() => {
              setNewTagModalVisible(false);
              props.navigation.navigate('AssetInfo', {
                asset: scannedAsset,
              });
            }}
          />
        </View>
      </View>
    </Modal>
  );

  const scanQr = (
    <View style={styles.qr}>
      <View style={styles.qrFade}></View>
      <Text style={styles.qrText}>Scan QR Code</Text>
      <View style={styles.windowScanner}>
        {/* <ScanOutline width={100}/> */}
        <Image style={styles.qrBorderImage} source={images.scanOutline} />
      </View>
    </View>
  );
  const qrCode = (
    <View style={styles.code}>
      <View style={styles.inputTextCode}>
        <TextInput
          style={styles.input}
          onChangeText={onChangeText}
          value={text}
          placeholder={'Enter code below QR Code'}
          returnKeyType="done"
          keyboardType="number-pad"
        />
      </View>

      <View style={styles.button}>
        <Pressable
          onPress={() => {
            setCheckAddressDone(false);
            getAssetForTag(text, false);
          }}
        >
          <View style={styles.codeSubmit}>
            <Text style={styles.textSubmit}>Submit</Text>
          </View>
        </Pressable>
      </View>
    </View>
  );

  return (
    <View style={styles.container}>
      <View
        style={{
          top: 100,
          alignItems: 'center',
          zIndex: 9999,
        }}
      >
        <Image
          style={{
            width: 90,
            height: 36,
          }}
          source={images.clkTagLogo}
        />
      </View>
      <View style={styles.content}>{qrCode}</View>
      {newTagModal}
    </View>
  );
}
