import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../../../../theme/colors';

const windowHeight = Dimensions.get('window').height;

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  loading: {
    height: 8,
    backgroundColor: 'white',
    marginTop: 24,
  },
  loadPercent: {
    height: 8,
    width: '66.66%',
    backgroundColor: '#132FF5',
  },
  assetStatus: {
    backgroundColor: '#ffcdcd',
    borderRadius: 13,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    paddingHorizontal: 8,
    paddingVertical: 3,
    marginRight: 4,
    width: '50%',
    marginTop: 13,
  },
  assetStatusText: {
    color: '#FF4D4D',
    fontFamily: 'NunitoSans_700Bold',
  },
  blueButton: {
    width: '100%',
    borderRadius: 8,
    backgroundColor: colors.mainBlue,
    paddingVertical: 10,
    marginTop: 32,
  },
  blueButtonText: {
    color: colors.white,
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    textAlign: 'center',
  },
  blueBorderButton: {
    width: '100%',
    borderRadius: 8,
    borderColor: colors.mainBlue,
    borderWidth: 1,
    paddingVertical: 10,
  },
  blueBorderButtonText: {
    color: colors.mainBlue,
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    textAlign: 'center',
  },
  blankButton: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  blankButtonText: {
    color: colors.mainBlue,
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    textAlign: 'center',
  },
  body: {
    backgroundColor: 'white',
    paddingHorizontal: 30,
    paddingTop: 113,
    marginTop: 18,
    flex: 1,
    // justifyContent: "center",
    // alignItems:"center",
  },
  boxContent: {
    borderWidth: 1,
    borderRadius: 8,
    borderColor: '#C7C7C7',
    paddingHorizontal: 15,
    paddingVertical: 22,
    alignItems: 'center',
  },
  buttonColumn: {
    position: 'absolute',
    bottom: 0,
    paddingHorizontal: 27,
    width: '100%',
    marginTop: 50,
  },
  title: {
    fontSize: 15,
    fontFamily: 'NunitoSans_700Bold',
  },
});
