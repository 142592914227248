import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';
const EnvConstants = require('../../../EnvConstants');
import * as Location from 'expo-location';

import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';

const AddressInput = ({ selectedAddress, selectAddress, inputStyle = false }) => {
  const [currentLocation, setCurrentLocation] = useState(null);
  const [hasValue, setHasValue] = useState(false);
  const [inputIsFocused, setInputIsFocused] = useState(false);
  const [currentValue, setCurrentValue] = useState('');

  // const domain = EnvConstants.config.STAGINGDOMAIN;
  const domain = EnvConstants.config.DOMAIN;

  const getCurrentLocation = async () => {
    // let location = await AsyncStorage.getItem('currentLoation');
    let { status } = await Location.requestForegroundPermissionsAsync();
    if (status !== 'granted') {
      console.log('Permission to access location was denied');
      return;
    }

    let location = await Location.getCurrentPositionAsync({});
    // location = JSON.parse(location);
    console.log('location: ', location);
    setCurrentLocation(location);
  };

  const getSelectedAddressString = () => {
    if (selectedAddress === undefined) {
      return '';
    } else if (typeof selectedAddress === 'object') {
      return `${selectedAddress.street}, ${selectedAddress.city}, ${selectedAddress.state}, ${selectedAddress.country}`;
    } else {
      return selectedAddress;
    }
  };

  useEffect(() => {
    if (currentLocation == null) {
      getCurrentLocation();
    }
  }, [currentLocation]);

  return (
    <View style={[styles.googlePlacesContainer, hasValue ? { height: 320 } : { height: 'auto' }]}>
      <GooglePlacesAutocomplete
        placeholder="Start typing address..."
        enablePoweredByContainer={false}
        styles={{
          textInput: [styles.autoCompleteInput, inputStyle],
        }}
        requestUrl={{
          useOnPlatform: 'all', // or "all"
          url: domain.replace(/\/+$/, ''), // or any proxy server that hits https://maps.googleapis.com/maps/api
        }}
        query={{
          key: EnvConstants.config.GMAPS_API,
          language: 'en',
          location:
            currentLocation !== null ? `${currentLocation.coords.latitude},${currentLocation.coords.longitude}` : '',
          radius: '100000', //1000 km
          type: 'address',
        }}
        onFail={(error) => console.error(error)}
        textInputProps={{
          value:
            inputIsFocused == false
              ? currentValue !== ''
                ? currentValue
                : selectedAddress !== undefined
                ? getSelectedAddressString()
                : ''
              : currentValue,
          placeholderTextColor: '#00000080',
          onChangeText: (text) => {
            text.length > 0 ? setHasValue(true) : setHasValue(false);
            setCurrentValue(text);
          },
          onFocus: () => setInputIsFocused(true),
          onBlur: () => setInputIsFocused(false),
        }}
        onPress={async (data, details = null) => {
          let geo = await axios.get(
            `${domain}place/details/json?place_id=${data.place_id}&key=${EnvConstants.config.GMAPS_API}`
          );
          if (geo.status === 200) {
            const lat = geo.data.result.geometry.location.lat;
            const lng = geo.data.result.geometry.location.lng;
            var address = data.description;
            const street = address.split(',')[0]?.trim() ?? '';
            const city = address.split(',')[1]?.split(' ').slice(0, -1).join(' ').trim() ?? '';
            const state = address.split(',')[1]?.split(' ').pop().trim() ?? '';
            const country = address.split(',')[2]?.trim() ?? '';
            const addressObj = {
              street: street,
              city: city,
              state: state,
              country: country,
              lat: lat,
              lng: lng,
            };
            console.log(addressObj);
            selectAddress(addressObj);
            setCurrentValue(`${addressObj.street}, ${addressObj.city}, ${addressObj.state}, ${addressObj.country}`);
          }
          setHasValue(false);
        }}
      />
    </View>
  );
};

export default AddressInput;

const styles = StyleSheet.create({
  googlePlacesContainer: {
    marginTop: 15,
    minHeight: 50,
    borderColor: '#C7C7C7',
  },
  autoCompleteInput: {
    borderColor: '#C7C7C7',
    borderWidth: 1,
    borderRadius: 8,
    paddingVertical: 10,
    paddingLeft: 13,
    fontSize: 14,
    height: 50,
  },
});
