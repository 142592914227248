import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';
import NotificationsPage from '../../../screens/tabs/notifications';
const Stack = createNativeStackNavigator();

const Notifications = ({ ...props }) => (
  <Stack.Navigator>
    <Stack.Screen
      {...props}
      name={'NotificationsPage'}
      component={NotificationsPage}
      options={{
        headerShown: false,
        headerStyle: { backgroundColor: 'transparent' },
        tabBarVisible: false,
      }}
      initialParams={{
        isAdmin: props.route.params?.isAdmin,
      }}
    />
  </Stack.Navigator>
);

export default Notifications;
