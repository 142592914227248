import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../../../../theme/colors';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  loading: {
    height: 8,
    backgroundColor: 'white',
    marginTop: 0,
  },
  loadPercent: {
    height: 8,
    width: '70%',
    backgroundColor: '#132FF5',
  },
  menu: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 30,
    paddingBottom: 30,
    paddingHorizontal: 24,
    alignItems: 'center',
    backgroundColor: '#EDEDED',
  },
  body: {
    paddingHorizontal: 25,
    paddingVertical: 14,
    height: Dimensions.get('screen').height / 1.15,
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
  title: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
  },
  textDetail: {
    fontFamily: 'NunitoSans_400Regular',
    marginTop: 16,
  },
  imgPicker: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#C7C7C7',
    paddingHorizontal: 7,
    paddingVertical: 8,
    marginTop: 10,
  },
  dasherBorder: {
    borderStyle: 'dotted',
    borderRadius: 8,
    borderWidth: 2,
    borderColor: '#C7C7C7',
    paddingVertical: 20,
    alignItems: 'center',
  },
  imgIcon: {
    fontSize: 34,
    paddingLeft: 6,
    color: '#C7C7C7',
  },
  pickImage: {
    marginTop: 16,
  },
  imgPicked: {
    borderWidth: 0.5,
    borderColor: '#C7C7C7',
    borderRadius: 4,
    marginLeft: 6,
  },
  documentPicked: {
    paddingVertical: 8,
    paddingLeft: 7,
    backgroundColor: '#8997fa1a',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#132ff5',
    borderRadius: 8,
    flex: 12,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  docIcon: {
    fontSize: 18,
    paddingRight: 6,
    color: '#132FF5',
  },
  titleText: {
    fontFamily: 'NunitoSans_600SemiBold',
    color: '#132FF5',
  },
  imgPickedContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  closeIcon: {
    fontSize: 18,
    color: 'gray',
  },
  closeField: {
    position: 'absolute',
    right: -4,
    top: -10,
    backgroundColor: '#C7C7C7',
    borderRadius: 16,
    padding: 4,
    zIndex: 9999,
    elevation: 9999,
  },
  uploadText: {
    fontFamily: 'NunitoSans_600SemiBold',
    color: '#000000',
    opacity: 0.5,
    fontSize: 14,
    marginTop: 8,
  },
  blueButton: {
    borderRadius: 8,
    opacity: 0.5,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
    marginBottom: 10,
  },
  blueButtonActive: {
    borderRadius: 8,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
    marginBottom: 10,
  },
  blueButtonText: {
    color: colors.white,
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 18,
    textAlign: 'center',
  },
  blankButton: {
    paddingVertical: 10,
  },
  blankButtonText: {
    color: '#000000',
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    textAlign: 'center',
    opacity: 0.5,
  },
  assignButton: {
    // position: "absolute",
    backgroundColor: 'white',
    width: '100%',
  },
  docTitle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  addMore: {
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#C7C7C7',
    width: 120,
    height: 120,
    borderRadius: 4,

    justifyContent: 'center',
  },
  addIcon: {
    fontSize: 32,
    color: '#C7C7C7',
  },
});
