import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  assetMap: {
    width: '100%',
    height: '100%',
    zIndex: -1,
    borderWidth: 1,
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
