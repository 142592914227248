import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../../../../theme/colors';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingBottom: 60,
  },
  // scrollViewContainer:{
  //   marginBottom: 40,
  // },
  input: {
    marginTop: 16,
    borderColor: '#C7C7C7',
    width: '100%',
    borderWidth: 1,
    borderRadius: 10,
    paddingVertical: 10,
    paddingLeft: 13,
  },
  loading: {
    height: 8,
    backgroundColor: 'white',
    marginTop: 24,
  },
  loadPercent: {
    height: 8,
    width: '33.33%',
    backgroundColor: '#132FF5',
  },
  body: {
    marginTop: 16,
    backgroundColor: 'white',
    paddingHorizontal: 23,
    paddingBottom: 22,
  },
  content: {
    marginTop: 23,
    borderRadius: 8,
    paddingHorizontal: 11,
    paddingVertical: 15,
    borderWidth: 1,
    borderColor: '#C7C7C7',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  validate: {
    borderBottomWidth: 1,
    width: '100%',
    alignItems: 'center',
    borderColor: '#bdbdbd',
  },
  collapse: {
    backgroundColor: '#F6F6F6',
    paddingHorizontal: 11,
    paddingVertical: 14,
    borderRadius: 8,
    marginTop: 4,
  },
  imgPicker: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#C7C7C7',
    paddingHorizontal: 7,
    paddingVertical: 8,
    marginTop: 10,
  },
  dasherBorder: {
    borderStyle: 'dotted',
    borderRadius: 8,
    borderWidth: 2,
    borderColor: '#C7C7C7',
    paddingVertical: 14,
    alignItems: 'center',
  },
  blueButton: {
    borderRadius: 8,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
    marginTop: 24,
  },
  blueButtonText: {
    color: colors.white,
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 18,
    textAlign: 'center',
  },
  accordionHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textSemiBold: {
    fontFamily: 'NunitoSans_600SemiBold',
  },
  accordionIcon: {
    fontSize: 14,
    paddingLeft: 6,
  },
  imgIcon: {
    fontSize: 31,
    paddingLeft: 6,
    color: '#C7C7C7',
  },
  imgPicker: {
    fontFamily: 'NunitoSans_600SemiBold',
    color: '#C7C7C7',
    paddingTop: 8,
  },
  textBoldMedium: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 16,
  },
  textBoldLarge: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
  },
  pressableWidth: {
    width: '100%',
    alignItems: 'center',
  },
  selecteImage: {
    width: '100%',
    height: 200,
    paddingTop: 12,
    borderRadius: 6,
  },
  image: {
    width: 180,
    height: 180,
    borderRadius: 6,
    objectFit: 'contain',
  },
});
