import React, { useState, useEffect } from 'react';
import { useIsFocused } from '@react-navigation/core';
import { Text, View, ScrollView, Image } from 'react-native';
import SearchScreen from '../../../components/Search';
import { styles } from './style';
import JoinRequestNotification from './joinRequestNotification';
import TaskReminderNotification from './taskReminderNotification';
import TagReminderNotification from './tagReminderNotification';
import RepairReminderNotification from './repairReminderNotification';
import OutsideRadiusWarningNotification from './outsideRadiusWarningNotification';
import { api } from '../../../utils/api';
import Loading from '../../../components/Loading';
import AsyncStorage from '@react-native-async-storage/async-storage';

export default function NotificationsPage({ ...props }) {
  const [notifications, setNotifications] = useState([]);
  const [hasWriteAccess, setHasWriteAccess] = useState(false);
  const [loading, setLoading] = useState(true);

  const getNotifications = async () => {
    const response = await api(`v1/notification`, 'GET', null);

    if (response.status === 200) {
      setNotifications(response.data.notifications);
    }

    setLoading(false);
  };

  const getUser = async () => {
    var user = await AsyncStorage.getItem('user');
    user = JSON.parse(user);

    if (user.role !== 'OHS') {
      setHasWriteAccess(true);
    }
  };

  const handleClosePress = async (id) => {
    const response = await api(`v1/notification/${id}/hide`, 'GET', null);

    if (response.status === 200 && response.data.success) {
      getNotifications();
    }
  };

  const isFocused = useIsFocused();
  useEffect(() => {
    getNotifications();
    getUser();
  }, [isFocused]);

  return (
    <SearchScreen
      body={
        <ScrollView>
          <View style={styles.body}>
            <Text style={styles.title}>Notifications</Text>
            {!loading ? (
              notifications && notifications.length > 0 ? (
                notifications.map((notification, index) => {
                  if (notification.type === 'joinRequest') {
                    if (notification.sender !== null) {
                      return (
                        <JoinRequestNotification
                          key={index}
                          notification={notification}
                          {...props}
                          handleClosePress={handleClosePress}
                          getNotifications={getNotifications}
                        />
                      );
                    }
                  } else if (notification.type === 'taskReminder') {
                    return (
                      <TaskReminderNotification
                        key={index}
                        notification={notification}
                        {...props}
                        handleClosePress={handleClosePress}
                        hasWriteAccess={hasWriteAccess}
                      />
                    );
                  } else if (notification.type === 'tagReminder') {
                    return (
                      <TagReminderNotification
                        key={index}
                        notification={notification}
                        {...props}
                        handleClosePress={handleClosePress}
                      />
                    );
                  } else if (notification.type === 'repairReminder') {
                    return (
                      <RepairReminderNotification
                        key={index}
                        notification={notification}
                        {...props}
                        handleClosePress={handleClosePress}
                        hasWriteAccess={hasWriteAccess}
                      />
                    );
                  } else if (notification.type === 'outsideRadiusWarning') {
                    return (
                      <OutsideRadiusWarningNotification
                        key={index}
                        notification={notification}
                        {...props}
                        handleClosePress={handleClosePress}
                        hasWriteAccess={hasWriteAccess}
                      />
                    );
                  }
                })
              ) : (
                <View style={styles.body}>
                  <Text style={styles.text}>No New Notifications</Text>
                </View>
              )
            ) : (
              <View style={{ marginTop: 50 }}>
                <Loading />
              </View>
            )}
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 25,
              marginBottom: 100,
            }}
          >
            <View style={styles.line}></View>
            <Text style={[styles.textRegular, { color: '#D0D0D0', paddingHorizontal: 7 }]}>End of notifications</Text>
            <View style={styles.line}></View>
          </View>
        </ScrollView>
      }
      {...props}
    />
  );
}
