import React, { useState } from 'react';
import { Text, View, SafeAreaView, TextInput, ScrollView } from 'react-native';
import { styles } from './style';
import images from '../../../../theme/images';
import NavigationHeader from '../../../../components/SignUp/SingUpNavigation';
import SignUpHeader from '../../../../components/SignUp/SignUpHeader';
import Button from '../../../../components/Button';
import AsyncStorage from '@react-native-async-storage/async-storage';

function LicenseNumber({ ...props }) {
  console.log('license props: ', props);
  const [text, onChangeText] = useState('');

  const isAdmin = props.route.params.userType == 'PM';
  const nextScreen = isAdmin ? 'UserType' : 'UserType';

  const notipush = (
    <View style={styles.notipush}>
      <Text style={styles.textBold}>Thanks!</Text>
      <Text style={styles.textRegular}>
        The team at ClkTag will validate your licence number once your test is submitted.
      </Text>
    </View>
  );

  return (
    <View style={styles.container}>
      <SafeAreaView
        style={{
          flex: 1,
        }}
      >
        <ScrollView>
          <View style={styles.loading}>
            <View style={styles.loadPercent}></View>
          </View>
          <NavigationHeader
            onPress={async () => {
              await AsyncStorage.multiRemove(['user', 'token', 'refreshToken', 'expiresIn']);
              props.navigation.navigate('GuestNavigation', { screen: 'QRCodeScan' });
            }}
            header={'Sign Up'}
            navigate={'Cancel'}
            menuStyles={styles.menu}
          />
          <SignUpHeader
            title={"Let's get you set up"}
            descript={'Please provide your licence number to start testing.'}
            userImage={props.route.params.userImage}
            disablePress={true}
          />
          <View style={styles.textField}>
            <Text style={styles.inputHeader}>Licence Number</Text>
            <TextInput placeholder="E.g XXXX XXXX XX" style={styles.input} onChangeText={onChangeText} value={text} />
            {text.length > 5 && notipush}
          </View>
        </ScrollView>
        <View style={styles.assignButton}>
          <Button
            title={'Next'}
            buttonStyle={text.length > 5 ? styles.blueButtonActive : styles.blueButton}
            textStyle={styles.blueButtonText}
            onPress={() =>
              text.length > 5 &&
              props.navigation.navigate(nextScreen, {
                userImage: props.route.params.userImage,
                userType: props.route.params.userType,
                userLicence: text,
                registerForPushNotificationsAsync: props.route.params.registerForPushNotificationsAsync,
              })
            }
          />
          {isAdmin && (
            <Button
              title={'Skip'}
              buttonStyle={styles.blueBorderButton}
              textStyle={styles.blankButtonText}
              onPress={() =>
                props.navigation.navigate(nextScreen, {
                  userImage: props.route.params.userImage,
                  userType: props.route.params.userType,
                  userLicence: null,
                  registerForPushNotificationsAsync: props.route.params.registerForPushNotificationsAsync,
                })
              }
            />
          )}

          <Button
            title={'Previous'}
            buttonStyle={[styles.blankButton, { marginBottom: 20 }]}
            textStyle={styles.blankButtonText}
            onPress={() => props.navigation.goBack()}
          />
        </View>
      </SafeAreaView>
    </View>
  );
}

export default LicenseNumber;
