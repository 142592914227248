import React, { useEffect, useState, useRef } from 'react';
import { Text, View, ScrollView, Image, TouchableOpacity } from 'react-native';
import SearchBar from '../../../components/SearchBar';
import { styles } from './style';
import { FaChevronRight } from 'react-icons/fa';
import images from '../../../theme/images';
import SearchScreen from '../../../components/Search';
import { useIsFocused } from '@react-navigation/native';
import { api } from '../../../utils/api';
import Loading from '../../../components/Loading';
import RBSheet from 'react-native-raw-bottom-sheet';
import * as Linking from 'expo-linking';

export default function AdminAssetRegistry({ ...props }) {
  const refRBSheet = useRef();
  const [text, onChangeText] = useState('');
  const [data, setProjectData] = useState([]);
  const [exportLoading, setExportLoading] = useState(false);
  const [projectsLoading, setProjectsLoading] = useState(true);
  const [modalType, setModalType] = useState({
    showModal: false,
    id: null,
    type: null,
  });

  const getProjects = async () => {
    var userProjects = [];
    const projects = await api(`v1/project/userprojects`, 'GET', null);
    if (projects.status == 200) {
      if (projects.data.success) {
        let data = projects.data;
        for (let i = 0; i < data.projects.length; i++) {
          const element = data.projects[i];
          let assetCount = element.assetCount;
          let siteCount = element.siteCount;
          const obj = {
            title: element.name,
            detail: `${element.address.street}, ${element.address.city}, ${element.address.state}, ${element.address.country}`,
            id: element._id,
            description1: assetCount == 1 ? `${assetCount} Asset` : `${assetCount} Assets`,
            description2: siteCount == 1 ? `${siteCount} Site` : `${siteCount} Sites`,
          };
          userProjects.push(obj);
        }
      }
    }

    setProjectData(userProjects);
    setProjectsLoading(false);
  };

  const exportFunction = async (projectid, type) => {
    console.log('project id: ', projectid);

    var fileURL = '';
    var fileName = '';

    setExportLoading(true);

    const data = await api(
      `v1/project/${projectid}/export?type=${type == 'CSV' ? 'CSV' : 'PDF'}&additionalDetails=false`,
      'GET',
      null
    );
    if (data.status == 200) {
      if (data.data.success) {
        fileURL = data.data.file;
        fileName = data.data.name;
      }
    }
    if (fileURL !== '') {
      console.log(fileName);
      // WebBrowser.openBrowserAsync(fileURL);
      Linking.openURL(fileURL);
      setExportLoading(false);
    }
  };

  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      getProjects();
    }
  }, [isFocused]);

  if (exportLoading) {
    return (
      <View style={{ flex: 1, marginTop: 50 }}>
        <Loading opacity={0} />
      </View>
    );
  }

  return (
    <SearchScreen
      body={
        <>
          <ScrollView>
            <View style={styles.body}>
              <Text style={styles.screenName}>Asset Registry</Text>

              <View style={styles.titleContainer}>
                <Text style={styles.title}>Projects ({data.length})</Text>
              </View>
              {projectsLoading ? (
                <View style={{ marginTop: 50 }}>
                  <Loading />
                </View>
              ) : (
                data.length == 0 && (
                  <View style={[styles.body, styles.contentBlock]}>
                    <Text style={[styles.textBold]}>You are not a part of any projects</Text>
                  </View>
                )
              )}
              {data.map((item, index) => {
                var itemId = item.id;
                return (
                  <TouchableOpacity
                    onPress={() =>
                      props.navigation.navigate('AdminSiteAssets', {
                        title: item.title,
                        dataAsset: item,
                        admin: false,
                      })
                    }
                    key={index}
                  >
                    <View style={styles.accordionHeader}>
                      <View>
                        <View
                          style={{
                            flexDirection: 'row',
                          }}
                        >
                          <View style={styles.iconContainer}>
                            <Image style={styles.accordionHeaderIcon} source={images.locationSmallWhiteIcon} />
                          </View>
                          <View style={styles.accordionHeaderContent}>
                            <Text style={styles.textBold}>{item.title}</Text>
                            <View style={styles.headerContentDescript}>
                              <Text style={styles.textRegularSmall}>{item.description1}</Text>
                              <Text style={styles.textRegularSmall}>{item.description2}</Text>
                            </View>
                          </View>
                        </View>
                      </View>
                      <View style={styles.export}>
                        <TouchableOpacity
                          onPress={() => {
                            refRBSheet.current.open();
                            setModalType({
                              ...modalType,
                              id: itemId,
                            });
                          }}
                        >
                          <Image
                            style={{
                              width: 28,
                              height: 28,
                            }}
                            source={images.lightGreyDownload}
                          />
                        </TouchableOpacity>
                        <FaChevronRight name="right" style={styles.accordionIcon} />
                      </View>
                      <RBSheet
                        ref={refRBSheet}
                        closeOnDragDown={true}
                        closeOnPressMask={true}
                        height={150}
                        openDuration={250}
                        customStyles={{
                          wrapper: {
                            backgroundColor: 'rgba(0,0,0,0.3)',
                          },
                          container: {
                            borderTopRightRadius: 8,
                            borderTopLeftRadius: 8,
                          },
                        }}
                      >
                        <View
                          style={{
                            alignItems: 'center',
                          }}
                        >
                          <View style={styles.validate}>
                            <TouchableOpacity
                              onPress={() => {
                                refRBSheet.current.close();
                                exportFunction(modalType.id, 'CSV');
                              }}
                            >
                              <Text
                                style={[
                                  styles.textBoldLarge,
                                  {
                                    paddingVertical: 20,
                                  },
                                ]}
                              >
                                Export as CSV
                              </Text>
                            </TouchableOpacity>
                          </View>
                          <TouchableOpacity
                            onPress={() => {
                              refRBSheet.current.close();
                              exportFunction(modalType.id, 'PDF');
                            }}
                          >
                            <Text
                              style={[
                                styles.textBoldLarge,
                                {
                                  paddingVertical: 20,
                                },
                              ]}
                            >
                              Export as PDF
                            </Text>
                          </TouchableOpacity>
                        </View>
                      </RBSheet>
                    </View>
                  </TouchableOpacity>
                );
              })}
            </View>
          </ScrollView>
        </>
      }
      {...props}
    />
  );
}
