import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../../theme/colors';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
  },
  signUpText: {
    textAlign: 'center',
    position: 'absolute',
    top: 70,
    left: 0,
    right: 0,
    zIndex: 9999,
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    color: '#6B6B6B',
  },
  loading: {
    height: 8,
    backgroundColor: 'white',
    marginTop: 24,
  },
  loadPercent: {
    height: 8,
    width: '100%',
    backgroundColor: '#132FF5',
  },
  welcomeBG: {
    width: 280,
    height: 355,
    resizeMode: 'contain',
    justifyContent: 'center',
  },
  loginLogoText: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '80%',
  },
  oneTagLogo: {
    width: 145,
    height: 60,
    marginTop: 40,
    marginBottom: 34,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  welcomeText: {
    color: colors.black,
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 28,
    lineHeight: 30,
    textAlign: 'center',
  },
  buttonColumn: {
    position: 'absolute',
    bottom: 0,
    paddingHorizontal: 27,
    width: '100%',
    marginTop: 50,
  },
  blueButton: {
    width: '100%',
    borderRadius: 8,
    backgroundColor: colors.mainBlue,
    paddingVertical: 10,
  },
  blueButtonText: {
    color: colors.white,
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 18,
    textAlign: 'center',
  },
});
