import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai';
import { View, Text } from 'react-native';

const CustomArrow = ({ direction, onPress }) => {
  const icon = direction === 'left' ? <AiFillCaretLeft /> : <AiFillCaretRight />;
  return (
    <View style={{ paddingHorizontal: 20 }}>
      <Text onPress={onPress} style={{ fontSize: 24, fontWeight: 'bold' }}>
        {icon}
      </Text>
    </View>
  );
};

export default CustomArrow;
