import React from 'react';
import { Image } from 'react-native';
import { View } from 'react-native';
import { Text, TouchableOpacity, ScrollView } from 'react-native';
import { styles } from './style';
import { FiChevronRight } from 'react-icons/fi';

import Status from '../../screens/assetInfo/status';
import images from '../../theme/images';
import moment from 'moment';
import 'moment-timezone';

function SearchResult({ ...props }) {
  const { isAdmin, navigation, data, type } = props;
  const parentScreenState = navigation.getParent().getState();
  const parentScreen = parentScreenState.routes[parentScreenState.index];
  const currentScreenState = navigation.getState();
  const currentScreen = currentScreenState.routes[currentScreenState.index];
  const currentScreenName =
    currentScreen.name === 'AdminSiteAssets' || currentScreen.name === 'AdminViewProject'
      ? 'AdminAssetRegistry'
      : currentScreen.name;
  const fromSearchScreenParams = [parentScreen.name, { isAdmin: isAdmin, screen: currentScreenName }];

  const results = data.map((item, id) => {
    let details = {};

    if (type === 'assets') {
      details.icon = item.assetType.icon;
      details.name = item.name;
      details.code = item.tag.code;
      details.status = item.currentStatus;
    } else if (type === 'tasks') {
      details.icon = item.asset.assetType.icon;
      details.name = item.asset ? `Test ${item.asset.name}` : item.title;
      details.desc = item.asset ? null : item.taskDescription;
      details.dueDate = item.asset
        ? moment(item.dueDateUTC).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('DD/MM/YYYY')
        : null;
      details.status = item.asset && item.asset.currentStatus === 'Needs Repair' ? item.asset.currentStatus : '';
    } else if (type === 'projects') {
      details.icon = item.logo;
      details.name = item.name;
      details.locationString = `${item.address.street}, ${item.address.city}, ${item.address.state}`;
    }

    const handlePress = () => {
      if (type === 'assets') {
        navigation.navigate('TabNavigator', {
          screen: 'Assets',
          params: {
            screen: 'AssetInfo',
            asset: item,
            fromSearchScreenParams: fromSearchScreenParams,
          },
        });
      } else if (type === 'tasks' && item.type !== 'manual') {
        navigation.navigate('QrCodeNav', {
          screen: 'TestAssetFlowStep1',
          params: {
            asset: item.asset,
            fromSearchScreenParams: fromSearchScreenParams,
          },
        });
      } else if (type === 'projects') {
        navigation.navigate('SettingNavigate', {
          screen: 'ProjectInfo',
          params: {
            projectTitle: details.name,
            id: item._id,
            locationString: details.locationString,
            location: item.address,
            image: details.icon,
            owner: item.company,
            hasWriteAccess: true,
            fromSearchScreenParams: fromSearchScreenParams,
          },
        });
      }
    };

    return (
      <TouchableOpacity onPress={handlePress} key={type + id}>
        <View style={styles.resultContainer}>
          <View style={styles.flexRow}>
            <Image
              source={details.icon ? { uri: details.icon } : images.companyTileIcon}
              style={{ width: 44, height: 44, borderRadius: 22 }}
            />
            <View style={styles.resultInfo}>
              <Text style={styles.textBoldMedium}>{details.name}</Text>
              <View style={styles.flexRow}>
                {details.code && (
                  <>
                    <Text style={styles.textBoldSmall}>QR Code</Text>
                    <Text style={[styles.textLightSmall, { marginLeft: 3 }]}>{details.code}</Text>
                  </>
                )}
                {details.dueDate && (
                  <>
                    <Text style={styles.textBoldSmall}>Due Date</Text>
                    <Text style={[styles.textLightSmall, { marginLeft: 3 }]}>{details.dueDate}</Text>
                  </>
                )}
                {details.desc && (
                  <>
                    <Text style={styles.textLightSmall}>{details.desc}</Text>
                  </>
                )}
                {details.locationString && <Text style={styles.textBoldSmall}>{details.locationString}</Text>}
              </View>
            </View>
          </View>
          <View style={styles.resultSubInfo}>
            {details.status && <Status status={details.status} />}
            {!details.desc && <FiChevronRight name="right" style={{ width: 9, height: 11, marginTop: 9 }} />}
          </View>
        </View>
      </TouchableOpacity>
    );
  });

  return <ScrollView style={{ height: '100%' }}>{results}</ScrollView>;
}

export default SearchResult;
