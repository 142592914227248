import {Dimensions, StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
    assetInforRow:{
      flexDirection:"row",
      justifyContent:"space-between",
      alignItems:"center",
      height:32,
      marginTop:4
    },
    assetInforTitle:{
      fontSize:14,
      fontFamily:"NunitoSans_700Bold"
    },
  });