import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../../../theme/colors';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  header: {
    backgroundColor: '#F6F6F6',
    paddingTop: 70,
    paddingBottom: 18,
  },
  body: {
    marginTop: 16,
    backgroundColor: 'white',
    marginBottom: 100,
  },
  screenNameIcon: {
    fontSize: 17,
    color: '#6B6B6B',
    alignSelf: 'center',
  },
  screenNameContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 23,
  },
  screenName: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    marginRight: 8,
    color: '#6B6B6B',
  },
  titleContainer: {
    paddingHorizontal: 25,
    paddingVertical: 13,
    marginTop: 6,
    borderBottomColor: '#00000014',
    borderBottomWidth: 1,
  },
  title: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 14,
    color: '#4A4A4A',
  },
  projectDetails: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 23,
    paddingVertical: 10,
  },
  accordionHeader: {
    paddingVertical: 4,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 23,
    alignItems: 'center',
    paddingVertical: 10,
    borderBottomColor: '#00000014',
    borderBottomWidth: 1,
  },
  accordionHeaderIcon: {
    width: 11,
    height: 13,
    tintColor: '#fff',
    alignSelf: 'center',
    backgroundColor: '#132FF5',
  },
  iconContainer: {
    backgroundColor: '#132FF5',
    height: 23,
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: 99,
    paddingHorizontal: 6,
    alignSelf: 'center',
  },
  accordionHeaderContent: {
    paddingLeft: 8,
    width: '80%',
  },
  textBold: {
    fontFamily: 'NunitoSans_700Bold',
  },
  textRegularSmall: {
    fontFamily: 'NunitoSans_400Regular',
    fontSize: 12,
    marginRight: 15,
  },
  export: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  accordionIcon: {
    fontSize: 14,
    paddingLeft: 8,
  },
  headerContentDescript: {
    flexDirection: 'row',
  },

  textBoldLarge: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
  },
  validate: {
    borderBottomWidth: 1,
    width: '100%',
    alignItems: 'center',
    borderColor: '#bdbdbd',
  },
  assignButton: {
    position: 'absolute',
    flexDirection: 'row',
    bottom: 80,
    paddingHorizontal: 27,
    backgroundColor: 'white',
    width: '100%',
  },
  blueButtonActive: {
    borderRadius: 8,
    backgroundColor: '#132FF5',
    paddingVertical: 8,
    marginBottom: 10,
    width: 170,
    height: 40,
  },
  blueButtonText: {
    color: colors.white,
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    textAlign: 'center',
  },
  blankButton: {
    width: 162,
    paddingVertical: 8,
    borderWidth: 1,
    borderColor: '#132FF5',
    borderRadius: 8,
    flex: 1,
    marginLeft: 8,
  },
  blankButtonText: {
    color: '#132FF5',
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    textAlign: 'center',
  },
  popupModal: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  popupWindowSuccess: {
    backgroundColor: 'white',
    paddingHorizontal: 27,
    paddingVertical: 20,
    borderRadius: 12,
    alignItems: 'center',
    marginHorizontal: 26,
    marginTop: 150,
  },
  popupWindow: {
    backgroundColor: 'white',
    paddingHorizontal: 18,
    paddingVertical: 19,
    borderRadius: 12,
    alignItems: 'center',
    marginHorizontal: 26,
    marginTop: 50,
  },
  popupIcon: {
    width: 70,
    height: 70,
    borderRadius: 16,
    marginTop: 8,
  },
  popupIconModal: {
    width: 15,
    height: 15,
    paddingHorizontal: 5,
    paddingVertical: 5,
    borderRadius: 16,
  },
  popupTitle: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    marginTop: 25,
  },
  popupScript: {
    fontFamily: 'NunitoSans_400Regular',
    marginTop: 8,
    textAlign: 'center',
  },
  addModalTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  addModalTitle: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    color: '#000000',
  },
  popupIconModal: {
    width: 15,
    height: 15,
    paddingHorizontal: 5,
    paddingVertical: 5,
    borderRadius: 16,
  },
  textFieldModal: {
    marginBottom: 14,
    paddingTop: 23,
    width: '100%',
  },
  firstTitle: {
    flexDirection: 'row',
  },
  titleText: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
  },
  inputHeaderModal: {
    fontFamily: 'NunitoSans_700Bold',
    marginTop: 14,
  },
  input: {
    marginTop: 16,
    borderColor: '#C7C7C7',
    width: '100%',
    borderWidth: 1,
    borderRadius: 8,
    paddingVertical: 15,
    paddingLeft: 13,
  },
  required: {
    color: '#FF4D4D',
  },
  inputSearchDrop: {
    marginTop: 16,
    width: '100%',
    backgroundColor: '#FFFFFF',
    borderRadius: 17,
    paddingVertical: 15,
    paddingHorizontal: 13,
  },
  closeIcon: {
    alignSelf: 'flex-end',
  },
  blueButtonText: {
    color: colors.white,
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 18,
    textAlign: 'center',
  },
  blueButtonActive: {
    borderRadius: 8,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
    marginBottom: 10,
  },
  blueButton: {
    borderRadius: 8,
    opacity: 0.5,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
    marginBottom: 10,
    width: '100%',
  },
  inputDrop: {
    marginTop: 16,
    borderColor: '#C7C7C7',
    width: '100%',
    borderWidth: 1,
    borderRadius: 8,
    paddingTop: 15,
    paddingLeft: 13,
  },
  dropTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 16,
  },
  calendarTitle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  calendarIcon: {
    fontSize: 25,
    marginRight: 9,
  },
  modalInputTitle: {
    marginBottom: -10,
    marginTop: 15,
  },
});
