import React, { useEffect } from 'react';
import { styles } from './style';
import { Text, TouchableOpacity, View, Pressable, Image } from 'react-native';
import { useState } from 'react';
import ProjectDetail from './projectDetail';
import ProjectTeam from './projectTeam';
import ProjectSite from './projectSite';
function ProjectInfo({ ...props }) {
  const [viewOptions, setViewOptions] = useState('Details');

  const body = () => {
    switch (viewOptions) {
      case 'Details':
        return <ProjectDetail {...props} />;
      case 'Team':
        return <ProjectTeam {...props} />;
      case 'Sites':
        return <ProjectSite {...props} />;
      default:
        return <ProjectDetail {...props} />;
    }
  };
  useEffect(() => {
    body();
  }, [viewOptions]);
  return (
    <View style={styles.detailContainer}>
      <View style={[styles.header, { paddingBottom: 0 }]}>
        <View style={styles.headerTitle}>
          <TouchableOpacity
            onPress={() => {
              props.navigation.getParent().setParams({ screen: 'AccountSettings', isAdmin: true });
              if (props.route.params.fromSearchScreenParams) {
                props.navigation.navigate(...props.route.params.fromSearchScreenParams);
              } else {
                props.navigation.navigate('AccountSettings', { isAdmin: true });
              }
            }}
          >
            <Text style={styles.textBold}>Back</Text>
          </TouchableOpacity>
          <View style={styles.projectDetailTitle}>
            <Image source={{ uri: props.route.params.image }} style={[styles.img, { marginRight: 8 }]} />
            <Text style={styles.textBold}>{props.route.params.projectTitle}</Text>
          </View>
        </View>

        <View style={styles.viewOptionsContainer}>
          <Pressable onPress={() => setViewOptions('Details')}>
            <View>
              <Text style={viewOptions == 'Details' ? styles.blueBoldText : styles.grayBoldTextView}>
                Project Details
              </Text>
              {viewOptions == 'Details' && <View style={[styles.activeBorder, { width: '90%' }]}></View>}
            </View>
          </Pressable>
          <Pressable onPress={() => setViewOptions('Team')}>
            <View>
              <Text style={viewOptions == 'Team' ? styles.blueBoldText : styles.grayBoldTextView}>Team</Text>
              {viewOptions == 'Team' && <View style={[styles.activeBorder, { width: '90%' }]}></View>}
            </View>
          </Pressable>
          <Pressable onPress={() => setViewOptions('Sites')}>
            <View>
              <Text style={viewOptions == 'Sites' ? styles.blueBoldText : styles.grayBoldTextView}>Site Cadence</Text>
              {viewOptions == 'Sites' && <View style={[styles.activeBorder, { width: '90%' }]}></View>}
            </View>
          </Pressable>
        </View>
      </View>
      <View style={styles.body}>{body()}</View>
    </View>
  );
}

export default ProjectInfo;
