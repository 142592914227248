import React from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import Button from '../../../components/Button';
import images from '../../../theme/images';
import { styles } from './style';

function TagReminderNotification({ ...props }) {
  const { notification, navigation, handleClosePress } = props;

  const handleTopUpPress = () => {
    navigation.navigate('SettingNavigate', {
      screen: 'SubscriptionBilling',
      params: {
        initialViewOption: 'Tag',
        company: notification.company,
        fromNotifications: true,
      },
    });
  };

  return (
    <View style={styles.notiBlock}>
      <View style={[styles.notiHeader]}>
        <View style={styles.notiTitle}>
          <Image source={images.companyTileIcon} style={{ width: 32, height: 32, marginRight: 8 }} />
          <Text style={styles.textBold}>You are running low on tags</Text>
        </View>
        <TouchableOpacity onPress={() => handleClosePress(notification._id)}>
          <AiOutlineCloseCircle name="close" style={styles.closeIcon} />
        </TouchableOpacity>
      </View>
      <View style={styles.notiDescr}>
        <Text style={styles.textRegular}>
          <Text style={styles.textBold}>{notification.company.name}</Text> only has {notification.message} tags left.
        </Text>
        <Text style={styles.textRegular}>We suggest making an order now to keep project running smoothly.</Text>
      </View>
      <View style={styles.buttonsContainer}>
        <Button
          title={'Top Up'}
          buttonStyle={[styles.blueButton, { width: '100%' }]}
          textStyle={styles.blueButtonText}
          onPress={handleTopUpPress}
        />
      </View>
    </View>
  );
}

export default TagReminderNotification;
