import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../../../theme/colors';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  loading: {
    height: 8,
    backgroundColor: 'white',
    marginTop: 24,
  },
  loadPercent: {
    height: 8,
    width: '30%',
    backgroundColor: '#132FF5',
  },

  imageDrop: {
    height: 100,
    width: 100,
    borderRadius: 1000,
    backgroundColor: '#ffff',
    shadowColor: '#000',
    shadowOffset: {
      width: 6,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 6,
    elevation: 5,
  },
  image: {
    alignItems: 'center',
  },
  textBoldLarge: {
    fontSize: 18,
    fontFamily: 'NunitoSans_700Bold',
  },
  blueBorderButton: {
    borderRadius: 8,
    borderColor: colors.gray,
    borderWidth: 1,
    paddingVertical: 10,
    marginBottom: 16,
  },

  blueBorderButtonText: {
    color: colors.black,
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 16,
    textAlign: 'center',
  },
  buttonJob: {
    paddingHorizontal: 40,
    width: '100%',
  },
  blueButton: {
    borderRadius: 8,
    opacity: 0.5,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
    marginBottom: 10,
  },
  blueButtonActive: {
    borderRadius: 8,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
    marginBottom: 10,
  },
  blueButtonText: {
    color: colors.white,
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 18,
    textAlign: 'center',
  },
  blankButton: {
    paddingVertical: 10,
  },
  blankButtonText: {
    color: '#132FF5',
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    textAlign: 'center',
  },
  assignButton: {
    position: 'absolute',
    bottom: 0,
    paddingHorizontal: 27,
    backgroundColor: 'white',
    width: '100%',
  },
  input: {
    marginTop: 16,
    borderColor: '#C7C7C7',
    width: '100%',
    borderWidth: 1,
    borderRadius: 10,
    paddingVertical: 10,
    paddingLeft: 13,
  },
  textField: {
    paddingHorizontal: 28,
    marginBottom: 140,
  },
  notipush: {
    marginTop: 25,
    backgroundColor: 'rgba(0,0,0,0.1)',
    borderRadius: 16,
    paddingHorizontal: 31,
    paddingVertical: 21,
    alignItems: 'center',
    textAlign: 'center',
  },
  menu: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 20,
    paddingBottom: 36,
    paddingHorizontal: 26,
    alignItems: 'center',
  },
  textBold: {
    fontFamily: 'NunitoSans_700Bold',
  },
  textRegular: {
    textAlign: 'center',
    fontFamily: 'NunitoSans_400Regular',
    marginTop: 16,
  },
  inputHeader: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    paddingTop: 80,
  },
});
