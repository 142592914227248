import React, { useState, useEffect } from 'react';
import { TextInput, Text, View, Pressable } from 'react-native';
import { styles } from './style';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';

export default function InputField({ ...props }) {
  const [selectedDropdownItem, setSelectedDropdownItem] = useState('Select  ');
  const [open, setOpen] = useState(false);
  const { value, onChangeText = {} } = props;
  const periodTesting = [
    {
      role: 'Technician',
      value: 'Tech',
    },
    {
      role: 'Administrator',
      value: 'PM',
    },
    {
      role: 'Builder / Safety Officer',
      value: 'OHS',
    },
  ];
  const handleDropdownPicker = (item) => {
    if (onChangeText) {
      onChangeText(item.value);
    }
    setSelectedDropdownItem(item.role);
    setOpen(false);
  };

  return (
    <>
      <Text
        style={[
          styles.inputHeader,
          {
            paddingTop: 20,
          },
        ]}
      >
        Their Role
      </Text>
      <Text style={styles.inputDescript}>Which role title best describes their role in this project?</Text>
      <Pressable onPress={() => setOpen(!open)}>
        <View style={styles.inputDrop}>
          <View style={styles.dropTitleContainer}>
            <Text style={selectedDropdownItem == 'Select' ? styles.unpickedTitle : styles.dropList}>
              {selectedDropdownItem}
            </Text>
            {open ? (
              <FiChevronUp name="angle-up" style={styles.imgIcon} />
            ) : (
              <FiChevronDown name="angle-down" style={styles.imgIcon} />
            )}
          </View>
          {open &&
            periodTesting.map((item, index) => {
              return (
                <Pressable key={index} onPress={() => handleDropdownPicker(item)}>
                  <View style={index !== periodTesting.length ? styles.dropItem : styles.dropLastItem}>
                    <Text style={styles.dropList}>{item.role}</Text>
                  </View>
                </Pressable>
              );
            })}
        </View>
      </Pressable>
    </>
  );
}
