import React from 'react';
import { TouchableOpacity } from 'react-native';
import { Text, View } from 'react-native';
import { styles } from './style';

import AccordionSubContent from './accordionSubContent';

const AccordionContent = ({ ...props }) => {
  const item = props;

  if (item.type === 'all' || item.type === 'my task') {
    return (
      <View
        style={{
          paddingLeft: 23,
          paddingRight: 27,
          paddingTop: 12,
        }}
      >
        {item.overdue.length > 0 && (
          <View>
            <Text style={styles.taskTitle}>Overdue</Text>
            {item.overdue.map((itm, id) => {
              return (
                <AccordionSubContent
                  key={id}
                  {...item}
                  period={'overdue'}
                  item={itm}
                  last={id == item.overdue.length - 1}
                />
              );
            })}
          </View>
        )}
        {item.today.length > 0 && (
          <View>
            <Text style={styles.taskTitle}>Today</Text>
            {item.today.map((itm, id) => {
              return (
                <AccordionSubContent
                  key={id}
                  {...item}
                  period={'today'}
                  item={itm}
                  last={id == item.today.length - 1}
                />
              );
            })}
          </View>
        )}
        {item.thisWeek.length > 0 && (
          <View>
            <Text style={styles.taskTitle}>This Week</Text>
            {item.thisWeek.map((itm, id) => {
              return (
                <AccordionSubContent
                  key={id}
                  {...item}
                  period={'thisWeek'}
                  item={itm}
                  last={id == item.thisWeek.length - 1}
                />
              );
            })}
          </View>
        )}
        {item.thisMonth.length > 0 && (
          <View>
            <Text style={styles.taskTitle}>This Month</Text>
            {item.thisMonth.map((itm, id) => {
              return (
                <AccordionSubContent
                  key={id}
                  {...item}
                  period={'thisMonth'}
                  item={itm}
                  last={id == item.thisMonth.length - 1}
                />
              );
            })}
          </View>
        )}
      </View>
    );
  }

  if (item.type === 'calendar') {
    return (
      <View
        style={{
          paddingLeft: 23,
          paddingRight: 27,
          paddingTop: 12,
        }}
      >
        {item.groups.map((itm, id) => {
          return (
            <AccordionSubContent key={id} {...item} period={'exact'} item={itm} last={id == item.groups.length - 1} />
          );
        })}
      </View>
    );
  }
};

export default AccordionContent;
