import React, { useEffect, useState, useRef } from 'react';
import { SafeAreaView, View, ScrollView } from 'react-native';
import { styles } from './style';

import Selected from './selected';
import Button from '../../../../../components/Button';
import CommonHeader from '../commonHeader';
import { StatusBar } from 'expo-status-bar';
import { useIsFocused } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';

export default function TestAssetFlowStep1({ ...props }) {
  const isFocused = useIsFocused();
  const params = props.route?.params;
  const scrollViewRef = useRef(null);

  props.navigation.getParent().setParams({
    isAdmin: true,
  });

  const selectedValuesDefaults = {
    visual_inspection: {
      status: '',
      details: {
        info: '',
        img: {},
      },
    },
    polarity: {
      status: '',
      details: {
        info: '',
        img: {},
      },
    },
    earth_continuity: {
      status: '',
      details: {
        info: '',
        img: {},
      },
    },
    insulation_resistance: {
      status: '',
      details: {
        info: '',
        img: {},
      },
    },
    operation_time_of_rcd: {
      status: '',
      details: {
        info: '',
        img: {},
      },
    },
    fault_loop_impedance: {
      status: '',
      details: {
        info: '',
        img: {},
      },
    },
  };

  const [selectedValues, updateSelectedValues] = useState(selectedValuesDefaults);

  const [user, setUser] = useState(null);
  const [disabledButton, setDisabledButton] = useState(true);

  const setSelectedValue = (id, value) => {
    let obj = selectedValues;
    obj[id].status = value;
    updateSelectedValues(obj);
    if (
      selectedValues.visual_inspection.status !== '' &&
      selectedValues.polarity.status !== '' &&
      selectedValues.earth_continuity.status !== '' &&
      selectedValues.insulation_resistance.status !== ''
    ) {
      setDisabledButton(false);
    }
  };

  const setSelectedDetails = (id, key, value) => {
    let obj = selectedValues;
    let detailObj = obj[id].details;
    detailObj[key] = value;
    updateSelectedValues(obj);
  };

  const getUser = async () => {
    let user = await AsyncStorage.getItem('user');
    user = JSON.parse(user);
    setUser(user);
  };

  useEffect(() => {
    if (isFocused) {
      props.navigation.getParent().setParams({
        screen: undefined,
      });
      updateSelectedValues(selectedValuesDefaults);
      getUser();
      scrollViewRef.current.scrollTo({ y: 0 });
    }
  }, [isFocused]);

  const testList = [
    {
      title: 'Visual Inspection',
      id: 'visual_inspection',
      require: true,
    },
    {
      title: 'Polarity',
      id: 'polarity',
      require: true,
    },
    {
      title: 'Earth Continuity',
      id: 'earth_continuity',
      require: true,
    },
    {
      title: 'Insulation Resistance',
      id: 'insulation_resistance',
      require: true,
    },
    {
      title: 'Operating time of RCD',
      id: 'operation_time_of_rcd',
      require: false,
    },
    {
      title: 'Fault Loop Impedance',
      id: 'fault_loop_impedance',
      require: false,
    },
  ];
  return (
    <SafeAreaView style={styles.container}>
      <StatusBar style="dark" />
      <ScrollView
        ref={scrollViewRef}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        style={styles.scrollViewContainer}
      >
        <View style={styles.loading}>
          <View style={styles.loadPercent}></View>
        </View>
        <View>
          <View>
            <CommonHeader
              onPress={() => {
                props.navigation.getParent().setParams({
                  screen: 'QrCodeNav',
                  params: { isAdmin: user?.role === 'PM' },
                });
                if (params.fromSearchScreenParams) {
                  props.navigation.navigate(...params.fromSearchScreenParams);
                } else {
                  // props.navigation.navigate('QrCodeNav');
                  props.navigation.goBack();
                }
              }}
              asset={params.asset}
            />
            <View style={styles.body}>
              {testList.map((item, index) => (
                <Selected
                  id={item.id}
                  title={item.title}
                  key={index}
                  required={item.require}
                  setSelectedValue={(id, value) => {
                    setSelectedValue(id, value);
                  }}
                  setSelectedDetails={(id, key, value) => setSelectedDetails(id, key, value)}
                />
              ))}
              <Button
                disabled={disabledButton}
                title={'Next'}
                buttonStyle={[styles.blueButton, { marginBottom: 10 }]}
                textStyle={styles.blueButtonText}
                onPress={() =>
                  props.navigation.navigate('TestAssetFlowStep2', {
                    ...params,
                    selectedValues: { ...selectedValues },
                    user: user,
                  })
                }
              />
            </View>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}
