import { useIsFocused } from '@react-navigation/native';
import * as Localization from 'expo-localization';
import { StatusBar } from 'expo-status-bar';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { Alert, Image, Pressable, SafeAreaView, ScrollView, Text, TouchableOpacity, View } from 'react-native';
import InfoRow from '../../components/AssetInfo/InfoRow';
import Button from '../../components/Button';
import CommonScaffold from '../../components/CommonScaffold';
import PlaceholderLoading from '../../components/PlaceholderLoading';
import { api } from '../../utils/api';
import AccordionContent from './accordionContent';
import Status from './status';
import { styles } from './style';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Loading from '../../components/Loading';
import GoogleMapWithMarker from '../../components/GoogleMapWithMarker';

const EnvConstants = require('../../../EnvConstants');

const AssetInfo = ({ ...props }) => {
  const assetInfoScrollRef = useRef();
  const params = props.route.params ? props.route.params : false;
  const isFocused = useIsFocused();
  const [testHistoryLoc, setTestHistoryLoc] = useState(0);
  const [infoLoading, setInfoLoading] = useState({
    assetInfoContent: true,
    assetInfoLoader: true,
  });
  const [ownerInfoLoading, setOwnerInfoLoading] = useState({
    content: true,
    loader: true,
  });
  const [testHistoryLoading, setTestHistoryLoading] = useState({
    content: true,
    loader: true,
  });
  const [assetInfo, setAssetInfo] = useState(null);
  const [ownerInfo, setOwnerInfo] = useState(null);
  const [assetNotes, setAssetNotes] = useState(null);
  const [testHistory, setTestHistory] = useState([]);
  const [user, setUser] = useState(null);
  const [hasWriteAccess, setHasWriteAccess] = useState(false);

  useEffect(() => {
    if (isFocused) {
      getUser();
      console.log('params: ', params);
      if (params?.asset) {
        setAssetInfo(params?.asset);
        retrieveAssetInfo(params?.asset);
      } else if (assetInfo) {
        retrieveAssetInfo(assetInfo);
      }
    }
  }, [isFocused]);

  const getUser = async () => {
    let user = await AsyncStorage.getItem('user');
    user = JSON.parse(user);
    setUser(user);
    if (user && user.role !== 'OHS') {
      setHasWriteAccess(true);
    }
  };

  const retrieveAssetInfo = async (asset) => {
    getOwnerInfo(asset._id, asset.site._id ? asset.site._id : asset.site);
    getTestHistory(asset._id);
    setInfoLoading({
      ...infoLoading,
      assetInfoLoader: false,
    });
    setTimeout(() => {
      setInfoLoading({
        ...infoLoading,
        assetInfoContent: false,
      });
    }, 200);
  };

  const getOwnerInfo = async (assetId, locationId) => {
    const owner = await api(`v1/asset/${assetId}/owner/${locationId}`, 'GET', null);
    if (owner.status == 404) {
      console.log('Cannot find owner information.', owner.data.message);
    } else {
      const result = owner.data;
      console.log('Owner Info: ', result);
      setOwnerInfo(result.owner);
      setOwnerInfoLoading({
        ...ownerInfoLoading,
        loader: false,
      });
      setTimeout(() => {
        setOwnerInfoLoading({
          ...ownerInfoLoading,
          content: false,
        });
      }, 200);
    }
  };

  const getTestHistory = async (assetId) => {
    const tests = await api(`v1/asset/${assetId}/pasttests`, 'GET', null);
    console.log('Past tests: ', tests.data);
    if (tests.status == 404) {
      Alert.alert('Unable to retrieve past test data.', tests.data.test);
    } else {
      const testResults = tests.data;
      var testResultSections = [];

      for (let tr = 0; tr < testResults.tests.length; tr++) {
        const element = testResults.tests[tr];
        testResultSections.push({
          section: [
            {
              title: moment(element.createdOnUTC)
                .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                .format('DD/MM/YY'),
              time: moment(element.createdOnUTC).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('hh:mma'),
            },
          ],
          data: element,
          status: element.result,
          testUser: element.user,
        });
      }

      setTestHistory(testResultSections);
      setTestHistoryLoading({
        ...testHistoryLoading,
        loader: false,
      });
      setTimeout(() => {
        setTestHistoryLoading({
          ...testHistoryLoading,
          content: false,
        });
      }, 200);
    }
  };

  const daysRemaining = () => {
    var nextTestDate = moment(assetInfo.nextTestUTC).tz(Intl.DateTimeFormat().resolvedOptions().timeZone);
    var today = moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone);
    return nextTestDate.diff(today, 'days');
  };

  const nextDate = () => {
    return moment(assetInfo.nextTestUTC).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('DD/MM/YYYY');
  };

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <StatusBar style="dark" />
      <CommonScaffold
        assetInfoLoading={infoLoading}
        assetInfo={assetInfo}
        title={assetInfo && assetInfo.name}
        icon={assetInfo && assetInfo.assetType.icon}
        headerIcon="close"
        onPress={() => {
          props.navigation.getParent().setParams({
            screen: 'QrCodeNav',
            params: { isAdmin: user?.role === 'PM' },
          });
          if (params?.fromNotifications) {
            props.navigation.navigate('Notifications', {
              screen: 'NotificationsPage',
              params: { isAdmin: params.isAdmin },
            });
          } else if (params?.fromSearchScreenParams) {
            console.log('params?.fromSearchScreenParams', params?.fromSearchScreenParams);
            props.navigation.navigate(...params.fromSearchScreenParams);
          } else if (params?.isFromGuest) {
            props.navigation.goBack();
          } else {
            // props.navigation.navigate('QrScanner');
            props.navigation.goBack();
          }
        }}
        navigation={props.navigation}
      />
      <ScrollView
        ref={assetInfoScrollRef}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={params?.fromQrCodeNav && { paddingBottom: 100 }}
      >
        {params && params?.isFromGuest ? (
          <></>
        ) : (
          <View style={styles.download}>
            <Text style={styles.downloadText}>Details</Text>
          </View>
        )}
        <View style={params && params?.isFromGuest ? styles.assetInfor : [styles.assetInfor, { marginTop: 16 }]}>
          <InfoRow
            title="Title"
            content={
              <Text
                style={{
                  fontFamily: 'NunitoSans_400Regular',
                }}
              >
                {infoLoading.assetInfoContent ? (
                  <PlaceholderLoading isFetching={infoLoading.assetInfoLoader} />
                ) : (
                  assetInfo.name
                )}
              </Text>
            }
          />
          <InfoRow
            title="Type"
            content={
              <View style={infoLoading.assetInfoContent ? null : styles.assetTypeContent}>
                {/* TODO Using icon pass from props */}
                {infoLoading.assetInfoContent ? (
                  <View
                    style={{
                      paddingRight: 4,
                    }}
                  >
                    <PlaceholderLoading isFetching={infoLoading.assetInfoLoader} />
                  </View>
                ) : (
                  <>
                    <View
                      style={{
                        paddingRight: 4,
                      }}
                    >
                      <Image
                        source={{ uri: assetInfo.assetType.icon }}
                        style={{
                          width: 22,
                          height: 22,
                        }}
                      />
                    </View>
                    <Text
                      style={{
                        fontFamily: 'NunitoSans_600SemiBold',
                      }}
                    >
                      {assetInfo.assetType.name}
                    </Text>
                  </>
                )}
              </View>
            }
          />
          <View>
            <InfoRow
              title="Serial #"
              content={
                infoLoading.assetInfoContent ? (
                  <PlaceholderLoading isFetching={infoLoading.assetInfoLoader} />
                ) : (
                  <Text style={styles.assetInforContent}>{assetInfo.serialNumber}</Text>
                )
              }
            />
          </View>
          <InfoRow
            title="Current Status"
            content={
              infoLoading.assetInfoContent ? (
                <PlaceholderLoading isFetching={infoLoading.assetInfoLoader} />
              ) : (
                <View
                  style={{
                    flexDirection: 'row',
                  }}
                >
                  <Status status={assetInfo.currentStatus} />
                  {/* {assetInfo.currentStatus !== 'Untested' && (
                    <Pressable
                      style={styles.assetTypeContent}
                      onPress={() => {
                        assetInfoScrollRef.current?.scrollTo({
                          x: 0,
                          y: testHistoryLoc,
                          animated: true,
                        });
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 12,
                          color: '#132FF5',
                          fontWeight: 'bold',
                        }}
                      >
                        View Test History
                      </Text>
                    </Pressable>
                  )} */}
                </View>
              )
            }
          />
        </View>
        <View style={styles.assetLocation}>
          {params?.location && (
            <>
              <Text style={[styles.locationTitle]}>Scanned Location</Text>
              <View style={[styles.mapView, { marginBottom: 15 }]}>
                {/* <MapView
                  style={[styles.assetMap, { borderColor: '#FF4D4D' }]}
                  initialRegion={{
                    latitude: params?.location.lat,
                    longitude: params?.location.lng,
                    latitudeDelta: 0.05,
                    longitudeDelta: 0.05,
                  }}
                  scrollEnabled={false}
                  zoomEnabled={false}
                  pitchEnabled={false}
                  rotateEnabled={false}
                  zoomTapEnabled={false}
                >
                 <MapView.Marker
                    coordinate={{
                      latitude: params?.location.lat,
                      longitude: params?.location.lng,
                    }}
                  /> 
                </MapView> */}
                <GoogleMapWithMarker latitude={params?.location.lat} longitude={params?.location.lng} />
              </View>
            </>
          )}

          <Text style={styles.locationTitle}>Site Location</Text>
          <View style={styles.mapView}>
            {ownerInfoLoading.content ? (
              <PlaceholderLoading isFetching={ownerInfoLoading.loader} width={'100%'} height={200} />
            ) : (
              // <MapView
              //   style={styles.assetMap}
              //   initialRegion={{
              //     latitude: ownerInfo.location.lat,
              //     longitude: ownerInfo.location.lng,
              //     latitudeDelta: 0.05,
              //     longitudeDelta: 0.05,
              //   }}
              //   scrollEnabled={false}
              //   zoomEnabled={false}
              //   pitchEnabled={false}
              //   rotateEnabled={false}
              //   zoomTapEnabled={false}
              // >
              //   <MapView.Marker
              //     coordinate={{
              //       latitude: ownerInfo.location.lat,
              //       longitude: ownerInfo.location.lng,
              //     }}
              //   />
              // </MapView>
              // <ScriptjsLoader
              //   hostname={'maps.googleapis.com'}
              //   pathname={'/maps/api/js'}
              //   query={{ v: `3.0`, libraries: 'geometry,drawing,places' }}
              //   loadingElement={
              //     <div>
              //       <Loading opacity={0} />
              //     </div>
              //   }
              //   containerElement={<div />}
              //   googleMapElement={
              //     <MapView defaultZoom={3} defaultCenter={{ lat: ...this.ownerInfo.location.lat, lng: ownerInfo.location.lng }}>
              //       {/* <Marker
              //         coordinate={{
              //           latitude: ownerInfo.location.lat,
              //           longitude: ownerInfo.location.lng,
              //         }}
              //       /> */}
              //     </MapView>
              //   }
              // />
              <GoogleMapWithMarker latitude={ownerInfo?.location.lat} longitude={ownerInfo?.location.lng} />
            )}
          </View>
          {assetNotes !== null && (
            <>
              <Text style={styles.note}>Notes</Text>
              <View style={styles.noteScript}>
                <Text
                  style={{
                    fontFamily: 'NunitoSans_400Regular',
                  }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.
                </Text>
              </View>
            </>
          )}
        </View>
        <View
          key={'assetHistory'}
          style={styles.assetHistory}
          onLayout={(event) => {
            const layout = event.nativeEvent.layout;
            setTestHistoryLoc(layout.y);
          }}
        >
          <Text style={styles.historyTitle}>Test History</Text>
          <View style={{ marginTop: 19 }}>
            <Text style={{ fontFamily: 'NunitoSans_700Bold' }}>
              {infoLoading.assetInfoContent ? (
                <PlaceholderLoading isFetching={infoLoading.assetInfoLoader} />
              ) : (
                `Next due: ${daysRemaining()} days - ${nextDate()}`
              )}
            </Text>
          </View>
          <View
            style={{
              marginTop: 11,
            }}
          >
            {testHistoryLoading.content ? (
              <>
                <PlaceholderLoading
                  isFetching={testHistoryLoading.loader}
                  width={'100%'}
                  height={40}
                  marginBottom={10}
                />
                <PlaceholderLoading
                  isFetching={testHistoryLoading.loader}
                  width={'100%'}
                  height={40}
                  marginBottom={10}
                />
                <PlaceholderLoading
                  isFetching={testHistoryLoading.loader}
                  width={'100%'}
                  height={40}
                  marginBottom={10}
                />
              </>
            ) : testHistory.length > 0 ? (
              testHistory.map((item, index) => (
                <AccordionContent
                  section={item.section}
                  key={index}
                  status={item.status}
                  asset={assetInfo}
                  selectedValues={item.data.entries}
                  user={item.testUser}
                />
              ))
            ) : (
              <Text>No Test's completed yet</Text>
            )}
          </View>
        </View>
      </ScrollView>
      <View
        style={[
          styles.bottomNav,
          (user || user == null) && hasWriteAccess == true && user && user.role == 'OHS' && { height: 170 },
        ]}
      >
        {hasWriteAccess && (
          <Button
            title={'Test Asset'}
            buttonStyle={[styles.blueButton, { marginBottom: 0 }]}
            textStyle={styles.blueButtonText}
            onPress={() => {
              if (params?.fromQrCodeNav || params?.fromSearchScreenParams || params?.fromNotifications) {
                props.navigation.navigate('QrCodeNav', {
                  screen: 'TestAssetFlowStep1',
                  fromQRCodeNav: true,
                  asset: assetInfo,
                  taskListDetails: params?.taskListDetails,
                });
              } else {
                props.navigation.navigate('OnboardingNavigation', {
                  screen: 'Auth0Screen',
                  params: {
                    assetInfo: assetInfo,
                  },
                });
              }
            }}
          />
        )}
        {((user && user.role == 'OHS') || user == null) && (
          <Button
            title={user !== null ? 'Asset Registry' : 'Log in'}
            buttonStyle={[
              styles.blueButton,
              { marginBottom: 0 },
              (user || user == null) && hasWriteAccess == true && { marginTop: 30 },
            ]}
            textStyle={styles.blueButtonText}
            onPress={() => {
              props.navigation.navigate('OnboardingNavigation', {
                screen: 'Auth0Screen',
                params: {
                  assetInfo: assetInfo,
                },
              });
            }}
          />
        )}
      </View>
    </SafeAreaView>
  );
};

export default AssetInfo;
