import React from 'react';
import { Text, View, Image } from 'react-native';
import { styles } from './style';
import NavigationHeader from '../../../../../components/SignUp/SingUpNavigation';
import images from '../../../../../theme/images';

export default function CommonHeader({ ...props }) {
  return (
    <View
      style={{
        paddingHorizontal: 23,
      }}
    >
      <NavigationHeader
        onPress={props.onPress}
        header={props.header ? props.header : 'Testing'}
        navigate={'Cancel'}
        menuStyles={styles.menu}
      />
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <View style={styles.assetImageContainer}>
          <View style={styles.assetImage}>
            <Image
              style={{
                width: 35,
                height: 35,
              }}
              source={{ uri: props.asset.assetType.icon }}
            />
          </View>
        </View>

        <View
          style={{
            paddingLeft: 16,
          }}
        >
          <Text style={styles.textBoldMedium}>{props.asset.name}</Text>
          <View
            style={{
              flexDirection: 'row',
              paddingTop: 3,
            }}
          >
            <Text
              style={[
                styles.textBoldSmall,
                {
                  paddingRight: 4,
                },
              ]}
            >
              Serial #
            </Text>
            <Text style={styles.textRegularSmall}>{props.asset.serialNumber}</Text>
          </View>
        </View>
      </View>
    </View>
  );
}
