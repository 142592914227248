import React from 'react';
import { useEffect } from 'react';

import { useState } from 'react';
import { Text, TouchableOpacity, View, Image } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import Button from '../../../../components/Button';
import SearchBar from '../../../../components/SearchBar';
import images from '../../../../theme/images';
import { styles } from './style';
import Loading from '../../../../components/Loading';
import { useIsFocused } from '@react-navigation/native';
import { api } from '../../../../utils/api';

export default function MyProject({ ...props }) {
  const [text, setText] = useState('');
  const [filteredData, setFilteredData] = useState();
  const [data, setProjectData] = useState([]);
  const [projectLoading, setProjectsLoading] = useState(true);

  const params = props.route.params;

  const getProjects = async () => {
    var userProjects = [];
    const projects = await api(`v1/project/userprojects`, 'GET', null);
    if (projects.status == 200) {
      if (projects.data.success) {
        let data = projects.data;
        console.log('project data: ', data);
        for (let i = 0; i < data.projects.length; i++) {
          const element = data.projects[i];
          console.log('logo', element.logo);
          const obj = {
            projectTitle: element.name,
            locationString: `${element.address.street}, ${element.address.city}, ${element.address.state}`,
            location: element.address,
            image: element.logo,
            owner: element.company,
            id: element._id,
          };
          userProjects.push(obj);
        }
      }
    }

    setProjectData(userProjects);
    setFilteredData(userProjects);
    setProjectsLoading(false);
  };

  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused) {
      getProjects();
    }
  }, [isFocused]);

  const handleSearch = (text) => {
    setText(text);
    if (text && text.length > 0) {
      setFilteredData(
        data.filter(
          (item) =>
            item.projectTitle.toLowerCase().includes(text.toLowerCase()) ||
            item.locationString.toLowerCase().includes(text.toLowerCase())
        )
      );
    } else if (text.length == 0) {
      setFilteredData(data);
    }
  };
  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <View style={styles.headerTitle}>
          <TouchableOpacity onPress={() => props.navigation.goBack()}>
            <Text style={styles.textBold}>Back</Text>
          </TouchableOpacity>

          <Text style={styles.textBoldGray}>My Projects</Text>
        </View>
      </View>
      <ScrollView>
        <View style={styles.body}>
          <SearchBar
            onChangeText={(text) => handleSearch(text)}
            text={text}
            placeholder="Start typing title or address..."
          />
          <View style={styles.projectsContainer}>
            {projectLoading && (
              <View style={{ marginTop: 30 }}>
                <Loading opacity={1} />
              </View>
            )}
            {filteredData?.map((item, id) => {
              return (
                <TouchableOpacity
                  key={id}
                  onPress={() =>
                    props.navigation.navigate('ProjectInfo', {
                      projectTitle: item.projectTitle,
                      id: item.id,
                      locationString: item.locationString,
                      location: item.location,
                      image: item.image,
                      owner: item.owner,
                      hasWriteAccess: params.hasWriteAccess,
                    })
                  }
                >
                  <View
                    style={[
                      styles.projectContainer,
                      id == filteredData?.length - 1 && {
                        borderBottomWidth: 0,
                      },
                    ]}
                    key={id}
                  >
                    <Image source={{ uri: item.image }} style={styles.img} />
                    <View style={styles.projectDetail}>
                      <Text style={styles.textBoldMedium}>{item.projectTitle}</Text>
                      <Text style={styles.textLight}>{item.locationString}</Text>
                    </View>
                  </View>
                </TouchableOpacity>
              );
            })}
          </View>
        </View>
      </ScrollView>
      {params.hasWriteAccess && (
        <Button
          title={'Add new project'}
          buttonStyle={styles.blankButton}
          textStyle={styles.blankButtonText}
          onPress={() => {
            props.navigation.navigate('SettingUpProjectStep4', {
              addNewProject: true,
              isAdmin: true,
            });
          }}
        />
      )}
    </View>
  );
}
