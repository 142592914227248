import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';
import Asset from '../../../screens/tabs/assetRegistry';
import AssetInfo from '../../../screens/assetInfo';
import QrScannerFromTask from '../../../screens/tabs/qrScanner';
import AdminAssetRegistry from '../../../screens/admin/assetRegistry';
import AdminViewProject from '../../../screens/admin/assetRegistry/viewProject';
import AdminSiteAssets from '../../../screens/admin/assetRegistry/siteAssets';
import AddAssets from '../../../screens/admin/assetRegistry/addAssets';
import TestingHistory from '../../../screens/admin/assetRegistry/addAssets/testingHistory';
import TestingSchedule from '../../../screens/admin/assetRegistry/addAssets/testingSchedule';
import ExtraInformation from '../../../screens/admin/assetRegistry/addAssets/extraInformation';

const Stack = createNativeStackNavigator();

const AssetRegistry = ({ ...props }) => (
  <Stack.Navigator>
    <Stack.Screen
      {...props}
      name={'Asset'}
      component={Asset}
      options={{
        headerShown: false,
        headerStyle: { backgroundColor: 'transparent' },
        tabBarVisible: false,
      }}
      initialParams={{
        isAdmin: props.route.params?.isAdmin,
      }}
    />
    <Stack.Screen
      {...props}
      name={'QrScannerFromTask'}
      component={QrScannerFromTask}
      options={{
        headerShown: false,
        headerStyle: { backgroundColor: 'transparent' },
      }}
      initialParams={{
        isAdmin: props.route.params?.isAdmin,
      }}
    />
    <Stack.Screen
      name={'AssetInfo'}
      component={AssetInfo}
      initialParams={{
        ...props.route.params,
        fromAssetDir: true,
        isAdmin: props.route.params?.isAdmin,
      }}
      options={{
        headerShown: false,
        headerStyle: { backgroundColor: 'transparent' },
      }}
    />
    <Stack.Screen
      {...props}
      name={'AddAssets'}
      component={AddAssets}
      options={{
        headerShown: false,
        gestureEnabled: true,
      }}
      initialParams={{
        ...props.route.params,
        isAdmin: props.route.params?.isAdmin,
      }}
    />
    <Stack.Screen
      {...props}
      name={'AdminAssetRegistry'}
      component={AdminAssetRegistry}
      options={{
        headerShown: false,
        gestureEnabled: true,
      }}
      initialParams={{
        isAdmin: props.route.params?.isAdmin,
      }}
    />
    <Stack.Screen
      {...props}
      name={'AdminViewProject'}
      component={AdminViewProject}
      options={{
        headerShown: false,
        gestureEnabled: true,
      }}
      initialParams={{
        isAdmin: props.route.params?.isAdmin,
      }}
    />
    <Stack.Screen
      {...props}
      name={'AdminSiteAssets'}
      component={AdminSiteAssets}
      options={{
        headerShown: false,
        gestureEnabled: true,
      }}
      initialParams={{
        isAdmin: props.route.params?.isAdmin,
      }}
    />
    <Stack.Screen
      name={'TestingSchedule'}
      component={TestingSchedule}
      options={{
        headerShown: false,
        headerStyle: { backgroundColor: 'transparent' },
      }}
      initialParams={{
        isAdmin: props.route.params?.isAdmin,
      }}
    />
    <Stack.Screen
      name={'TestingHistory'}
      component={TestingHistory}
      options={{
        headerShown: false,
        headerStyle: { backgroundColor: 'transparent' },
      }}
      initialParams={{
        isAdmin: props.route.params?.isAdmin,
      }}
    />
    <Stack.Screen
      name={'ExtraInformation'}
      component={ExtraInformation}
      options={{
        headerShown: false,
        headerStyle: { backgroundColor: 'transparent' },
      }}
      initialParams={{
        isAdmin: props.route.params?.isAdmin,
      }}
    />
  </Stack.Navigator>
);

export default AssetRegistry;
