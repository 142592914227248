import React, { useState, useEffect } from 'react';
import { TextInput, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import Button from '../../../../components/Button';
import { styles } from './style';
import { useIsFocused } from '@react-navigation/core';
import { api } from '../../../../utils/api';
import { showSuccessToast, showErrorToast } from '../../../../utils/toastUtils';
import Loading from '../../../../components/Loading';
import ConfirmModal from './confirmModal';

function Tags({ ...props }) {
  const { company, updateCompanyAndList } = props;
  const [tagCounts, setTagCounts] = useState(null);
  const [addTagsAmount, setAddTagsAmount] = useState(100);
  const [customAmountText, setCustomAmountText] = useState('');
  const [loading, setLoading] = useState(true);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  const getCostString = () => '$' + Number(addTagsAmount * 25).toLocaleString();

  const reset = () => {
    setAddTagsAmount(100);
    setCustomAmountText('');
    getCurrentTagCounts();
  };

  const requestTags = async () => {
    const response = await api(`v1/company/${company._id}/order`, 'POST', {
      newTags: addTagsAmount,
    });

    if (response.status === 200 && response.data.success) {
      const updatedCompany = response.data.company;
      console.log('updatedCompany', updatedCompany);
      updateCompanyAndList({ ...updatedCompany, id: updatedCompany._id, title: updatedCompany.name });
      showSuccessToast('Order placed successfully', 'Allow 3-5 business days for delivery');
    } else {
      showErrorToast('Order failed', 'Please try again');
    }
    reset();
  };

  const getCurrentTagCounts = async () => {
    const response = await api(`v1/company/${company._id}/tags/count`, 'GET', null);

    if (response.status === 200 && response.data.success) {
      setTagCounts(response.data.counts);
    }

    setLoading(false);
  };

  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      getCurrentTagCounts();
    }
  }, [isFocused]);

  if (loading) {
    return (
      <View style={{ marginTop: 50 }}>
        <Loading />
      </View>
    );
  }

  if (!tagCounts) {
    return <></>;
  }

  return (
    <>
      <KeyboardAwareScrollView>
        <View style={styles.body}>
          <View style={styles.tagContainer}>
            <View style={styles.currentTagsContainer}>
              <View style={styles.currentTagsSection}>
                <Text style={styles.textBoldLarge}>Available</Text>
                <Text style={styles.textBoldLarge}>{tagCounts.available}</Text>
              </View>
              <View style={styles.breakLine}></View>
              <View style={styles.currentTagsSection}>
                <Text style={styles.textBoldGray}>Assigned</Text>
                <Text style={styles.textBoldGray}>{tagCounts.assigned}</Text>
              </View>
              <View style={styles.breakLine}></View>
              <View style={styles.currentTagsSection}>
                <Text style={styles.textBoldGray}>Requested</Text>
                <Text style={styles.textBoldGray}>{tagCounts.requested}</Text>
              </View>
            </View>
            <View style={styles.addTaskContainer}>
              <View style={{ marginBottom: 10 }}>
                <Text style={[styles.textBoldMedium, { color: '#343147' }]}>Add more tags</Text>
              </View>
              <View style={styles.buttonContainer}>
                <Button
                  title={'+ 100'}
                  buttonStyle={[
                    { marginRight: 8 },
                    addTagsAmount == 100 ? styles.blueButtonAddTag : styles.whiteButton,
                  ]}
                  textStyle={[addTagsAmount == 100 ? styles.blueButtonText : styles.whiteButtonText, { fontSize: 14 }]}
                  onPress={() => {
                    setCustomAmountText('');
                    setAddTagsAmount(100);
                  }}
                />
                <Button
                  title={'+ 500'}
                  buttonStyle={addTagsAmount == 500 ? styles.blueButtonAddTag : styles.whiteButton}
                  textStyle={[addTagsAmount == 500 ? styles.blueButtonText : styles.whiteButtonText, { fontSize: 14 }]}
                  onPress={() => {
                    setCustomAmountText('');
                    setAddTagsAmount(500);
                  }}
                />
              </View>
              <TextInput
                placeholder="Enter custom amount (over 500)"
                value={customAmountText}
                onChangeText={(text) => {
                  setCustomAmountText(text);
                  setAddTagsAmount(Number(text));
                }}
                style={styles.input}
              />
              <View style={styles.tagDetails}>
                <Text style={styles.textBold}>Extra Tags</Text>
                <Text style={styles.textBold}>{addTagsAmount} Tags</Text>
              </View>
              <View style={styles.tagDetails}>
                <Text style={styles.textBoldLarge}>Order Total:</Text>
                <Text style={styles.textBoldLarge}>{getCostString()}</Text>
              </View>
              <View style={styles.caution}>
                <Text style={styles.cautionText}>* Allow 3-5 business days for delivery </Text>
              </View>
            </View>
          </View>
        </View>
      </KeyboardAwareScrollView>
      <View style={{ marginHorizontal: 25, marginBottom: 8 }}>
        <Button
          title={'Request Tags'}
          buttonStyle={addTagsAmount > 0 ? styles.blueButtonActive : styles.blueButton}
          textStyle={styles.blueButtonText}
          onPress={() => {
            if (addTagsAmount > 0) {
              setConfirmModalVisible(true);
            }
          }}
        />
      </View>
      <ConfirmModal
        visible={confirmModalVisible}
        setVisible={setConfirmModalVisible}
        title={'Are you sure?'}
        details={`Request for ${addTagsAmount} tags will be created.`}
        submitButtonText={'Yes, request'}
        handleSubmit={requestTags}
      />
    </>
  );
}

export default Tags;
