import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../../../theme/colors';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  header: {
    backgroundColor: '#F6F6F6',
    paddingTop: 70,
    paddingBottom: 18,
  },
  body: {
    backgroundColor: 'white',
    marginBottom: 100,

    paddingTop: 10,
  },
  screenNameIcon: {
    fontSize: 17,
    color: '#6B6B6B',
    alignSelf: 'center',
  },
  screenNameContainer: {
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingHorizontal: 20,
    flexDirection: 'row',
  },

  grayBoldText: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 14,
    color: '#6B6B6B',
    paddingBottom: 9,
    paddingRight: 18,
  },
  blueBoldText: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 14,
    color: '#132FF5',
    paddingBottom: 9,
    paddingRight: 18,
  },
  screenTitle: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    color: '#6B6B6B',
  },
  activeBorder: {
    height: 1,
    width: '90%',
    position: 'absolute',
    backgroundColor: '#132FF5',
    bottom: -1,
  },
  screenTitleIcon: {
    width: 22,
  },
  imgIcon: {
    width: 22,
    height: 22,
    marginHorizontal: 13,
  },
  taskIcon: {
    width: 22,
    height: 22,
    borderRadius: 11,
  },
  iconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  viewOptionsContainer: {
    marginTop: 22,
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 23,
    paddingRight: 27,
  },

  taskTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderColor: '#00000014',
    paddingVertical: 10,
    paddingHorizontal: 23,
  },
  taskInfo: {
    flexDirection: 'row',

    alignItems: 'center',
  },
  taskTitle: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 14,
    color: '#000000',
  },
  taskDesc: {
    paddingBottom: 6,
  },
  tasks: {
    fontFamily: 'NunitoSans_400Regular',
    fontSize: 12,
    color: '#000000',
  },
  taskDetailBlock: {
    marginTop: 10,
    borderRadius: 8,
    paddingHorizontal: 13,
    paddingVertical: 6,
  },
  taskDetailRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  taskDetailAssignee: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 8,
    paddingVertical: 6,
    borderRadius: 17,
  },
  assignee: {
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 12,
    color: '#000000',
    marginLeft: 4,
  },
  taskDetailContainer: {
    paddingLeft: 23,
    paddingRight: 27,
  },
  ModalContainer: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  popupWindow: {
    backgroundColor: 'white',
    paddingVertical: 20,
    borderRadius: 12,
    paddingHorizontal: 17,
    marginHorizontal: 13,
  },
  popupHeader: {
    alignItems: 'center',
  },
  confirmationTileIcon: {
    width: 70,
    height: 70,
    borderRadius: 16,
    marginTop: 8,
  },
  blueBorderButton: {
    borderRadius: 8,
    borderColor: '#132FF5',
    borderWidth: 1,
    paddingVertical: 10,
    marginTop: 8,
  },
  blueBorderButtonText: {
    color: '#132FF5',
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 16,
    textAlign: 'center',
  },
  blueButtonActive: {
    borderRadius: 8,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
    marginBottom: 10,
  },
  blueButtonText: {
    color: colors.white,
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 18,
    textAlign: 'center',
  },

  blueButton: {
    borderRadius: 8,
    opacity: 0.5,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
    marginBottom: 10,
  },
  script: {
    marginTop: 8,
    marginBottom: 9,
    fontFamily: 'NunitoSans_400Regular',
    textAlign: 'center',
  },
  textBoldLarge: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    color: '#000000',
  },
});
