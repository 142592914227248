export const AssetTypes = [
    {
        title: 'Construction Equipment',
        image: require('../assets/images/constructionIcon.png'),
        id: '63e57368cc63131facd544d5'
    },
    {
        title: 'Fixed Equipment',
        image: require('../assets/images/fixedIcon.png'),
        id: '640a6e39bd47701b56fa127c'
    },
    {
        title: 'Factory Equipment',
        image: require('../assets/images/factoryIcon.png'),
        id: '640a6eb3bd47701b56fa127d'
    }, 
    {
        title: 'Kitchen Equipment',
        image: require('../assets/images/kitchenIcon.png'),
        id: '640a6ecfbd47701b56fa127e'
    }, 
    {
        title: 'Leads / Chargers',
        image: require('../assets/images/leadIcon.png'),
        id: '640a6ef0bd47701b56fa127f'
    }, 
    {
        title: 'Medical Equipment',
        image: require('../assets/images/medicalIcon.png'),
        id: '640a6f21bd47701b56fa1280'
    }, 
    {
        title: 'Office Equipment',
        image: require('../assets/images/officeIcon.png'),
        id: '640a6f47bd47701b56fa1281'
    }, 
    {
        title: 'On-hire Equipment',
        image: require('../assets/images/hireIcon.png'),
        id: '640a6f7cbd47701b56fa1282'
    }, 
    {
        title: 'Plant & Other Equipment',
        image: require('../assets/images/plantIcon.png'),
        id: '640a6f9fbd47701b56fa1283'
    },
    {
        title: 'Residual Current Device',
        image: require('../assets/images/residualIcon.png'),
        id: '640a6fbcbd47701b56fa1284'
    },
    {
        title: 'Switchboards',
        image: require('../assets/images/switchboardIcon.png'),
        id: '640a6fddbd47701b56fa1285'
    },
]