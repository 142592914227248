import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../../../theme/colors';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  loading: {
    height: 8,
    backgroundColor: 'white',
    marginTop: 24,
  },
  loadPercent: {
    height: 8,
    width: '30%',
    backgroundColor: '#132FF5',
  },
  imageDrop: {
    height: 100,
    width: 100,
    borderRadius: 1000,
    backgroundColor: '#ffff',
    shadowColor: '#000',
    shadowOffset: {
      width: 6,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 6,
    elevation: 5,
  },
  image: {
    alignItems: 'center',
  },
  textBoldLarge: {
    fontSize: 18,
    fontFamily: 'NunitoSans_700Bold',
  },
  blueBorderButton: {
    borderRadius: 26,
    borderColor: colors.gray,
    borderWidth: 1,
    paddingVertical: 10,
    marginBottom: 16,
  },
  blueBorderButtonText: {
    color: colors.black,
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 16,
    textAlign: 'center',
  },
  buttonJob: {
    paddingHorizontal: 40,
    width: '100%',
  },
  blueButton: {
    borderRadius: 8,
    opacity: 0.5,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
    marginBottom: 10,
  },
  blueButtonActive: {
    borderRadius: 8,
    backgroundColor: '#132FF5',
    paddingVertical: 10,
    marginBottom: 10,
  },
  blueButtonText: {
    color: colors.white,
    fontFamily: 'NunitoSans_600SemiBold',
    fontSize: 18,
    textAlign: 'center',
  },
  blankButton: {
    paddingVertical: 10,
  },
  blankButtonText: {
    color: '#132FF5',
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    textAlign: 'center',
  },
  assignButton: {
    position: 'absolute',
    bottom: 0,
    paddingHorizontal: 27,
    backgroundColor: 'white',
    width: '100%',
  },
  input: {
    marginTop: 16,
    borderColor: '#C7C7C7',
    width: '100%',
    borderWidth: 1,
    borderRadius: 8,
    paddingVertical: 15,
    paddingLeft: 13,
  },
  textField: {
    paddingHorizontal: 28,
    marginBottom: 140,
  },
  notipush: {
    marginTop: 25,
    backgroundColor: 'rgba(0,0,0,0.1)',
    borderRadius: 16,
    paddingHorizontal: 31,
    paddingVertical: 21,
    alignItems: 'center',
    textAlign: 'center',
  },
  menu: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 20,
    paddingBottom: 19,
    paddingHorizontal: 26,
    alignItems: 'center',
  },
  textBold: {
    fontFamily: 'NunitoSans_700Bold',
  },
  textRegular: {
    textAlign: 'center',
    fontFamily: 'NunitoSans_400Regular',
    marginTop: 16,
  },
  inputHeader: {
    fontFamily: 'NunitoSans_700Bold',
    paddingTop: 20,
  },
  title: {
    paddingTop: 26,
    flexDirection: 'row',
  },
  firstTitle: {
    paddingTop: 55,
    flexDirection: 'row',
  },
  titleText: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 16,
  },
  required: {
    color: '#FF4D4D',
  },
  addReq: {
    marginTop: 13,
    color: '#132FF5',
    fontSize: 16,
    fontFamily: 'NunitoSans_600SemiBold',
  },
  imgPicker: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#C7C7C7',
    paddingHorizontal: 7,
    paddingVertical: 8,
    marginTop: 10,
  },
  dasherBorder: {
    borderStyle: 'dotted',
    borderRadius: 8,
    borderWidth: 2,
    borderColor: '#C7C7C7',
    paddingVertical: 20,
    alignItems: 'center',
  },
  imgIcon: {
    fontSize: 34,
    paddingLeft: 6,
    color: '#C7C7C7',
  },

  imgPicked: {
    paddingVertical: 8,
    paddingLeft: 7,
    backgroundColor: '#8997fa1a',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#132ff5',
    borderRadius: 8,
    flex: 12,
  },
  imgPickedContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  closeIcon: {
    flex: 1,
    fontSize: 29,
    alignSelf: 'center',
    paddingLeft: 17,
    color: '#C7C7C7',
  },
  optional: {
    fontFamily: 'NunitoSans_400Regular',
    color: '#000000',
    fontSize: 14,
    opacity: 0.5,
  },
  uploadText: {
    fontFamily: 'NunitoSans_600SemiBold',
    color: '#000000',
    opacity: 0.5,
    fontSize: 14,
  },
  textBold: {
    fontSize: 18,
    fontFamily: 'NunitoSans_700Bold',
    paddingTop: 8,
  },
  scriptStyle: {
    paddingTop: 8,
    paddingHorizontal: 62,
    textAlign: 'center',
    fontFamily: 'NunitoSans_400Regular',
  },
  wrapper: {
    backgroundColor: `rgba(0,0,0,0.3)`,
    justifyContent: 'flex-end',
    flex: 1,
  },
  modal: {
    backgroundColor: colors.c_ffffff,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    paddingBottom: 30,
  },
  line: {
    height: 5,
    width: 85,
    borderRadius: 8,
    backgroundColor: colors.c_dddddd,
  },
  content: {
    backgroundColor: 'white',
    // padding: 22,
    justifyContent: 'center',
    borderRadius: 4,
    borderColor: 'rgba(0, 0, 0, 0.1)',
  },
  contentTitle: {
    fontSize: 20,
    // marginBottom: 12,
  },
  panel: {
    alignItems: 'center',
    overflow: 'hidden',
    paddingHorizontal: 16,
    height: 55,
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(221, 221, 221, 0.3)',
    backgroundColor: 'white',
  },
  text: {
    fontFamily: 'NunitoSans_400Regular',
    fontWeight: '400',
    fontSize: 16,
    color: colors.black,
  },
  textChecked: {
    fontFamily: 'NunitoSans_400Regular',
    fontWeight: '400',
    fontSize: 16,
    color: colors.mainBlue,
  },
  popupWindow: {
    backgroundColor: 'white',
    paddingHorizontal: 18,
    paddingVertical: 19,
    borderRadius: 12,
    alignItems: 'center',
    marginHorizontal: 26,
    marginBottom: 140,
  },
  popupWindowSuccess: {
    backgroundColor: 'white',
    paddingHorizontal: 27,
    paddingVertical: 20,
    borderRadius: 12,
    alignItems: 'center',
    marginHorizontal: 26,
  },
  popupModal: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  popupIcon: {
    width: 70,
    height: 70,
    borderRadius: 16,
    marginTop: 8,
  },
  popupIconModal: {
    width: 13,
    height: 13,
    borderRadius: 16,
  },
  popupTitle: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    marginTop: 25,
  },
  popupScript: {
    fontFamily: 'NunitoSans_400Regular',
    marginTop: 8,
    textAlign: 'center',
  },
  addModalTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  addModalTitle: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
    color: '#000000',
  },
  image: {
    alignItems: 'center',
  },
  textBoldLarge: {
    fontSize: 18,
    fontFamily: 'NunitoSans_700Bold',
  },
  blueBorderButton: {
    borderRadius: 8,
    borderColor: '#132FF5',
    borderWidth: 1,
    paddingVertical: 10,
    marginBottom: 16,
  },
  createModalButton: {
    marginTop: 20,
    borderWidth: 1,
    borderColor: '#132FF5',
    paddingHorizontal: 30,
  },
  blankButton: {
    paddingVertical: 10,
  },
  blankButtonText: {
    color: '#000000',
    fontFamily: 'NunitoSans_700Bold',
    opacity: 0.5,
    fontSize: 18,
    textAlign: 'center',
  },
  assignButton: {
    position: 'absolute',
    bottom: 10,
    paddingHorizontal: 27,
    backgroundColor: 'white',
    width: '100%',
  },
  input: {
    marginTop: 16,
    borderColor: '#C7C7C7',
    width: '100%',
    borderWidth: 1,
    borderRadius: 8,
    paddingVertical: 15,
    paddingLeft: 13,
  },
  inputSearchDrop: {
    marginTop: 16,
    borderColor: '#C7C7C7',
    width: '100%',
    borderWidth: 1,
    borderRadius: 8,
    paddingVertical: 15,
    paddingHorizontal: 13,
  },
  inputTitle: {
    marginTop: 16,
    borderColor: '#C7C7C7',
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 16,
    width: '100%',
    borderWidth: 1,
    borderRadius: 8,
    paddingVertical: 15,
    paddingLeft: 13,
  },
  textField: {
    paddingHorizontal: 14,
    marginBottom: 20,
    paddingVertical: 16,
  },
  textFieldModal: {
    marginBottom: 14,
    paddingTop: 23,
    width: '100%',
  },
  textBold: {
    fontFamily: 'NunitoSans_700Bold',
  },
  textRegular: {
    textAlign: 'center',
    fontFamily: 'NunitoSans_400Regular',
    marginTop: 16,
  },
  inputHeader: {
    fontFamily: 'NunitoSans_700Bold',
  },
  inputHeaderModal: {
    fontFamily: 'NunitoSans_700Bold',
    marginTop: 14,
  },
  inputDescript: {
    fontFamily: 'NunitoSans_400Regular',
    marginTop: 8,
  },
  title: {
    paddingTop: 15,
    flexDirection: 'row',
    alignItems: 'center',
  },
  firstTitle: {
    flexDirection: 'row',
  },
  titleText: {
    fontFamily: 'NunitoSans_700Bold',
    fontSize: 18,
  },
  required: {
    color: '#FF4D4D',
  },
  // scrollContainer: {
  //   marginTop: Platform.OS == 'ios' ? 0 : 0,
  //   maxHeight: '100%',
  //   paddingBottom: 100,
  // },
});
