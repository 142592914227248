import React, { useEffect, useState, useRef } from 'react';
import { Text, View, ScrollView, Image, TouchableOpacity, Modal, Pressable } from 'react-native';
import { styles } from './style';
import { FaChevronRight } from 'react-icons/fa';

import RBSheet from 'react-native-raw-bottom-sheet';
import images from '../../../theme/images';
import Button from '../../../components/Button';
import SearchScreen from '../../../components/Search';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useIsFocused } from '@react-navigation/native';
import { api } from '../../../utils/api';
import Loading from '../../../components/Loading';
import * as Linking from 'expo-linking';

export default function AdminAssetRegistry({ ...props }) {
  const refRBSheet = useRef();
  const [data, setProjectData] = useState([]);
  const [projectsLoading, setProjectsLoading] = useState(true);
  const [exportLoading, setExportLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalType, setModalType] = useState({
    showModal: false,
    id: null,
    type: null,
  });
  const [companySubscription, setCompanySubscription] = useState(null);

  console.log('images on assert registy: ', images);

  const [hasWriteAccess, setHasWriteAccess] = useState(false);

  const getProjects = async () => {
    var userProjects = [];
    const projects = await api(`v1/project/userprojects`, 'GET', null);
    if (projects.status == 200) {
      if (projects.data.success) {
        let data = projects.data;
        for (let i = 0; i < data.projects.length; i++) {
          const element = data.projects[i];
          let assetCount = element.assetCount;
          let siteCount = element.siteCount;
          const obj = {
            title: element.name,
            detail: `${element.address.street}, ${element.address.city}, ${element.address.state}, ${element.address.country}`,
            id: element._id,
            description1: assetCount == 1 ? `${assetCount} Asset` : `${assetCount} Assets`,
            description2: siteCount == 1 ? `${siteCount} Site` : `${siteCount} Sites`,
            companyid: element.company._id,
            logo: element.logo,
          };
          userProjects.push(obj);
        }
      }
    }

    setProjectData(userProjects);
    setProjectsLoading(false);
  };

  const exportFunction = async (withData = false, projectid = false, type = false) => {
    console.log('project id: ', modalType.id);

    var fileURL = '';
    var fileName = '';

    var pId = projectid == false ? modalType.id : projectid;
    var t = type == false ? modalType.type : type;

    setExportLoading(true);

    const data = await api(`v1/project/${pId}/export?type=${t}&additionalDetails=${withData}`, 'GET', null);
    if (data.status == 200) {
      if (data.data.success) {
        fileURL = data.data.file;
        fileName = data.data.name;
      }
    }
    if (fileURL !== '') {
      console.log(fileName);
      // WebBrowser.openBrowserAsync(fileURL);
      Linking.openURL(fileURL);
      setExportLoading(false);
    }
    setModalShow(false);
  };

  const handleModal = (data) => {
    setModalType({
      type: data.type,
      id: data.id,
    });

    setTimeout(() => {
      setModalShow(true);
    }, 500);
  };

  const getUser = async () => {
    var user = await AsyncStorage.getItem('user');
    user = JSON.parse(user);
    console.log('user: ', user);
    if (user.role === 'PM') {
      setHasWriteAccess(true);
    }
    getCompanySubscription(user.workedWithCompanies[0]);
  };

  const getCompanySubscription = async (company) => {
    const companySubscription = await api(`v1/company/${company}`, 'GET', null);
    console.log('companySubscription: ', companySubscription);
    if (companySubscription.status == 200) {
      if (companySubscription.data.success) {
        let company = companySubscription.data.company;
        setCompanySubscription(company.subscriptionType);
      }
    }
  };

  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      getProjects();
      getUser();
    }
  }, [isFocused]);

  if (exportLoading) {
    return (
      <View style={{ flex: 1, marginTop: 50 }}>
        <Loading opacity={0} />
      </View>
    );
  }

  return (
    <SearchScreen
      body={
        <>
          <ScrollView>
            <View style={styles.body}>
              <Text style={styles.screenName}>Asset Registry</Text>

              <View style={styles.titleContainer}>
                <Text style={styles.title}>Projects ({data.length})</Text>
              </View>
              {projectsLoading && (
                <View style={{ marginTop: 50 }}>
                  <Loading />
                </View>
              )}
              {data.map((item, index) => {
                var itemId = item.id;
                return (
                  <TouchableOpacity
                    onPress={() =>
                      props.navigation.navigate('AdminSiteAssets', {
                        title: item.title,
                        dataAsset: item,
                        admin: true,
                        hasWriteAccess: hasWriteAccess,
                      })
                    }
                    key={index}
                  >
                    <View style={styles.accordionHeader}>
                      <View>
                        <View
                          style={{
                            flexDirection: 'row',
                          }}
                        >
                          <Image style={styles.accordionHeaderIcon} source={{ uri: item.logo }} />
                          <View style={styles.accordionHeaderContent}>
                            <Text style={styles.textBold}>{item.title}</Text>
                            <View style={styles.headerContentDescript}>
                              <Text style={styles.textRegularSmall}>{item.description1}</Text>
                              <Text style={styles.textRegularSmall}>{item.description2}</Text>
                            </View>
                          </View>
                        </View>
                      </View>
                      <View style={styles.export}>
                        <TouchableOpacity
                          onPress={() => {
                            refRBSheet.current.open();
                            setModalType({
                              ...modalType,
                              id: itemId,
                            });
                          }}
                        >
                          <Image
                            style={{
                              width: 28,
                              height: 28,
                            }}
                            source={images.lightGreyDownload}
                          />
                        </TouchableOpacity>
                        <FaChevronRight name="right" style={styles.accordionIcon} />
                      </View>
                      <RBSheet
                        ref={refRBSheet}
                        closeOnDragDown={true}
                        closeOnPressMask={true}
                        height={150}
                        openDuration={250}
                        customStyles={{
                          wrapper: {
                            backgroundColor: 'rgba(0,0,0,0.3)',
                          },
                          container: {
                            borderTopRightRadius: 8,
                            borderTopLeftRadius: 8,
                          },
                        }}
                      >
                        <View
                          style={{
                            alignItems: 'center',
                          }}
                        >
                          <View style={styles.validate}>
                            <TouchableOpacity
                              onPress={() => {
                                refRBSheet.current.close();
                                hasWriteAccess
                                  ? handleModal({
                                      type: 'CSV',
                                      id: modalType.id,
                                    })
                                  : exportFunction(false, modalType.id, 'CSV');
                              }}
                            >
                              <Text
                                style={[
                                  styles.textBoldLarge,
                                  {
                                    paddingVertical: 20,
                                  },
                                ]}
                              >
                                Export as CSV
                              </Text>
                            </TouchableOpacity>
                          </View>
                          <TouchableOpacity
                            onPress={() => {
                              refRBSheet.current.close();
                              exportFunction(false, modalType.id, 'PDF');
                            }}
                          >
                            <Text
                              style={[
                                styles.textBoldLarge,
                                {
                                  paddingVertical: 20,
                                },
                              ]}
                            >
                              Export as PDF
                            </Text>
                          </TouchableOpacity>
                        </View>
                      </RBSheet>
                    </View>
                  </TouchableOpacity>
                );
              })}
            </View>
          </ScrollView>
          {hasWriteAccess && (
            <View style={styles.assignButton}>
              <Button
                title={'+ Add Asset'}
                buttonStyle={styles.blueButtonActive}
                textStyle={styles.blueButtonText}
                onPress={() => {
                  props.navigation.navigate('TabNavigator', {
                    screen: 'Assets',
                    params: {
                      screen: 'AddAssets',
                      fromQRCodeNav: true,
                    },
                    isAdmin: props.route.params.isAdmin,
                  });
                }}
              />
              <Button
                title={'+ Add Project'}
                buttonStyle={[styles.blankButton]}
                textStyle={styles.blankButtonText}
                onPress={() =>
                  props.navigation.navigate('SettingUpProjectStep4', {
                    addNewProject: true,
                    subscription: companySubscription,
                    isAdmin: true,
                  })
                }
              />
            </View>
          )}
          <Modal
            visible={modalShow}
            animationType="fade"
            transparent={true}
            onRequestClose={() => {
              setModalShow(false);
            }}
          >
            <View style={styles.popupModal}>
              <View style={styles.popupWindow}>
                <Pressable style={styles.popupClose} onPress={() => setModalShow(false)}>
                  <Image style={styles.popupCloseIcon} source={images.lightGreyX} />
                </Pressable>
                <Text style={styles.popupHeading}>Export With or Without Additional Asset Information?</Text>
                <View style={styles.buttonSection}>
                  <Pressable style={[styles.popupButton, styles.withoutButton]} onPress={() => exportFunction(false)}>
                    <Text style={styles.withoutButtonText}>Export Without</Text>
                  </Pressable>
                  <Pressable style={[styles.popupButton, styles.withButton]} onPress={() => exportFunction(true)}>
                    <Text style={styles.withButtonText}>Export With</Text>
                  </Pressable>
                </View>
              </View>
            </View>
          </Modal>
        </>
      }
      {...props}
    />
  );
}
