import React, { useState } from 'react';
import { Image, Pressable, ScrollView, Text, View } from 'react-native';
import { AiOutlineCamera, AiOutlineCloseCircle } from 'react-icons/ai';
import { BiSolidAddToQueue } from 'react-icons/bi';
import ModalSelect from '../../../../../components/ModalSelect';
import { styles } from './style';

export default function UploadField({ ...props }) {
  const [image, setImage] = useState(null);
  const [displayModal, setDisplayModal] = useState(false);
  const [listImage, setListImage] = useState([]);
  const openOption = async (value) => {
    // if (value.toLowerCase() == 'camera') {
    //   let permissions = await ImagePicker.getCameraPermissionsAsync();
    //   if (permissions.status == 'undetermined') {
    //     permissions = await ImagePicker.requestCameraPermissionsAsync();
    //   }
    //   if (permissions.granted) {
    //     setTimeout(async () => {
    //       let result = await ImagePicker.launchCameraAsync({
    //         mediaTypes: 'Images',
    //         quality: 0.7,
    //       });
    //       setListImage([...listImage, result.uri]);
    //       setImage(result.uri);
    //       let images = props.selectedImages;
    //       images.push({
    //         type: result.type,
    //         uri: result.uri,
    //         name: result.fileName,
    //       });
    //       props.setImages(images);
    //     }, 500);
    //   }
    // } else if (value.toLowerCase() == 'gallery') {
    //   let permissions = await ImagePicker.getMediaLibraryPermissionsAsync();
    //   if (permissions.status == 'undetermined') {
    //     permissions = await ImagePicker.requestMediaLibraryPermissionsAsync();
    //   }
    //   if (permissions.granted) {
    //     setTimeout(async () => {
    //       let result = await ImagePicker.launchImageLibraryAsync({
    //         allowsEditing: true,
    //         mediaTypes: ImagePicker.MediaTypeOptions.Images,
    //         allowsMultipleSelection: true,
    //       });
    //       setListImage([...listImage, result.assets[0].uri]);
    //       setImage(result.assets[0].uri);
    //       let images = props.selectedImages;
    //       images.push({
    //         type: result.assets[0].type,
    //         uri: result.assets[0].uri,
    //         name: result.assets[0].fileName,
    //       });
    //       props.setImages(images);
    //     }, 500);
    //   }
    // }
  };
  const images = listImage.map((item, idx) => {
    return (
      <View style={styles.imgPicked} key={idx}>
        <Image source={{ uri: item }} style={{ width: 120, height: 120, borderRadius: 4 }} />
        <View style={styles.closeField}>
          <AiOutlineCloseCircle name="close" style={styles.closeIcon} onPress={() => handleDeleteImage(item)} />
        </View>
      </View>
    );
  });
  const handleDeleteImage = (item) => {
    const newList = listImage.filter((element) => element !== item);
    setListImage(newList);
    const selectedNewList = props.selectedImages.filter((element) => element.uri !== item);
    props.setImages(selectedNewList);
  };
  return (
    <View style={styles.pickImage}>
      <Pressable
        onPress={() => {
          setDisplayModal(true);
        }}
      >
        {listImage.length < 1 && (
          <View style={styles.imgPicker}>
            <View style={styles.dasherBorder}>
              <AiOutlineCamera name="camera" style={styles.imgIcon} />
              <Text style={styles.uploadText}>Add a photo</Text>
            </View>
          </View>
        )}
      </Pressable>
      {listImage.length >= 1 && (
        <View style={styles.imgPickedContainer}>
          <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
            <Pressable onPress={() => setDisplayModal(true)}>
              <View style={styles.addMore}>
                <BiSolidAddToQueue name="add" style={styles.addIcon} />
              </View>
            </Pressable>
            {images}
          </ScrollView>
        </View>
      )}
      <ModalSelect
        modalVisible={displayModal}
        setVisible={setDisplayModal}
        setValue={openOption}
        heading="Select Camera or Gallery"
        data={['Gallery']}
      />
    </View>
  );
}
