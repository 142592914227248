import React, { useState } from 'react';
import { Image, ImageBackground, Text, View, Platform } from 'react-native';
import Button from '../../../components/Button';
import images from '../../../theme/images';
import { styles } from './style';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as AuthSession from 'expo-auth-session';
import toQueryString from 'to-querystring';
import { LinearGradient } from 'expo-linear-gradient';
import { api } from '../../../utils/api';
import { StatusBar } from 'expo-status-bar';
const EnvConstants = require('../../../../EnvConstants');

export default function Login({ ...props }) {
  const redirectUrl = AuthSession.makeRedirectUri();
  console.log(redirectUrl);
  const [userPic, setUserPic] = useState('');
  async function getUserInfo(token) {
    let email,
      name,
      lastName,
      mobile,
      picture = '';
    let verified = false;
    const resp = await api('v1/user/getauth0info', 'GET', null);
    if (resp.status == 200) {
      var result = resp.data;
      if (result.success) {
        console.log(result);
        email = result.data.email;
        name = result.data?.given_name;
        lastName = result.data?.family_name;

        if (result.data.user_metadata) {
          if (result.data.user_metadata.firstName) {
            name = result.data.user_metadata.firstName;
          }
          if (result.data.user_metadata.lastName) {
            lastName = result.data.user_metadata.lastName;
          }
          if (result.data.user_metadata.mobile) {
            mobile = result.data.user_metadata.mobile;
          }
        }

        verified = result.data.email_verfied ? result.data.email_verfied : false;

        let formData = new FormData();

        formData.append('email', email);
        formData.append('firstName', name);
        formData.append('lastName', lastName);
        formData.append('mobile', mobile);
        formData.append('emailVerified', verified);
        registerUser(formData, result.data.picture);
      }
    }
  }

  const getUser = async (token = false) => {
    if (token) {
      token = token;
    } else {
      token = await AsyncStorage.getItem('token');
    }

    const resp = await api('v1/user', 'GET', null);
    if (resp.status == 200) {
      var result = resp.data;
      if (result.success) {
        if (result.user == null) {
          getUserInfo(token);
        } else {
          if (result.user) {
            AsyncStorage.setItem('user', JSON.stringify(result.user));
            if ((result.user.role = 'PM')) {
              props.navigation.navigate('TabNavigator', {
                screen: 'Assets',
                isAdmin: true,
                params: {
                  screen: 'AdminAssetRegistry',
                  fromQRCodeNav: true,
                },
              });
            } else {
              props.navigation.navigate('TabNavigator', { screen: 'QrCodeNav', isAdmin: false });
            }
          } else {
            getUserInfo(token);
          }
        }
      } else {
        if (result.error) {
          if (result.error == 'jwt expired') {
            let removeToken = AsyncStorage.removeItem('token');
            let removerefreshToken = AsyncStorage.removeItem('refreshToken');
            props.navigation.navigate('OnboardingNavigation');
          }
        }
      }
    }
  };

  async function registerUser(data, pic) {
    const resp = await api('v1/user', 'POST', data);
    if (resp.status == 200) {
      var result = resp.data;
      if (result.success) {
        console.log('Registered User Pic: ', userPic);
        await AsyncStorage.setItem('user', JSON.stringify(result.user));
        props.navigation.navigate('UserProfile', {
          user: result.user,
          userImage: { uri: pic, type: 'string' },
        });
      }
    }
  }

  const setRefreshToken = async (code) => {
    var options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        grant_type: 'authorization_code',
        client_id: EnvConstants.config.CLIENTID,
        client_secret: EnvConstants.config.SECRET,
        // client_id: "rzTGl0fuSOv388oJz8EDatplTLooTeCP",
        // client_secret: "e74oXStuAs9Elvp6SQXFXtwhBs6Oicde7k8NCfhUGq3sdeyX_Jpy31clXzml-u3z",
        code: code,
        redirect_uri: Platform.OS == 'android' ? redirectUrl : redirectUrl + '/home',
      }),
    };

    const getAuth = fetch(EnvConstants.config.AUTHDOMAIN + '/oauth/token', options)
      .then((res) => res.json())
      .then((result) => {
        if (result.access_token) {
          AsyncStorage.setItem('token', result.access_token);
          AsyncStorage.setItem('refreshToken', result.refresh_token);
          let expiry = moment().add(result.expires_in, 'seconds').format('YYYY-MM-DD HH:mm:ss');
          AsyncStorage.setItem('expiresIn', String(expiry));
        }
      })
      .catch((error) => {
        console.log('Using Code to get Auth');
      });
  };

  const goToAuth0 = async (hint) => {
    console.log('redirect url login', redirectUrl);
    let authUrl =
      `${EnvConstants.config.AUTHDOMAIN}/authorize?` +
      toQueryString({
        client_id: EnvConstants.config.CLIENTID,
        response_type: 'token code',
        scope:
          'openid profile email client_credentials offline_access read:current_user update:users update:current_user_identities create:current_user_metadata update:current_user_metadata delete:current_user_metadata create:current_user_device_credentials delete:current_user_device_credentials',
        redirect_uri: Platform.OS == 'android' ? redirectUrl : redirectUrl + '/home',
        audience: EnvConstants.config.AUDIENCE,
        prompt: 'login',
        screen_hint: hint,
      });

    const result = await AuthSession.startAsync({ authUrl: authUrl });
    if (result.params) {
      const response = result.params;
      // console.log('result', result);
      if (!result.errorCode) {
        await AsyncStorage.setItem('token', result.params.access_token);
        // setRefreshToken(result.params.code);
        console.log(response.access_token);
        getUser(response.access_token);
      }
    } else {
      console.log('Failed Login');
      console.log(result);
    }
  };

  return (
    <View style={styles.container}>
      <StatusBar style="dark" />
      <LinearGradient
        // Background Linear Gradient
        colors={['#4065E0', '#0017FE']}
        style={styles.background}
      />
      <View style={styles.loginLogoText}>
        <Image source={images.clkTagWhiteLogo} style={styles.oneTagLogo} />
      </View>
      <View style={styles.buttonColumn}>
        <Button
          title={'Create Account'}
          buttonStyle={[styles.whiteButton, { marginBottom: 10 }]}
          textStyle={styles.whiteButtonText}
          onPress={() => goToAuth0('signUp')}
        />
        <Button
          title={'Log In'}
          buttonStyle={[styles.whiteBorderButton, { marginBottom: 10 }]}
          textStyle={styles.whiteBorderButtonText}
          onPress={() => goToAuth0('login')}
        />
      </View>
    </View>
  );
}
