import React, { useEffect, useState } from 'react';
import { Image, Pressable, Text, TouchableOpacity, View, Modal, SafeAreaView } from 'react-native';
import images from '../../../../../theme/images';
import { styles } from './style';
import { AiOutlineFilePdf, AiOutlineCloseCircle } from 'react-icons/ai';
import { BsFillPencilFill } from 'react-icons/bs';
import { ScrollView, TextInput } from 'react-native-gesture-handler';
import { useRef } from 'react';
import RBSheet from 'react-native-raw-bottom-sheet';
import Loading from '../../../../../components/Loading';
import { useIsFocused } from '@react-navigation/native';
import moment from 'moment';
import { api } from '../../../../../utils/api';
import Button from '../../../../../components/Button';

function TaskOverView({ ...props }) {
  const params = props.route.params;
  const [isDel, setIsDel] = useState(params.document);
  const refRBSheet = useRef();
  const [loading, setLoading] = useState(true);

  console.log('params: ', params);

  const createTask = async () => {
    const taskData = new FormData();

    var utcDate = String(moment(params.date + ' 00:00:00', 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'));
    taskData.append('project', params.project.id);
    taskData.append('dueDateUTC', utcDate);
    taskData.append('cutOffDateUTC', moment(utcDate, 'YYYY-MM-DD HH:mm:ss').add(1, 'months').utc().format());
    taskData.append('user', params.assign.id);
    taskData.append('taskTitle', params.title);
    taskData.append('taskDescription', params.description);

    if (params.document !== null) {
      taskData.append('taskAttachment', document);
    }

    console.log('Task Data: ', taskData);
    const createTask = await api('v1/task', 'POST', taskData, true);
    if (createTask.status == 200) {
      if (createTask.data.success) {
        const result = createTask.data.newTask;
        console.log('createdTask: ', result);
      }
    } else {
      console.error('createTask error: ', createTask);
    }
    setLoading(false);
  };

  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      createTask();
    }
  }, [isFocused]);

  return (
    <View style={styles.container}>
      <SafeAreaView
        style={{
          flex: 1,
        }}
      >
        <ScrollView>
          <View style={styles.header}>
            <View style={styles.menuContainer}>
              <TouchableOpacity onPress={() => props.navigation.popToTop()}>
                <Text style={{ fontFamily: 'NunitoSans_700Bold' }}>Done</Text>
              </TouchableOpacity>
              <View style={{ alignItems: 'center', maxWidth: 225 }}>
                <Text
                  style={[
                    styles.textBold,
                    {
                      color: '#6B6B6B',
                      textAlign: 'center',
                    },
                  ]}
                >
                  {params.title}
                </Text>
              </View>
              <Image style={styles.appbarTrailingIcon} source={images.logoIconOnlyWhite} />
            </View>
          </View>
          {loading ? (
            <View style={[styles.body, { marginTop: 50 }]}>
              <Loading opacity={0} />
            </View>
          ) : (
            <View style={styles.body}>
              <View style={styles.overview}>
                <Text style={[styles.textBold, { color: '#4A4A4A' }]}>Task Created - Overview</Text>
                <View style={{ marginTop: 18 }}>
                  <View style={styles.overviewDetail}>
                    <Text style={styles.textSmallBold}>Status</Text>
                    <View style={styles.statusContainer}>
                      <View style={[styles.status]}>
                        <Text style={[styles.textTinyBold, { color: '#132FF5' }]}>Open</Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.overviewDetail}>
                    <Text style={styles.textSmallBold}>Project</Text>
                    <View style={styles.overviewInfo}>
                      <Image source={images.blueDrillIcon} style={{ width: 23, height: 23, marginRight: 3 }} />
                      <Text style={styles.textSmallSemibold}>{params.project.title}</Text>
                    </View>
                  </View>
                  <Pressable>
                    <View style={styles.overviewDetail}>
                      <Text style={styles.textSmallBold}>Assignee</Text>

                      <View style={styles.overviewInfo}>
                        <Image source={images.blueDrillIcon} style={{ width: 23, height: 23, marginRight: 3 }} />
                        <Text style={styles.textSmallSemibold}>{params.assign.title}</Text>
                      </View>
                    </View>
                  </Pressable>
                  <View style={styles.overviewDetail}>
                    <Text style={styles.textSmallBold}>Due Date</Text>
                    <TouchableOpacity>
                      <View style={styles.dueDate}>
                        <Text style={[styles.textTinyBold, { color: '#4A4A4A' }]}>{params.date}</Text>
                      </View>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
              <View style={styles.taskDetailContainer}>
                <View style={styles.taskDetailTitle}>
                  <Text style={[styles.textBold, { color: '#4A4A4A' }]}>Details</Text>
                  <Pressable style={styles.penIcon} onPress={() => props.navigation.goBack()}>
                    <BsFillPencilFill name="pen" style={{ fontSize: 13 }}></BsFillPencilFill>
                  </Pressable>
                </View>
                <View style={styles.title}>
                  <Text style={styles.inputHeader}>Task Description</Text>
                  <Text style={styles.required}> *</Text>
                </View>
                <View style={styles.noteField}>
                  <TextInput
                    placeholder="Add a note here…"
                    onChangeText={() => ''}
                    value={params.description}
                    maxLength={600}
                    multiline
                  />
                  <Text style={styles.textCount}>{params.description.length}/600 characters</Text>
                </View>
                {isDel && (
                  <View style={styles.uploadField}>
                    <Text style={styles.inputHeader}>Upload Attachments </Text>
                    <View style={styles.imgPickedContainer}>
                      <View style={styles.documentPicked}>
                        <View style={styles.docTitle}>
                          <AiOutlineFilePdf name="file-pdf-o" style={styles.docIcon} />
                          <Text style={styles.titleTextPicked}>{isDel.name}</Text>
                        </View>
                      </View>
                      <AiOutlineCloseCircle name="close" style={styles.closeIcon} onPress={() => setIsDel(null)} />
                    </View>
                  </View>
                )}
                <Button
                  title={'Done'}
                  buttonStyle={[styles.blueButtonActive, { marginTop: 50, marginRight: 25, marginLeft: 25 }]}
                  textStyle={styles.blueButtonText}
                  onPress={() => {
                    props.navigation.popToTop();
                  }}
                />
              </View>
            </View>
          )}
        </ScrollView>
      </SafeAreaView>
    </View>
  );
}
export default TaskOverView;
