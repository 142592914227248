import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Text, View, Image, TouchableOpacity } from 'react-native';
import { styles } from './style';
import images from '../../../theme/images';
import SearchScreen from '../../../components/Search';
import { FaChevronRight } from 'react-icons/fa';
import 'react-native-gesture-handler';
import BottomSheet, { BottomSheetFlatList } from '@gorhom/bottom-sheet';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { useIsFocused } from '@react-navigation/native';
import moment from 'moment';
import TaskCalendar from './taskCalendar';
import TaskMap from './taskMap';
import { api } from '../../../utils/api';
import { dateStringToUTC } from '../../../utils/dateUtils';
import Loading from '../../../components/Loading';

export default function Tasks({ ...props }) {
  const [text, onChangeText] = useState('');
  const [taskGroups, setTaskGroups] = useState([]);
  const [taskGroupsLoading, setTaskGroupsLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));
  const [selectedProject, setSelectedProject] = useState(null);
  const [snapPoints, setSnapPoint] = useState(useMemo(() => ['35%', '78%'], []));
  const [tab, setTab] = useState(0);
  const sheetRef = useRef(null);

  // variables
  const data = useMemo(
    () =>
      Array(1)
        .fill(0)
        .map((_, index) => `index-${index}`),
    []
  );

  const getTabView = () => {
    if (tab === 1) {
      return (
        <View style={styles.mapView}>
          <TaskMap setSelectedProject={setSelectedProject} />
        </View>
      );
    } else {
      return (
        <View
          style={{
            paddingHorizontal: 8,
          }}
        >
          <TaskCalendar
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            setTaskGroupsLoading={setTaskGroupsLoading}
          />
        </View>
      );
    }
  };

  const getTaskGroups = async () => {
    if (tab === 1 && selectedProject === null) {
      setTaskGroups([]);
      setTaskGroupsLoading(false);
      return;
    }

    const url =
      tab === 0
        ? `v1/task/group/calendar?selected_date_utc=${dateStringToUTC(selectedDate)}`
        : `v1/task/group/map?selected_project=${selectedProject}&timezone=${
            Intl.DateTimeFormat().resolvedOptions().timeZone
          }`;

    const response = await api(url, 'GET', null);
    if (response.status === 200 && response.data.success) {
      const groupsArr = [];
      for (const groupId in response.data.groups) {
        const group = response.data.groups[groupId];

        if (group.type === 'manual' && group.task.status === 'Pending') {
          groupsArr.push(group);
        } else {
          if (group.complete < group.total) {
            const groupInfo = {
              type: 'auto',
              project: group.project,
              name: `Test ${group.total} Asset(s)`,
              complete: `${group.complete}/${group.total} Complete`,
            };
            if (tab === 1) groupInfo.dueDateUTC = group.dueDateUTC;
            groupsArr.push(groupInfo);
          }
        }
      }

      setTaskGroups(groupsArr);
    }

    setTaskGroupsLoading(false);
  };

  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      getTaskGroups();
    }
  }, [isFocused, tab, selectedDate, selectedProject]);

  const renderTaskGroup = (item, index) => {
    return (
      <TouchableOpacity
        key={index}
        onPress={() =>
          props.navigation.navigate('TaskList', {
            ...item,
            dueDateUTC: tab === 0 ? dateStringToUTC(selectedDate) : item.dueDateUTC,
          })
        }
      >
        <View style={styles.taskBox}>
          <View>
            <View style={styles.boxHeader}>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: '70%',
                }}
              >
                <Text style={styles.textBold}>{item.project.name}</Text>
                <FaChevronRight name="right" style={styles.showMoreIcon} />
              </View>
              <Text>{item.complete}</Text>
            </View>
            {tab === 0 ? (
              <View style={styles.boxContent}>
                <Image
                  style={{
                    width: 22,
                    height: 22,
                    borderRadius: 11,
                    marginRight: 3,
                  }}
                  source={{ uri: item.project.logo }}
                />
                <Text>{item.project.address.street}</Text>
              </View>
            ) : (
              <View style={styles.boxContent}>
                <Text>
                  Due {moment(item.dueDateUTC).fromNow() + ' - ' + moment(item.dueDateUTC).format('DD/MM/YYYY')}
                </Text>
              </View>
            )}
          </View>
        </View>
      </TouchableOpacity>
    );
  };

  const renderManualTask = (item, index) => {
    console.log(item);
    return (
      <View key={index} style={styles.taskBox}>
        <View>
          <View style={styles.boxHeader}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                width: '55%',
              }}
            >
              <Text style={styles.textBold}>{item.task.taskTitle}</Text>
            </View>
            <Text>{item.complete}</Text>
          </View>
          <Text style={styles.taskDesc}>{item.task.taskDescription}</Text>
          {tab === 0 ? (
            <View style={styles.boxContent}>
              <Image
                style={{
                  width: 22,
                  height: 22,
                  borderRadius: 11,
                  marginRight: 3,
                }}
                source={{ uri: item.project.logo }}
              />
              <Text>{item.project.address.street}</Text>
            </View>
          ) : (
            <View style={styles.boxContent}>
              <Text>
                Due{' '}
                {moment(item.task.dueDateUTC).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).fromNow() +
                  ' - ' +
                  moment(item.task.dueDateUTC)
                    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                    .format('DD/MM/YYYY')}
              </Text>
            </View>
          )}
        </View>
      </View>
    );
  };

  const renderTaskGroups = () => {
    return (
      <View style={styles.bottomSheet}>
        {tab === 1 && <Text style={styles.taskTitle}>{taskGroups[0].project.address.street}</Text>}
        {taskGroups.map((item, index) => {
          return item.type === 'manual' ? renderManualTask(item, index) : renderTaskGroup(item, index);
        })}
      </View>
    );
  };

  const updateTab = (tabIndex) => {
    setSelectedDate(moment().format('YYYY-MM-DD'));
    setSelectedProject(null);
    setTab(tabIndex);
  };

  return (
    <SearchScreen
      body={
        <GestureHandlerRootView style={{ flex: 1 }}>
          <View style={styles.body}>
            <View style={styles.title}>
              <Text
                style={[
                  styles.textBoldLarge,
                  {
                    color: '#6B6B6B',
                  },
                ]}
              >
                Your Tasks
              </Text>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <TouchableOpacity onPress={() => updateTab(0)}>
                  <Image
                    style={[styles.calendarViewIcon, tab === 0 ? { tintColor: 'black' } : {}]}
                    source={images.calendarViewIcon}
                  />
                </TouchableOpacity>
                <TouchableOpacity onPress={() => updateTab(1)}>
                  <Image
                    style={[styles.calendarViewIcon, tab === 1 ? { tintColor: 'black' } : {}]}
                    source={images.locationSmallWhiteIcon}
                  />
                </TouchableOpacity>
              </View>
            </View>
            {getTabView()}
          </View>
          {taskGroupsLoading ? (
            <View style={{ marginTop: 50 }}>
              <Loading opacity={0} />
            </View>
          ) : (
            taskGroups.length > 0 && (
              // <BottomSheet
              //   ref={sheetRef}
              //   snapPoints={snapPoints}
              //   enableHandlePanningGesture={true}
              //   enablePanDownToClose={false}
              //   handleStyle={{
              //     backgroundColor: '#F6F6F6',
              //     borderTopLeftRadius: 16,
              //     borderTopRightRadius: 16,
              //   }}
              // >
              //   <BottomSheetFlatList data={data} keyExtractor={(i) => i} renderItem={renderTaskGroups} />
              // </BottomSheet>
              <></>
            )
          )}
        </GestureHandlerRootView>
      }
      {...props}
    />
  );
}
