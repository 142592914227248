import React from 'react';
import { Image, Text, View } from 'react-native';
import { MdKeyboardControlKey, MdKeyboardArrowDown } from 'react-icons/md';
import { styles } from './style';

const AccordionHeader = ({ ...props }) => {
  const { project, image, taskCount } = props;

  return (
    <View style={styles.taskTitleContainer}>
      <View style={styles.taskInfo}>
        {image && <Image source={image} style={[styles.taskIcon, { marginRight: 8 }]} />}

        <View>
          <Text style={styles.taskTitle}>{project.name}</Text>
          <Text style={styles.tasks}>{taskCount} Tasks</Text>
        </View>
      </View>
      {props.active ? (
        <MdKeyboardControlKey name="keyboard-arrow-up" style={{ fontSize: 20 }} />
      ) : (
        <MdKeyboardArrowDown name="keyboard-arrow-down" style={{ fontSize: 20 }} />
      )}
    </View>
  );
};

export default AccordionHeader;
