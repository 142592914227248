import React, { useState, useEffect, useCallback } from 'react';
import { Pressable, View } from 'react-native';
import { Text } from 'react-native';
import SearchBar from '../SearchBar';
import SearchResult from './SearchResult';
import { styles } from './style';
import { debounce } from 'lodash';
import { api } from '../../utils/api';
import { useIsFocused } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Loading from '../Loading';

function SearchScreen({ ...props }) {
  const [text, setText] = useState('');
  const [viewOptions, setViewOptions] = useState('Assets');
  const [assetResult, setAssetResult] = useState([]);
  const [taskResult, setTaskResult] = useState([]);
  const [projectResult, setProjectResult] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(false);

  const getUser = async () => {
    var user = await AsyncStorage.getItem('user');
    user = JSON.parse(user);
    if (user.role === 'PM') {
      setIsAdmin(true);
    }
  };

  const isFocused = useIsFocused();
  useEffect(() => {
    getUser();
  }, [isFocused]);

  const debouncedGetSearchResults = useCallback(
    debounce(async (searchString) => {
      if (searchString.length > 2) {
        setLoading(true);
        const response = await api(`v1/asset/search/?search=${searchString}`, 'GET', null);

        if (response.status === 200 && response.data.success) {
          const results = response.data.results;
          setAssetResult(results.assets);
          setTaskResult(results.tasks);
          if (results.projects) {
            setProjectResult(results.projects);
          }
        }
        setLoading(false);
      } else {
        setAssetResult([]);
        setTaskResult([]);
        setProjectResult([]);
        setLoading(false);
      }
    }, 300),
    []
  );

  const body = () => {
    switch (viewOptions) {
      case 'Assets':
        return <SearchResult isAdmin={isAdmin} data={assetResult} type="assets" {...props} />;
      case 'Tasks':
        return <SearchResult isAdmin={isAdmin} data={taskResult} type="tasks" {...props} />;
      case 'Projects':
        return <SearchResult isAdmin={isAdmin} data={projectResult} type="projects" {...props} />;
    }
  };
  const handleSearch = (text) => {
    setText(text);
    debouncedGetSearchResults(text);
  };

  const searchResult = (
    <View style={styles.body}>
      <View style={styles.viewOptionsContainer}>
        <Pressable onPress={() => setViewOptions('Assets')}>
          <View>
            <Text style={viewOptions == 'Assets' ? styles.blueBoldText : styles.grayBoldTextView}>
              Assets ({assetResult?.length})
            </Text>
            {viewOptions == 'Assets' && <View style={[styles.activeBorder, { width: '90%' }]}></View>}
          </View>
        </Pressable>
        <Pressable onPress={() => setViewOptions('Tasks')}>
          <View>
            <Text style={viewOptions == 'Tasks' ? styles.blueBoldText : styles.grayBoldTextView}>
              Tasks ({taskResult.length})
            </Text>
            {viewOptions == 'Tasks' && <View style={[styles.activeBorder, { width: '90%' }]}></View>}
          </View>
        </Pressable>
        {isAdmin && (
          <Pressable onPress={() => setViewOptions('Projects')}>
            <View>
              <Text style={viewOptions == 'Projects' ? styles.blueBoldText : styles.grayBoldTextView}>
                Projects ({projectResult.length})
              </Text>
              {viewOptions == 'Projects' && <View style={[styles.activeBorder, { width: '90%' }]}></View>}
            </View>
          </Pressable>
        )}
      </View>
      <View style={{ marginTop: 25 }}>{body()}</View>
    </View>
  );
  return (
    <View style={styles.container}>
      <View style={styles.header}>
        {text && (
          <Pressable onPress={() => setText('')}>
            <View style={{ paddingHorizontal: 25, paddingBottom: 12 }}>
              <Text style={styles.textBold}>Cancel</Text>
            </View>
          </Pressable>
        )}
        <SearchBar onChangeText={(text) => handleSearch(text)} text={text} placeholder="Start typing..." />
      </View>
      {loading && <Loading opacity={0} />}
      {text == '' ? props.body : searchResult}
    </View>
  );
}

export default SearchScreen;
