import React, { useEffect, useState,useRef } from "react";
import {
  Platform,
  SafeAreaView,
  ScrollView,
  Text,
  TextInput,
  View,
} from "react-native";
import Button from "../../../../../components/Button";
import NavigationHeader from "../../../../../components/SignUp/SingUpNavigation";
import { styles } from "./style";
import UploadField from "../../../../../components/UpdateDocument";


function TaskInfo({ ...props }) {
  const [taskTitle, setTaskTile] = useState("");
  const [descrTitle, setDescrTile] = useState("");
  const [document, setDocument] = useState(null);
  const [fieldsValidated, setFieldsValidated] = useState(false);
  useEffect(() => {
    if (taskTitle.length > 5 && descrTitle.length > 5) {
      setFieldsValidated(true);
    }
  }, [taskTitle, descrTitle]);
  return (
    <View style={styles.container}>
      <SafeAreaView
        style={{
          flex: 1,
        }}
      >
        <ScrollView keyboardShouldPersistTaps="always">
          <View
            style={
              Platform.OS === "ios"
                ? [
                    styles.loading,
                    {
                      marginTop: 0,
                    },
                  ]
                : styles.loading
            }
          >
            <View style={styles.loadPercent}></View>
          </View>
          <NavigationHeader
            onPress={() => props.navigation.goBack()}
            header={"Add A Task"}
            navigate={"Cancel"}
            menuStyles={styles.menu}
          />
          <View style={styles.form}>
            <View style={styles.textField}>
              <View style={styles.title}>
                <Text style={styles.inputHeader}>Task Title</Text>
                <Text style={styles.required}> *</Text>
              </View>

              <TextInput
                placeholder="Technician"
                style={styles.inputTitle}
                onChangeText={(text) => {
                  setTaskTile(text);
                }}
                value={taskTitle}
              />

              <View style={styles.title}>
                <Text style={styles.inputHeader}>Task Description</Text>
                <Text style={styles.required}> *</Text>
              </View>
              <View style={styles.noteField}>
                <TextInput
                  placeholder="Add a note here…"
                  onChangeText={(text) => setDescrTile(text)}
                  value={descrTitle}
                  maxLength={600}
                  multiline
                />
                <Text style={styles.textCount}>
                  {descrTitle.length}/600 characters
                </Text>
              </View>
              <View style={styles.title}>
                <Text style={styles.inputHeader}>Upload Attachment</Text>
              </View>
              <UploadField document={document} setDocument={setDocument}/>
            </View>
          </View>
          <View style={styles.assignButton}>
          <Button
            title={"Next"}
            buttonStyle={
              fieldsValidated ? styles.blueButtonActive : styles.blueButton
            }
            textStyle={styles.blueButtonText}
            onPress={() => {
              fieldsValidated
                ? props.navigation.navigate("TaskOverView",{
                  title:taskTitle,
                  description: descrTitle,
                  document: document,
                  assign: props.route.params.assign,
                  project: props.route.params.project,
                  site: props.route.params.site,
                  date: props.route.params.date,
                  // setDocument:setDocument()
                })
                : ""
            }}
          />
          <Button
            title={"Previous"}
            buttonStyle={[styles.blankButton]}
            textStyle={styles.blankButtonText}
            onPress={() => {
              props.navigation.goBack();
            }}
          />
        </View>
        </ScrollView>
        
      </SafeAreaView>
    </View>
  );
}
export default TaskInfo;
